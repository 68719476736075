import { getNewOrder } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationUpdateUser,
	mutationUpdateLearning,
} from 'graphql/customMutations';
import update from 'immutability-helper';

/**
 * dropInChapter moves dragged learning/chapter to dropped chapter.
 *
 * @param {Object}
 */
const dropInChapter = async ({
	type,
	learningID,
	newWithinChapterID,
	withinChapterID,
	user,
	setUser,
	setJourney,
}) => {
	// 1. Update to new chapterID in database
	const updateLearningInput = {
		learningID,
		withinChapterID: newWithinChapterID,
	};
	try {
		await API.graphql(
			graphqlOperation(mutationUpdateLearning, { input: updateLearningInput })
		);
	} catch (error) {
		console.log('Error updating withinChapterID');
		return;
	}

	// 2. Update to new order in database for learning or chapter order
	const updateUserInput = {
		userID: user.userID,
	};
	let newOrderSuccess = false;
	const prevOrder =
		type === 'learning' ? user.learningOrder : user.chapterOrder;
	// Delete learning/chapter in current ChapterID
	const [tempOrder, tempSuccess] = getNewOrder(
		prevOrder,
		withinChapterID,
		learningID,
		'delete'
	);
	if (tempSuccess) {
		// Add learingID to newWithinChapterID
		const [finalOrder, finalSuccess] = getNewOrder(
			tempOrder,
			newWithinChapterID,
			learningID
		);
		newOrderSuccess = finalSuccess;
		if (type === 'learning') updateUserInput.learningOrder = finalOrder;
		else updateUserInput.chapterOrder = finalOrder;
	}

	let newUser;
	if (newOrderSuccess) {
		try {
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input: updateUserInput })
			);
			newUser = newUserProfile.data.updateUser;
		} catch (error) {
			console.log('Error updating learning/chapter order: ', error);
			// ignore error
		}
	}

	// 3. Update learning/chapter locally
	//    Delete it in current chapterID, add it to newChpaterID if exists
	setJourney((prev) => {
		const key = `${user.userID}-${withinChapterID}`;
		const idx = prev[key].findIndex((item) => {
			const itemID = item.parentLearningID || item.learningID;
			return itemID === learningID;
		});
		const update1 = update(prev, {
			[key]: { $splice: [[idx, 1]] },
		});
		const newKey = `${user.userID}-${newWithinChapterID}`;
		if (!prev[newKey]) return update1;
		return update(update1, { $unset: [newKey] });
	});

	// 4. Update user order locally
	if (newUser) {
		setUser((prev) => update(prev, { $merge: newUser }));
	}
};

export default dropInChapter;
