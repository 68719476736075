import { useEffect, useRef } from 'react';
import { useUser } from 'utils';
import update from 'immutability-helper';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetCommentByReceiverId } from 'graphql/customQueries';
import { GENERAL_ERROR_MSG } from 'utils/constants';

/**
 * useGetCommentsByReceiverID hook fetches inbox comments.
 * It also refetches new comments when user scrolls to bottom.
 *
 * @param {Object}
 */
const useGetCommentsByReceiverID = ({
	isBottom,
	setIsBottom,
	setComments,
	setError,
}) => {
	const [user] = useUser();
	const isFirstFetch = useRef(true);
	const nextToken = useRef(null);
	const loading = useRef(false);

	useEffect(() => {
		// Return if not login
		if (!user.isLogin) return;
		// Return if no userID
		if (!user.userID) return;

		const variables = {
			receiverID: user.userID,
		};

		// If not first fetch, refetch with nextToken
		// Return if nextToken is null or not at bottom
		if (!isFirstFetch.current) {
			if (!nextToken.current) return;
			if (!isBottom) return;
			variables.nextToken = nextToken.current;
		}

		const getCommentsByReceiverID = async () => {
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetCommentByReceiverId, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const commentsItem = res.data.getCommentByReceiverID.items;
				setComments((prev) => update(prev, { $push: commentsItem }));
				nextToken.current = res.data.getCommentByReceiverID.nextToken;
				setIsBottom(false);
				if (isFirstFetch.current) isFirstFetch.current = false;
				// Empty message
				if (isFirstFetch.current && commentsItem.length === 0) {
					setError('Inbox is currently empty😉');
				}
			} catch (error) {
				console.log('Error getting comments: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};

		loading.current = true;
		getCommentsByReceiverID();
		loading.current = false;
	}, [user.isLogin, user.userID, isBottom, setIsBottom, setComments, setError]);
};

export default useGetCommentsByReceiverID;
