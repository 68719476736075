import { getYearsToCurrentYear } from 'components/Home/ScholarPortal/MonthlyTask/utils';
import { getNumMonthsActivatedByYear } from 'components/Home/ScholarPortal/utils';
import ScholarBadge from './ScholarBadge';
export default ScholarBadge;

export const BADGE_TYPE = {
	BRONZE: 'BRONZE',
	SILVER: 'SILVER',
	GOLD: 'GOLD',
	BEGINNER: 'BEGINNER',
};

/**
 * Get an array of badges for scholar from current year to 2021.
 */
export const getScholarBadges = (scholarMeta) => {
	const years = getYearsToCurrentYear().reverse();
	const badges = [];
	for (let year of years) {
		let type = '';
		const numMonths = getNumMonthsActivatedByYear(scholarMeta, year);
		if (numMonths >= 6) type = BADGE_TYPE.GOLD;
		else if (numMonths >= 4) type = BADGE_TYPE.SILVER;
		else if (numMonths >= 2) type = BADGE_TYPE.BRONZE;
		if (type) badges.push({ year, type });
	}
	// If badges are empty but is scholar, return a beginner badge
	if (badges.length === 0 && scholarMeta.blueBadge) {
		badges.push({ type: BADGE_TYPE.BEGINNER });
	}
	return badges;
};
