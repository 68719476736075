import React from 'react';
import styles from './LearningPanelTop.module.css';
import classNames from 'classnames';
import { ExternalLinkSvg } from 'icons';
import { getTagList } from 'utils';

function LearningPanelTop(props) {
	const { tag, linkInfo = '{}' } = props;
	// Display max 3 tags
	const tagList = getTagList(tag).slice(0, 3);
	const displayTagList = tagList.length > 0;

	// Extract linkInfoObj
	let linkInfoObj = {};
	try {
		if (linkInfo) linkInfoObj = JSON.parse(linkInfo);
	} catch (error) {
		console.log('Error parsing linkInfo: ', error);
	}
	const { link, ogLink } = linkInfoObj;
	const finalLink = ogLink || link;
	const displayLink = !!finalLink;

	if (!displayTagList && !displayLink) return null;

	return (
		<header className={styles.header}>
			{displayTagList && <TagList tagList={tagList} />}
			{displayLink && <ExternalLink link={finalLink} />}
		</header>
	);
}

function TagList({ tagList }) {
	const lastTagIdx = tagList.length - 1;
	return (
		<div className={styles.tagList}>
			{tagList.map((item, idx) => (
				<span
					key={idx}
					className={classNames(styles.tag, {
						[styles.lastTag]: idx === lastTagIdx,
					})}
				>
					{item}
				</span>
			))}
		</div>
	);
}

function ExternalLink({ link }) {
	let displayLink = link;
	const MAX_LINK_LENGTH = 45;
	if (link.length > MAX_LINK_LENGTH)
		displayLink = `${link.slice(0, MAX_LINK_LENGTH)}...`;

	return (
		<a
			href={link}
			target={'_blank'}
			rel={'noopener noreferrer nofollow ugc'}
			className={styles.link}
		>
			{displayLink}
			<ExternalLinkSvg className={styles.linkSvg} />
		</a>
	);
}

export default LearningPanelTop;
