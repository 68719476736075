/**
 * Return an object that contains profileURL and profileURLIdx.
 *
 * @param {String} fullProfileURL
 * @returns {Object}
 * @example
 * // returns {profileURL : "xitang", profileURLIdx : 5}
 * getProfileURLAndIdx("xitang-5")
 */
const getProfileURLAndIdx = (fullProfileURL) => {
	try {
		if (typeof fullProfileURL !== 'string')
			throw new Error('fullProfileURL must be a string');

		const str = fullProfileURL.toLowerCase();
		let profileURL = str;
		let profileURLIdx = 1;
		const numberStartIdx = str.lastIndexOf('-');
		const URLIdx = parseInt(str.slice(numberStartIdx + 1), 10);
		if (Number.isFinite(URLIdx)) {
			profileURL = str.slice(0, numberStartIdx);
			profileURLIdx = URLIdx;
		}
		return { profileURL, profileURLIdx };
	} catch (error) {
		console.log('Error: ', error);
		return {};
	}
};

export default getProfileURLAndIdx;
