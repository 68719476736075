import React from 'react';
import PrimaryButton from 'components/Button';
import { useUser } from 'utils';
import styles from './DownloadVolunteeringCertificate.module.css';
import { useHistory } from 'react-router';

function DownloadVolunteeringCertificate({ learningsWrite, year }) {
	const [user] = useUser();
	const history = useHistory();
	const numLearnings = learningsWrite.filter((item) =>
		item.createdAt.startsWith(year)
	).length;

	const handleOnClick = () => {
		if (numLearnings < 2) return;
		history.push(`/scholar/volunteer/${year}/${user.userID}`);
	};
	return (
		<div className={styles.volunteeringCertificate}>
			<PrimaryButton
				children={'Download Certificate'}
				onClick={handleOnClick}
				disable={numLearnings < 2}
			/>
		</div>
	);
}

export default DownloadVolunteeringCertificate;
