import React from 'react';
import styles from './ResourcePanel.module.css';
import classNames from 'classnames';

function ResourcePanel({ resource }) {
	const { name, img, description, link, tagList } = resource;
	return (
		<a
			className={styles.resourcePanelLink}
			href={link}
			target={'_blank'}
			rel={'noopener noreferrer nofollow'}
		>
			<div className={styles.resourcePanel}>
				<TagList tagList={tagList} />
				<div className={styles.resourcePanelBottom}>
					<div>
						<img src={img} className={styles.img} alt={name} />
					</div>
					<div className={styles.rightDiv}>
						<div className={styles.name}>{name}</div>
						<div>{description}</div>
					</div>
				</div>
			</div>
		</a>
	);
}

function TagList({ tagList }) {
	const lastTagIdx = tagList.length - 1;
	return (
		<div className={styles.tagList}>
			{tagList.map((item, idx) => (
				<span
					key={idx}
					className={classNames(styles.tag, {
						[styles.lastTag]: idx === lastTagIdx,
					})}
				>
					{item}
				</span>
			))}
		</div>
	);
}

export default ResourcePanel;
