import React from 'react';
import styles from './ButtonGroup.module.css';
import { EditSvg } from 'icons';
import { Link } from 'react-router-dom';
import { useUser } from 'utils';
import ReadButton, { HelpfulButton, InterestingButton } from './ReadButton';
import SaveButton from './SaveButton';
import ShareButton from './ShareButton';

function ButtonGroup(props) {
	const { connectUser } = props;
	const { userID: authorID } = connectUser || {};
	const [user] = useUser();
	const isAuthor = user.userID === authorID;

	return (
		<div className={styles.buttonGroup}>
			{!isAuthor && (
				<div className={styles.groupOne}>
					<HelpfulButton {...props} />
					<InterestingButton {...props} />
					<ReadButton {...props} />
				</div>
			)}
			<div className={styles.groupTwo}>
				{!isAuthor && <SaveButton {...props} />}
				<ShareButton />
				{isAuthor && <EditButton {...props} />}
			</div>
		</div>
	);
}

function EditButton(props) {
	const { learningID } = props;
	const linkToEdit = `/edit?learning=${learningID}`;
	return (
		<Link className={styles.button} to={linkToEdit}>
			<div className={styles.buttonSvgS}>
				<EditSvg />
			</div>
			<span>Edit</span>
		</Link>
	);
}

export default ButtonGroup;
