import React from 'react';
import styles from './TopSection.module.css';
import DropdownInput from 'components/DropdownInput';
import update from 'immutability-helper';
import Tag from './Tag';
import LinkInfo from './LinkInfo';

function TopSection(props) {
	return (
		<section className={styles.topSection}>
			<Source {...props} />
			<Tag {...props} />
			<LinkInfo {...props} />
		</section>
	);
}

export const SOURCE_LIST = [
	'Learning Note',
	'Reflection Story',
	'Project',
	'Tutorial',
	'Website',
	'Video',
	'Book',
	'Podcast',
	// 'Music',
	// 'Image',
	'Other',
];

function Source({ newLearning, setNewLearning }) {
	const { source } = newLearning;
	const changeSource = (event) => {
		const newSource = event.currentTarget.name;
		setNewLearning((prev) => update(prev, { source: { $set: newSource } }));
	};
	return (
		<div>
			<h1 className={styles.sourceH1}>Source</h1>
			<DropdownInput
				list={SOURCE_LIST}
				showIcon={true}
				value={source}
				onClick={changeSource}
			/>
		</div>
	);
}

export default TopSection;
