import { useEffect, useRef } from 'react';
import { useUser } from 'utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetReadsByUserId } from 'graphql/customQueries';
import update from 'immutability-helper';

/**
 * useGetReads hook fetches and sets reads of profile user.
 * It also refetches new reads when user scrolls to bottom.
 *
 * @param {Object}
 */
const useGetReads = ({
	profile,
	isBottom,
	setIsBottom,
	setReads,
	setError,
}) => {
	const [user] = useUser();
	const isFirstFetch = useRef(true);
	const nextToken = useRef(null);
	const loading = useRef(false);

	useEffect(() => {
		// Skip if user is loading
		if (user.isLoading) return;

		// Skip if profile.userID is empty
		if (!profile.userID) return;

		const variables = {
			userID: profile.userID,
			limit: 100,
		};

		// If not first fetch, refetch with nextToken
		// Return if nextToken is null or not at bottom
		if (!isFirstFetch.current) {
			if (!nextToken.current) return;
			if (!isBottom) return;
			variables.nextToken = nextToken.current;
		}

		const getReadsByUserID = async () => {
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetReadsByUserId, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const readsItem = res.data.getReadByUserID.items;
				setReads((prev) => update(prev, { $push: readsItem }));
				nextToken.current = res.data.getReadByUserID.nextToken;
				setIsBottom(false);
				if (isFirstFetch.current) isFirstFetch.current = false;
			} catch (error) {
				console.log('Error getting reads: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};

		loading.current = true;
		getReadsByUserID();
		loading.current = false;
	}, [
		user.isLoading,
		isBottom,
		setIsBottom,
		profile.userID,
		setReads,
		setError,
		user.isLogin,
	]);
};

export default useGetReads;
