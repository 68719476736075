import { useRef } from 'react';
import { getLinkInfo, isUrl, useInterval } from 'utils';
import update from 'immutability-helper';

/**
 * useGetLinkCard hook automatically calls API Endpoint to retrieve link info.
 *
 * @param {Object}
 */
const useGetLinkCard = ({ linkInfo, setNewLearning }) => {
	const apiCall = useRef({
		firstAttempt: true,
		loading: false,
		lastRetrievedLink: linkInfo.link,
	});
	// Run every 1s
	const intervalTime = 1000;
	useInterval(async () => {
		const link = linkInfo.link;
		if (!isUrl(link)) return;
		// Return if no change to link
		if (link === apiCall.current.lastRetrievedLink) return;
		// Return if still loading
		if (apiCall.current.loading) return;

		// Call API
		apiCall.current.loading = true;
		let linkInfoRes = await getLinkInfo(link);

		// Retry if there is an error at firstAttempt (b/c of lambda coldstart)
		if (apiCall.current.firstAttempt && linkInfoRes.error) {
			linkInfoRes = await getLinkInfo(link);
		}
		const { result, error } = linkInfoRes;
		if (!error) {
			const newLinkInfo = { displayLinkCard: true };
			const { ogUrl, ogVideo, ogImage, ogTitle, ogDescription } = result;
			if (ogUrl) newLinkInfo.ogLink = ogUrl;
			if (ogVideo && ogVideo.url) newLinkInfo.videoLink = ogVideo.url;
			if (ogImage && ogImage.url) newLinkInfo.imageLink = ogImage.url;
			if (ogTitle) newLinkInfo.title = ogTitle;
			if (ogDescription) newLinkInfo.description = ogDescription;
			setNewLearning((prev) => {
				const removePrevLinkCard = update(prev, {
					linkInfo: {
						$unset: [
							'displayLinkCard',
							'ogLink',
							'videoLink',
							'imageLink',
							'title',
							'description',
						],
					},
				});
				return update(removePrevLinkCard, {
					linkInfo: { $merge: newLinkInfo },
				});
			});
		}
		apiCall.current.firstAttempt = false;
		apiCall.current.loading = false;
		apiCall.current.lastRetrievedLink = link;
	}, intervalTime);
};

export default useGetLinkCard;
