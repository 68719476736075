const sortWithUpdatedAt = (arr = []) => {
	if (arr.length === 0) return [];
	const newArr = [...arr];
	newArr.sort((b, a) => {
		if (a.updatedAt < b.updatedAt) {
			return -1;
		}
		if (a.updatedAt > b.updatedAt) {
			return 1;
		}
		return 0;
	});
	return newArr;
};

export default sortWithUpdatedAt;
