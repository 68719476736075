import { useEffect } from 'react';

/**
 * useCloseDropdown hook is activated when a dropdown/modal opens (isOpen is true).
 * It automatically closes dropdown/modal (by calling setIsOpen with false) when
 * user clicks outside of node or when escape key is pressed.
 *
 * @param {Object} {node, isOpen, setIsOpen, mouse, callback}
 */

const useCloseDropdown = ({
	node,
	isOpen,
	setIsOpen,
	mouse = 'mousedown',
	callback,
}) => {
	useEffect(
		() => {
			const handleClick = (event) => {
				const target = event.target;
				if (node && node.current.contains(target)) {
					return;
				}
				if (callback) callback();
				setIsOpen(false);
			};
			const handleKeyDown = (event) => {
				if (event.key === 'Escape') {
					event.preventDefault();
					if (callback) callback();
					setIsOpen(false);
				}
			};
			if (isOpen) {
				document.addEventListener(mouse, handleClick);
				document.addEventListener('keydown', handleKeyDown);
			} else {
				document.removeEventListener(mouse, handleClick);
				document.removeEventListener('keydown', handleKeyDown);
			}
			return () => {
				document.removeEventListener(mouse, handleClick);
				document.removeEventListener('keydown', handleKeyDown);
			};
		},
		//eslint-disable-next-line
		[node, isOpen, setIsOpen]
	);
};

export default useCloseDropdown;
