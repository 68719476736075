import {
	MonthMaySvg,
	MonthJunSvg,
	MonthJulSvg,
	MonthAugSvg,
	MonthSepSvg,
	MonthOctSvg,
	MonthNovSvg,
	MonthDecSvg,
	MonthJanSvg,
	MonthFebSvg,
	MonthMarSvg,
	MonthAprSvg,
} from 'icons';
import { getCurrentMonth } from '../utils';
function MonthSvg(props) {
	const currentMonth = getCurrentMonth(true);
	switch (currentMonth) {
		case 'Jan':
			return <MonthJanSvg {...props} />;
		case 'Feb':
			return <MonthFebSvg {...props} />;
		case 'Mar':
			return <MonthMarSvg {...props} />;
		case 'Apr':
			return <MonthAprSvg {...props} />;
		case 'May':
			return <MonthMaySvg {...props} />;
		case 'Jun':
			return <MonthJunSvg {...props} />;
		case 'Jul':
			return <MonthJulSvg {...props} />;
		case 'Aug':
			return <MonthAugSvg {...props} />;
		case 'Sep':
			return <MonthSepSvg {...props} />;
		case 'Oct':
			return <MonthOctSvg {...props} />;
		case 'Nov':
			return <MonthNovSvg {...props} />;
		case 'Dec':
			return <MonthDecSvg {...props} />;
		default:
			return <MonthMaySvg {...props} />;
	}
}

export default MonthSvg;
