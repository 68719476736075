import React from 'react';
import styles from './User.module.css';
import { profileSvg } from 'icons';
import IconGroup from './IconGroup';

function User() {
	const name = 'Master Yoda';
	const profileImg =
		'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A8541ae15-114c-4bd9-bf64-c0936194c422/profile_vpIaXduBCu.jpeg';
	const headline = 'Always pass on what you have learned';
	return (
		<div className={styles.user}>
			<div>
				<img
					src={profileImg || profileSvg}
					className={styles.img}
					alt={'Profile'}
				/>
			</div>
			<div className={styles.profile}>
				<div className={styles.name}>
					<h1 className={styles.nameH1}>{name}</h1>
				</div>
				<div className={styles.headline}>{headline}</div>
				<IconGroup />
			</div>
		</div>
	);
}

export default User;
