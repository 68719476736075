import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningByUserByDate } from 'graphql/customQueries';
import { useUser } from 'utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { useParams } from 'react-router';

/**
 * useGetScholarLearnings hook gets learnings written by user in a given year.
 *
 * @param {Object}
 */
function useGetScholarLearnings({ setScholarLearnings, setError }) {
	const [user] = useUser();
	const { year = '2021', userID } = useParams();

	useEffect(() => {
		// Skip if user is loading
		if (user.isLoading) return;

		// Skip if userID is missing
		if (!userID) return;

		const getLearningsWrite = async () => {
			const variables = {
				userID,
				createdAt: { beginsWith: year },
				filter: { type: { eq: 'learning' } },
				limit: 300,
			};
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetLearningByUserByDate, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const learningsItem = res.data.getLearningByUserByDate.items;
				setScholarLearnings(learningsItem);
			} catch (error) {
				console.log('Error getting learnings write: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};
		getLearningsWrite();
	}, [
		user.isLoading,
		year,
		userID,
		user.isLogin,
		setError,
		setScholarLearnings,
	]);
}
export default useGetScholarLearnings;
