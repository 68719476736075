import React from 'react';
import styles from './Profile.module.css';
import { getFormatDate, getPlural } from 'utils';
import { profileSvg } from 'icons';

function Profile(props) {
	return (
		<div className={styles.profile}>
			<ProfileIcon {...props} />
			<Description {...props} />
		</div>
	);
}

function ProfileIcon(props) {
	const { profileImg } = props.connectUser || {};
	return (
		<div className={styles.profileIcon}>
			<img
				src={profileImg || profileSvg}
				className={styles.img}
				alt='Profile'
			/>
		</div>
	);
}

function Description(props) {
	const { createdAt, numWords = 0, description } = props;
	const { firstName = '', lastName = '', headline = '' } =
		props.connectUser || {};
	const formatDate = getFormatDate(createdAt);
	const topLine = `${firstName} ${lastName} · ${formatDate} · ${numWords} word${getPlural(
		numWords
	)}`;
	return (
		<div className={styles.description}>
			<span className={styles.topLine}>{topLine}</span>
			<br />
			<span>{description || headline}</span>
		</div>
	);
}

export default Profile;
