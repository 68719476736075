import getCurrentYear from './getCurrentYear';

/**
 * Return the list of years from 2021 to current year in string.
 * e.g. if current year is 2022, return ['2021', '2022']
 *      if current year is 2023, return ['2021', '2022', '2023']
 */
const getYearsToCurrentYear = (currentYear = Number(getCurrentYear())) => {
	const years = [];
	let startYear = 2021;
	while (startYear <= currentYear) {
		years.push(String(startYear));
		startYear++;
	}
	return years;
};

export default getYearsToCurrentYear;
