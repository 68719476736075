import React, { useState } from 'react';
import { getProfileLink, useUser } from 'utils';
import styles from './VolunteeringCertificate.module.css';
import { PDFViewer } from '@react-pdf/renderer';
import useGetScholarLearnings from './hooks';
import { useGetScholar } from '../ScholarCertificate/hook';
import { useParams } from 'react-router';
import TipStoryCertificate from './TipStoryCertificate';
import SharingOverview, { getLearningsObj } from './SharingOverview';

function VolunteeringCertificate() {
	const [scholar, setScholar] = useState({});
	const [scholarLearnings, setScholarLearnings] = useState([]);
	const [error, setError] = useState('');
	const { year } = useParams();
	useGetScholar({ setScholar, setError });
	useGetScholarLearnings({ setScholarLearnings, setError });
	const sortedScholarLearnings = scholarLearnings.sort((a, b) =>
		a.createdAt.localeCompare(b.createdAt)
	);
	const learningsObj = getLearningsObj(scholarLearnings, year);
	console.log(learningsObj);
	const displayCertificate = scholar.userID && scholarLearnings.length > 0;

	return (
		<div className={styles.volunteeringCertificate}>
			<div className={styles.containerBlock}>
				<h1 className={styles.h1}>
					🎓{year} TipStory Scholar Volunteering Certificate
				</h1>
				<Message
					error={error}
					scholar={scholar}
					scholarLearnings={scholarLearnings}
					totalVolunteerHours={learningsObj.totalVolunteerHours}
				/>
			</div>
			{displayCertificate && (
				<PDFViewer className={styles.pdfViewer}>
					<TipStoryCertificate
						scholar={scholar}
						scholarLearnings={sortedScholarLearnings}
						totalVolunteerHours={learningsObj.totalVolunteerHours}
						year={year}
					/>
				</PDFViewer>
			)}
			{displayCertificate && (
				<div className={styles.containerBlock}>
					<p className={`${styles.bottomP}`}>
						*This is our initial certificate design and we might make some
						updates to the design later on. If you have any suggestions or
						feedback, please let us know at{' '}
						<a href='mailto:scholar@tipstory.org' className={styles.link}>
							scholar@tipstory.org
						</a>
					</p>
				</div>
			)}
			{displayCertificate && (
				<SharingOverview scholar={scholar} learningsObj={learningsObj} />
			)}
		</div>
	);
}

const Message = ({
	error,
	scholar,
	scholarLearnings,
	totalVolunteerHours = 0,
}) => {
	const [user] = useUser();
	const { year, userID } = useParams();
	const isAuthor = user.userID === userID;
	const displayCertificate = scholarLearnings.length > 0;

	if (error) return <p className={styles.p}>{error}</p>;
	if (!userID) return <p className={styles.p}>Page not found🤯</p>;
	// Loading
	if (!scholar.userID) return null;
	// No Certificate
	if (!displayCertificate) return <p className={styles.p}>Page not found🤯</p>;
	// Get Certificate
	if (isAuthor) {
		return (
			<p className={styles.p}>
				Thank you {scholar.firstName} for your outstanding contributions in{' '}
				{year} to share knowledge and support your peers🤝 You can now download
				or print your volunteering certificate. <br />
				*Note that this certificate aggregates all your learnings and hours in{' '}
				{year}
			</p>
		);
	} else {
		return (
			<p className={styles.p}>
				TipStory certifies that {scholar.firstName} {scholar.lastName} has
				volunteered a total of {totalVolunteerHours} hours in {year}🤝 <br />
				Visit{' '}
				<a
					href={getProfileLink(scholar.profileURL, scholar.profileURLIdx)}
					className={styles.link}
				>
					{scholar.firstName}'s Journey Page
				</a>{' '}
				to read about {scholar.firstName}'s shared learnings, or visit the{' '}
				<a href={'/scholar-application'} className={styles.link}>
					TipStory Scholar Application
				</a>{' '}
				to learn more about the scholar program.
			</p>
		);
	}
};

export default VolunteeringCertificate;
