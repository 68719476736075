import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningByUserByDate } from 'graphql/customQueries';
import { useUser } from 'utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';

/**
 * useGetLearningsWrite hook gets all learnings written by user (max 1000).
 *
 * @param {Object}
 */
function useGetLearningsWrite({ setLearningsWrite, setError }) {
	const [user] = useUser();

	useEffect(() => {
		// Skip if user is not login
		if (!user.isLogin) return;

		const getLearningsWrite = async () => {
			const variables = {
				userID: user.userID,
				filter: { type: { eq: 'learning' } },
				limit: 1000,
			};
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetLearningByUserByDate, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const learningsItem = res.data.getLearningByUserByDate.items;
				setLearningsWrite(learningsItem);
			} catch (error) {
				console.log('Error getting learnings write: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};
		getLearningsWrite();
	}, [user.isLogin, user.userID, setError, setLearningsWrite]);
}
export default useGetLearningsWrite;
