import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from 'utils';
import update from 'immutability-helper';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningAndUser } from 'graphql/customQueries';

/**
 * useGetLearningByID hook gets learning based on learningID and also
 * user's followed status of the learning's author. Additionally, it sets
 * sidebar variables to activate useGetSidebar hook.
 *
 * @param {Object}
 */

const useGetLearningByID = ({
	learnings,
	setLearnings,
	setUserFollowed,
	setSidebarVariable,
	setError,
}) => {
	const [user] = useUser();
	const { learningID } = useParams();
	const isFirstFetch = useRef(true);

	useEffect(() => {
		if (user.isLoading) return;

		// Missing learningID
		if (!learningID) {
			setError(`What learning are you looking at 😕`);
			return;
		}

		// Skip query learning if found in cache
		if (learnings[learningID]) return;

		// Define and call getLearning
		const variables = {
			learningID,
			visitorUserID: user.userID,
			isLogin: user.isLogin,
		};

		const getLearning = async () => {
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetLearningAndUser, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const learningObj = res.data.getLearning;

				if (!learningObj) {
					setError(`Learning doesn't exist 😕`);
					return;
				}

				if (learningObj.saveLearningID && !learningObj.connectSaveLearning) {
					setError(`Saved learning no longer exists 😕`);
					return;
				}

				// Set learning / set save learning
				if (!learningObj.connectSaveLearning) {
					setLearnings((prev) =>
						update(prev, { [learningID]: { $set: learningObj } })
					);
				} else {
					learningObj.connectSaveLearning.parentLearningID =
						learningObj.learningID;
					setLearnings((prev) =>
						update(prev, {
							[learningID]: { $set: learningObj.connectSaveLearning },
						})
					);
				}

				// Followed status of learning author
				const author = !learningObj.connectSaveLearning
					? learningObj.connectUser
					: learningObj.connectSaveLearning.connectUser;
				const { userID: authorID, connectFollow } = author || {};
				if (connectFollow && connectFollow.items.length > 0) {
					setUserFollowed((prev) =>
						update(prev, { [authorID]: { $set: true } })
					);
				} else {
					setUserFollowed((prev) =>
						update(prev, { [authorID]: { $set: false } })
					);
				}

				// Set side variable for first fetch
				const { withinChapterID, connectUser } = learningObj;
				const { userID, firstName, learningOrder, chapterOrder, chapterName } =
					connectUser || {};
				if (isFirstFetch.current) {
					setSidebarVariable({
						userID,
						withinChapterID,
						firstName,
						learningOrder,
						chapterOrder,
						chapterName,
					});
					isFirstFetch.current = false;
				}
			} catch (error) {
				console.log('Error getting learning: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};
		getLearning();
	}, [
		user.isLoading,
		learningID,
		learnings,
		user.isLogin,
		user.userID,
		setLearnings,
		setUserFollowed,
		setSidebarVariable,
		setError,
	]);
};

export default useGetLearningByID;
