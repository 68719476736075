import React from 'react';
import { getProfileLink, useUser } from 'utils';
import { PDFViewer } from '@react-pdf/renderer';
import {
	getNumMonthsActivatedByYear,
	getScholarMeta,
} from '../ScholarPortal/utils';
import styles from './ScholarCertificate.module.css';
import { useState } from 'react';
import { useGetScholar } from './hook';
import { useParams } from 'react-router';
import TipStoryCertificate from './TipStoryCertificate';

function ScholarCertificate() {
	const [scholar, setScholar] = useState({});
	const [error, setError] = useState('');
	const { year = '2021' } = useParams();
	useGetScholar({ setScholar, setError });
	const scholarMeta = getScholarMeta(scholar);
	const numMonths = getNumMonthsActivatedByYear(scholarMeta, year);
	const displayCertificate = numMonths >= 2;

	return (
		<div className={styles.scholarCertificate}>
			<div className={styles.containerBlock}>
				<h1 className={styles.h1}>
					🎓{year} TipStory Scholar Honorary Certificate
				</h1>
				<Message error={error} scholar={scholar} numMonths={numMonths} />
			</div>
			{displayCertificate && (
				<PDFViewer className={styles.pdfViewer}>
					<TipStoryCertificate
						numMonths={numMonths}
						scholar={scholar}
						year={year}
					/>
				</PDFViewer>
			)}
			{displayCertificate && (
				<div className={styles.containerBlock}>
					<p className={`${styles.bottomP}`}>
						*This is our initial certificate design and we might make some
						updates to the design later on. If you have any suggestions or
						feedback, please let us know at{' '}
						<a href='mailto:scholar@tipstory.org' className={styles.link}>
							scholar@tipstory.org
						</a>
					</p>
				</div>
			)}
		</div>
	);
}

const Message = ({ error, scholar, numMonths }) => {
	const [user] = useUser();
	const { year, userID } = useParams();
	const isAuthor = user.userID === userID;
	const displayCertificate = numMonths >= 2;

	if (error) return <p className={styles.p}>{error}</p>;
	if (!userID) return <p className={styles.p}>Page not found🤯</p>;
	// Loading
	if (!scholar.userID) return null;
	// No Certificate
	if (!displayCertificate) return <p className={styles.p}>Page not found🤯</p>;
	// Display Certificate
	let honorColor = 'Bronze';
	if (numMonths >= 4) honorColor = 'Silver';
	if (numMonths >= 6) honorColor = 'Gold';
	if (isAuthor) {
		return (
			<p className={styles.p}>
				Congratulations {scholar.firstName}!🎉 You are awarded the TipStory
				Scholar {honorColor} Honorary Certificate for your outstanding
				contributions in {year}. You can now download, print, or share your
				accomplishment with your family and friends!
			</p>
		);
	} else {
		return (
			<p className={styles.p}>
				TipStory certifies that {scholar.firstName} {scholar.lastName} was
				awarded the TipStory Scholar {honorColor} Honorary Certificate for
				outstanding contributions in {year}🎉 <br />
				Visit{' '}
				<a
					href={getProfileLink(scholar.profileURL, scholar.profileURLIdx)}
					className={styles.link}
				>
					{scholar.firstName}'s Journey Page
				</a>{' '}
				to read about {scholar.firstName}'s shared learnings, or visit the{' '}
				<a href={'/scholar-application'} className={styles.link}>
					TipStory Scholar Application
				</a>{' '}
				to learn more about the scholar program.
			</p>
		);
	}
};

export default ScholarCertificate;
