import { useEffect } from 'react';
import { useUser } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningAndUser } from 'graphql/customQueries';

/**
 * useGetPinLearning hook gets pin learning based on learningID.
 *
 * @param {Object}
 */

const useGetPinLearning = ({ learningID, setPinLearning }) => {
	const [user] = useUser();

	useEffect(() => {
		if (user.isLoading) return;

		// Missing learningID
		if (!learningID) return;

		// Define and call getLearning
		const variables = {
			learningID,
			isLogin: false,
		};

		const getLearning = async () => {
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetLearningAndUser, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const learningObj = res.data.getLearning;

				if (!learningObj) return;

				setPinLearning(learningObj);
			} catch (error) {
				console.log('Error getting pinLearning: ', error);
			}
		};
		getLearning();
	}, [user.isLoading, learningID, user.isLogin, setPinLearning]);
};

export default useGetPinLearning;
