import React, { useState } from 'react';
import styles from './FollowContainer.module.css';
import { useUser } from 'utils';
import { useParams } from 'react-router-dom';
import { useGetFollowers, useGetFollowing } from '../hooks';
import FollowCard from './FollowCard';

function FollowContainer({ profile }) {
	const [profiles, setProfiles] = useState([]);
	const [error, setError] = useState('');

	// Get followers / following
	useGetFollowers({ profile, setProfiles, setError });
	useGetFollowing({ profile, setProfiles, setError });

	const empty = profiles.length === 0;
	const displayEmptyMsg = empty && !error;

	return (
		<div className={styles.follow}>
			<div className={styles.followContainer}>
				{error && <Error error={error} />}
				{displayEmptyMsg && <EmptyMsg profile={profile} />}
				{profiles.map((item) => (
					<FollowCard
						key={item.userID}
						profile={item}
						setProfiles={setProfiles}
					/>
				))}
			</div>
		</div>
	);
}

function Error({ error }) {
	return <h1 className={styles.error}>{error}</h1>;
}

function EmptyMsg({ profile }) {
	const [user] = useUser();
	const isAuthor = user.userID === profile.userID;
	const { profileSection } = useParams();
	if (profileSection === 'followers') {
		return (
			<p className={styles.emptyMsg}>
				{isAuthor ? 'Your' : 'This'} follower list is a little empty 😮 <br />
				{isAuthor
					? 'Share some helpful and interesting learnings to attract some followers 😃'
					: ''}
			</p>
		);
	} else {
		return (
			<p className={styles.emptyMsg}>
				{isAuthor ? 'Your' : 'This'} following list is a little empty 😮 <br />
				{isAuthor
					? 'Peek around and follow people who share helpful and interesting learnings 😃'
					: ''}
			</p>
		);
	}
}

export default FollowContainer;
