/**
 * getDefaultTheme returns the default theme setting in boolean.
 *
 * True means default to light theme. False means default to dark theme.
 *
 * Original source: https://stackoverflow.com/questions/56300132/how-to-override-css-prefers-color-scheme-setting (Credits to JimmyBanks)
 *
 * @returns {Boolean} isLightTheme
 */

import { LOCAL_STORAGE_THEME } from './constants';

const getDefaultTheme = () => {
	let isLightTheme = true;

	//1. Check setting save in local storage
	if (localStorage.getItem(LOCAL_STORAGE_THEME)) {
		if (localStorage.getItem(LOCAL_STORAGE_THEME) === 'dark') {
			isLightTheme = false;
		}
	}
	//2. Check matchMedia OS theme setting, change to dark if found
	else if (
		window.matchMedia &&
		window.matchMedia('(prefers-color-scheme: dark)').matches
	) {
		isLightTheme = false;
	}

	return isLightTheme;
};

export default getDefaultTheme;
