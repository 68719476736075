import React, { useEffect, useState } from 'react';
import styles from './ContextMenu.module.css';
import ContextMenuModal from './ContextMenuModal';
import JourneyMenu from './JourneyMenu';
import LearningMenu from './LearningMenu';
import ChapterMenu from './ChapterMenu';
import { useCloseMenu } from 'utils';

function ContextMenu({ setLearnings, setChapters }) {
	const [isOpen, setIsOpen] = useState(false);
	const [menu, setMenu] = useState({
		x: 0,
		y: 0,
		type: 'journey',
	});
	const [modal, setModal] = useState({ isOpen: false, state: '' });

	// Effect: listen for right click to open context menu
	useEffect(() => {
		const handleContextMenu = (event) => {
			const clickX = event.clientX;
			const clickY = event.clientY + window.pageYOffset;
			// Open if clicks in journey panel
			const id = document.activeElement.id || '';
			if (id === 'journey') {
				event.preventDefault();
				setMenu({ x: clickX, y: clickY, type: 'journey' });
				setIsOpen(true);
			}
			// Open if clicks in chapter / learning / saveLearning
			const [type, learningID, parentLearningID] = id.split('-');
			const title = document.activeElement.title;
			if (type === 'chapter' || type === 'learning') {
				event.preventDefault();
				setMenu({
					x: clickX,
					y: clickY,
					type,
					title,
					id: learningID,
					parentLearningID,
				});
				setIsOpen(true);
			}
		};
		document.addEventListener('contextmenu', handleContextMenu);
		return () => document.removeEventListener('contextmenu', handleContextMenu);
	}, []);

	// useCloseMenu hook to close menu
	useCloseMenu({ isOpen, setIsOpen });

	return (
		<>
			{isOpen && (
				<div
					className={styles.contextMenu}
					style={{ left: `${menu.x}px`, top: `${menu.y}px` }}
				>
					{menu.type === 'journey' && (
						<JourneyMenu setModal={setModal} setLearnings={setLearnings} />
					)}
					{menu.type === 'learning' && (
						<LearningMenu menu={menu} setModal={setModal} />
					)}
					{menu.type === 'chapter' && (
						<ChapterMenu menu={menu} setModal={setModal} />
					)}
				</div>
			)}
			{modal.isOpen && (
				<ContextMenuModal
					modal={modal}
					setModal={setModal}
					setLearnings={setLearnings}
					setChapters={setChapters}
				/>
			)}
		</>
	);
}

export default ContextMenu;
