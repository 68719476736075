import React from 'react';
import PrimaryButton from 'components/Button';
import { useLogin, useUser } from 'utils';
import {
	mutationCreateFollow,
	mutationDeleteFollow,
} from 'graphql/customMutations';
import { API, graphqlOperation } from 'aws-amplify';
import update from 'immutability-helper';

function FollowButton({ profile, setProfile, className }) {
	const { followed } = profile;
	const [user, setUser] = useUser();
	const [, setLogin] = useLogin();
	// HandleFollowClick for profile
	const handleFollowClick = async () => {
		// Prompt login screen if not login
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'login' });
			return;
		}
		if (!profile.userID || !user.userID) return;
		const input = {
			followUserID: profile.userID,
			userID: user.userID,
		};
		// Follow profile user if not followed
		if (!followed) {
			try {
				await API.graphql(graphqlOperation(mutationCreateFollow, { input }));
				setProfile((prev) => {
					const update1 = update(prev, { followed: { $set: true } });
					return update(update1, { numFollower: { $apply: (n) => n + 1 } });
				});
				setUser((prev) =>
					update(prev, { numFollowing: { $apply: (n) => n + 1 } })
				);
			} catch (error) {
				console.log('Error following profile: ', error);
			}
		}
		// Unfollow profile user
		else {
			try {
				await API.graphql(graphqlOperation(mutationDeleteFollow, { input }));
				setProfile((prev) => {
					const update1 = update(prev, { followed: { $set: false } });
					return update(update1, { numFollower: { $apply: (n) => n - 1 } });
				});
				setUser((prev) =>
					update(prev, { numFollowing: { $apply: (n) => n - 1 } })
				);
			} catch (error) {
				console.log('Error unfollowing profile: ', error);
			}
		}
	};
	return !followed ? (
		<PrimaryButton
			onClick={handleFollowClick}
			children={'Follow'}
			className={className}
		/>
	) : (
		<PrimaryButton
			primary={false}
			onClick={handleFollowClick}
			children={'Following'}
			className={className}
		/>
	);
}

export default FollowButton;
