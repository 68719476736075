import React from 'react';
import styles from './MeasureImpact.module.css';
import Title from '../Title';
import User from './User';

function MeasureImpact() {
	return (
		<div className={styles.measureImpact}>
			<div className={styles.header}>
				<Title>
					2. <span className='emoji'>💖</span>Measure your sharing impact
				</Title>
				<p className={styles.p}>
					You would be rewarded a point for sharing when a reader marks it
					helpful, interesting or read. The reader is also rewarded a point for
					learning.
				</p>
			</div>
			<User />
		</div>
	);
}
export default MeasureImpact;
