import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from './LearningCard.module.css';
import classNames from 'classnames';
import { GetSourceSvg } from 'components/DropdownInput';
import { getFormatDate } from 'utils';
import update from 'immutability-helper';
import {
	HelpfulActiveSvg,
	InterestingActiveSvg,
	ReadActiveSvg,
	ReadInactiveAltSvg,
} from 'icons';

function LearningCard({ learning, index, readStatus, setLearnings }) {
	const { learningID: id, parentLearningID = '', title } = learning;

	// Store this learning in a ref
	const ref = useRef(null);

	// dropSide is used to displays the resulting position of item when drag over
	// learning, it can be either 'left' or 'right'
	const [dropSide, setDropSide] = useState('');

	// learning can be dragged if person is author and not on mobile view
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'learningCard', id, parentLearningID, index },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});

	// drop and hover action
	const moveLearningWrapper = (dragIndex, currentIdx) => {
		setLearnings((preLearnings) => {
			const dragLearning = preLearnings[dragIndex];
			const newlearnings = update(preLearnings, {
				$splice: [
					[dragIndex, 1],
					[currentIdx, 0, dragLearning],
				],
			});
			return newlearnings;
		});
	};
	const [{ isOver }, drop] = useDrop({
		accept: ['learningCard'],
		drop: (item, monitor) => {
			if (!ref.current) return;
			// learning can be dropped to the left/right of a learning
			// Return if drop to the same learning
			const dragItemIndex = item.index;
			if (dragItemIndex === index) return;
			// Get middle of learning being hovered
			const boundingRect = ref.current.getBoundingClientRect();
			const horizontalMiddle = (boundingRect.left + boundingRect.right) / 2;
			// Get drag item's X value
			const dragItemX = monitor.getClientOffset().x;
			// On the left side of learning
			if (dragItemX <= horizontalMiddle) {
				// Return if learning is 1 learning on the right of dragged learning
				if (dragItemIndex + 1 === index) return;
				if (dragItemIndex < index)
					moveLearningWrapper(dragItemIndex, index - 1);
				if (dragItemIndex > index) moveLearningWrapper(dragItemIndex, index);
			}
			// On the right side of learning
			else {
				// Return if learning is 1 learning on the left of dragged learning
				if (dragItemIndex - 1 === index) return;
				if (dragItemIndex < index) moveLearningWrapper(dragItemIndex, index);
				if (dragItemIndex > index)
					moveLearningWrapper(dragItemIndex, index + 1);
			}
		},
		hover: (item, monitor) => {
			if (!ref.current) return;
			// Reset drop side
			setDropSide('');
			// learning can be dropped to the left/right of a learning
			// Return if drop to the same learning
			const dragItemIndex = item.index;
			if (dragItemIndex === index) return;
			// Get middle of learning being hovered
			const boundingRect = ref.current.getBoundingClientRect();
			const horizontalMiddle = (boundingRect.left + boundingRect.right) / 2;
			// Get drag item's X value
			const dragItemX = monitor.getClientOffset().x;
			// On the left side of learning
			if (dragItemX <= horizontalMiddle) {
				// Return if learning is 1 learning on the right of dragged learning
				if (dragItemIndex + 1 === index) return;
				setDropSide('left');
			}
			// On the right side of learning
			else {
				// Return if learning is 1 learning on the left of dragged learning
				if (dragItemIndex - 1 === index) return;
				setDropSide('right');
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
	});

	drop(drag(ref));

	const dropLeft = isOver && dropSide === 'left';
	const dropRight = isOver && dropSide === 'right';

	return (
		<div
			className={classNames(styles.learningCard, {
				[styles.dropLeft]: dropLeft,
				[styles.dropRight]: dropRight,
			})}
			ref={ref}
		>
			<button
				type='button'
				id={`learning-${id}`}
				title={title}
				className={classNames(styles.button, {
					[styles.dragEffect]: isDragging,
				})}
			>
				<Content learning={learning} readStatus={readStatus} />
			</button>
		</div>
	);
}

function Content({ learning, readStatus = [] }) {
	const { source, tag = '', title, numWords, createdAt } = learning;
	const displayTime = getFormatDate(createdAt).split(',')[0];
	const footer = ` · ${displayTime} · ${numWords}w`;

	const readActive = readStatus.includes('read');
	const helpfulActive = readStatus.includes('helpful');
	const interestingActive = readStatus.includes('interesting');

	return (
		<>
			<div className={styles.top}>
				<div className={styles.tag}>{tag}</div>
			</div>
			<div className={styles.bottom}>
				<h1 className={styles.title}>{title}</h1>
				<footer className={styles.footer}>
					<div className={styles.leftFooter}>
						{GetSourceSvg({ source: source, className: styles.sourceSvg })}
						<span>{footer}</span>
					</div>
					<div className={styles.svgGroup}>
						{helpfulActive && <HelpfulActiveSvg className={styles.svg} />}
						{interestingActive && (
							<InterestingActiveSvg className={styles.svg} />
						)}
						{readActive ? (
							<ReadActiveSvg className={styles.svg} />
						) : (
							<ReadInactiveAltSvg className={styles.svg} />
						)}
					</div>
				</footer>
			</div>
		</>
	);
}

export default LearningCard;
