import React, { useState } from 'react';
import styles from './MonthlyTask.module.css';
import classNames from 'classnames';
import TaskCheckbox from '../TaskCheckbox';
import PrimaryButton from 'components/Button';
import { useUser } from 'utils';
import { getCurrentMonth, getCurrentYear } from './utils';
import MonthSvg from './MonthSvg';
import {
	useGetCommentsWrite,
	useGetLearningsRead,
	useGetLearningsWrite,
} from './hooks';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationUpdateUser } from 'graphql/customMutations';
import update from 'immutability-helper';
import TimelineBadge from '../TimelineBadge';
import { getScholarMeta } from '../utils';

function MonthlyTask({ learningsWrite, setLearningsWrite }) {
	// Get scholarMeta
	const [user, setUser] = useUser();
	const scholarMeta = getScholarMeta(user);
	// Current month is activated
	const currentMonthStr = getCurrentMonth(true);
	const currentYear = getCurrentYear();
	const currentMonthNum = getCurrentMonth();
	const currentYearMonth = `${currentYear}-${currentMonthNum}`;
	const monthActivated = scholarMeta[currentYearMonth];
	// inactive if one time task is incomplete
	const inactive = !scholarMeta.oneTimeTaskDone;

	const [error, setError] = useState('');
	// Get learningsWrite for current month
	useGetLearningsWrite({ setLearningsWrite, setError });
	const monthLearningsWrite = learningsWrite.filter((item) =>
		item.createdAt.startsWith(currentYearMonth)
	);
	// Get learningsRead for current month
	const [learningsRead, setLearningsRead] = useState([]);
	useGetLearningsRead({
		setLearningsRead,
		setError,
		monthActivated,
	});
	// Get commentsWrite for current month
	const [commentsWrite, setCommentsWrite] = useState([]);
	useGetCommentsWrite({
		setCommentsWrite,
		setError,
		monthActivated,
	});

	// Disable button if doesn't meet requirement or current month has been activated
	const buttonDisable =
		monthLearningsWrite.length < 1 ||
		learningsRead < 2 ||
		commentsWrite < 2 ||
		monthActivated;
	// Activate Month
	const [loading, setLoading] = useState(false);
	const handleActivateMonth = async () => {
		if (inactive) return;
		if (buttonDisable) return;
		setLoading(true);
		setError('');
		try {
			// 1. Add current month to scholarMeta
			const finalScholarMeta = update(scholarMeta, {
				$merge: { [currentYearMonth]: true },
			});
			const userInput = {
				userID: user.userID,
				scholarMeta: JSON.stringify(finalScholarMeta),
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, {
					input: userInput,
					condition: {
						scholarMeta: { eq: JSON.stringify(scholarMeta) },
					},
				})
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log('Activate Error: ', error);
			setError(GENERAL_ERROR_MSG);
		}
		setLoading(false);
	};

	return (
		<div
			className={classNames(styles.monthlyTask, {
				[styles.blur]: inactive,
			})}
		>
			<h1 className={styles.h1}>{currentMonthStr} Monthly Task</h1>
			{error && <div className={styles.error}>{error}</div>}
			<WriteLearning
				monthLearningsWrite={monthLearningsWrite}
				monthActivated={monthActivated}
			/>
			<ReadLearning
				learningsRead={learningsRead}
				monthActivated={monthActivated}
			/>
			<WriteComment
				commentsWrite={commentsWrite}
				monthActivated={monthActivated}
			/>
			<div className={styles.buttonDiv}>
				<MonthSvg
					className={classNames(styles.monthSvg, {
						[styles.monthSvgActive]: monthActivated,
					})}
				/>
				<PrimaryButton
					children={!monthActivated ? 'Activate Month' : 'Activated😎'}
					onClick={handleActivateMonth}
					disable={buttonDisable}
					loading={loading}
				/>
			</div>
			<TimelineBadge />
		</div>
	);
}

function WriteLearning({ monthLearningsWrite, monthActivated }) {
	const numWrites = monthActivated ? 1 : monthLearningsWrite.length;
	const checked = numWrites >= 1 || monthActivated;
	return (
		<div className={styles.taskDiv}>
			<TaskCheckbox checked={checked} />
			<div className={styles.taskRightDiv}>
				<div
					className={classNames(styles.task, { [styles.checkedTask]: checked })}
				>
					Write and share 1 learning post📝
				</div>
				<ProgressDots total={1} numActive={numWrites} />
			</div>
		</div>
	);
}

function ReadLearning({ learningsRead, monthActivated }) {
	const numReads = monthActivated ? 2 : learningsRead.length;
	const checked = numReads >= 2 || monthActivated;
	return (
		<div className={styles.taskDiv}>
			<TaskCheckbox checked={checked} />
			<div className={styles.taskRightDiv}>
				<div
					className={classNames(styles.task, { [styles.checkedTask]: checked })}
				>
					Read and learn 2 learning posts📖
				</div>
				<ProgressDots total={2} numActive={numReads} />
			</div>
		</div>
	);
}

function WriteComment({ commentsWrite, monthActivated }) {
	const numComments = monthActivated ? 2 : commentsWrite.length;
	const checked = numComments >= 2 || monthActivated;
	return (
		<div className={styles.taskDiv}>
			<TaskCheckbox checked={checked} />
			<div className={styles.taskRightDiv}>
				<div
					className={classNames(styles.task, { [styles.checkedTask]: checked })}
				>
					Write and share 2 comments💬
				</div>
				<ProgressDots total={2} numActive={numComments} />
			</div>
		</div>
	);
}

function ProgressDots({ total = 1, numActive = 0 }) {
	const actives = [];
	for (let i = 0; i < total; i++) {
		if (i < numActive) actives.push(true);
		else actives.push(false);
	}
	return (
		<div className={styles.progressDotDiv}>
			{actives.map((active, idx) => (
				<div
					className={classNames(styles.progressDot, {
						[styles.progressDotActive]: active,
					})}
					key={idx}
				/>
			))}
		</div>
	);
}

export default MonthlyTask;
