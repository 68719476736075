import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { timesSvg } from 'icons';
import styles from './Modal.module.css';
import PrimaryButton from 'components/Button';
import Embed from '../Embed';
import {
	isReactElementNull,
	isImageUrl,
	useCloseDropdown,
	resizeAndUploadImage,
	useEnterCallback,
} from 'utils';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { GENERAL_ERROR_MSG } from 'utils/constants';

function Modal({ modal, setModal }) {
	const { isOpen, state } = modal;
	const setIsOpenFalse = () => setModal(() => ({ isOpen: false, state: '' }));
	// Close modal when click outside of node or press escape key
	const nodeRef = useRef(null);
	useCloseDropdown({
		node: nodeRef,
		isOpen: isOpen,
		setIsOpen: setIsOpenFalse,
	});

	let render;
	switch (state) {
		case 'embed':
			render = <EmbedBox modal={modal} setIsOpenFalse={setIsOpenFalse} />;
			break;
		case 'imageLink':
			render = <ImageLinkBox modal={modal} setIsOpenFalse={setIsOpenFalse} />;
			break;
		case 'imageFile':
			render = <ImageFileBox modal={modal} setIsOpenFalse={setIsOpenFalse} />;
			break;
		default:
			render = null;
	}
	return ReactDOM.createPortal(
		<section className={styles.section}>
			<div className={styles.div} ref={nodeRef}>
				<button className={styles.btnTimes} onClick={setIsOpenFalse}>
					<img
						src={timesSvg}
						className={styles.timesSvg}
						alt='close form icon'
					/>
				</button>
				{render}
			</div>
		</section>,
		document.body
	);
}

function EmbedBox({ modal, setIsOpenFalse }) {
	const { isOpen } = modal;
	const [link, setLink] = useState('');
	const inputRef = useRef(null);
	const editor = useSlate();

	const handleOnChange = (event) => {
		const value = event.target.value;
		setLink(value);
	};

	const Iframe = <Embed element={{ link: link }} />;
	const isIframeNull = isReactElementNull(Iframe);

	const handleOnClick = () => {
		if (!isIframeNull) {
			Transforms.insertNodes(editor, {
				type: 'embed',
				link: link,
				children: [{ text: '' }],
			});
			setIsOpenFalse();
			Transforms.collapse(editor, { edge: 'end' });
			Transforms.insertNodes(editor, {
				type: 'paragraph',
				children: [{ text: '' }],
			});
			ReactEditor.focus(editor);
		}
	};
	useEnterCallback({ isOpen: isOpen, callback: handleOnClick });

	return (
		<div className={styles.box}>
			<div className={styles.maxWidth}>
				<input
					ref={inputRef}
					autoFocus={true}
					type='text'
					placeholder='Paste a link to that supports embed'
					value={link}
					onChange={handleOnChange}
					className={styles.linkInput}
				/>
				<PrimaryButton
					className={styles.marginLeft10}
					disable={isIframeNull}
					onClick={handleOnClick}
				>
					Embed
				</PrimaryButton>
				{isIframeNull && (
					<div className={styles.supportTextDiv}>
						*Embed supports links from Google Slide, Fusion, Tinkercad, Codepen,
						CodeSandbox, YouTube, Viemo, Google Map, and many more...
						<br />
						<br />
						*Want some fun examples?
						<ul className={styles.supportTextUl}>
							<li>
								Google Slide:
								https://docs.google.com/presentation/d/e/2PACX-1vSIs5Qe1OFBiFlPUDfGppYZ1zYDqxXSQQm0GKiG78I2VLY5yScZe3JkPIZrZtzSdJAvTcFnikDmUhS2/pub
							</li>
							<li>
								Tinkercad:
								https://www.tinkercad.com/things/f9QN4skaguI-play-pirates-of-the-caribbean-theme-song-on-buzzer
							</li>
							<li>YouTube: https://www.youtube.com/watch?v=zU62hh3DBfg</li>
							<li>
								Google Map:
								https://www.google.com/maps/@40.7540268,-74.0020327,3a,75y,75.99h,118.88t
							</li>
						</ul>
						<br />
						Tip: You can also paste the link in the editor to directly embed
						content.
						<br />
					</div>
				)}
			</div>
			{!isIframeNull && <div className={styles.iframeDiv}>{Iframe}</div>}
		</div>
	);
}

function ImageLinkBox({ modal, setIsOpenFalse }) {
	const { isOpen } = modal;
	const [link, setLink] = useState('');
	const inputRef = useRef(null);
	const editor = useSlate();
	const isImageLink = isImageUrl(link);

	const handleOnChange = (event) => {
		const value = event.target.value;
		setLink(value);
	};

	const handleOnClick = () => {
		if (isImageLink) {
			Transforms.insertNodes(editor, {
				type: 'image',
				link: link,
				children: [{ text: '' }],
			});
			setIsOpenFalse();
			Transforms.collapse(editor, { edge: 'end' });
			Transforms.insertNodes(editor, {
				type: 'paragraph',
				children: [{ text: '' }],
			});
			ReactEditor.focus(editor);
		}
	};
	useEnterCallback({ isOpen: isOpen, callback: handleOnClick });

	return (
		<div className={styles.box}>
			<div className={styles.maxWidth}>
				<input
					ref={inputRef}
					type='text'
					autoFocus={true}
					placeholder='Paste a link of an image'
					value={link}
					onChange={handleOnChange}
					className={styles.linkInput}
				/>
				<PrimaryButton
					className={styles.marginLeft10}
					disable={!isImageLink}
					onClick={handleOnClick}
				>
					Add
				</PrimaryButton>
			</div>
			{isImageLink && (
				<div className={styles.imageDiv}>
					<img src={link} className={styles.imageImg} alt='' />
				</div>
			)}
		</div>
	);
}

function ImageFileBox({ modal, setIsOpenFalse }) {
	const { isOpen } = modal;
	const imgObject = useRef('');
	const [imgFile, setImgFile] = useState(null);
	const inputRef = useRef(null);
	const editor = useSlate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	// Effects to revoke imgObject to avoid memory leaks at unmount
	useEffect(() => {
		return () => {
			if (imgObject.current) URL.revokeObjectURL(imgObject.current);
		};
	}, []);

	const handleOnChange = async (event) => {
		const file = event.target.files[0];
		if (file) {
			// Revoke old image before creating new one
			if (imgObject.current) URL.revokeObjectURL(imgObject.current);
			const objectURL = URL.createObjectURL(file);
			imgObject.current = objectURL;
			setImgFile(file);
		}
	};

	const handleOnClick = async () => {
		if (imgFile && !loading) {
			setLoading(true);
			setError('');
			try {
				const imgLink = await resizeAndUploadImage(imgFile);
				if (imgLink) {
					Transforms.insertNodes(editor, {
						type: 'image',
						link: imgLink,
						children: [{ text: '' }],
					});
					setIsOpenFalse();
					Transforms.collapse(editor, { edge: 'end' });
					Transforms.insertNodes(editor, {
						type: 'paragraph',
						children: [{ text: '' }],
					});
					ReactEditor.focus(editor);
				}
			} catch (error) {
				console.log('Error uploading image: ', error);
				setLoading(false);
				setError(GENERAL_ERROR_MSG);
			}
		}
	};
	useEnterCallback({ isOpen: isOpen, callback: handleOnClick });

	return (
		<div className={styles.box}>
			{error && <div className={styles.error}>{error}</div>}
			<div className={styles.maxWidth}>
				<input
					ref={inputRef}
					type='file'
					name='profileImg'
					accept='image/*'
					onChange={handleOnChange}
					style={{ display: 'none' }}
				/>
				<PrimaryButton primary={false} onClick={() => inputRef.current.click()}>
					Choose an image file
				</PrimaryButton>
				<PrimaryButton
					className={styles.marginLeft10}
					disable={!imgFile}
					loading={loading}
					onClick={handleOnClick}
				>
					Add
				</PrimaryButton>
			</div>
			{imgFile && (
				<div className={styles.imageDiv}>
					<img src={imgObject.current} className={styles.imageImg} alt='' />
				</div>
			)}
		</div>
	);
}

export default Modal;
