import Editor from 'components/Editor';
import React, { useState } from 'react';
import styles from './EditorDemo.module.css';
import INITIAL_VALUE from './utils/initialValue';
import Title from '../Title';

function EditorDemo() {
	const [value, setValue] = useState(INITIAL_VALUE);
	return (
		<div>
			<div className={styles.header}>
				<Title>
					1. <span className='emoji'>📝</span>Best editor to document learnings
				</Title>
				<p className={styles.p}>
					TipStory Editor is built to meet every creative need of students and
					learners to document learnings.
				</p>
			</div>
			<div className={styles.editor}>
				<Editor value={value} setValue={setValue} />
			</div>
		</div>
	);
}

export default EditorDemo;
