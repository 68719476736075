import React, { useState } from 'react';
import styles from './LoginModal.module.css';
import classNames from 'classnames';
import { Auth } from 'aws-amplify';
import { logoSvg, facebookSvg, googleSvg, timesSvg } from 'icons';
import { useHistory } from 'react-router-dom';
import { useLogin } from 'utils';
import { LOCAL_STORAGE_SAVE_PATHNAME } from 'utils/constants';

function FacebookGoogleLogin() {
	const history = useHistory();
	const handleSignIn = async (provider) => {
		try {
			localStorage.setItem(
				LOCAL_STORAGE_SAVE_PATHNAME,
				history.location.pathname
			);
			await Auth.federatedSignIn({ provider: provider });
		} catch (error) {
			console.log('error: ', error);
		}
	};
	return (
		<>
			<div className={styles.horizontalDivider}>OR</div>
			<button
				type='button'
				className={styles.buttonGoogle}
				onClick={() => handleSignIn('Google')}
			>
				<img src={googleSvg} className={styles.buttonImg} alt='google icon' />
				<span className={styles.span}>
					Log in With Google&nbsp;&nbsp;&nbsp;&nbsp;
				</span>
			</button>
			<button
				type='button'
				className={styles.button}
				onClick={() => handleSignIn('Facebook')}
			>
				<img
					src={facebookSvg}
					className={styles.buttonImg}
					alt='facebook icon'
				/>
				<span className={styles.span}>Log in With Facebook</span>
			</button>
		</>
	);
}

const isNumber = (str) => {
	return Number.isFinite(Number(str));
};

const emailFormatError = (str) => {
	// Don't check numbers string (phone number)
	if (!isNumber(str)) {
		// Don't check string starts with '+'
		if (str[0] === '+') return false;
		// Email must contain @ and .
		if (!str.includes('@') || !str.includes('.')) {
			return true;
		}
	}
	return false;
};

const phoneFormatError = (str) => {
	// Phone number must contain at least 10 digits
	if (isNumber(str)) {
		if (str.length < 10) {
			return true;
		}
	}
	return false;
};

const processUsername = (username) => {
	// Append +1 if is a phone number
	let newUsername = username;
	if (isNumber(username)) {
		newUsername = '+1' + username;
	}
	return newUsername;
};

const checkSignUpError = ({ state, setError }) => {
	// Check First Name, Last Name, Username, Passowrd
	const error = { ...signUpErrorInitial };
	if (state.firstName === '') {
		error.firstName = true;
		error.msg = error.msg || 'Please enter your first name.';
	}
	if (state.lastName === '') {
		error.lastName = true;
		error.msg = error.msg || 'Please enter your last name.';
	}
	if (state.username === '') {
		error.username = true;
		error.msg = error.msg || 'Please enter an email.';
	}
	if (emailFormatError(state.username)) {
		error.username = true;
		error.msg = error.msg || 'Please enter a valid email.';
	}
	if (phoneFormatError(state.username)) {
		error.username = true;
		error.msg = error.msg || 'Please enter a valid phone number.';
	}
	if (state.password.length < 6) {
		error.password = true;
		error.msg =
			error.msg ||
			'Please enter a password that contains at least 6 characters.';
	}
	setError(error);
	if (error.msg) {
		return true;
	}
	return false;
};

const signUpErrorInitial = {
	msg: '',
	firstName: false,
	lastName: false,
	username: false,
	password: false,
};

function SignUp({ changeLoginState, state, handleInputChange }) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(signUpErrorInitial);
	const setErrorMsg = (msg) => {
		setError((prev) => {
			return { ...prev, msg: msg };
		});
	};
	let stateChange = false;

	const signUp = async (e) => {
		e.preventDefault();
		if (loading) return;
		if (checkSignUpError({ state, setError })) return;
		setLoading(true);
		const username = processUsername(state.username);
		try {
			// Cognito requires given_name and family_name attributes
			const { user } = await Auth.signUp({
				username,
				password: state.password,
				attributes: {
					//email: state.username,
					// phone_number: '+13475585695',
					given_name: state.firstName,
					family_name: state.lastName,
				},
			});
			console.log(user);
			changeLoginState('confirmSignUp');
			stateChange = true;
		} catch (error) {
			console.log('Error signing up: ', error);
			switch (error.code) {
				case 'UsernameExistsException':
					setErrorMsg(
						'Looks like you already created a TipStory account. Please log in instead.'
					);
					break;
				default:
					setErrorMsg(
						'Sorry, we are unable to create your account at this time. Please try again later or contact us if you continue to experience this issue.'
					);
			}
			//{code: "UsernameExistsException", name: "UsernameExistsException"}
			//{code: "InvalidParameterException", name: "InvalidParameterException"}
		}
		if (!stateChange) {
			setLoading(false);
		}
	};

	return (
		<>
			<h1 className={styles.h1}>Sign up to learn and share learnings</h1>
			{error.msg && <p className={styles.errorMsg}>{error.msg}</p>}
			<form
				name='signUp'
				autoComplete='off'
				onSubmit={signUp}
				className={styles.form}
			>
				<input
					autoFocus={true}
					type='text'
					name='firstName'
					value={state.firstName}
					onChange={handleInputChange}
					placeholder='First name'
					className={classNames(styles.input, {
						[styles.inputError]: error.firstName,
					})}
				/>
				<input
					type='text'
					name='lastName'
					value={state.lastName}
					onChange={handleInputChange}
					placeholder='Last name'
					className={classNames(styles.input, {
						[styles.inputError]: error.lastName,
					})}
				/>
				<input
					type='text'
					name='username'
					value={state.username}
					onChange={handleInputChange}
					placeholder='Email or Phone'
					className={classNames(styles.input, {
						[styles.inputError]: error.username,
					})}
				/>
				<input
					type='password'
					name='password'
					value={state.password}
					onChange={handleInputChange}
					placeholder='Password (6 or more characters)'
					className={classNames(styles.input, {
						[styles.inputError]: error.password,
					})}
				/>
				<div className={styles.submitDiv}>
					<input
						type='submit'
						value='Sign up'
						className={classNames(styles.submit, {
							[styles.submitLoading]: loading,
						})}
					/>
					<span className={classNames({ [styles.loading]: loading })} />
				</div>
			</form>
			<FacebookGoogleLogin />
			<p className={styles.p}>
				By signing up, you agree to TipStory's{' '}
				<a href='/terms' className={styles.a}>
					Terms
				</a>{' '}
				and{' '}
				<a href='/privacy' className={styles.a}>
					Privacy Policy
				</a>
				.
			</p>
			<p className={styles.p}>
				Already a member?{' '}
				<button
					className={styles.btnLink}
					onClick={() => changeLoginState('login')}
				>
					Log in
				</button>
			</p>
		</>
	);
}

function ConfirmSignUp({
	changeLoginState,
	state,
	handleInputChange,
	closeLogin,
	resetState,
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({
		msg: '',
		code: false,
	});
	const setErrorMsg = (msg) => {
		setError((prev) => {
			return { ...prev, msg: msg };
		});
	};
	const [infoMsg, setInfoMsg] = useState('');
	let stateChange = false;

	const resendCode = async () => {
		const username = processUsername(state.username);
		try {
			await Auth.resendSignUp(username);
			setInfoMsg('A new verification code has been sent.');
		} catch (error) {
			setErrorMsg('Please try again later.');
		}
	};

	const confirmSignUp = async (e) => {
		e.preventDefault();
		if (loading) return;
		setInfoMsg('');
		if (state.code === '') {
			setErrorMsg('Please enter a verification code.');
			return;
		} else {
			setErrorMsg('');
		}
		setLoading(true);
		const username = processUsername(state.username);
		try {
			await Auth.confirmSignUp(username, state.code);
			try {
				await Auth.signIn(username, state.password);
				closeLogin();
			} catch (error) {
				resetState();
				changeLoginState('login');
				stateChange = true;
			}
		} catch (error) {
			console.log('error confirming sign up:', error);
			switch (error.code) {
				case 'CodeMismatchException':
					setErrorMsg('The verification code is invalid, please try again.');
					break;
				case 'ExpiredCodeException':
					setErrorMsg(
						'The verification code has expired, please requset a new code.'
					);
					break;
				case 'LimitExceededException':
					setErrorMsg(
						'You have reached your resend attempt limit. Please try again later.'
					);
					break;
				default:
					setErrorMsg(
						'Sorry, we are unable to verify your account at this time. Please try again later or contact us if you continue to experience this issue.'
					);
			}
			//{code: "CodeMismatchException", name: "CodeMismatchException"}
		}
		if (!stateChange) {
			setLoading(false);
		}
	};

	return (
		<>
			<h1 className={styles.h1}>Please confirm your account</h1>
			{error.msg && <p className={classNames(styles.errorMsg)}>{error.msg}</p>}
			{infoMsg && (
				<p className={classNames(styles.errorMsg, styles.nonErrorMsg)}>
					{infoMsg}
				</p>
			)}
			<form
				name='confirmSignUp'
				autoComplete='off'
				onSubmit={confirmSignUp}
				className={styles.form}
			>
				<input
					autoFocus={true}
					type='text'
					name='code'
					value={state.code}
					onChange={handleInputChange}
					placeholder='Verification code'
					className={classNames(styles.input, {
						[styles.inputError]: error.code,
					})}
				/>
				<div className={styles.submitDiv}>
					<input
						type='submit'
						value='Confirm'
						className={classNames(styles.submit, {
							[styles.submitLoading]: loading,
						})}
					/>
					<span className={classNames({ [styles.loading]: loading })} />
				</div>
			</form>
			<p className={styles.p}>
				Lost your code?{' '}
				<button className={styles.btnLink} onClick={resendCode}>
					Resend code
				</button>
			</p>
		</>
	);
}

const checkLoginError = ({ state, setError }) => {
	// Check Username, Password
	const error = {
		username: false,
		password: false,
		msg: '',
	};
	if (state.username === '') {
		error.username = true;
		error.msg = error.msg || 'Please enter an email.';
	}
	if (emailFormatError(state.username)) {
		error.username = true;
		error.msg = error.msg || 'Please enter a valid email.';
	}
	if (phoneFormatError(state.username)) {
		error.username = true;
		error.msg = error.msg || 'Please enter a valid phone number.';
	}
	if (state.password.length < 6) {
		error.password = true;
		error.msg =
			error.msg ||
			'Please enter a password that contains at least 6 characters.';
	}
	setError(error);
	if (error.msg) {
		return true;
	}
	return false;
};

function Login({
	changeLoginState,
	state,
	handleInputChange,
	closeLogin,
	resetState,
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({
		username: false,
		password: false,
		msg: '',
	});
	const setErrorMsg = (msg) => {
		setError((prev) => {
			return { ...prev, msg: msg };
		});
	};

	const login = async (e) => {
		e.preventDefault();
		if (loading) return;
		if (checkLoginError({ state, setError })) return;
		setLoading(true);
		const username = processUsername(state.username);
		try {
			await Auth.signIn(username, state.password);
			// console.log(user);
			closeLogin();
		} catch (error) {
			console.log('error signing in', error);
			switch (error.code) {
				case 'UserNotConfirmedException':
					try {
						await Auth.resendSignUp(username);
						changeLoginState('confirmSignUp');
					} catch (error) {
						setErrorMsg('Please try again later.');
					}
					break;
				case 'UserNotFoundException':
					setErrorMsg(
						'The email or phone number you entered does not belong to an account. Please double-check and try again.'
					);
					break;
				case 'NotAuthorizedException':
					if (error.message === 'Password attempts exceeded') {
						setErrorMsg(
							'Sorry, you have tried to log in too fast. Please try again later.'
						);
					} else {
						setErrorMsg(
							'Sorry, your password was incorrect. Please double-check your password.'
						);
					}
					break;
				case 'PasswordResetRequiredException':
					// Currently wouldn't occur, need a better transition if used
					setErrorMsg('Please reset your password.');
					break;
				default:
					setErrorMsg(
						'Sorry, we are unable to access your account at this time. Please try again later or contact us if you continue to experience this issue.'
					);
			}
		}
		setLoading(false);
	};

	return (
		<>
			<h1 className={styles.h1}>Welcome back to TipStory</h1>
			{error.msg && <p className={classNames(styles.errorMsg)}>{error.msg}</p>}
			<form
				name='login'
				autoComplete='on'
				onSubmit={login}
				className={styles.form}
			>
				<input
					autoFocus={true}
					type='text'
					name='username'
					value={state.username}
					onChange={handleInputChange}
					placeholder='Email or Phone'
					className={classNames(styles.input, {
						[styles.inputError]: error.username,
					})}
				/>
				<input
					type='password'
					name='password'
					value={state.password}
					onChange={handleInputChange}
					placeholder='Password (6 or more characters)'
					className={classNames(styles.input, {
						[styles.inputError]: error.password,
					})}
				/>
				<div className={styles.submitDiv}>
					<input
						type='submit'
						value='Log in'
						className={classNames(styles.submit, {
							[styles.submitLoading]: loading,
						})}
					/>
					<span className={classNames({ [styles.loading]: loading })} />
				</div>
			</form>
			<FacebookGoogleLogin />
			<p className={styles.p}>
				Not a member yet?{' '}
				<button
					className={styles.btnLink}
					onClick={() => changeLoginState('signUp')}
				>
					Sign up
				</button>
			</p>
			<p className={styles.p}>
				<button
					className={styles.btnLink}
					onClick={() => {
						changeLoginState('forgotPassword');
						resetState();
					}}
				>
					Forgot your password?
				</button>
			</p>
		</>
	);
}

const checkForgotPasswordError = ({ state, setError }) => {
	// Check Username
	const error = {
		username: false,
		msg: '',
	};
	if (state.username === '') {
		error.username = true;
		error.msg = error.msg || 'Please enter an email.';
	}
	if (emailFormatError(state.username)) {
		error.username = true;
		error.msg = error.msg || 'Please enter a valid email.';
	}
	if (phoneFormatError(state.username)) {
		error.username = true;
		error.msg = error.msg || 'Please enter a valid phone number.';
	}
	setError(error);
	if (error.msg) {
		return true;
	}
	return false;
};

function ForgotPassword({ changeLoginState, state, handleInputChange }) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({
		username: false,
		msg: '',
	});
	const setErrorMsg = (msg) => {
		setError((prev) => {
			return { ...prev, msg: msg };
		});
	};

	const forgotPassword = async (e) => {
		e.preventDefault();
		if (loading) return;
		if (checkForgotPasswordError({ state, setError })) return;
		setLoading(true);
		const username = processUsername(state.username);
		try {
			await Auth.forgotPassword(username);
			setLoading(false);
			changeLoginState('confirmForgotPassword');
		} catch (error) {
			switch (error.code) {
				case 'UserNotFoundException':
					if (error.name === 'UserNotFoundException') {
						setErrorMsg(
							'There is no TipStory account associated with this email or phone number.'
						);
					} else {
						setErrorMsg(
							'Sorry, we are unable to reset your account at this time. Please try again later or contact us if you continue to experience this issue.'
						);
					}
					break;
				default:
					setErrorMsg(
						'Sorry, we are unable to reset your account at this time. Please try again later or contact us if you continue to experience this issue.'
					);
			}
			setLoading(false);
		}
	};

	return (
		<>
			<h1 className={styles.h1}>Trouble logging in?</h1>

			<p className={classNames(styles.errorMsg, styles.regularMsg)}>
				Enter your email or phone number, and we'll send you a verification code
				to get back into your account.
			</p>
			{error.msg && <p className={classNames(styles.errorMsg)}>{error.msg}</p>}
			<form
				name='forgotPassword'
				autoComplete='off'
				onSubmit={forgotPassword}
				className={styles.form}
			>
				<input
					autoFocus={true}
					type='text'
					name='username'
					value={state.username}
					onChange={handleInputChange}
					placeholder='Email or Phone'
					className={classNames(styles.input, {
						[styles.inputError]: error.username,
					})}
				/>
				<div className={styles.submitDiv}>
					<input
						type='submit'
						value='Send Code'
						className={classNames(styles.submit, {
							[styles.submitLoading]: loading,
						})}
					/>
					<span className={classNames({ [styles.loading]: loading })} />
				</div>
			</form>
			<p className={styles.p}>
				<button
					className={styles.btnLink}
					onClick={() => changeLoginState('login')}
				>
					Back to login
				</button>
			</p>
		</>
	);
}

const checkConfirmForgotPasswordError = ({ state, setError }) => {
	// Check Code & Password
	const error = {
		code: false,
		password: false,
		msg: '',
	};
	if (state.code === '') {
		error.code = true;
		error.msg = error.msg || 'Please enter a verification code.';
	}
	if (state.password.length < 6) {
		error.password = true;
		error.msg =
			error.msg ||
			'Please enter a password that contains at least 6 characters.';
	}
	setError(error);
	if (error.msg) {
		return true;
	}
	return false;
};

function ConfirmForgotPassword({
	changeLoginState,
	state,
	handleInputChange,
	closeLogin,
	resetState,
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({
		code: false,
		password: false,
		msg: '',
	});
	const setErrorMsg = (msg) => {
		setError((prev) => {
			return { ...prev, msg: msg };
		});
	};
	const [infoMsg, setInfoMsg] = useState(
		'A verification code has been sent. Please enter the code and a new password.'
	);

	const resendCode = async () => {
		const username = processUsername(state.username);
		try {
			await Auth.resendSignUp(username);
			setInfoMsg('A new verification code has been sent.');
		} catch (error) {
			setInfoMsg('');
			setErrorMsg('Please try again later.');
		}
	};

	const confirmForgotPassword = async (e) => {
		e.preventDefault();
		if (loading) return;
		if (checkConfirmForgotPasswordError({ state, setError })) return;
		setLoading(true);
		const username = processUsername(state.username);
		try {
			await Auth.forgotPasswordSubmit(username, state.code, state.password);
			try {
				await Auth.signIn(username, state.password);
				closeLogin();
			} catch (error) {
				resetState();
				changeLoginState('login');
			}
		} catch (error) {
			console.log('error reseting', error);
			switch (error.code) {
				case 'CodeMismatchException':
					setErrorMsg('The verification code is invalid, please try again.');
					break;
				case 'ExpiredCodeException':
					setErrorMsg(
						'The verification code has expired, please requset a new code.'
					);
					break;
				case 'LimitExceededException':
					setErrorMsg(
						'You have reached your reset attempt limit. Please try again later.'
					);
					break;
				default:
					setErrorMsg(
						'Sorry, we are unable to reset your account at this time. Please try again later or contact us if you continue to experience this issue.'
					);
			}
		}
	};

	return (
		<>
			<h1 className={styles.h1}>Trouble logging in?</h1>
			{infoMsg && (
				<p className={classNames(styles.errorMsg, styles.nonErrorMsg)}>
					{infoMsg}
				</p>
			)}
			<form
				name='confirmForgotPassword'
				autoComplete='off'
				onSubmit={confirmForgotPassword}
				className={styles.form}
			>
				<input
					autoFocus={true}
					type='text'
					name='code'
					value={state.code}
					onChange={handleInputChange}
					placeholder='Verification code'
					className={classNames(styles.input, {
						[styles.inputError]: error.code,
					})}
				/>
				<input
					type='password'
					name='password'
					value={state.password}
					onChange={handleInputChange}
					placeholder='Password (6 or more characters)'
					className={classNames(styles.input, {
						[styles.inputError]: error.password,
					})}
				/>
				<div className={styles.submitDiv}>
					<input
						type='submit'
						value='Confirm'
						className={classNames(styles.submit, {
							[styles.submitLoading]: loading,
						})}
					/>
					<span className={classNames({ [styles.loading]: loading })} />
				</div>
			</form>
			<p className={styles.p}>
				Lost your code?{' '}
				<button className={styles.btnLink} onClick={resendCode}>
					Resend code
				</button>
			</p>
		</>
	);
}

const initialState = {
	firstName: '',
	lastName: '',
	username: '',
	password: '',
	code: '',
};

function LoginModal() {
	const [login, setLogin] = useLogin();
	const loginState = login.state;
	// 4 states: 1. signUp 2. confirmSignUp 3. login
	//           4. forgotPassword 5. loggedIn
	function changeLoginState(newState) {
		setLogin((prev) => {
			return { ...prev, state: newState };
		});
	}

	// useEffect(() => {
	// 	changeLoginState('forgotPassword');
	// }, [loginState]);

	let render;

	const [state, setState] = useState(initialState);
	const resetState = () => {
		setState(initialState);
	};
	const closeLogin = () => {
		// Important: reset state to prevent data breach on close
		resetState();
		setLogin((prev) => {
			return { ...prev, visible: false };
		});
	};

	const handleInputChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setState((prev) => {
			return { ...prev, [name]: value };
		});
	};

	switch (loginState) {
		case 'signUp':
			render = (
				<SignUp
					changeLoginState={changeLoginState}
					state={state}
					handleInputChange={handleInputChange}
				/>
			);
			break;
		case 'confirmSignUp':
			render = (
				<ConfirmSignUp
					changeLoginState={changeLoginState}
					state={state}
					handleInputChange={handleInputChange}
					closeLogin={closeLogin}
					resetState={resetState}
				/>
			);
			break;
		case 'login':
			render = (
				<Login
					changeLoginState={changeLoginState}
					state={state}
					handleInputChange={handleInputChange}
					closeLogin={closeLogin}
					resetState={resetState}
				/>
			);
			break;
		case 'forgotPassword':
			render = (
				<ForgotPassword
					changeLoginState={changeLoginState}
					state={state}
					handleInputChange={handleInputChange}
				/>
			);
			break;
		case 'confirmForgotPassword':
			render = (
				<ConfirmForgotPassword
					changeLoginState={changeLoginState}
					state={state}
					handleInputChange={handleInputChange}
					closeLogin={closeLogin}
					resetState={resetState}
				/>
			);
			break;
		default:
			render = null;
	}

	if (loginState === 'loggedIn') return null;

	return (
		<section
			className={classNames(styles.section, {
				[styles.displayNone]: !login.visible,
			})}
		>
			<div className={styles.div}>
				<button className={styles.btnTimes} onClick={closeLogin}>
					<img
						src={timesSvg}
						className={styles.timesSvg}
						alt='close form icon'
					/>
				</button>
				<img src={logoSvg} className={styles.img} alt='TipStory logo' />
				{render}
			</div>
		</section>
	);
}

export default LoginModal;
