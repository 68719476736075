import { DeleteSvg, NoteSvg } from 'icons';
import React from 'react';
import Item from '../Item';

// Open, Delete
function SaveLearningMenu({ menu, setModal }) {
	return (
		<>
			<OpenLearning menu={menu} />
			<OpenOriginalLearning menu={menu} />
			{/* <Item name={'moveLearning'} svg={<MoveToSvg />} text={'Move to'} /> */}
			{/* <Item name={'shareLearning'} svg={<ShareSvg />} text={'Share'} /> */}
			<DeleteLearning menu={menu} setModal={setModal} />
		</>
	);
}

function OpenLearning({ menu }) {
	const handleOnClick = () => {
		window.open(`/learning/${menu.parentLearningID}`, '_blank');
	};
	return <Item svg={<NoteSvg />} text={'Open'} onClick={handleOnClick} />;
}

function OpenOriginalLearning({ menu }) {
	const handleOnClick = () => {
		window.open(`/learning/${menu.id}`, '_blank');
	};
	return (
		<Item svg={<NoteSvg />} text={'Open Original'} onClick={handleOnClick} />
	);
}

function DeleteLearning({ menu, setModal }) {
	const handleOnClick = () => {
		setModal({
			isOpen: true,
			state: 'deleteSaveLearning',
			title: menu.title,
			id: menu.parentLearningID,
		});
	};
	return <Item svg={<DeleteSvg />} text={'Delete'} onClick={handleOnClick} />;
}

export default SaveLearningMenu;
