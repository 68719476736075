/**
 * removeNewLineChars replaces new line characters (\r\n)
 * of an input string with space ' '
 * @param {String} inputString
 */
const removeNewLineChars = (inputString) => {
	return inputString.slice().replace(/[\r\n]+/gm, ' ');
};

export default removeNewLineChars;
