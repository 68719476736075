import React from 'react';
import styles from './SidebarLearning.module.css';
import {
	HelpfulActiveSvg,
	InterestingActiveSvg,
	ReadInactiveAltSvg,
	ReadActiveSvg,
} from 'icons';
import { getFormatDate } from 'utils';
import { GetSourceSvg } from 'components/DropdownInput';

function SidebarLearning(props) {
	const { last } = props;
	const className = !last
		? styles.sidebarRow
		: `${styles.sidebarRow} ${styles.lastSidebarRow}`;
	return (
		<div className={className}>
			<Header {...props} />
			<Title {...props} />
			<Meta {...props} />
		</div>
	);
}

function Header(props) {
	const { tag } = props;
	return (
		<header className={styles.header}>
			<span className={styles.tag}>{tag}</span>
		</header>
	);
}

function Title(props) {
	const { source, title } = props;
	return (
		<div className={styles.title}>
			<div className={styles.leftTitle}>
				<div className={styles.titleSvg}>
					{GetSourceSvg({ source, className: styles.sidebarSvg })}
				</div>
				<h1 className={styles.titleH1}>{title}</h1>
			</div>
		</div>
	);
}

function Meta(props) {
	const { numWords, createdAt, readStatus = [] } = props;
	const displayDate = getFormatDate(createdAt).split(',')[0];

	const readActive = readStatus.includes('read');
	const helpfulActive = readStatus.includes('helpful');
	const interestingActive = readStatus.includes('interesting');

	return (
		<div className={styles.meta}>
			<div className={styles.metaDes}>
				{displayDate} · {numWords} words
			</div>
			<div className={styles.metaIcons}>
				{helpfulActive && <HelpfulActiveSvg className={styles.metaSvg} />}
				{interestingActive && (
					<InterestingActiveSvg className={styles.metaSvg} />
				)}
				{readActive ? (
					<ReadActiveSvg className={styles.metaSvg} />
				) : (
					<ReadInactiveAltSvg className={styles.metaSvg} />
				)}
			</div>
		</div>
	);
}

export default SidebarLearning;
