import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { FolderSvg, DownSvg, timesSvg } from 'icons';
import styles from './Modal.module.css';
import PrimaryButton from 'components/Button';
import { getNanoid, getNewOrder, useCloseDropdown, useUser } from 'utils';
import getAllChapters from 'utils/getAllChapters';
import classNames from 'classnames';
import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationCreateLearning,
	mutationUpdateUser,
} from 'graphql/customMutations';
import { GENERAL_ERROR_MSG, SAVE_LEARNING_ID_LENGTH } from 'utils/constants';
import update from 'immutability-helper';

function Modal({ modal, setModal }) {
	const { isOpen, state } = modal;
	const setIsOpenFalse = () => setModal(() => ({ isOpen: false, state: '' }));
	// Close modal when click outside of node or press escape key
	const nodeRef = useRef(null);
	useCloseDropdown({
		node: nodeRef,
		isOpen: isOpen,
		setIsOpen: setIsOpenFalse,
	});

	let render;
	switch (state) {
		case 'saveLearning':
			render = <SaveLearning modal={modal} setIsOpenFalse={setIsOpenFalse} />;
			break;
		default:
			render = null;
	}

	return ReactDOM.createPortal(
		<section className={styles.section}>
			<div className={styles.div} ref={nodeRef}>
				<button className={styles.btnTimes} onClick={setIsOpenFalse}>
					<img
						src={timesSvg}
						className={styles.timesSvg}
						alt='close form icon'
					/>
				</button>
				{render}
			</div>
		</section>,
		document.body
	);
}

function SaveRow({ name, nestLevel, hasChildren, active, onClick }) {
	let width = (nestLevel + 1) * 25;
	if (hasChildren) width = width - 25;
	return (
		<button
			type='button'
			className={classNames(styles.saveRow, {
				[styles.saveRowActive]: active,
			})}
			onClick={onClick}
		>
			{!!width && <div style={{ width: `${width}px` }}></div>}
			{hasChildren && (
				<div className={styles.downSvgDiv}>
					<DownSvg className={styles.downSvg} />
				</div>
			)}
			<FolderSvg className={styles.folderSvg} />
			<span>{name}</span>
		</button>
	);
}

function SaveLearning({ modal, setIsOpenFalse }) {
	const { learningID: saveLearningID } = modal;
	const [user, setUser] = useUser();
	const [activeID, setActiveID] = useState('home');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const handleSave = async () => {
		if (loading) return;
		if (!saveLearningID) return;
		setLoading(true);
		setError('');
		// 1. Create saveLearning
		const learningID = getNanoid(SAVE_LEARNING_ID_LENGTH);
		const withinChapterID = activeID;
		try {
			const learningInput = {
				learningID,
				userID: user.userID,
				withinChapterID,
				type: 'saveLearning',
				saveLearningID,
			};
			await API.graphql(
				graphqlOperation(mutationCreateLearning, { input: learningInput })
			);
		} catch (error) {
			console.log('Error saving learning: ', error);
			setLoading(false);
			setError(GENERAL_ERROR_MSG);
			return;
		}
		// 2. Create saveLearning in learningOrder
		const [learningOrder, success] = getNewOrder(
			user.learningOrder,
			withinChapterID,
			learningID
		);
		if (success) {
			try {
				const userInput = {
					userID: user.userID,
					learningOrder,
				};
				const newUserProfile = await API.graphql(
					graphqlOperation(mutationUpdateUser, { input: userInput })
				);
				const newUser = newUserProfile.data.updateUser;
				setUser((prev) => update(prev, { $merge: newUser }));
			} catch (error) {
				console.log('Error updating learningOrder: ', error);
			}
			// ignore error
		}
		setIsOpenFalse();
	};

	const allChapters = getAllChapters(user.chapterOrder);
	let chapterNameObj = {};
	if (user.chapterName) {
		try {
			chapterNameObj = JSON.parse(user.chapterName);
		} catch (error) {
			console.log('Error parsing chapterName: ', error);
		}
	}

	return (
		<div className={styles.box}>
			{error && <div className={styles.error}>{error}</div>}
			<h1 className={styles.saveH1}>Save to Chapter</h1>
			<div className={styles.saveChapterDiv}>
				{allChapters.map(({ id, nestLevel, hasChildren, parent }) => {
					let name;
					if (id === 'home') name = 'Home';
					else name = chapterNameObj[id] || 'Untitled Chapter';
					return (
						<SaveRow
							key={id}
							name={name}
							nestLevel={nestLevel}
							hasChildren={hasChildren}
							active={id === activeID}
							onClick={() => {
								setActiveID(id);
							}}
						/>
					);
				})}
			</div>
			<div className={styles.buttonGroup}>
				<PrimaryButton primary={false} onClick={setIsOpenFalse}>
					Cancel
				</PrimaryButton>
				<PrimaryButton onClick={handleSave} loading={loading}>
					Save
				</PrimaryButton>
			</div>
		</div>
	);
}

export default Modal;
