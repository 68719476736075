import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { timesSvg } from 'icons';
import styles from './DraftModal.module.css';
import { GetSourceSvg } from 'components/DropdownInput';
import PrimaryButton from 'components/Button';
import { getPlural, useEnterCallback } from 'utils';
import update from 'immutability-helper';
import { useState } from 'react';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationDeleteLearning } from 'graphql/customMutations';

function DraftModal({
	isOpen,
	setIsOpen,
	drafts,
	setDrafts,
	newLearning,
	setNewLearning,
}) {
	const setIsOpenFalse = () => setIsOpen(false);
	// Close modal when click outside of node or press escape key
	const nodeRef = useRef(null);
	// Close modal when press escape key
	useEnterCallback({ isOpen, callback: setIsOpenFalse, key: 'Escape' });
	const [error, setError] = useState('');

	return ReactDOM.createPortal(
		<section className={styles.section}>
			<div className={styles.div} ref={nodeRef}>
				<button className={styles.btnTimes} onClick={setIsOpenFalse}>
					<img
						src={timesSvg}
						className={styles.timesSvg}
						alt='close form icon'
					/>
				</button>
				{error && <p className={styles.error}>{error}</p>}
				<div className={styles.draftBox}>
					{drafts.map((item) => (
						<DraftLearning
							key={item.learningID}
							draftLearning={item}
							setIsOpenFalse={setIsOpenFalse}
							setDrafts={setDrafts}
							setNewLearning={setNewLearning}
							setError={setError}
							isCurrentDraft={newLearning.learningID === item.learningID}
						/>
					))}
				</div>
			</div>
		</section>,
		document.body
	);
}

function DraftLearning({
	draftLearning,
	setIsOpenFalse,
	setDrafts,
	setNewLearning,
	setError,
	isCurrentDraft,
}) {
	const {
		learningID,
		source,
		title,
		learningBrief,
		numWords,
		updatedAt,
	} = draftLearning;
	const description = learningBrief.slice(0, 40);
	const newOrEdit = learningID.startsWith('draft-new')
		? 'New learning'
		: 'Edit learning';
	const numWordsText = `${numWords} word${getPlural(numWords)}`;
	const openClick = () => {
		const temp = {
			linkInfo: JSON.parse(draftLearning.linkInfo),
			learning: JSON.parse(draftLearning.learning),
		};
		const finalDraftLearning = update(draftLearning, { $merge: temp });
		setNewLearning(finalDraftLearning);
		setIsOpenFalse();
	};
	const deleteClick = async () => {
		try {
			const input = {
				learningID,
			};
			await API.graphql(graphqlOperation(mutationDeleteLearning, { input }));
			setDrafts((drafts) =>
				drafts.filter((item) => item.learningID !== learningID)
			);
			if (isCurrentDraft) {
				setNewLearning(DEFAULT_NEW_LEARNING);
			}
		} catch (error) {
			console.log('Error deleting draft: ', error);
			setError(GENERAL_ERROR_MSG);
		}
	};
	return (
		<div className={styles.draftLearning}>
			<div>
				<div className={styles.title}>
					{GetSourceSvg({ source, className: styles.svg })}
					<h1 className={styles.h1}>{title}</h1>
				</div>
				<p>
					{description}
					{learningBrief.length > 40 ? '...' : ''}
				</p>
				<p className={styles.saveDate}>
					{newOrEdit} · {numWordsText} · saved on {getDate(new Date(updatedAt))}
				</p>
			</div>
			<div className={styles.buttonGroup}>
				<PrimaryButton children={'Open'} onClick={openClick} />
				<PrimaryButton
					primary={false}
					children={'Delete'}
					className={styles.deleteButton}
					onClick={deleteClick}
				/>
			</div>
		</div>
	);
}

function getDate(date = new Date()) {
	const year = date.getFullYear();
	const thisYear = new Date().getFullYear();
	const displayYear = year !== thisYear;
	const options = {
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	};
	if (displayYear) options.year = 'numeric';
	return date.toLocaleString(undefined, options);
}

const DEFAULT_NEW_LEARNING = {
	learningID: '',
	source: 'Learning Note',
	linkInfo: { link: '' },
	tag: '',
	title: '',
	description: '',
	learning: [
		{
			type: 'paragraph',
			children: [{ text: '' }],
		},
	],
};

export default DraftModal;
