/**
 * Returns link to profile
 *
 * @param {String} profileURL
 * @param {Number} profileURLIdx
 * @returns {String}
 * @example
 * // returns '/journey/xitang-5'
 * getProfileLink("xitang", 5)
 */

const getProfileLink = (profileURL, profileURLIdx) => {
	if (profileURLIdx === 1) return `/journey/${profileURL}`;
	else return `/journey/${profileURL}-${profileURLIdx}`;
};

export default getProfileLink;
