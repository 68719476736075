import { useEffect } from 'react';
import { useUser } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningsByFollowing } from 'graphql/customQueries';
import { GENERAL_ERROR_MSG } from 'utils/constants';

/**
 * useGetLearningsByFollowing hooks query and set learnings from whom the
 * user follows.
 *
 * @param {Object}
 */
const useGetLearningsByFollowing = ({ active, setLearnings, setError }) => {
	const [user] = useUser();
	useEffect(() => {
		if (!active) return;
		if (user.isLoading) return;
		if (!user.userID) {
			setError(`Sign in to see what is in your home's feed 😉`);
			return;
		}

		if (user.numFollowing === 0) {
			setError(
				'Welcome to TipStory! This is the best place to discover helpful and interesting learnings. Find some people to follow now 😉'
			);
			return;
		}

		const getLearningsByFollowing = async () => {
			const variables = {
				userID: user.userID,
				limit: 200,
			};
			try {
				const learningRes = await API.graphql({
					...graphqlOperation(queryGetLearningsByFollowing, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const followingArray = learningRes.data.getFollowingByUserID.items;
				const learningArray = extractLearning(followingArray);
				if (learningArray.length === 0) {
					setError(
						'Welcome to TipStory! This is the best place to discover helpful and interesting learnings. Find more people to follow now 😉'
					);
					return;
				}
				sortLearningByLatest(learningArray);
				setLearnings(learningArray);
			} catch (error) {
				console.log('Error getting following learning: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};
		getLearningsByFollowing();
	}, [
		active,
		user.isLoading,
		user.userID,
		user.numFollowing,
		user.isLogin,
		setLearnings,
		setError,
	]);

	// Reset state when not active
	useEffect(() => {
		if (!active) {
			setLearnings([]);
			setError('');
		}
	}, [active, setLearnings, setError]);
};

const extractLearning = (followingArray) => {
	const learningArray = [];
	followingArray.forEach((item) => {
		const { connectLearning, ...connectUser } = item.connectFollowUser;
		const userLearnings = connectLearning.items;
		if (userLearnings.length === 0) return;
		userLearnings.forEach((learning) => {
			learningArray.push({ connectUser, ...learning });
		});
	});
	return learningArray;
};

const sortLearningByLatest = (learningArray) => {
	if (learningArray.length === 0) return;
	learningArray.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
};

export default useGetLearningsByFollowing;
