import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { timesSvg } from 'icons';
import styles from './CommentsModal.module.css';
import PrimaryButton from 'components/Button';
import { useCloseDropdown } from 'utils';
import classNames from 'classnames';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationDeleteComment } from 'graphql/customMutations';
import update from 'immutability-helper';
import { useParams } from 'react-router';

function CommentsModal({ modal, setModal, ...props }) {
	const { isOpen, state } = modal;
	const setIsOpenFalse = () => setModal(() => ({ isOpen: false, state: '' }));
	// Close modal when click outside of node or press escape key
	const nodeRef = useRef(null);
	useCloseDropdown({
		node: nodeRef,
		isOpen: isOpen,
		setIsOpen: setIsOpenFalse,
	});

	let render;
	switch (state) {
		case 'deleteComment':
			render = (
				<DeleteComment
					modal={modal}
					setIsOpenFalse={setIsOpenFalse}
					{...props}
				/>
			);
			break;
		default:
			render = null;
	}

	return ReactDOM.createPortal(
		<section
			className={classNames(styles.section, { [styles.displayNone]: !isOpen })}
		>
			<div className={styles.div} ref={nodeRef}>
				<button className={styles.btnTimes} onClick={setIsOpenFalse}>
					<img
						src={timesSvg}
						className={styles.timesSvg}
						alt='close form icon'
					/>
				</button>
				{render}
			</div>
		</section>,
		document.body
	);
}

function DeleteComment({ modal, setIsOpenFalse, setComments, setLearnings }) {
	const { deleteInput, nested, parentCommentID } = modal;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	// parentLearningID is used to fix bug in saved learning
	const { learningID: parentLearningID } = useParams();

	const handleDelete = async () => {
		if (loading) return;
		setLoading(true);
		setError('');
		let success;
		if (!nested) {
			success = deleteComment(
				deleteInput,
				setComments,
				setLearnings,
				parentLearningID
			);
		} else {
			success = deleteNestedComment(
				deleteInput,
				parentCommentID,
				setComments,
				setLearnings,
				parentLearningID
			);
		}
		if (success) setIsOpenFalse();
		else {
			setLoading(false);
			setError(GENERAL_ERROR_MSG);
		}
	};

	return (
		<div className={styles.box}>
			<div className={styles.maxWidth}>
				{error && <div className={styles.error}>{error}</div>}
				<h1 className={styles.saveH1}>Delete Comment</h1>
				<p>Are you sure about deleting your comment?</p>
				<div className={styles.buttonGroup}>
					<PrimaryButton primary={false} onClick={setIsOpenFalse}>
						Cancel
					</PrimaryButton>
					<PrimaryButton onClick={handleDelete} disable={loading}>
						Delete
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
}

const deleteComment = async (
	input,
	setComments,
	setLearnings,
	parentLearningID
) => {
	try {
		await API.graphql(graphqlOperation(mutationDeleteComment, { input }));
		const { learningID, commentID } = input;
		setComments((prev) => {
			const comments = prev[learningID];
			const idx = comments.findIndex((item) => item.commentID === commentID);
			if (idx === -1) return prev;
			return update(prev, { [learningID]: { $splice: [[idx, 1]] } });
		});
		setLearnings((prev) =>
			update(prev, {
				[parentLearningID]: {
					numComment: { $apply: (n) => n - 1 },
				},
			})
		);
		return true;
	} catch (error) {
		console.log('Error deleting comment: ', error);
	}
};

const deleteNestedComment = async (
	input,
	parentCommentID,
	setComments,
	setLearnings,
	parentLearningID
) => {
	try {
		await API.graphql(graphqlOperation(mutationDeleteComment, { input }));
		const { learningID, commentID } = input;
		setComments((prev) => {
			const comments = prev[learningID];
			const parentIdx = comments.findIndex(
				(item) => item.commentID === parentCommentID
			);
			if (parentIdx === -1) return prev;
			const idx = comments[parentIdx].connectComment.items.findIndex(
				(item) => item.commentID === commentID
			);
			if (idx === -1) return prev;
			const update1 = update(prev, {
				[learningID]: {
					[parentIdx]: { numReply: { $apply: (n) => n - 1 } },
				},
			});
			return update(update1, {
				[learningID]: {
					[parentIdx]: { connectComment: { items: { $splice: [[idx, 1]] } } },
				},
			});
		});
		setLearnings((prev) =>
			update(prev, {
				[parentLearningID]: {
					numComment: { $apply: (n) => n - 1 },
				},
			})
		);
		return true;
	} catch (error) {
		console.log('Error deleting comment: ', error);
	}
};

export default CommentsModal;
