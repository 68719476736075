import update from 'immutability-helper';
import React, { useState } from 'react';
import styles from './Reads.module.css';
import { useUser } from 'utils';
import { useGetReads } from '../hooks';
import ReadCard from './ReadCard';
import { useSetScrollToBottom } from 'components/Home/hooks';

function Reads({ profile }) {
	const [reads, setReads] = useState([]);
	const [error, setError] = useState('');

	// Hook for infinite scroll refetch
	const [isBottom, setIsBottom] = useState(false);
	useSetScrollToBottom({ active: reads.length !== 0, setIsBottom });

	// Get reads of user
	useGetReads({ profile, isBottom, setIsBottom, setReads, setError });

	const empty = reads.length === 0;
	const displayEmptyMsg = empty && !error;
	const reducedReads = reads.reduce(readReducer, []);

	return (
		<div className={styles.reads}>
			<div className={styles.readsContainer}>
				{error && <Error error={error} />}
				{displayEmptyMsg && <EmptyMsg profile={profile} />}
				{!empty &&
					reducedReads.map((item) => (
						<ReadCard key={item.learningID} learning={item} />
					))}
			</div>
		</div>
	);
}

function Error({ error }) {
	return <h1 className={styles.error}>{error}</h1>;
}

function EmptyMsg({ profile }) {
	const [user] = useUser();
	const isAuthor = user.userID === profile.userID;
	const firstMsg = `${
		isAuthor ? 'Your' : 'This'
	} read list is a little empty 😮`;
	const secondMsg =
		isAuthor &&
		'Time to explore around and discover helpful and interesting reads 😃';

	return (
		<p className={styles.emptyMsg}>
			{firstMsg}
			<br />
			{secondMsg}
		</p>
	);
}

// readReducer combines same read learning from three different types
// ['read', 'helpful', 'interesting']
const readReducer = (prevArray, currentRead) => {
	const idx = prevArray.findIndex(
		(item) => item.learningID === currentRead.learningID
	);
	// New Read
	if (idx === -1) {
		const { learningID, type, connectLearning } = currentRead;
		// Add only when learning exists
		if (connectLearning) {
			const newRead = { learningID, type: [type], ...connectLearning };
			return update(prevArray, { $push: [newRead] });
		}
		return prevArray;
	}
	// Existing read
	else {
		return update(prevArray, {
			[idx]: { type: { $push: [currentRead.type] } },
		});
	}
};

export default Reads;
