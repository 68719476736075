import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationUpdateLearning,
	mutationCreateTag,
} from 'graphql/customMutations';
import { getTagList, getTextFromSlateObj, getWordCount } from 'utils';

const updateLearning = async ({ newLearning, history, user }) => {
	const {
		learningID,
		source,
		linkInfo,
		tag,
		title,
		description,
		learning,
		// withinChapterID,
		createdAt,
	} = newLearning;
	// 1. Update learning
	try {
		const learningText = getTextFromSlateObj(learning);
		const learningInput = {
			learningID,
			source,
			linkInfo: JSON.stringify(linkInfo),
			tag,
			title,
			description,
			learning: JSON.stringify(learning),
			learningBrief: learningText.slice(0, 500),
			// withinChapterID,
			numWords: getWordCount(learningText),
		};
		await API.graphql(
			graphqlOperation(mutationUpdateLearning, { input: learningInput })
		);

		// 2. Update tag if within 3 days of created learning
		const createdAtDate = new Date(createdAt);
		const nowDate = new Date();
		const createdAtMs = createdAtDate.getTime();
		const nowMs = nowDate.getTime();
		const elapsedSec = (nowMs - createdAtMs) / 1000;
		const threeDaysInSec = 3 * 24 * 60 * 60;
		if (elapsedSec < threeDaysInSec) {
			const tagList = getTagList(tag);
			if (tagList.length > 0) {
				tagList.forEach(async (tag) => {
					try {
						const tagInput = {
							learningID,
							tag: tag.toLowerCase(),
							userID: user.userID,
						};
						await API.graphql(
							graphqlOperation(mutationCreateTag, { input: tagInput })
						);
					} catch (error) {
						console.log('Error creating tag: ', tag);
					}
				});
			}
		}

		// 3. Go to learning
		const learningLink = `/learning/${learningID}`;
		history.push(learningLink);
		return true;
	} catch (error) {
		console.log('Error updating learning: ', error);
	}
};

export default updateLearning;
