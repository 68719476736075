import { HelpfulActiveSvg, InterestingActiveSvg } from 'icons';
import React from 'react';
import styles from './Icons.module.css';
import classNames from 'classnames';

function Icons(props) {
	const { numHelpful = 0, numInteresting = 0 } = props;
	const ICONS = [
		{
			svg: <HelpfulActiveSvg />,
			title: 'Helpfuls',
			num: numHelpful,
			display: numHelpful > 0,
		},
		{
			svg: <InterestingActiveSvg />,
			title: 'Interestings',
			num: numInteresting,
			display: numInteresting > 0,
		},
	];
	const displayIcons = ICONS.filter((item) => item.display);
	if (displayIcons.length === 0) return null;

	// Update font based on readStatus
	const { learningID, readList } = props;
	const readStatus = readList[learningID] || [];
	const helpfulActive = readStatus.includes('helpful');
	const interestingActive = readStatus.includes('interesting');

	return (
		<ul className={styles.icons}>
			{displayIcons.map((item) => {
				const { svg, title, num } = item;
				return (
					<li key={title} className={styles.iconsLi}>
						<div className={styles.svg}>{svg}</div>
						<span
							className={classNames({
								[styles.helpfulActive]: title === 'Helpfuls' && helpfulActive,
								[styles.interestingActive]:
									title === 'Interestings' && interestingActive,
							})}
						>
							{num}
						</span>
					</li>
				);
			})}
		</ul>
	);
}

export default Icons;
