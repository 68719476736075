import React, { useRef, useState } from 'react';
import styles from './ShareButton.module.css';
import {
	ShareSvg,
	LinkSvg,
	FacebookSvg,
	MessengerSvg,
	TwitterSvg,
	RedditSvg,
	EmailSvg,
} from 'icons';
import { getIsMobile, useCloseDropdown } from 'utils';
import copy from 'copy-to-clipboard';
import { FACEBOOK_APP_ID_PROD, TWITTER_USERNAME } from 'utils/constants';

function ShareButton() {
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });

	const handleSelectClick = () => setIsOpen((prev) => !prev);
	const closeDropdown = () => setIsOpen(false);

	return (
		<div className={styles.shareButton} ref={node}>
			<button
				type={'button'}
				className={styles.button}
				onClick={handleSelectClick}
			>
				<ShareSvg className={styles.svg} />
				<span>Share</span>
			</button>
			{isOpen && <DropdownList closeDropdown={closeDropdown} />}
		</div>
	);
}

function DropdownList(props) {
	return (
		<div className={styles.dropdownList}>
			<CopyLink {...props} />
			<FacebookShare {...props} />
			<MessengerShare {...props} />
			<TwitterShare {...props} />
			<RedditShare {...props} />
			<EmailShare {...props} />
		</div>
	);
}

function DropdownItem({ name, svg, onClick }) {
	return (
		<button type={'button'} className={styles.dropdownItem} onClick={onClick}>
			<div className={styles.dropdownSvg}>{svg}</div>
			<span className={styles.dropdownText}>{name}</span>
		</button>
	);
}

function CopyLink({ closeDropdown }) {
	const copyLink = () => {
		copy(window.location.href);
		closeDropdown();
	};
	return (
		<DropdownItem name={'Copy Link'} svg={<LinkSvg />} onClick={copyLink} />
	);
}

function FacebookShare({ closeDropdown }) {
	const facebookShare = () => {
		const currentLink = encodeURIComponent(`${window.location.href}`);
		const shareLink = `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID_PROD}&href=${currentLink}&display=popup`;
		window.open(shareLink, '_blank', 'noreferrer');
		closeDropdown();
	};
	return (
		<DropdownItem
			name={'Facebook'}
			svg={<FacebookSvg />}
			onClick={facebookShare}
		/>
	);
}

function MessengerShare({ closeDropdown }) {
	const isMobile = getIsMobile();
	if (!isMobile) return null;
	const messengerShare = () => {
		const currentLink = encodeURIComponent(`${window.location.href}`);
		const shareLink = `fb-messenger://share?app_id=${FACEBOOK_APP_ID_PROD}&link=${currentLink}`;
		window.open(shareLink);
		closeDropdown();
	};
	return (
		<DropdownItem
			name={'Messenger'}
			svg={<MessengerSvg />}
			onClick={messengerShare}
		/>
	);
}

function TwitterShare({ closeDropdown }) {
	const twitterShare = () => {
		const currentLink = encodeURIComponent(window.location.href);
		const [title, author] = document.title.split(' | ');
		const text = encodeURI(`${title} - ${author}`);
		const shareLink = `https://twitter.com/intent/tweet?text=${text}&url=${currentLink}&via=${TWITTER_USERNAME}&related=${TWITTER_USERNAME}`;
		window.open(shareLink, '_blank', 'noreferrer');
		closeDropdown();
	};
	return (
		<DropdownItem
			name={'Twitter'}
			svg={<TwitterSvg />}
			onClick={twitterShare}
		/>
	);
}

function RedditShare({ closeDropdown }) {
	const shareToReddit = () => {
		const currentLink = encodeURIComponent(`${window.location.href}`);
		const shareLink = `https://www.reddit.com/submit?url=${currentLink}&title=${document.title}`;
		window.open(shareLink, '_blank', 'noreferrer');
		closeDropdown();
	};
	return (
		<DropdownItem name={'Reddit'} svg={<RedditSvg />} onClick={shareToReddit} />
	);
}

function EmailShare({ closeDropdown }) {
	const shareViaEmail = () => {
		const currentLink = encodeURIComponent(`${window.location.href}`);
		const text = `Check out this helpful interesting learning ${currentLink}`;
		const shareLink = `mailto:?subject=${document.title}&body=${text}`;
		window.open(shareLink, '_blank', 'noreferrer');
		closeDropdown();
	};
	return (
		<DropdownItem name={'Email'} svg={<EmailSvg />} onClick={shareViaEmail} />
	);
}

export default ShareButton;
