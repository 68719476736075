import { useState, useEffect } from 'react';
import { Auth, API, graphqlOperation, Hub } from 'aws-amplify';
import { queryGetUser } from 'graphql/customQueries';
import { queryGetLastProfileURLIdx } from 'graphql/customQueries';
import { mutationCreateUser } from 'graphql/customMutations';
import slugify from './slugify';

const userDefault = { isLogin: false, isLoading: true, userID: '' };

/**
 * useCreateUser hook checks if the user is login and then query user's data
 * from database for login user. If user has no data in database (meaning
 * first time login user), it creates a new user profile for the user in the database.
 *
 * It returns an array, in which the zero index is user, and the first index is
 * setUser. useCreateUser should only called once when App is first launched.
 * (Other components should call useUser() to access user and setUser)
 *
 * @returns {Array} [user, setUser]
 */

const useCreateUser = () => {
	const [user, setUser] = useState(userDefault);

	useEffect(() => {
		const updateUser = async () => {
			let authUser = null;
			let firstTimeLogin = false;
			// Check if user is login
			try {
				authUser = await Auth.currentAuthenticatedUser();
				// console.log('Auth Success: ', authUser);
			} catch (error) {
				setUser({ isLogin: false, isLoading: false });
				// console.log('Auth Error: ', error);
			}

			// Query user profile if login
			if (authUser) {
				try {
					const userProfile = await API.graphql(
						graphqlOperation(queryGetUser, {
							//userID: authUser.attributes.sub,
							userID: authUser.username,
						})
					);
					const data = userProfile.data.getUser;
					if (data) {
						setUser({ isLogin: true, isLoading: false, ...data });
						// console.log('Get profile successfully');
					} else {
						firstTimeLogin = true;
					}
				} catch (error) {
					setUser({ isLogin: false, isLoading: false });
					console.log('graphQLError reading profile: ', error);
				}
			}

			// Create user profile if first time login
			if (firstTimeLogin) {
				let {
					given_name: firstName = '',
					family_name: lastName = '',
					picture: profileImg = '',
					identities,
				} = authUser.attributes;
				const profileURL = slugify(`${firstName}-${lastName}`.toLowerCase());

				let profileURLIdx = 1;
				// Get last profileURLIdx
				try {
					const profileRes = await API.graphql(
						graphqlOperation(queryGetLastProfileURLIdx, {
							profileURL,
						})
					);
					const profileResItems = profileRes.data.getUserByProfileURL.items;
					if (profileResItems.length > 0) {
						profileURLIdx = profileResItems[0].profileURLIdx + 1;
					}
				} catch (error) {
					console.log('Error getting last profileURLIdx: ', error);
				}

				// Extract image from facebook
				if (
					identities &&
					profileImg &&
					identities.includes(`"providerType":"Facebook"`)
				) {
					try {
						const pictureObj = JSON.parse(profileImg);
						profileImg = pictureObj.data.url;
					} catch (error) {
						profileImg = '';
						console.log('Parsing Facebook picture error: ', error);
					}
				}

				// Create user profile
				const userInput = {
					profileURL,
					profileURLIdx,
					firstName,
					lastName,
					profileImg,
					userID: authUser.username,
					//userID: authUser.attributes.sub,
				};
				try {
					const newUserProfile = await API.graphql(
						graphqlOperation(mutationCreateUser, { input: userInput })
					);
					const data = newUserProfile.data.createUser;
					setUser({ isLogin: true, isLoading: false, ...data });
				} catch (error) {
					setUser({ isLogin: false, isLoading: false });
					console.log('Error creating profile: ', error);
				}
			}
		};
		// Listen for login/signout events
		Hub.listen('auth', updateUser);
		// Invoke function for first time
		updateUser();
		return () => {
			Hub.remove('auth', updateUser);
		};
	}, []);

	return [user, setUser];
};

export default useCreateUser;
