import React from 'react';
import Title from '../Title';
import Sidebar from './Sidebar';
import styles from './TrackRead.module.css';

function TrackRead(props) {
	return (
		<div>
			<div className={styles.header}>
				<Title>
					3. <span className='emoji'>✔️</span>Track your learning progress
				</Title>
				<p className={styles.p}>
					TipStory Sidebar is your learning companion that helps you keep track
					of what you have read and learned.
				</p>
			</div>
			<Sidebar {...props} />
			<Sidebar {...props} desktopStyle={false} />
		</div>
	);
}

export default TrackRead;
