import React from 'react';
import { getFormatDate } from 'utils';
import styles from './SharingOverview.module.css';
import { getHours } from './utils';
import { GetSourceSvg } from 'components/DropdownInput';

function SharingOverview({ scholar, learningsObj }) {
	return (
		<div className={styles.sharingOverview}>
			<h1 className={styles.h1}>
				{scholar.firstName} {scholar.lastName}'s Writing & Sharing Overview
			</h1>
			{learningsObj.sharingArray}
			<div className={styles.bottomDiv}>
				<div className={styles.totalDiv}>
					<div className={styles.textDiv}>Total Words: </div>
					<div>{learningsObj.totalNumWords}</div>
				</div>
				<div className={styles.totalDiv}>
					<div className={styles.textDiv}>Total Writing Hours: </div>
					<div>{learningsObj.totalHours}</div>
				</div>
				<div className={styles.totalDiv}>
					<div className={styles.textDiv}>Total Volunteer Hours: </div>
					<div>{learningsObj.totalVolunteerHours}</div>
				</div>
				<br />
				<div className={styles.bottomP}>
					**Volunteer hours may be lower than writing hours because student can
					earn up to a maximum of 5 volunteer hours per learning post and a
					maximum of 10 volunteer hours on any given month.
				</div>
			</div>
		</div>
	);
}

const monthLookUp = {
	'01': 'January',
	'02': 'February',
	'03': 'March',
	'04': 'April',
	'05': 'May',
	'06': 'June',
	'07': 'July',
	'08': 'August',
	'09': 'September',
	10: 'October',
	11: 'November',
	12: 'December',
};

export const getLearningsObj = (learningsWrite, year) => {
	const learningsObj = {
		sharingArray: [],
		totalHours: 0,
		totalVolunteerHours: 0,
		totalNumWords: 0,
	};
	Object.keys(monthLookUp)
		.sort()
		.forEach((month) => {
			const tempArray = [];
			const capMonthVolunteerHours = 10;
			let monthNumWords = 0;
			let monthHours = 0;
			let monthVolunteerHours = 0;
			// Get learnings of month
			const monthLearnings = learningsWrite.filter((learning) =>
				learning.createdAt.startsWith(`${year}-${month}`)
			);
			if (monthLearnings.length === 0) return;
			const sortedMonthLearnings = monthLearnings.sort((a, b) =>
				a.createdAt.localeCompare(b.createdAt)
			);
			sortedMonthLearnings.forEach((learning) => {
				const { numWords } = learning;
				// Add numWords to monthNumWords and totalWords
				monthNumWords += numWords;
				learningsObj.totalNumWords += numWords;
				// Add uncap hours to monthHours and totalHours
				const numHours = getHours(numWords, false);
				monthHours += numHours;
				learningsObj.totalHours += numHours;
				// Add cap hours to monthVolunteerHours and totalVolunteerHours
				const numVolunteerHours = getHours(numWords);
				const remainHours = capMonthVolunteerHours - monthVolunteerHours;
				if (remainHours >= numVolunteerHours) {
					monthVolunteerHours += numVolunteerHours;
					learningsObj.totalVolunteerHours += numVolunteerHours;
				} else if (remainHours > 0) {
					monthVolunteerHours += remainHours;
					learningsObj.totalVolunteerHours += remainHours;
				}
				// Add learning to tempArray
				tempArray.push(
					<Learning key={learning.learningID} learning={learning} />
				);
			});
			tempArray.unshift(
				<Month
					key={month}
					month={month}
					monthNumWords={monthNumWords}
					monthHours={monthHours}
				/>
			);
			tempArray.forEach((item) => {
				learningsObj.sharingArray.push(item);
			});
		});
	return learningsObj;
};

function Month({ month, monthNumWords, monthHours }) {
	return (
		<div className={styles.month}>
			{monthLookUp[month]} · {monthNumWords} words · {monthHours} writing hours
		</div>
	);
}

function Learning({ learning }) {
	const { learningID, source, title, numWords, createdAt } = learning;
	const formatDate = getFormatDate(createdAt).split(',')[0];
	return (
		<div className={styles.learning}>
			{GetSourceSvg({ source, className: styles.svg })}
			<div>
				<a href={`/learning/${learningID}`} className={styles.link}>
					<h1 className={styles.learningH1}>{title}</h1>
				</a>
				<div>
					{formatDate} · {numWords} words · {getHours(numWords, false)} hours
				</div>
			</div>
		</div>
	);
}

export default SharingOverview;
