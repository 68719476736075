import { useEffect, useRef } from 'react';
import { useUser } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningsByLatest } from 'graphql/customQueries';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import update from 'immutability-helper';

/**
 * useGetLearningsByLatest hooks fetches and sets learnings from latest.
 * It also refetches new learnings when user scrolls to bottom.
 *
 * @param {Object}
 */
const useGetLearningsByLatest = ({
	active,
	isBottom,
	setIsBottom,
	setLearnings,
	setError,
}) => {
	const [user] = useUser();
	const isFirstFetch = useRef(true);
	const nextToken = useRef(null);
	const loading = useRef(false);

	useEffect(() => {
		if (!active) return;
		if (user.isLoading) return;

		const variables = { limit: 10 };

		// If not first fetch, refetch with nextToken
		// Return if nextToken is null or not at bottom
		if (!isFirstFetch.current) {
			if (!nextToken.current) return;
			if (!isBottom) return;
			variables.nextToken = nextToken.current;
		}

		if (loading.current) return;

		const getLearningsByLatest = async () => {
			try {
				const learningRes = await API.graphql({
					...graphqlOperation(queryGetLearningsByLatest, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const learningArray = learningRes.data.getLearningByLatest.items;
				if (isFirstFetch.current) setLearnings(learningArray);
				else setLearnings((prev) => update(prev, { $push: learningArray }));
				nextToken.current = learningRes.data.getLearningByLatest.nextToken;
				setIsBottom(false);
				if (isFirstFetch.current) isFirstFetch.current = false;
			} catch (error) {
				console.log('Error getting latest learnings: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};

		loading.current = true;
		getLearningsByLatest();
		loading.current = false;
	}, [
		active,
		user.isLoading,
		isBottom,
		user.isLogin,
		setIsBottom,
		setLearnings,
		setError,
	]);

	// Reset state when not active
	useEffect(() => {
		if (!active) {
			setLearnings([]);
			setError('');
			isFirstFetch.current = true;
			nextToken.current = null;
			loading.current = false;
		}
	}, [active, setLearnings, setError]);
};

export default useGetLearningsByLatest;
