import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'utils';
import { LOCAL_STORAGE_SAVE_PATHNAME } from 'utils/constants';

/**
 * useAutoExternalSignInRedirect hook redirects user to previous save location.
 */

const useAutoExternalSignInRedirect = () => {
	const [user] = useUser();
	const history = useHistory();

	useEffect(() => {
		if (user.isLogin) {
			const savePathname = localStorage.getItem(LOCAL_STORAGE_SAVE_PATHNAME);
			if (savePathname) {
				localStorage.setItem(LOCAL_STORAGE_SAVE_PATHNAME, '');
				history.push(savePathname);
			}
		}
	}, [user.isLogin, history]);
};

export default useAutoExternalSignInRedirect;
