import { customAlphabet } from 'nanoid';
// https://github.com/ai/nanoid

const alphabet =
	'0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

/**
 * Return a 15 digit nanoid that is composed of Alphanumeric Characters.
 * Warning: Alphanumeric chars only, including other chars (e.g. -) would
 * probably break some of the codes.
 *
 * @param {Number} numChars
 */
const getNanoid = (numChars = 15) => {
	const nanoid = customAlphabet(alphabet, numChars);
	return nanoid();
};

export default getNanoid;
