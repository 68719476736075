import React from 'react';
import styles from './LinkCard.module.css';
import TextTruncate from 'react-text-truncate';
import { ExternalLinkSvg } from 'icons';

function LinkCard(props) {
	const { linkInfo = '{}' } = props;

	let linkInfoObj = {};
	try {
		if (linkInfo) linkInfoObj = JSON.parse(linkInfo);
	} catch (error) {
		console.log('Error parsing linkInfo: ', error);
	}
	if (!linkInfoObj.link) return null;

	return (
		<div className={styles.linkCard}>
			<CardTop {...linkInfoObj} />
			<div className={styles.mainDiv}>
				<ExternalLink {...linkInfoObj} />
				<Title {...linkInfoObj} />
				<Description {...linkInfoObj} />
			</div>
		</div>
	);
}

function CardTop(props) {
	const { title, videoLink, imageLink } = props;
	if (!videoLink && !imageLink) return null;

	if (videoLink)
		return (
			<iframe
				title={title}
				src={videoLink}
				frameBorder={'0'}
				className={styles.cardTop}
				allowFullScreen={true}
			/>
		);

	if (imageLink)
		return <img src={imageLink} alt={title} className={styles.cardTop} />;
}

function ExternalLink(props) {
	const { link, ogLink } = props;
	const finalLink = ogLink || link;
	if (!finalLink) return null;

	let displayLink = finalLink;
	const MAX_LINK_LENGTH = 45;
	if (finalLink.length > MAX_LINK_LENGTH)
		displayLink = `${finalLink.slice(0, MAX_LINK_LENGTH)}...`;

	return (
		<a
			href={finalLink}
			target={'_blank'}
			rel={'noopener noreferrer nofollow ugc'}
			className={styles.link}
		>
			{displayLink}
			<ExternalLinkSvg className={styles.linkSvg} />
		</a>
	);
}

function Title(props) {
	const { title } = props;
	if (!title) return null;

	return (
		<TextTruncate
			line={2}
			element={'div'}
			text={title}
			className={styles.title}
		/>
	);
}

function Description(props) {
	const { description } = props;
	if (!description) return null;

	return <TextTruncate line={1} element={'div'} text={description} />;
}

export default LinkCard;
