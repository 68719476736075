/**
 * getWordCount returns the number of words in an inputString.
 *
 * @param {String}
 */
const getWordCount = (inputString) => {
	if (inputString) {
		const strMatch = inputString.match(/(\w+)/g);
		if (strMatch) {
			return strMatch.length;
		}
	}
	return 0;
};

export default getWordCount;
