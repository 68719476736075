import { useEffect } from 'react';

/**
 * useEnterCallback hook is activated when a dropdown/modal opens (isOpen is true).
 * It performs a callback function when key (default to 'Enter') is pressed.
 *
 * @param {Object} {isOpen, callback, key}
 */

const useEnterCallback = ({ isOpen, callback, key = 'Enter' }) => {
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === key) {
				event.preventDefault();
				callback();
			}
		};
		if (isOpen) {
			document.addEventListener('keydown', handleKeyDown);
		} else {
			document.removeEventListener('keydown', handleKeyDown);
		}
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, callback, key]);
};

export default useEnterCallback;
