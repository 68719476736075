import { FolderSvg } from 'icons';
import React from 'react';
import styles from './Sidebar.module.css';
import SidebarLearning from './SidebarLearning';

function Sidebar({ readStatus, learnings, chapters, desktopStyle = true }) {
	const name = 'Master Yoda';
	const className = desktopStyle
		? `${styles.sidebar} ${styles.hideInMobile}`
		: `${styles.mobileSidebar} ${styles.hideInDesktop}`;
	return (
		<section className={className}>
			<h1 className={styles.sidebarTitle}>{name}'s Learning Journey</h1>
			<nav>
				{chapters.map((item) => (
					<SidebarChapter key={item.learningID} title={item.title} />
				))}
				{learnings.map((item, idx, arr) => (
					<SidebarLearning
						{...item}
						key={item.learningID}
						readStatus={item.learningID === 'main' ? readStatus : []}
						last={idx === arr.length - 1}
					/>
				))}
			</nav>
		</section>
	);
}

function SidebarChapter({ title }) {
	return (
		<div className={styles.sidebarChapter}>
			<div className={styles.sidebarChapterRow}>
				<div className={styles.sidebarSvgDiv}>
					<FolderSvg className={styles.sidebarSvg} />
				</div>
				<h1 className={styles.chapterH1}>{title}</h1>
			</div>
		</div>
	);
}

export default Sidebar;
