import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Learning.module.css';
import Comments from './Comments';
import { useGetLearningByID, useGetSidebar } from './hooks';
import Meta from './Meta';
import Sidebar from './Sidebar';
import Header from './Header';
import LearningArticle from './LearningArticle';

function Learning() {
	// learnings store all learings
	// key: learningID, value: an learning object
	const [learnings, setLearnings] = useState({});
	const { learningID } = useParams();
	const learning = learnings[learningID] || {};

	// userFollowed store user's followed status of other users
	// key: userID, value: Boolean (true means followed)
	const [userFollowed, setUserFollowed] = useState({});

	// sidebarVariable stores query parameters for useGetSidebar hook
	const [sidebarVariable, setSidebarVariable] = useState({
		userID: '',
		withinChapterID: '',
	});

	// sidebarLearnings store all sidebarLearnings
	// key: {userID}-{withinChapterID}, value: an array of learnings and chapters
	const [sidebarLearnings, setSidebarLearnings] = useState({});
	const sidebarKey = `${sidebarVariable.userID}-${sidebarVariable.withinChapterID}`;
	const sidebarLearning = sidebarLearnings[sidebarKey] || [];

	// readList store user's read status of learnings
	// key: learningID, value: Array of strings, e.g. ["helpful", "interesting", "read"]
	const [readList, setReadList] = useState({});

	const [error, setError] = useState('');

	// Hooks to get main learning and sidebar learnings
	useGetLearningByID({
		learnings,
		setLearnings,
		setUserFollowed,
		setSidebarVariable,
		setError,
	});
	useGetSidebar({
		sidebarVariable,
		sidebarLearnings,
		setSidebarLearnings,
		setReadList,
	});

	// Effect to scroll to top on change of learning
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [learningID]);

	return (
		<div className={styles.learning}>
			<Meta learning={learning} />
			<Sidebar
				sidebarLearning={sidebarLearning}
				readList={readList}
				sidebarVariable={sidebarVariable}
				setSidebarVariable={setSidebarVariable}
			/>
			<main className={styles.main}>
				<ErrorMsg error={error} />
				<Header learning={learning} />
				<LearningArticle
					learning={learning}
					setLearnings={setLearnings}
					userFollowed={userFollowed}
					setUserFollowed={setUserFollowed}
					readList={readList}
					setReadList={setReadList}
				/>
				<MobileSidebar
					sidebarLearning={sidebarLearning}
					readList={readList}
					sidebarVariable={sidebarVariable}
					setSidebarVariable={setSidebarVariable}
				/>
				<Comments learning={learning} setLearnings={setLearnings} />
			</main>
		</div>
	);
}

function ErrorMsg({ error }) {
	if (!error) return null;
	return (
		<div className={styles.error}>
			<h1 className={styles.errorH1}>{error}</h1>
		</div>
	);
}

function MobileSidebar(props) {
	return <Sidebar {...props} desktopStyle={false} />;
}

export default Learning;
