import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LearningCard from './LearningCard';
import Chapter from './Chapter';
import styles from './Journey.module.css';
import ContextMenu from './ContextMenu';
import { useParams } from 'react-router-dom';
import { getSortedLearnings, useUser } from 'utils';
import Breadcomb from './Breadcomb';
import { useGetJourney } from '../hooks';

// @refresh reset
function Journey({ profile }) {
	// journeys store all learings and chapters
	// key: {userID}-{withinChapterID}, value: an array of learnings and chapters
	const [journey, setJourney] = useState({});
	const [error, setError] = useState('');

	// readList store user's read status of learnings
	// key: learningID, value: Array of strings, e.g. ["helpful", "interesting", "read"]
	const [readList, setReadList] = useState({});

	// Get learnings and chapters in journey
	useGetJourney({ profile, journey, setJourney, setError, setReadList });

	// Current learnings and chapters
	const { withinChapterID = 'home' } = useParams();
	const key = `${profile.userID}-${withinChapterID}`;
	const currentPage = journey[key] || [];
	// Get and sort learnings and chapters
	const learnings = currentPage.filter(
		(i) => i.type === 'learning' || i.type === 'saveLearning'
	);
	const chapters = currentPage.filter((i) => i.type === 'chapter');
	const sortedLearnings = getSortedLearnings(
		profile.learningOrder,
		withinChapterID,
		learnings
	);
	const sortedChapters = getSortedLearnings(
		profile.chapterOrder,
		withinChapterID,
		chapters
	);

	// isAuthor
	const { userID } = profile;
	const [user] = useUser();
	const isAuthor = user.userID === userID;

	const displayEmptyMsg = currentPage.length === 0 && !error;
	const displayChapter = sortedChapters.length !== 0;
	const displayLearning = sortedLearnings.length !== 0;

	return (
		<DndProvider backend={HTML5Backend}>
			<div className={styles.journey} tabIndex={'0'} id={'journey'}>
				<div className={styles.journeyContainer}>
					<Breadcomb profile={profile} setJourney={setJourney} />
					{error && <Error error={error} />}
					{displayEmptyMsg && <EmptyMsg profile={profile} />}
					{displayChapter && (
						<>
							<h1 className={styles.chapterTitle}>Chapters</h1>
							<div className={styles.chapterContainer}>
								{sortedChapters.map((item, index) => (
									<Chapter
										key={item.learningID}
										chapter={item}
										index={index}
										isAuthor={isAuthor}
										chapters={sortedChapters}
										setJourney={setJourney}
									/>
								))}
							</div>
						</>
					)}
					{displayLearning && (
						<>
							<h1 className={styles.learningTitle}>Learnings</h1>
							<div className={styles.chapterContainer}>
								{sortedLearnings.map((item, index) => (
									<LearningCard
										key={item.parentLearningID || item.learningID}
										readStatus={readList[item.learningID]}
										learning={item}
										index={index}
										isAuthor={isAuthor}
										learnings={sortedLearnings}
									/>
								))}
							</div>
						</>
					)}
				</div>
			</div>
			{isAuthor && <ContextMenu setJourney={setJourney} />}
		</DndProvider>
	);
}

function Error({ error }) {
	return <h1 className={styles.error}>{error}</h1>;
}

function EmptyMsg({ profile }) {
	const { userID } = profile;
	const [user] = useUser();
	const isAuthor = user.userID === userID;
	const { withinChapterID = 'home' } = useParams();
	const homeChapter = withinChapterID === 'home';
	const firstMsg = `${isAuthor ? 'Your' : 'This'} learning ${
		homeChapter ? 'home' : 'chapter'
	} is a little empty 😮`;
	const secondMsg = `Right click and select New Learning to create your first learning 😃`;

	return (
		<div className={styles.emptyMsg}>
			<span>{firstMsg}</span>
			<br />
			{isAuthor && homeChapter && <span>{secondMsg}</span>}
		</div>
	);
}

export default Journey;
