import Resizer from 'react-image-file-resizer';
import { Storage } from 'aws-amplify';
import getNanoid from './getNanoid';

// Resize image to max 1920 x 1920 jpg
const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file, //file
			1920, //maxWidth
			1920, //maxHeight
			'JPEG', //compressFormat
			100, //quality
			0, //rotation
			(uri) => {
				resolve(uri);
			}, // callback function
			'blob' //outputType
		);
	});

/**
 * Take an image file object.
 * If it is not a gif, compress it to max 1920x1920 jpeg blob.
 * If it is a gif, check to make sure it is under maxFileSizeMB (2MB).
 * Then upload image blob to public read S3 and return its image link if succeed,
 * or return null if fail.
 *
 * @param {File} file
 * @returns {String}
 */
const resizeAndUploadImage = async (file) => {
	let imgBlob = file;
	let fileExtension = 'jpeg';
	let contentType = 'image/jpeg';
	// Don't resize gif
	if (file.type === 'image/gif') {
		fileExtension = 'gif';
		contentType = 'image/gif';
		const fileSizeMB = file.size / 1024 / 1024;
		// Only take gif with less than maxFileSizeMB (2MB)
		const maxFileSizeMB = 2;
		if (fileSizeMB > maxFileSizeMB) {
			console.log('Gif exceeds 2MB');
			return;
		}
	}
	// Resize all other image files
	else {
		try {
			// Resize file to max 1920 x 1920
			imgBlob = await resizeFile(file);
		} catch (error) {
			console.log('Error resizing image: ', error);
			return;
		}
	}
	try {
		// Upload file to s3 bucket
		const imgName = `tipstory-${getNanoid(25)}.${fileExtension}`;
		await Storage.put(imgName, imgBlob, {
			acl: 'public-read',
			level: 'protected',
			contentType: contentType,
		});
		const fullImgLink = await Storage.get(imgName, {
			level: 'protected',
		});
		const idx = fullImgLink.indexOf(imgName);
		const imgLinkWithoutSignature = fullImgLink.slice(0, idx + imgName.length);
		return imgLinkWithoutSignature;
	} catch (error) {
		console.log('Error uploading/getting file from bucket: ', error);
		return;
	}
};

export default resizeAndUploadImage;
