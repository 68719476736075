import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from './LearningCard.module.css';
import classNames from 'classnames';
import { GetSourceSvg } from 'components/DropdownInput';
import { getFormatDate, getIsMobile, getNewOrder, useUser } from 'utils';
import { Link, useParams } from 'react-router-dom';
import update from 'immutability-helper';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationUpdateUser } from 'graphql/customMutations';
import {
	BookmarkSvg,
	HelpfulActiveSvg,
	InterestingActiveSvg,
	ReadActiveSvg,
	ReadInactiveAltSvg,
} from 'icons';

function LearningCard({ learning, index, isAuthor, learnings, readStatus }) {
	const { learningID: id, parentLearningID = '', type, title } = learning;

	// Store this learning in a ref
	const ref = useRef(null);

	// dropSide is used to displays the resulting position of item when drag over
	// learning, it can be either 'left' or 'right'
	const [dropSide, setDropSide] = useState('');

	// learning can be dragged if person is author and not on mobile view
	const isMobile = getIsMobile();
	const canDrag = isAuthor && !isMobile;
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'learningCard', id, parentLearningID, index },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		canDrag: () => canDrag,
	});

	// drop and hover action
	const [user, setUser] = useUser();
	const { withinChapterID = 'home' } = useParams();
	const moveLearningWrapper = async (dragIndex, currentIdx) => {
		await moveLearning({
			dragIndex,
			currentIdx,
			learnings,
			withinChapterID,
			user,
			setUser,
		});
	};
	const [{ isOver }, drop] = useDrop({
		accept: ['learningCard'],
		drop: (item, monitor) => {
			if (!ref.current) return;
			// learning can be dropped to the left/right of a learning
			// Return if drop to the same learning
			const dragItemIndex = item.index;
			if (dragItemIndex === index) return;
			// Get middle of learning being hovered
			const boundingRect = ref.current.getBoundingClientRect();
			const horizontalMiddle = (boundingRect.left + boundingRect.right) / 2;
			// Get drag item's X value
			const dragItemX = monitor.getClientOffset().x;
			// On the left side of learning
			if (dragItemX <= horizontalMiddle) {
				// Return if learning is 1 learning on the right of dragged learning
				if (dragItemIndex + 1 === index) return;
				if (dragItemIndex < index)
					moveLearningWrapper(dragItemIndex, index - 1);
				if (dragItemIndex > index) moveLearningWrapper(dragItemIndex, index);
			}
			// On the right side of learning
			else {
				// Return if learning is 1 learning on the left of dragged learning
				if (dragItemIndex - 1 === index) return;
				if (dragItemIndex < index) moveLearningWrapper(dragItemIndex, index);
				if (dragItemIndex > index)
					moveLearningWrapper(dragItemIndex, index + 1);
			}
		},
		hover: (item, monitor) => {
			if (!ref.current) return;
			// Reset drop side
			setDropSide('');
			// learning can be dropped to the left/right of a learning
			// Return if drop to the same learning
			const dragItemIndex = item.index;
			if (dragItemIndex === index) return;
			// Get middle of learning being hovered
			const boundingRect = ref.current.getBoundingClientRect();
			const horizontalMiddle = (boundingRect.left + boundingRect.right) / 2;
			// Get drag item's X value
			const dragItemX = monitor.getClientOffset().x;
			// On the left side of learning
			if (dragItemX <= horizontalMiddle) {
				// Return if learning is 1 learning on the right of dragged learning
				if (dragItemIndex + 1 === index) return;
				setDropSide('left');
			}
			// On the right side of learning
			else {
				// Return if learning is 1 learning on the left of dragged learning
				if (dragItemIndex - 1 === index) return;
				setDropSide('right');
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
	});

	drop(drag(ref));

	const dropLeft = isOver && dropSide === 'left';
	const dropRight = isOver && dropSide === 'right';

	// if canDrag, double click opens learning
	const to = `/learning/${parentLearningID || id}`;
	const handleOnDoubleClick = () => {
		if (canDrag) window.open(to, '_blank');
	};

	return (
		<div
			className={classNames(styles.learningCard, {
				[styles.dropLeft]: dropLeft,
				[styles.dropRight]: dropRight,
			})}
			ref={ref}
		>
			{/* Show Button for canDrag, show Link otherwise */}
			{canDrag ? (
				<button
					type='button'
					id={`${type}-${id}-${parentLearningID}`}
					title={title}
					className={classNames(styles.button, {
						[styles.dragEffect]: isDragging,
					})}
					onDoubleClick={handleOnDoubleClick}
				>
					<Content learning={learning} readStatus={readStatus} />
				</button>
			) : (
				<Link
					to={to}
					id={`${type}-${id}-${parentLearningID}`}
					title={title}
					className={`${styles.button} ${styles.link}`}
				>
					<Content learning={learning} readStatus={readStatus} />
				</Link>
			)}
		</div>
	);
}

function Content({ learning, readStatus = [] }) {
	const {
		type,
		source,
		tag = '',
		title,
		numWords,
		createdAt,
		userID: authorID,
	} = learning;
	const firstTag = tag ? tag.split(',')[0].trim() : '';
	const displayBookmark = type === 'saveLearning';
	const displayTime = getFormatDate(createdAt).split(',')[0];

	const footer = ` · ${displayTime} · ${numWords}w`;

	const [user] = useUser();
	const isAuthor = user.userID === authorID;
	const readActive = readStatus.includes('read');
	const helpfulActive = readStatus.includes('helpful');
	const interestingActive = readStatus.includes('interesting');

	return (
		<>
			<div className={styles.top}>
				{firstTag ? <div className={styles.tag}>{firstTag}</div> : <div />}
				{displayBookmark && (
					<div className={styles.bookmark} title={'Saved learning'}>
						<BookmarkSvg />
					</div>
				)}
			</div>
			<div className={styles.bottom}>
				<h1 className={styles.title}>{title}</h1>
				<footer className={styles.footer}>
					<div className={styles.leftFooter}>
						{GetSourceSvg({ source: source, className: styles.sourceSvg })}
						<span>{footer}</span>
					</div>
					{!isAuthor && (
						<div className={styles.svgGroup}>
							{helpfulActive && <HelpfulActiveSvg className={styles.svg} />}
							{interestingActive && (
								<InterestingActiveSvg className={styles.svg} />
							)}
							{readActive ? (
								<ReadActiveSvg className={styles.svg} />
							) : (
								<ReadInactiveAltSvg className={styles.svg} />
							)}
						</div>
					)}
				</footer>
			</div>
		</>
	);
}

/**
 * moveLearning updates learningOrder.
 *
 * @param {Object}
 */
const moveLearning = async ({
	dragIndex,
	currentIdx,
	learnings,
	withinChapterID,
	user,
	setUser,
}) => {
	// Get new learning arrays
	const dragLearning = learnings[dragIndex];
	const newlearnings = update(learnings, {
		$splice: [
			[dragIndex, 1],
			[currentIdx, 0, dragLearning],
		],
	});
	// Get new learing orders in current chapterID
	const newlearningsMap = newlearnings.map(
		(item) => item.parentLearningID || item.learningID
	);
	const [newLearningOrder, success] = getNewOrder(
		user.learningOrder,
		withinChapterID,
		newlearningsMap,
		'replace'
	);
	// Update order
	if (success) {
		try {
			const input = {
				userID: user.userID,
				learningOrder: newLearningOrder,
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log('Error updating learning order: ', error);
			// ignore error
		}
	}
};

export default LearningCard;
