import React, { useMemo } from 'react';
import styles from './LearningArticle.module.css';
import Editor from 'components/Editor';
import Profile from './Profile';
import Icons from './Icons';
import { PinSvg } from 'icons';
import ButtonGroup from './ButtonGroup';

function LearningArticle({ pinLearning }) {
	const { title } = pinLearning;

	return (
		<article className={styles.learningArticle}>
			<h1 className={styles.pinH1}>
				<PinSvg className={styles.pinSvg} /> Pinned
			</h1>
			<h1 className={styles.h1}>{title}</h1>
			<Profile {...pinLearning} />
			<div className={styles.divider} />
			<Learning {...pinLearning} />
			<Icons {...pinLearning} />
			<ButtonGroup {...pinLearning} />
		</article>
	);
}

function Learning(props) {
	const { learning } = props;
	const learningObj = useMemo(() => {
		try {
			if (learning) return JSON.parse(learning);
		} catch (error) {
			console.log('Error parsing learning: ', error);
		}
	}, [learning]);
	return <Editor value={learningObj} readOnly={true} />;
}

export default LearningArticle;
