import React, { useState } from 'react';
import styles from './About.module.css';
import EditorDemo from './EditorDemo';
import MeasureImpact from './MeasureImpact';
import OrganizeLearnings from './OrganizeLearnings';
import TrackRead from './TrackRead';
import PrimaryButton from 'components/Button';
import { getNanoid, useLogin, useUser } from 'utils';

function About() {
	const readStatus = ['helpful', 'interesting', 'read'];
	const [learnings, setLearnings] = useState(initialLearnings);
	const [chapters, setChapters] = useState(initialChapters);
	const [user] = useUser();
	const [, setLogin] = useLogin();
	const signUp = () => {
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'signUp' });
		}
	};

	return (
		<div className={styles.about}>
			<div className={styles.header}>
				<h1 className={styles.h1}>
					<span className={styles.tipstory}>TipStory</span> - collective
					learning and sharing<span className='emoji'>🤝</span>
				</h1>
				<p className={styles.p}>
					TipStory is the first collective learning community for students to
					learn and share learnings with each other. TipStory makes it easy for
					you to access others’ learnings and document your learnings by
					delivering <span className={styles.tipstory}>4 key features</span>:
				</p>
			</div>
			<EditorDemo />
			<MeasureImpact />
			<TrackRead
				readStatus={readStatus}
				learnings={learnings}
				chapters={chapters}
			/>
			<OrganizeLearnings
				readStatus={readStatus}
				learnings={learnings}
				chapters={chapters}
				setLearnings={setLearnings}
				setChapters={setChapters}
			/>
			<div className={styles.footer}>
				<p className={styles.p}>
					<b>
						Ready to share the joy of learning while making learnings and
						guidance more accessible for each other?
					</b>
				</p>
				<div className={styles.buttonDiv}>
					<PrimaryButton className={styles.button} onClick={signUp}>
						Join TipStory Now 😃
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
}

const initialLearnings = [
	{
		learningID: 'main',
		tag: 'The Force',
		source: 'Learning Note',
		title: 'Feeling and harnessing the Force',
		numWords: 1138,
	},
	{
		learningID: getNanoid(),
		tag: 'Training',
		source: 'Learning Note',
		title: 'Jedi Training 101',
		numWords: 1138,
	},
];

const initialChapters = [{ learningID: getNanoid(), title: 'Jedi' }];

export default About;
