import React, { useEffect, useRef, useState } from 'react';
import styles from './TagSearch.module.css';
import { useHistory, useParams } from 'react-router-dom';
import {
	getDecodedUrl,
	getEncodedUrl,
	useCloseDropdown,
	useEnterCallback,
} from 'utils';
import { BrowseSvg, HistorySvg } from 'icons';
import update from 'immutability-helper';
import { getTagHistory, saveTagHistory } from './utils';
import { TextButton } from 'components/Button';

function TagSearch() {
	const { tag } = useParams();
	const [input, setInput] = useState(getDecodedUrl(tag) || '');
	const handleOnChange = (event) => {
		const value = event.target.value;
		setInput(value);
	};

	const [tags, setTags] = useState(getTagHistory());
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef('');
	useCloseDropdown({ node, isOpen, setIsOpen });

	// Auto save tag history in localStorage
	useEffect(() => {
		if (tag) {
			const decodeTag = getDecodedUrl(tag);
			const tagHistory = getTagHistory().filter((item) => item !== decodeTag);
			const newTagHistory = update(tagHistory, { $unshift: [decodeTag] });
			if (newTagHistory.length > 15) newTagHistory.pop();
			saveTagHistory(newTagHistory);
			setTags(newTagHistory);
		}
	}, [tag]);

	// Display history when input is empty during tag search
	useEffect(() => {
		if (tag && input === '') {
			setIsOpen(true);
		}
	}, [tag, input, setIsOpen]);

	return (
		<div className={styles.outsideDiv}>
			<div className={styles.tagSearch} ref={node}>
				<input
					type={'text'}
					value={input}
					autoComplete={'off'}
					onChange={handleOnChange}
					className={styles.input}
					onFocus={() => setIsOpen(true)}
				/>
				<SearchIcon input={input} setIsOpen={setIsOpen} />
				{isOpen && (
					<History
						tags={tags.slice(0, 8)}
						setInput={setInput}
						setIsOpen={setIsOpen}
						setTags={setTags}
					/>
				)}
			</div>
		</div>
	);
}

function History({ tags, setInput, setIsOpen, setTags }) {
	const history = useHistory();

	if (tags.length === 0) return null;

	const handleOnClick = (input) => {
		setInput(input);
		setIsOpen(false);
		history.push(`/tag/${getEncodedUrl(input)}`);
	};

	const handleRemove = (input) => {
		const tagHistory = getTagHistory().filter((item) => item !== input);
		saveTagHistory(tagHistory);
		setTags(tagHistory);
	};

	return (
		<div className={styles.history}>
			{tags.map((item, idx, array) => (
				<Tag
					key={item}
					children={item}
					onClick={() => handleOnClick(item)}
					lastIdx={idx === array.length - 1}
					handleRemove={() => handleRemove(item)}
				/>
			))}
		</div>
	);
}

function Tag({ children, onClick, lastIdx, handleRemove }) {
	const tagClassName = lastIdx ? `${styles.tag} ${styles.lastTag}` : styles.tag;
	const tagButtonClassName = lastIdx
		? `${styles.tagButton} ${styles.lastTag}`
		: styles.tagButton;
	return (
		<div className={tagClassName}>
			<button
				className={tagButtonClassName}
				onMouseDown={(event) => event.preventDefault()}
				onClick={onClick}
			>
				<HistorySvg className={styles.historySvg} />
				{children}
			</button>
			<TextButton
				className={styles.removeButton}
				onMouseDown={(event) => event.preventDefault()}
				onClick={handleRemove}
				children={'Remove'}
			/>
		</div>
	);
}

function SearchIcon({ input, setIsOpen }) {
	const history = useHistory();
	const handleOnClick = () => {
		if (!input) return;
		setIsOpen(false);
		history.push(`/tag/${getEncodedUrl(input)}`);
	};
	useEnterCallback({ isOpen: true, callback: handleOnClick });
	return (
		<button
			type={'button'}
			className={styles.searchIcon}
			onClick={handleOnClick}
		>
			<BrowseSvg />
		</button>
	);
}

export default TagSearch;
