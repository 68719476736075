import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningsRead } from 'graphql/customQueries';
import { useUser } from 'utils';
import { getCurrentMonth, getCurrentYear } from '../utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';

/**
 * useGetLearningsRead hook gets learnings read by user in current month.
 *
 * @param {Object}
 */
function useGetLearningsRead({ setLearningsRead, setError, monthActivated }) {
	const [user] = useUser();

	useEffect(() => {
		// Skip if user is not login
		if (!user.isLogin) return;
		// Skip if month is activated
		if (monthActivated) return;

		const getLearningsRead = async () => {
			const variables = {
				userID: user.userID,
				createdAt: { beginsWith: `${getCurrentYear()}-${getCurrentMonth()}` },
				filter: { type: { eq: 'read' } },
				limit: 20,
			};
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetLearningsRead, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const learningsReadItem = res.data.getReadByUserID.items;
				setLearningsRead(learningsReadItem);
			} catch (error) {
				console.log('Error getting learnings read: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};
		getLearningsRead();
	}, [user.isLogin, monthActivated, user.userID, setError, setLearningsRead]);
}
export default useGetLearningsRead;
