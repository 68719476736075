import degreeCurve from './LearningsImg/38DegreeCurve.jpg';
import squareRootNum from './LearningsImg/squareRootNum.jpg';
import summerPrograms from './LearningsImg/summerPrograms.jpg';
import collegeGuide from './LearningsImg/collegeGuide.jpg';
import schoolSupply from './LearningsImg/schoolSupply.jpg';
import stockTips from './LearningsImg/stockTips.jpg';
import coolMath from './LearningsImg/coolMath.jpg';
import cartoon from './LearningsImg/cartoon.jpg';
import presentationTips from './LearningsImg/presentationTips.jpg';
import danceProject from './LearningsImg/danceProject.jpg';
import slowDown from './LearningsImg/slowDown.jpg';
import becomeADoctor from './LearningsImg/becomeADoctor.jpg';
import betterAtHistory from './LearningsImg/betterAtHistory.jpg';
import handleStress from './LearningsImg/handleStress.jpg';
import studyTechniques from './LearningsImg/studyTechniques.jpg';
import cakePops from './LearningsImg/cakePops.jpg';
import skincare from './LearningsImg/skincare.jpg';
import APPhysics from './LearningsImg/APPhysics.jpg';
import tipstory from './LearningsImg/tipstory.jpg';
import technology from './LearningsImg/technology.jpg';
import fishing from './LearningsImg/fishing.jpg';
import montyHall from './LearningsImg/montyHall.jpg';
import pharmacy from './LearningsImg/pharmacy.jpg';
import stopAsianHate from './LearningsImg/stopAsianHate.jpg';
import binary from './LearningsImg/binary.jpg';
import freshman from './LearningsImg/freshman.jpg';
import money from './LearningsImg/money.jpg';
import fibonacci from './LearningsImg/fibonacci.jpg';
import language from './LearningsImg/language.jpg';
import SAT from './LearningsImg/SAT.jpg';
import nostalgia from './LearningsImg/nostalgia.jpg';
import exercise from './LearningsImg/exercise.jpg';

const LEARNINGS_CONFIG = [
	{
		img: degreeCurve,
		title: 'My 38-degree Curve Journey',
		author: 'Zeynep Alakuş',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Abe8afb30-1268-490e-9c81-6aba0c0a4a75/profile_XQUhVOZhZR.jpeg',
		link: '/learning/DMva4FJjRVegTP9',
	},
	{
		img: squareRootNum,
		title: 'How to take square root of any non-negative numbers MANUALLY!!!',
		author: 'Moe Hay Mann',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Ad6e4feda-2756-40d1-80d4-abf5e9d843ac/profile_xK9JnPIJbm.jpeg',
		link: '/learning/xGEFAIU1RKZuKsP',
	},
	{
		img: summerPrograms,
		title: 'Summer Programs for High School Students',
		author: 'Sara Spiegel',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Adf342ed6-4f3b-42e0-ae04-2d453a8102f4/profile_cuIxLev3BW.jpeg',
		link: '/learning/RbOvBuQ8FbSKSKA',
	},
	{
		img: collegeGuide,
		title: 'How to Start Thinking About College Early On: A Step-by-Step Guide',
		author: 'Noor Muneeb',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A2ade1433-b6f5-4bfc-9aa7-a539a3067b29/profile_lsnxA9Fxd9.jpeg',
		link: '/learning/naIRLQNQDz0OL1R',
	},
	{
		img: schoolSupply,
		title: 'School Supply List + Optimal Study Tips',
		author: 'Sumaya Jashim',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A1e0463fd-a089-41f2-af98-35c1ada43d6b/profile_AozOsgimPk.jpeg',
		link: '/learning/9CmihER8FDXyBuU',
	},
	{
		img: stockTips,
		title: 'Stocks Tips from Mistakes #1',
		author: 'William Wu',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Adbd8f66b-5f4b-4df9-8e4e-740866a9b5ed/profile_TBpMbYXX8P.jpeg',
		link: '/learning/HCH7JhojduX7hRT',
	},
	{
		img: coolMath,
		title: 'COOL Mathematics 😎',
		author: 'Saira Masud',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A05294563-27e6-46bd-b455-d53577734067/profile_n9ShmxxyD0.jpeg',
		link: '/learning/2QvbGML4eeQLNKp',
	},
	{
		img: cartoon,
		title: 'Cartoon Psychology',
		author: 'Raine Wu',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A55503acf-db7a-447e-8242-1127a8402360/profile_lXUPFI7g5k.jpeg',
		link: '/learning/Mu9ITxFhaovQpdd',
	},
	{
		img: presentationTips,
		title: 'Presentation Tips',
		author: 'Ethan Chu',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Af9fd0b9d-56fe-40da-b7d5-3aac7d9cf92c/profile_Lle95U7mfu.jpeg',
		link: '/learning/4yogF4JQkjVSSbl',
	},
	{
		img: danceProject,
		title: 'A Personal Project I Worked On 👯‍♀️',
		author: 'Christine Pak',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A70aae10e-dbf8-4e10-814c-286cb6012978/profile_SLnY2fi75a.jpeg',
		link: '/learning/0pvL1GFZVRPRLQy',
	},
	{
		img: slowDown,
		title: 'My personal struggle on slowing down (part 1 of 2)',
		author: 'Dania Gonzalez',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Ac2bb3c09-e028-42f3-9f4e-e09675a040a3/profile_dapEw1qf2p.jpeg',
		link: '/learning/UHenBIDbDY59EDR',
	},
	{
		img: becomeADoctor,
		title: 'How To Become A Doctor',
		author: 'Dawn Roy',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Aee07f312-052c-47f0-b8f2-621fe12cb6dd/profile_EdOLpSecy8.jpeg',
		link: '/learning/hNcxRmir9zBbdoZ',
	},
	{
		img: betterAtHistory,
		title: 'How to get Better At History',
		author: 'Chelseaca Henry',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Ab92d5c2d-4fd5-4f88-9496-d98f0f00dbba/profile_vCGMNBnB47.jpeg',
		link: '/learning/Io8TPZb8QSV8viL',
	},
	{
		img: handleStress,
		title: 'How to handle stress when studying',
		author: 'Jennifer Huang',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Aae2492a8-6a07-4f18-9f79-3965ef4ade4b/profile_KUUc9GyXnN.jpeg',
		link: '/learning/4QaHz1XluQiKstC',
	},
	{
		img: studyTechniques,
		title: 'Study Techniques',
		author: 'Annika Richard',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A75d6cbf8-cc77-439d-90e1-da66c055cdbc/profile_WheUsRfuQq.jpeg',
		link: '/learning/rVmhquYoAR77TRt',
	},
	{
		img: cakePops,
		title: "June's Learning Experience - CAKE POPS!",
		author: 'Yoselyn Banuelos',
		authorImg:
			'https://lh3.googleusercontent.com/a-/AOh14Ghgtoy4QUdzzBF7NEM89bo4SSz225QXDNs3eYCqnV8=s96-c',
		link: '/learning/MhLAVWGxFw29JtU',
	},
	{
		img: skincare,
		title: 'Skincare Tips!',
		author: 'Emily Lai',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A1f1dc0cc-12d1-4634-a915-e75916ad5b51/profile_1jNdkBrcSS.jpeg',
		link: '/learning/BbXxZiBIxZWZCPg',
	},
	{
		img: APPhysics,
		title: 'AP Physics Tips!',
		author: 'Muhammad Alam',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Aa2cb17ca-32a6-4166-a160-66a5ba1539e4/profile_BZDtHUnYDc.jpeg',
		link: '/learning/15WLat2Q0zXZOdK',
	},
	{
		img: tipstory,
		title: 'Writng TipStory in beautiful font',
		author: 'Zahin Tasnin',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A9232d8a6-ffb0-4e06-9967-63351bd06c4f/profile_5yswz9hqWY.jpeg',
		link: '/learning/JX7SlXDd95LuNZM',
	},
	{
		img: technology,
		title: 'Does Technology Make Us More Alone?',
		author: 'Felipe Assef',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A27385a0e-19b3-4f49-b890-fb12c4cc7aa5/profile_b3eOvzJK9M.jpeg',
		link: '/learning/TmHFABMDdZK0jOL',
	},
	{
		img: fishing,
		title: 'Fishing Tips',
		author: 'Brandon Lung',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A5babd512-92c5-4b5a-b3c6-29b1d7b3b0f5/profile_wfX4jkI9Yl.jpeg',
		link: '/learning/IDSiEJOLnsc2VsW',
	},
	{
		img: montyHall,
		title: 'Math Paradox (Monty Hall Problem)',
		author: 'Xuyuan Chen',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A8e4482e8-f7b9-42f2-95bb-86d6b92cf671/profile_3P5gonv4Bu.jpeg',
		link: '/learning/HSRWWrTOHJUwKHt',
	},
	{
		img: pharmacy,
		title: 'Pharmacy Abbreviations',
		author: 'Jason Yu',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Aecd1fc78-66ad-4171-a03b-ba2161b2c057/profile_MZCIkhUd9j.jpeg',
		link: '/learning/SAaOYUviGxTyFJx',
	},
	{
		img: stopAsianHate,
		title: 'Anti-Asian Racism and Violence',
		author: 'Stephanie Romero',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A47551a84-63fd-490e-9ead-7f594af75cc5/profile_f2ytHJ2Ytf.jpeg',
		link: '/learning/Cy3onqis3cJjEJx',
	},
	{
		img: binary,
		title: 'Binary System! 💻',
		author: 'Nicole Zheng',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A587e2201-95da-45b3-ae8b-1bcb0f59a52c/profile_YNPk8fyVFW.jpeg',
		link: '/learning/IcuNOrDfqJWzKAD',
	},
	{
		img: freshman,
		title: 'High School Advice: Freshman Year',
		author: 'Faiza Tasnim',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A03ae195f-6f88-456b-a421-c0cb5b634ecd/profile_qp7OsfPnE6.jpeg',
		link: '/learning/XNc5KwZ90zG56Y9',
	},
	{
		img: money,
		title: 'The Evolution of Money',
		author: 'Jaiden Martinez',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Ae543438c-cc56-4249-b651-3ed4f2081c2a/profile_TE1rMSILI2.jpeg',
		link: '/learning/5xWSSIFEpc4UWaZ',
	},
	{
		img: fibonacci,
		title: 'What is the Fibonacci Sequence?',
		author: 'Jenny Li',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Afc958b2e-9a1c-4284-89b4-8f9b12c6ecba/profile_1EEJjPDlR6.jpeg',
		link: '/learning/UhMD3KH4zqoQTEH',
	},
	{
		img: language,
		title: '4 Tips to Learn a Language Faster',
		author: 'Sonam Thukral',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A5a2b445d-5a7c-4f8b-8ef4-19bde37c12ec/profile_1FwXSK2Jj9.jpeg',
		link: '/learning/TFMAA7dmXU1uHGb',
	},
	{
		img: SAT,
		title: 'SAT Study Tips',
		author: 'Amelia Harrington',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A12b5050c-5c9b-4603-90b7-882a07ba6326/profile_R838JycuH1.jpeg',
		link: '/learning/UgVhtWvD0CtXX16',
	},
	{
		img: nostalgia,
		title: 'Why do we feel Nostalgia?',
		author: 'Audrey Alexander',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3A34fb429b-f042-450a-b425-4dacab7f5ee6/profile_YOaRTTl8C7.jpeg',
		link: '/learning/MLXwaySpGpbsWCE',
	},
	{
		img: exercise,
		title: 'Helpful Facts/Tips About Exercise and Health',
		author: 'Alisha Zubair',
		authorImg:
			'https://tipstorys3bucket13315-prod.s3.amazonaws.com/protected/us-east-1%3Af365f014-374e-4e7b-9d06-2012aeeb7827/profile_leGevaapCc.jpeg',
		link: '/learning/65Q6TIRxpXxV0tR',
	},
];

export default LEARNINGS_CONFIG;
