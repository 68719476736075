/**
 * Get an array of tags from a comma delimited tagString.
 *
 * @param {String} tagString
 * @return {Array}
 */
const getTagList = (tagString = '') => {
	if (!tagString) return [];
	return tagString
		.split(',')
		.map((item) => item.trim())
		.filter((item) => item !== '');
};

export default getTagList;
