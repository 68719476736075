/*eslint no-useless-escape: "off"*/
/**
 * RegExps.
 * A URL must match #1 and then at least one of #2/#3.
 * Use two levels of REs to avoid REDOS.
 *
 * https://github.com/segmentio/is-url/blob/master/index.js
 * by zeke
 */

var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

/**
 * Loosely validate a URL `string`.
 *
 * @param {String} string
 * @return {Boolean}
 */

const isUrl = (string) => {
	if (typeof string !== 'string') {
		return false;
	}

	var match = string.match(protocolAndDomainRE);
	if (!match) {
		return false;
	}

	var everythingAfterProtocol = match[1];
	if (!everythingAfterProtocol) {
		return false;
	}

	if (
		localhostDomainRE.test(everythingAfterProtocol) ||
		nonLocalhostDomainRE.test(everythingAfterProtocol)
	) {
		return true;
	}

	return false;
};

export default isUrl;
