import React from 'react';
import ReactDOM from 'react-dom';
import styles from './ContextMenuModal.module.css';
import { useEnterCallback } from 'utils';
import NewChapter from './NewChapter';
import DeleteChapter from './DeleteChapter';
import DeleteLearning from './DeleteLearning';

function ContextMenuModal({ modal, setModal, setLearnings, setChapters }) {
	const { isOpen, state } = modal;
	const setIsOpenFalse = () => setModal({ isOpen: false, state: '' });
	// Close modal when press escape key
	useEnterCallback({ isOpen, callback: setIsOpenFalse, key: 'Escape' });
	const props = { modal, setIsOpenFalse, setLearnings, setChapters };
	let render;
	switch (state) {
		case 'newChapter':
			render = <NewChapter {...props} />;
			break;
		case 'deleteChapter':
			render = <DeleteChapter {...props} />;
			break;
		case 'deleteLearning':
			render = <DeleteLearning {...props} />;
			break;
		default:
			render = null;
	}
	return ReactDOM.createPortal(
		<section className={styles.conextMenuModal}>{render}</section>,
		document.body
	);
}

export default ContextMenuModal;
