import React from 'react';
import { useLogin, useUser } from 'utils';
import ProfileButton from '../ProfileButton';
import ThemeButton from '../ThemeButton';
import styles from './RightNav.module.css';
import PrimaryButton from 'components/Button';
import { useHistory } from 'react-router-dom';
import DropdownIcon from '../DropdownIcon';
import InboxButton from '../InboxButton';

function RightNav() {
	const [user] = useUser();
	const isLogin = user.isLogin;

	return (
		<div className={styles.div}>
			{isLogin ? <LoginView /> : <VisitorView />}
			<ThemeButton />
		</div>
	);
}

function LoginView() {
	const history = useHistory();
	const visitNewLearning = () => history.push('/new');

	return (
		<>
			<PrimaryButton
				children={'New Learning'}
				onClick={visitNewLearning}
				className={styles.hideInMobile}
			/>
			<DropdownIcon />
			<InboxButton />
			<ProfileButton />
		</>
	);
}

function VisitorView() {
	const [, setLogin] = useLogin();
	const showSignUp = () => setLogin({ visible: true, state: 'signUp' });
	const showLogin = () => setLogin({ visible: true, state: 'login' });

	return (
		<>
			<PrimaryButton
				children={'Sign up'}
				primary={false}
				className={styles.hideInMobile}
				onClick={showSignUp}
			/>
			<PrimaryButton
				children={'Log in'}
				className={styles.marginLeft10}
				onClick={showLogin}
			/>
		</>
	);
}

export default RightNav;
