/**
 * Emojis generated from https://emojipedia.org/ - Dec 14, 20
 * 7 categories: 'Smileys & People', 'Animals & Nature',
 * 'Food & Drink', 'Activity', 'Travel & Places',
 * 'Objects', 'Symbols'.
 */

const emoji = {
	'Smileys & People': [
		['😀', 'Grinning Face'],
		['😃', 'Grinning Face with Big Eyes'],
		['😄', 'Grinning Face with Smiling Eyes'],
		['😁', 'Beaming Face with Smiling Eyes'],
		['😆', 'Grinning Squinting Face'],
		['😅', 'Grinning Face with Sweat'],
		['🤣', 'Rolling on the Floor Laughing'],
		['😂', 'Face with Tears of Joy'],
		['🙂', 'Slightly Smiling Face'],
		['🙃', 'Upside-Down Face'],
		['😉', 'Winking Face'],
		['😊', 'Smiling Face with Smiling Eyes'],
		['😇', 'Smiling Face with Halo'],
		['🥰', 'Smiling Face with Hearts'],
		['😍', 'Smiling Face with Heart-Eyes'],
		['🤩', 'Star-Struck'],
		['😘', 'Face Blowing a Kiss'],
		['😗', 'Kissing Face'],
		['😚', 'Kissing Face with Closed Eyes'],
		['😙', 'Kissing Face with Smiling Eyes'],
		['😋', 'Face Savoring Food'],
		['😛', 'Face with Tongue'],
		['😜', 'Winking Face with Tongue'],
		['🤪', 'Zany Face'],
		['😝', 'Squinting Face with Tongue'],
		['🤑', 'Money-Mouth Face'],
		['🤗', 'Hugging Face'],
		['🤭', 'Face with Hand Over Mouth'],
		['🤫', 'Shushing Face'],
		['🤔', 'Thinking Face'],
		['🤐', 'Zipper-Mouth Face'],
		['🤨', 'Face with Raised Eyebrow'],
		['😐', 'Neutral Face'],
		['😑', 'Expressionless Face'],
		['😶', 'Face Without Mouth'],
		['😏', 'Smirking Face'],
		['😒', 'Unamused Face'],
		['🙄', 'Face with Rolling Eyes'],
		['😬', 'Grimacing Face'],
		['🤥', 'Lying Face'],
		['😌', 'Relieved Face'],
		['😔', 'Pensive Face'],
		['😪', 'Sleepy Face'],
		['🤤', 'Drooling Face'],
		['😴', 'Sleeping Face'],
		['😷', 'Face with Medical Mask'],
		['🤒', 'Face with Thermometer'],
		['🤕', 'Face with Head-Bandage'],
		['🤢', 'Nauseated Face'],
		['🤮', 'Face Vomiting'],
		['🤧', 'Sneezing Face'],
		['🥵', 'Hot Face'],
		['🥶', 'Cold Face'],
		['🥴', 'Woozy Face'],
		['😵', 'Dizzy Face'],
		['🤯', 'Exploding Head'],
		['🤠', 'Cowboy Hat Face'],
		['🥳', 'Partying Face'],
		['😎', 'Smiling Face with Sunglasses'],
		['🤓', 'Nerd Face'],
		['🧐', 'Face with Monocle'],
		['😕', 'Confused Face'],
		['😟', 'Worried Face'],
		['🙁', 'Slightly Frowning Face'],
		['☹️', 'Frowning Face'],
		['😮', 'Face with Open Mouth'],
		['😯', 'Hushed Face'],
		['😲', 'Astonished Face'],
		['😳', 'Flushed Face'],
		['🥺', 'Pleading Face'],
		['😦', 'Frowning Face with Open Mouth'],
		['😧', 'Anguished Face'],
		['😨', 'Fearful Face'],
		['😰', 'Anxious Face with Sweat'],
		['😥', 'Sad but Relieved Face'],
		['😢', 'Crying Face'],
		['😭', 'Loudly Crying Face'],
		['😱', 'Face Screaming in Fear'],
		['😖', 'Confounded Face'],
		['😣', 'Persevering Face'],
		['😞', 'Disappointed Face'],
		['😓', 'Downcast Face with Sweat'],
		['😩', 'Weary Face'],
		['😫', 'Tired Face'],
		['🥱', 'Yawning Face'],
		['😤', 'Face with Steam From Nose'],
		['😡', 'Pouting Face'],
		['😠', 'Angry Face'],
		['🤬', 'Face with Symbols on Mouth'],
		['😈', 'Smiling Face with Horns'],
		['👿', 'Angry Face with Horns'],
		['💀', 'Skull'],
		['☠️', 'Skull and Crossbones'],
		['💩', 'Pile of Poo'],
		['🤡', 'Clown Face'],
		['👹', 'Ogre'],
		['👺', 'Goblin'],
		['👻', 'Ghost'],
		['👽', 'Alien'],
		['👾', 'Alien Monster'],
		['🤖', 'Robot'],
		['😺', 'Grinning Cat'],
		['😸', 'Grinning Cat with Smiling Eyes'],
		['😹', 'Cat with Tears of Joy'],
		['😻', 'Smiling Cat with Heart-Eyes'],
		['😼', 'Cat with Wry Smile'],
		['😽', 'Kissing Cat'],
		['🙀', 'Weary Cat'],
		['😿', 'Crying Cat'],
		['😾', 'Pouting Cat'],
		['💋', 'Kiss Mark'],
		['👋', 'Waving Hand'],
		['🤚', 'Raised Back of Hand'],
		['🖐️', 'Hand with Fingers Splayed'],
		['✋', 'Raised Hand'],
		['🖖', 'Vulcan Salute'],
		['👌', 'OK Hand'],
		['🤏', 'Pinching Hand'],
		['✌️', 'Victory Hand'],
		['🤞', 'Crossed Fingers'],
		['🤟', 'Love-You Gesture'],
		['🤘', 'Sign of the Horns'],
		['🤙', 'Call Me Hand'],
		['👈', 'Backhand Index Pointing Left'],
		['👉', 'Backhand Index Pointing Right'],
		['👆', 'Backhand Index Pointing Up'],
		['🖕', 'Middle Finger'],
		['👇', 'Backhand Index Pointing Down'],
		['☝️', 'Index Pointing Up'],
		['👍', 'Thumbs Up'],
		['👎', 'Thumbs Down'],
		['✊', 'Raised Fist'],
		['👊', 'Oncoming Fist'],
		['🤛', 'Left-Facing Fist'],
		['🤜', 'Right-Facing Fist'],
		['👏', 'Clapping Hands'],
		['🙌', 'Raising Hands'],
		['👐', 'Open Hands'],
		['🤲', 'Palms Up Together'],
		['🤝', 'Handshake'],
		['🙏', 'Folded Hands'],
		['✍️', 'Writing Hand'],
		['💅', 'Nail Polish'],
		['🤳', 'Selfie'],
		['💪', 'Flexed Biceps'],
		['🦾', 'Mechanical Arm'],
		['🦿', 'Mechanical Leg'],
		['🦵', 'Leg'],
		['🦶', 'Foot'],
		['👂', 'Ear'],
		['🦻', 'Ear with Hearing Aid'],
		['👃', 'Nose'],
		['🧠', 'Brain'],
		['🦷', 'Tooth'],
		['🦴', 'Bone'],
		['👀', 'Eyes'],
		['👁️', 'Eye'],
		['👅', 'Tongue'],
		['👄', 'Mouth'],
		['👶', 'Baby'],
		['🧒', 'Child'],
		['👦', 'Boy'],
		['👧', 'Girl'],
		['🧑', 'Person'],
		['👱', 'Person: Blond Hair'],
		['👨', 'Man'],
		['🧔', 'Person: Beard'],
		['👨‍🦰', 'Man: Red Hair'],
		['👨‍🦱', 'Man: Curly Hair'],
		['👨‍🦳', 'Man: White Hair'],
		['👨‍🦲', 'Man: Bald'],
		['👩', 'Woman'],
		['👩‍🦰', 'Woman: Red Hair'],
		['👩‍🦱', 'Woman: Curly Hair'],
		['👩‍🦳', 'Woman: White Hair'],
		['👩‍🦲', 'Woman: Bald'],
		['👱‍♀️', 'Woman: Blond Hair'],
		['👱‍♂️', 'Man: Blond Hair'],
		['🧓', 'Older Person'],
		['👴', 'Old Man'],
		['👵', 'Old Woman'],
		['🙍', 'Person Frowning'],
		['🙍‍♂️', 'Man Frowning'],
		['🙍‍♀️', 'Woman Frowning'],
		['🙎', 'Person Pouting'],
		['🙎‍♂️', 'Man Pouting'],
		['🙎‍♀️', 'Woman Pouting'],
		['🙅', 'Person Gesturing No'],
		['🙅‍♂️', 'Man Gesturing No'],
		['🙅‍♀️', 'Woman Gesturing No'],
		['🙆', 'Person Gesturing OK'],
		['🙆‍♂️', 'Man Gesturing OK'],
		['🙆‍♀️', 'Woman Gesturing OK'],
		['💁', 'Person Tipping Hand'],
		['💁‍♂️', 'Man Tipping Hand'],
		['💁‍♀️', 'Woman Tipping Hand'],
		['🙋', 'Person Raising Hand'],
		['🙋‍♂️', 'Man Raising Hand'],
		['🙋‍♀️', 'Woman Raising Hand'],
		['🧏', 'Deaf Person'],
		['🧏‍♂️', 'Deaf Man'],
		['🧏‍♀️', 'Deaf Woman'],
		['🙇', 'Person Bowing'],
		['🙇‍♂️', 'Man Bowing'],
		['🙇‍♀️', 'Woman Bowing'],
		['🤦', 'Person Facepalming'],
		['🤦‍♂️', 'Man Facepalming'],
		['🤦‍♀️', 'Woman Facepalming'],
		['🤷', 'Person Shrugging'],
		['🤷‍♂️', 'Man Shrugging'],
		['🤷‍♀️', 'Woman Shrugging'],
		['👨‍⚕️', 'Man Health Worker'],
		['👩‍⚕️', 'Woman Health Worker'],
		['👨‍🎓', 'Man Student'],
		['👩‍🎓', 'Woman Student'],
		['👨‍🏫', 'Man Teacher'],
		['👩‍🏫', 'Woman Teacher'],
		['👨‍⚖️', 'Man Judge'],
		['👩‍⚖️', 'Woman Judge'],
		['👨‍🌾', 'Man Farmer'],
		['👩‍🌾', 'Woman Farmer'],
		['👨‍🍳', 'Man Cook'],
		['👩‍🍳', 'Woman Cook'],
		['👨‍🔧', 'Man Mechanic'],
		['👩‍🔧', 'Woman Mechanic'],
		['👨‍🏭', 'Man Factory Worker'],
		['👩‍🏭', 'Woman Factory Worker'],
		['👨‍💼', 'Man Office Worker'],
		['👩‍💼', 'Woman Office Worker'],
		['👨‍🔬', 'Man Scientist'],
		['👩‍🔬', 'Woman Scientist'],
		['👨‍💻', 'Man Technologist'],
		['👩‍💻', 'Woman Technologist'],
		['👨‍🎤', 'Man Singer'],
		['👩‍🎤', 'Woman Singer'],
		['👨‍🎨', 'Man Artist'],
		['👩‍🎨', 'Woman Artist'],
		['👨‍✈️', 'Man Pilot'],
		['👩‍✈️', 'Woman Pilot'],
		['👨‍🚀', 'Man Astronaut'],
		['👩‍🚀', 'Woman Astronaut'],
		['👨‍🚒', 'Man Firefighter'],
		['👩‍🚒', 'Woman Firefighter'],
		['👮', 'Police Officer'],
		['👮‍♂️', 'Man Police Officer'],
		['👮‍♀️', 'Woman Police Officer'],
		['🕵️', 'Detective'],
		['🕵️‍♂️', 'Man Detective'],
		['🕵️‍♀️', 'Woman Detective'],
		['💂', 'Guard'],
		['💂‍♂️', 'Man Guard'],
		['💂‍♀️', 'Woman Guard'],
		['👷', 'Construction Worker'],
		['👷‍♂️', 'Man Construction Worker'],
		['👷‍♀️', 'Woman Construction Worker'],
		['🤴', 'Prince'],
		['👸', 'Princess'],
		['👳', 'Person Wearing Turban'],
		['👳‍♂️', 'Man Wearing Turban'],
		['👳‍♀️', 'Woman Wearing Turban'],
		['👲', 'Person With Skullcap'],
		['🧕', 'Woman with Headscarf'],
		['🤵', 'Person in Tuxedo'],
		['👰', 'Person With Veil'],
		['🤰', 'Pregnant Woman'],
		['🤱', 'Breast-Feeding'],
		['👼', 'Baby Angel'],
		['🎅', 'Santa Claus'],
		['🤶', 'Mrs. Claus'],
		['🦸', 'Superhero'],
		['🦸‍♂️', 'Man Superhero'],
		['🦸‍♀️', 'Woman Superhero'],
		['🦹', 'Supervillain'],
		['🦹‍♂️', 'Man Supervillain'],
		['🦹‍♀️', 'Woman Supervillain'],
		['🧙', 'Mage'],
		['🧙‍♂️', 'Man Mage'],
		['🧙‍♀️', 'Woman Mage'],
		['🧚', 'Fairy'],
		['🧚‍♂️', 'Man Fairy'],
		['🧚‍♀️', 'Woman Fairy'],
		['🧛', 'Vampire'],
		['🧛‍♂️', 'Man Vampire'],
		['🧛‍♀️', 'Woman Vampire'],
		['🧜', 'Merperson'],
		['🧜‍♂️', 'Merman'],
		['🧜‍♀️', 'Mermaid'],
		['🧝', 'Elf'],
		['🧝‍♂️', 'Man Elf'],
		['🧝‍♀️', 'Woman Elf'],
		['🧞', 'Genie'],
		['🧞‍♂️', 'Man Genie'],
		['🧞‍♀️', 'Woman Genie'],
		['🧟', 'Zombie'],
		['🧟‍♂️', 'Man Zombie'],
		['🧟‍♀️', 'Woman Zombie'],
		['💆', 'Person Getting Massage'],
		['💆‍♂️', 'Man Getting Massage'],
		['💆‍♀️', 'Woman Getting Massage'],
		['💇', 'Person Getting Haircut'],
		['💇‍♂️', 'Man Getting Haircut'],
		['💇‍♀️', 'Woman Getting Haircut'],
		['🚶', 'Person Walking'],
		['🚶‍♂️', 'Man Walking'],
		['🚶‍♀️', 'Woman Walking'],
		['🧍', 'Person Standing'],
		['🧍‍♂️', 'Man Standing'],
		['🧍‍♀️', 'Woman Standing'],
		['🧎', 'Person Kneeling'],
		['🧎‍♂️', 'Man Kneeling'],
		['🧎‍♀️', 'Woman Kneeling'],
		['👨‍🦯', 'Man with White Cane'],
		['👩‍🦯', 'Woman with White Cane'],
		['👨‍🦼', 'Man in Motorized Wheelchair'],
		['👩‍🦼', 'Woman in Motorized Wheelchair'],
		['👨‍🦽', 'Man in Manual Wheelchair'],
		['👩‍🦽', 'Woman in Manual Wheelchair'],
		['🏃', 'Person Running'],
		['🏃‍♂️', 'Man Running'],
		['🏃‍♀️', 'Woman Running'],
		['💃', 'Woman Dancing'],
		['🕺', 'Man Dancing'],
		['🕴️', 'Person in Suit Levitating'],
		['👯', 'People with Bunny Ears'],
		['👯‍♂️', 'Men with Bunny Ears'],
		['👯‍♀️', 'Women with Bunny Ears'],
		['🧖', 'Person in Steamy Room'],
		['🧖‍♂️', 'Man in Steamy Room'],
		['🧖‍♀️', 'Woman in Steamy Room'],
		['🧘', 'Person in Lotus Position'],
		['🧑‍🤝‍🧑', 'People Holding Hands'],
		['👭', 'Women Holding Hands'],
		['👫', 'Woman and Man Holding Hands'],
		['👬', 'Men Holding Hands'],
		['💏', 'Kiss'],
		['👩‍❤️‍💋‍👨', 'Kiss: Woman, Man'],
		['👨‍❤️‍💋‍👨', 'Kiss: Man, Man'],
		['👩‍❤️‍💋‍👩', 'Kiss: Woman, Woman'],
		['💑', 'Couple with Heart'],
		['👩‍❤️‍👨', 'Couple with Heart: Woman, Man'],
		['👨‍❤️‍👨', 'Couple with Heart: Man, Man'],
		['👩‍❤️‍👩', 'Couple with Heart: Woman, Woman'],
		['👪', 'Family'],
		['👨‍👩‍👦', 'Family: Man, Woman, Boy'],
		['👨‍👩‍👧', 'Family: Man, Woman, Girl'],
		['👨‍👩‍👧‍👦', 'Family: Man, Woman, Girl, Boy'],
		['👨‍👩‍👦‍👦', 'Family: Man, Woman, Boy, Boy'],
		['👨‍👩‍👧‍👧', 'Family: Man, Woman, Girl, Girl'],
		['👨‍👨‍👦', 'Family: Man, Man, Boy'],
		['👨‍👨‍👧', 'Family: Man, Man, Girl'],
		['👨‍👨‍👧‍👦', 'Family: Man, Man, Girl, Boy'],
		['👨‍👨‍👦‍👦', 'Family: Man, Man, Boy, Boy'],
		['👨‍👨‍👧‍👧', 'Family: Man, Man, Girl, Girl'],
		['👩‍👩‍👦', 'Family: Woman, Woman, Boy'],
		['👩‍👩‍👧', 'Family: Woman, Woman, Girl'],
		['👩‍👩‍👧‍👦', 'Family: Woman, Woman, Girl, Boy'],
		['👩‍👩‍👦‍👦', 'Family: Woman, Woman, Boy, Boy'],
		['👩‍👩‍👧‍👧', 'Family: Woman, Woman, Girl, Girl'],
		['👨‍👦', 'Family: Man, Boy'],
		['👨‍👦‍👦', 'Family: Man, Boy, Boy'],
		['👨‍👧', 'Family: Man, Girl'],
		['👨‍👧‍👦', 'Family: Man, Girl, Boy'],
		['👨‍👧‍👧', 'Family: Man, Girl, Girl'],
		['👩‍👦', 'Family: Woman, Boy'],
		['👩‍👦‍👦', 'Family: Woman, Boy, Boy'],
		['👩‍👧', 'Family: Woman, Girl'],
		['👩‍👧‍👦', 'Family: Woman, Girl, Boy'],
		['👩‍👧‍👧', 'Family: Woman, Girl, Girl'],
		['🗣️', 'Speaking Head'],
		['👤', 'Bust in Silhouette'],
		['👥', 'Busts in Silhouette'],
		['👣', 'Footprints'],
		['🧳', 'Luggage'],
		['🌂', 'Closed Umbrella'],
		['☂️', 'Umbrella'],
		['🎃', 'Jack-O-Lantern'],
		['🧵', 'Thread'],
		['🧶', 'Yarn'],
		['👓', 'Glasses'],
		['🕶️', 'Sunglasses'],
		['🥽', 'Goggles'],
		['🥼', 'Lab Coat'],
		['🦺', 'Safety Vest'],
		['👔', 'Necktie'],
		['👕', 'T-Shirt'],
		['👖', 'Jeans'],
		['🧣', 'Scarf'],
		['🧤', 'Gloves'],
		['🧥', 'Coat'],
		['🧦', 'Socks'],
		['👗', 'Dress'],
		['👘', 'Kimono'],
		['🥻', 'Sari'],
		['🩱', 'One-Piece Swimsuit'],
		['🩲', 'Briefs'],
		['🩳', 'Shorts'],
		['👙', 'Bikini'],
		['👚', 'Woman’s Clothes'],
		['👛', 'Purse'],
		['👜', 'Handbag'],
		['👝', 'Clutch Bag'],
		['🎒', 'Backpack'],
		['👞', 'Man’s Shoe'],
		['👟', 'Running Shoe'],
		['🥾', 'Hiking Boot'],
		['🥿', 'Flat Shoe'],
		['👠', 'High-Heeled Shoe'],
		['👡', 'Woman’s Sandal'],
		['🩰', 'Ballet Shoes'],
		['👢', 'Woman’s Boot'],
		['👑', 'Crown'],
		['👒', 'Woman’s Hat'],
		['🎩', 'Top Hat'],
		['🎓', 'Graduation Cap'],
		['🧢', 'Billed Cap'],
		['⛑️', 'Rescue Worker’s Helmet'],
		['💄', 'Lipstick'],
		['💍', 'Ring'],
		['💼', 'Briefcase'],
		['🩸', 'Drop of Blood'],
	],
	'Animals & Nature': [
		['🙈', 'See-No-Evil Monkey'],
		['🙉', 'Hear-No-Evil Monkey'],
		['🙊', 'Speak-No-Evil Monkey'],
		['💥', 'Collision'],
		['💫', 'Dizzy'],
		['💦', 'Sweat Droplets'],
		['💨', 'Dashing Away'],
		['🐵', 'Monkey Face'],
		['🐒', 'Monkey'],
		['🦍', 'Gorilla'],
		['🦧', 'Orangutan'],
		['🐶', 'Dog Face'],
		['🐕', 'Dog'],
		['🦮', 'Guide Dog'],
		['🐕‍🦺', 'Service Dog'],
		['🐩', 'Poodle'],
		['🐺', 'Wolf'],
		['🦊', 'Fox'],
		['🦝', 'Raccoon'],
		['🐱', 'Cat Face'],
		['🐈', 'Cat'],
		['🦁', 'Lion'],
		['🐯', 'Tiger Face'],
		['🐅', 'Tiger'],
		['🐆', 'Leopard'],
		['🐴', 'Horse Face'],
		['🐎', 'Horse'],
		['🦄', 'Unicorn'],
		['🦓', 'Zebra'],
		['🦌', 'Deer'],
		['🐮', 'Cow Face'],
		['🐂', 'Ox'],
		['🐃', 'Water Buffalo'],
		['🐄', 'Cow'],
		['🐷', 'Pig Face'],
		['🐖', 'Pig'],
		['🐗', 'Boar'],
		['🐽', 'Pig Nose'],
		['🐏', 'Ram'],
		['🐑', 'Ewe'],
		['🐐', 'Goat'],
		['🐪', 'Camel'],
		['🐫', 'Two-Hump Camel'],
		['🦙', 'Llama'],
		['🦒', 'Giraffe'],
		['🐘', 'Elephant'],
		['🦏', 'Rhinoceros'],
		['🦛', 'Hippopotamus'],
		['🐭', 'Mouse Face'],
		['🐁', 'Mouse'],
		['🐀', 'Rat'],
		['🐹', 'Hamster'],
		['🐰', 'Rabbit Face'],
		['🐇', 'Rabbit'],
		['🐿️', 'Chipmunk'],
		['🦔', 'Hedgehog'],
		['🦇', 'Bat'],
		['🐻', 'Bear'],
		['🐨', 'Koala'],
		['🐼', 'Panda'],
		['🦥', 'Sloth'],
		['🦦', 'Otter'],
		['🦨', 'Skunk'],
		['🦘', 'Kangaroo'],
		['🦡', 'Badger'],
		['🐾', 'Paw Prints'],
		['🦃', 'Turkey'],
		['🐔', 'Chicken'],
		['🐓', 'Rooster'],
		['🐣', 'Hatching Chick'],
		['🐤', 'Baby Chick'],
		['🐥', 'Front-Facing Baby Chick'],
		['🐦', 'Bird'],
		['🐧', 'Penguin'],
		['🕊️', 'Dove'],
		['🦅', 'Eagle'],
		['🦆', 'Duck'],
		['🦢', 'Swan'],
		['🦉', 'Owl'],
		['🦩', 'Flamingo'],
		['🦚', 'Peacock'],
		['🦜', 'Parrot'],
		['🐸', 'Frog'],
		['🐊', 'Crocodile'],
		['🐢', 'Turtle'],
		['🦎', 'Lizard'],
		['🐍', 'Snake'],
		['🐲', 'Dragon Face'],
		['🐉', 'Dragon'],
		['🦕', 'Sauropod'],
		['🦖', 'T-Rex'],
		['🐳', 'Spouting Whale'],
		['🐋', 'Whale'],
		['🐬', 'Dolphin'],
		['🐟', 'Fish'],
		['🐠', 'Tropical Fish'],
		['🐡', 'Blowfish'],
		['🦈', 'Shark'],
		['🐙', 'Octopus'],
		['🐚', 'Spiral Shell'],
		['🐌', 'Snail'],
		['🦋', 'Butterfly'],
		['🐛', 'Bug'],
		['🐜', 'Ant'],
		['🐝', 'Honeybee'],
		['🐞', 'Lady Beetle'],
		['🦗', 'Cricket'],
		['🕷️', 'Spider'],
		['🕸️', 'Spider Web'],
		['🦂', 'Scorpion'],
		['🦟', 'Mosquito'],
		['🦠', 'Microbe'],
		['💐', 'Bouquet'],
		['🌸', 'Cherry Blossom'],
		['💮', 'White Flower'],
		['🏵️', 'Rosette'],
		['🌹', 'Rose'],
		['🥀', 'Wilted Flower'],
		['🌺', 'Hibiscus'],
		['🌻', 'Sunflower'],
		['🌼', 'Blossom'],
		['🌷', 'Tulip'],
		['🌱', 'Seedling'],
		['🌲', 'Evergreen Tree'],
		['🌳', 'Deciduous Tree'],
		['🌴', 'Palm Tree'],
		['🌵', 'Cactus'],
		['🌾', 'Sheaf of Rice'],
		['🌿', 'Herb'],
		['☘️', 'Shamrock'],
		['🍀', 'Four Leaf Clover'],
		['🍁', 'Maple Leaf'],
		['🍂', 'Fallen Leaf'],
		['🍃', 'Leaf Fluttering in Wind'],
		['🍄', 'Mushroom'],
		['🌰', 'Chestnut'],
		['🦀', 'Crab'],
		['🦞', 'Lobster'],
		['🦐', 'Shrimp'],
		['🦑', 'Squid'],
		['🌍', 'Globe Showing Europe-Africa'],
		['🌎', 'Globe Showing Americas'],
		['🌏', 'Globe Showing Asia-Australia'],
		['🌐', 'Globe with Meridians'],
		['🌑', 'New Moon'],
		['🌒', 'Waxing Crescent Moon'],
		['🌓', 'First Quarter Moon'],
		['🌔', 'Waxing Gibbous Moon'],
		['🌕', 'Full Moon'],
		['🌖', 'Waning Gibbous Moon'],
		['🌗', 'Last Quarter Moon'],
		['🌘', 'Waning Crescent Moon'],
		['🌙', 'Crescent Moon'],
		['🌚', 'New Moon Face'],
		['🌛', 'First Quarter Moon Face'],
		['🌜', 'Last Quarter Moon Face'],
		['☀️', 'Sun'],
		['🌝', 'Full Moon Face'],
		['🌞', 'Sun with Face'],
		['⭐', 'Star'],
		['🌟', 'Glowing Star'],
		['🌠', 'Shooting Star'],
		['☁️', 'Cloud'],
		['⛅', 'Sun Behind Cloud'],
		['⛈️', 'Cloud with Lightning and Rain'],
		['🌤️', 'Sun Behind Small Cloud'],
		['🌥️', 'Sun Behind Large Cloud'],
		['🌦️', 'Sun Behind Rain Cloud'],
		['🌧️', 'Cloud with Rain'],
		['🌨️', 'Cloud with Snow'],
		['🌩️', 'Cloud with Lightning'],
		['🌪️', 'Tornado'],
		['🌫️', 'Fog'],
		['🌬️', 'Wind Face'],
		['🌈', 'Rainbow'],
		['☂️', 'Umbrella'],
		['☔', 'Umbrella with Rain Drops'],
		['⚡', 'High Voltage'],
		['❄️', 'Snowflake'],
		['☃️', 'Snowman'],
		['⛄', 'Snowman Without Snow'],
		['☄️', 'Comet'],
		['🔥', 'Fire'],
		['💧', 'Droplet'],
		['🌊', 'Water Wave'],
		['🎄', 'Christmas Tree'],
		['✨', 'Sparkles'],
		['🎋', 'Tanabata Tree'],
		['🎍', 'Pine Decoration'],
	],
	'Food & Drink': [
		['🍇', 'Grapes'],
		['🍈', 'Melon'],
		['🍉', 'Watermelon'],
		['🍊', 'Tangerine'],
		['🍋', 'Lemon'],
		['🍌', 'Banana'],
		['🍍', 'Pineapple'],
		['🥭', 'Mango'],
		['🍎', 'Red Apple'],
		['🍏', 'Green Apple'],
		['🍐', 'Pear'],
		['🍑', 'Peach'],
		['🍒', 'Cherries'],
		['🍓', 'Strawberry'],
		['🥝', 'Kiwi Fruit'],
		['🍅', 'Tomato'],
		['🥥', 'Coconut'],
		['🥑', 'Avocado'],
		['🍆', 'Eggplant'],
		['🥔', 'Potato'],
		['🥕', 'Carrot'],
		['🌽', 'Ear of Corn'],
		['🌶️', 'Hot Pepper'],
		['🥒', 'Cucumber'],
		['🥬', 'Leafy Green'],
		['🥦', 'Broccoli'],
		['🧄', 'Garlic'],
		['🧅', 'Onion'],
		['🍄', 'Mushroom'],
		['🥜', 'Peanuts'],
		['🌰', 'Chestnut'],
		['🍞', 'Bread'],
		['🥐', 'Croissant'],
		['🥖', 'Baguette Bread'],
		['🥨', 'Pretzel'],
		['🥯', 'Bagel'],
		['🥞', 'Pancakes'],
		['🧇', 'Waffle'],
		['🧀', 'Cheese Wedge'],
		['🍖', 'Meat on Bone'],
		['🍗', 'Poultry Leg'],
		['🥩', 'Cut of Meat'],
		['🥓', 'Bacon'],
		['🍔', 'Hamburger'],
		['🍟', 'French Fries'],
		['🍕', 'Pizza'],
		['🌭', 'Hot Dog'],
		['🥪', 'Sandwich'],
		['🌮', 'Taco'],
		['🌯', 'Burrito'],
		['🥙', 'Stuffed Flatbread'],
		['🧆', 'Falafel'],
		['🥚', 'Egg'],
		['🍳', 'Cooking'],
		['🥘', 'Shallow Pan of Food'],
		['🍲', 'Pot of Food'],
		['🥣', 'Bowl with Spoon'],
		['🥗', 'Green Salad'],
		['🍿', 'Popcorn'],
		['🧈', 'Butter'],
		['🧂', 'Salt'],
		['🥫', 'Canned Food'],
		['🍱', 'Bento Box'],
		['🍘', 'Rice Cracker'],
		['🍙', 'Rice Ball'],
		['🍚', 'Cooked Rice'],
		['🍛', 'Curry Rice'],
		['🍜', 'Steaming Bowl'],
		['🍝', 'Spaghetti'],
		['🍠', 'Roasted Sweet Potato'],
		['🍢', 'Oden'],
		['🍣', 'Sushi'],
		['🍤', 'Fried Shrimp'],
		['🍥', 'Fish Cake with Swirl'],
		['🥮', 'Moon Cake'],
		['🍡', 'Dango'],
		['🥟', 'Dumpling'],
		['🥠', 'Fortune Cookie'],
		['🥡', 'Takeout Box'],
		['🦪', 'Oyster'],
		['🍦', 'Soft Ice Cream'],
		['🍧', 'Shaved Ice'],
		['🍨', 'Ice Cream'],
		['🍩', 'Doughnut'],
		['🍪', 'Cookie'],
		['🎂', 'Birthday Cake'],
		['🍰', 'Shortcake'],
		['🧁', 'Cupcake'],
		['🥧', 'Pie'],
		['🍫', 'Chocolate Bar'],
		['🍬', 'Candy'],
		['🍭', 'Lollipop'],
		['🍮', 'Custard'],
		['🍯', 'Honey Pot'],
		['🍼', 'Baby Bottle'],
		['🥛', 'Glass of Milk'],
		['☕', 'Hot Beverage'],
		['🍵', 'Teacup Without Handle'],
		['🍶', 'Sake'],
		['🍾', 'Bottle with Popping Cork'],
		['🍷', 'Wine Glass'],
		['🍸', 'Cocktail Glass'],
		['🍹', 'Tropical Drink'],
		['🍺', 'Beer Mug'],
		['🍻', 'Clinking Beer Mugs'],
		['🥂', 'Clinking Glasses'],
		['🥃', 'Tumbler Glass'],
		['🥤', 'Cup with Straw'],
		['🧃', 'Beverage Box'],
		['🧉', 'Mate'],
		['🧊', 'Ice'],
		['🥢', 'Chopsticks'],
		['🍽️', 'Fork and Knife with Plate'],
		['🍴', 'Fork and Knife'],
		['🥄', 'Spoon'],
	],
	Activity: [
		['🕴️', 'Person in Suit Levitating'],
		['🧗', 'Person Climbing'],
		['🧗‍♂️', 'Man Climbing'],
		['🧗‍♀️', 'Woman Climbing'],
		['🤺', 'Person Fencing'],
		['🏇', 'Horse Racing'],
		['⛷️', 'Skier'],
		['🏂', 'Snowboarder'],
		['🏌️', 'Person Golfing'],
		['🏌️‍♂️', 'Man Golfing'],
		['🏌️‍♀️', 'Woman Golfing'],
		['🏄', 'Person Surfing'],
		['🏄‍♂️', 'Man Surfing'],
		['🏄‍♀️', 'Woman Surfing'],
		['🚣', 'Person Rowing Boat'],
		['🚣‍♂️', 'Man Rowing Boat'],
		['🚣‍♀️', 'Woman Rowing Boat'],
		['🏊', 'Person Swimming'],
		['🏊‍♂️', 'Man Swimming'],
		['🏊‍♀️', 'Woman Swimming'],
		['⛹️', 'Person Bouncing Ball'],
		['⛹️‍♂️', 'Man Bouncing Ball'],
		['⛹️‍♀️', 'Woman Bouncing Ball'],
		['🏋️', 'Person Lifting Weights'],
		['🏋️‍♂️', 'Man Lifting Weights'],
		['🏋️‍♀️', 'Woman Lifting Weights'],
		['🚴', 'Person Biking'],
		['🚴‍♂️', 'Man Biking'],
		['🚴‍♀️', 'Woman Biking'],
		['🚵', 'Person Mountain Biking'],
		['🚵‍♂️', 'Man Mountain Biking'],
		['🚵‍♀️', 'Woman Mountain Biking'],
		['🤸', 'Person Cartwheeling'],
		['🤸‍♂️', 'Man Cartwheeling'],
		['🤸‍♀️', 'Woman Cartwheeling'],
		['🤼', 'People Wrestling'],
		['🤼‍♂️', 'Men Wrestling'],
		['🤼‍♀️', 'Women Wrestling'],
		['🤽', 'Person Playing Water Polo'],
		['🤽‍♂️', 'Man Playing Water Polo'],
		['🤽‍♀️', 'Woman Playing Water Polo'],
		['🤾', 'Person Playing Handball'],
		['🤾‍♂️', 'Man Playing Handball'],
		['🤾‍♀️', 'Woman Playing Handball'],
		['🤹', 'Person Juggling'],
		['🤹‍♂️', 'Man Juggling'],
		['🤹‍♀️', 'Woman Juggling'],
		['🧘', 'Person in Lotus Position'],
		['🧘‍♂️', 'Man in Lotus Position'],
		['🧘‍♀️', 'Woman in Lotus Position'],
		['🎪', 'Circus Tent'],
		['🛹', 'Skateboard'],
		['🛶', 'Canoe'],
		['🎗️', 'Reminder Ribbon'],
		['🎟️', 'Admission Tickets'],
		['🎫', 'Ticket'],
		['🎖️', 'Military Medal'],
		['🏆', 'Trophy'],
		['🏅', 'Sports Medal'],
		['🥇', '1st Place Medal'],
		['🥈', '2nd Place Medal'],
		['🥉', '3rd Place Medal'],
		['⚽', 'Soccer Ball'],
		['⚾', 'Baseball'],
		['🥎', 'Softball'],
		['🏀', 'Basketball'],
		['🏐', 'Volleyball'],
		['🏈', 'American Football'],
		['🏉', 'Rugby Football'],
		['🎾', 'Tennis'],
		['🥏', 'Flying Disc'],
		['🎳', 'Bowling'],
		['🏏', 'Cricket Game'],
		['🏑', 'Field Hockey'],
		['🏒', 'Ice Hockey'],
		['🥍', 'Lacrosse'],
		['🏓', 'Ping Pong'],
		['🏸', 'Badminton'],
		['🥊', 'Boxing Glove'],
		['🥋', 'Martial Arts Uniform'],
		['🥅', 'Goal Net'],
		['⛳', 'Flag in Hole'],
		['⛸️', 'Ice Skate'],
		['🎣', 'Fishing Pole'],
		['🎽', 'Running Shirt'],
		['🎿', 'Skis'],
		['🛷', 'Sled'],
		['🥌', 'Curling Stone'],
		['🎯', 'Direct Hit'],
		['🎱', 'Pool 8 Ball'],
		['🎮', 'Video Game'],
		['🎰', 'Slot Machine'],
		['🎲', 'Game Die'],
		['🧩', 'Puzzle Piece'],
		['♟️', 'Chess Pawn'],
		['🎭', 'Performing Arts'],
		['🎨', 'Artist Palette'],
		['🧵', 'Thread'],
		['🧶', 'Yarn'],
		['🎼', 'Musical Score'],
		['🎤', 'Microphone'],
		['🎧', 'Headphone'],
		['🎷', 'Saxophone'],
		['🎸', 'Guitar'],
		['🎹', 'Musical Keyboard'],
		['🎺', 'Trumpet'],
		['🎻', 'Violin'],
		['🥁', 'Drum'],
		['🎬', 'Clapper Board'],
		['🏹', 'Bow and Arrow'],
	],
	'Travel & Places': [
		['🚣', 'Person Rowing Boat'],
		['🗾', 'Map of Japan'],
		['🏔️', 'Snow-Capped Mountain'],
		['⛰️', 'Mountain'],
		['🌋', 'Volcano'],
		['🗻', 'Mount Fuji'],
		['🏕️', 'Camping'],
		['🏖️', 'Beach with Umbrella'],
		['🏜️', 'Desert'],
		['🏝️', 'Desert Island'],
		['🏞️', 'National Park'],
		['🏟️', 'Stadium'],
		['🏛️', 'Classical Building'],
		['🏗️', 'Building Construction'],
		['🏘️', 'Houses'],
		['🏚️', 'Derelict House'],
		['🏠', 'House'],
		['🏡', 'House with Garden'],
		['🏢', 'Office Building'],
		['🏣', 'Japanese Post Office'],
		['🏤', 'Post Office'],
		['🏥', 'Hospital'],
		['🏦', 'Bank'],
		['🏨', 'Hotel'],
		['🏩', 'Love Hotel'],
		['🏪', 'Convenience Store'],
		['🏫', 'School'],
		['🏬', 'Department Store'],
		['🏭', 'Factory'],
		['🏯', 'Japanese Castle'],
		['🏰', 'Castle'],
		['💒', 'Wedding'],
		['🗼', 'Tokyo Tower'],
		['🗽', 'Statue of Liberty'],
		['⛪', 'Church'],
		['🕌', 'Mosque'],
		['🛕', 'Hindu Temple'],
		['🕍', 'Synagogue'],
		['⛩️', 'Shinto Shrine'],
		['🕋', 'Kaaba'],
		['⛲', 'Fountain'],
		['⛺', 'Tent'],
		['🌁', 'Foggy'],
		['🌃', 'Night with Stars'],
		['🏙️', 'Cityscape'],
		['🌄', 'Sunrise Over Mountains'],
		['🌅', 'Sunrise'],
		['🌆', 'Cityscape at Dusk'],
		['🌇', 'Sunset'],
		['🌉', 'Bridge at Night'],
		['🎠', 'Carousel Horse'],
		['🎡', 'Ferris Wheel'],
		['🎢', 'Roller Coaster'],
		['🚂', 'Locomotive'],
		['🚃', 'Railway Car'],
		['🚄', 'High-Speed Train'],
		['🚅', 'Bullet Train'],
		['🚆', 'Train'],
		['🚇', 'Metro'],
		['🚈', 'Light Rail'],
		['🚉', 'Station'],
		['🚊', 'Tram'],
		['🚝', 'Monorail'],
		['🚞', 'Mountain Railway'],
		['🚋', 'Tram Car'],
		['🚌', 'Bus'],
		['🚍', 'Oncoming Bus'],
		['🚎', 'Trolleybus'],
		['🚐', 'Minibus'],
		['🚑', 'Ambulance'],
		['🚒', 'Fire Engine'],
		['🚓', 'Police Car'],
		['🚔', 'Oncoming Police Car'],
		['🚕', 'Taxi'],
		['🚖', 'Oncoming Taxi'],
		['🚗', 'Automobile'],
		['🚘', 'Oncoming Automobile'],
		['🚙', 'Sport Utility Vehicle'],
		['🚚', 'Delivery Truck'],
		['🚛', 'Articulated Lorry'],
		['🚜', 'Tractor'],
		['🏎️', 'Racing Car'],
		['🏍️', 'Motorcycle'],
		['🛵', 'Motor Scooter'],
		['🛺', 'Auto Rickshaw'],
		['🚲', 'Bicycle'],
		['🛴', 'Kick Scooter'],
		['🚏', 'Bus Stop'],
		['🛣️', 'Motorway'],
		['🛤️', 'Railway Track'],
		['⛽', 'Fuel Pump'],
		['🚨', 'Police Car Light'],
		['🚥', 'Horizontal Traffic Light'],
		['🚦', 'Vertical Traffic Light'],
		['🚧', 'Construction'],
		['⚓', 'Anchor'],
		['⛵', 'Sailboat'],
		['🚤', 'Speedboat'],
		['🛳️', 'Passenger Ship'],
		['⛴️', 'Ferry'],
		['🛥️', 'Motor Boat'],
		['🚢', 'Ship'],
		['✈️', 'Airplane'],
		['🛩️', 'Small Airplane'],
		['🛫', 'Airplane Departure'],
		['🛬', 'Airplane Arrival'],
		['🪂', 'Parachute'],
		['💺', 'Seat'],
		['🚁', 'Helicopter'],
		['🚟', 'Suspension Railway'],
		['🚠', 'Mountain Cableway'],
		['🚡', 'Aerial Tramway'],
		['🛰️', 'Satellite'],
		['🚀', 'Rocket'],
		['🛸', 'Flying Saucer'],
		['🪐', 'Ringed Planet'],
		['🌠', 'Shooting Star'],
		['🌌', 'Milky Way'],
		['⛱️', 'Umbrella on Ground'],
		['🎆', 'Fireworks'],
		['🎇', 'Sparkler'],
		['🎑', 'Moon Viewing Ceremony'],
		['💴', 'Yen Banknote'],
		['💵', 'Dollar Banknote'],
		['💶', 'Euro Banknote'],
		['💷', 'Pound Banknote'],
		['🗿', 'Moai'],
		['🛂', 'Passport Control'],
		['🛃', 'Customs'],
		['🛄', 'Baggage Claim'],
		['🛅', 'Left Luggage'],
	],
	Objects: [
		['💌', 'Love Letter'],
		['🕳️', 'Hole'],
		['💣', 'Bomb'],
		['🛀', 'Person Taking Bath'],
		['🛌', 'Person in Bed'],
		['🔪', 'Kitchen Knife'],
		['🏺', 'Amphora'],
		['🗺️', 'World Map'],
		['🧭', 'Compass'],
		['🧱', 'Brick'],
		['💈', 'Barber Pole'],
		['🦽', 'Manual Wheelchair'],
		['🦼', 'Motorized Wheelchair'],
		['🛢️', 'Oil Drum'],
		['🛎️', 'Bellhop Bell'],
		['🧳', 'Luggage'],
		['⌛', 'Hourglass Done'],
		['⏳', 'Hourglass Not Done'],
		['⌚', 'Watch'],
		['⏰', 'Alarm Clock'],
		['⏱️', 'Stopwatch'],
		['⏲️', 'Timer Clock'],
		['🕰️', 'Mantelpiece Clock'],
		['🌡️', 'Thermometer'],
		['⛱️', 'Umbrella on Ground'],
		['🧨', 'Firecracker'],
		['🎈', 'Balloon'],
		['🎉', 'Party Popper'],
		['🎊', 'Confetti Ball'],
		['🎎', 'Japanese Dolls'],
		['🎏', 'Carp Streamer'],
		['🎐', 'Wind Chime'],
		['🧧', 'Red Envelope'],
		['🎀', 'Ribbon'],
		['🎁', 'Wrapped Gift'],
		['🤿', 'Diving Mask'],
		['🪀', 'Yo-Yo'],
		['🪁', 'Kite'],
		['🔮', 'Crystal Ball'],
		['🧿', 'Nazar Amulet'],
		['🕹️', 'Joystick'],
		['🧸', 'Teddy Bear'],
		['🖼️', 'Framed Picture'],
		['🧵', 'Thread'],
		['🧶', 'Yarn'],
		['🛍️', 'Shopping Bags'],
		['📿', 'Prayer Beads'],
		['💎', 'Gem Stone'],
		['📯', 'Postal Horn'],
		['🎙️', 'Studio Microphone'],
		['🎚️', 'Level Slider'],
		['🎛️', 'Control Knobs'],
		['📻', 'Radio'],
		['🪕', 'Banjo'],
		['📱', 'Mobile Phone'],
		['📲', 'Mobile Phone with Arrow'],
		['☎️', 'Telephone'],
		['📞', 'Telephone Receiver'],
		['📟', 'Pager'],
		['📠', 'Fax Machine'],
		['🔋', 'Battery'],
		['🔌', 'Electric Plug'],
		['💻', 'Laptop'],
		['🖥️', 'Desktop Computer'],
		['🖨️', 'Printer'],
		['⌨️', 'Keyboard'],
		['🖱️', 'Computer Mouse'],
		['🖲️', 'Trackball'],
		['💽', 'Computer Disk'],
		['💾', 'Floppy Disk'],
		['💿', 'Optical Disk'],
		['📀', 'DVD'],
		['🧮', 'Abacus'],
		['🎥', 'Movie Camera'],
		['🎞️', 'Film Frames'],
		['📽️', 'Film Projector'],
		['📺', 'Television'],
		['📷', 'Camera'],
		['📸', 'Camera with Flash'],
		['📹', 'Video Camera'],
		['📼', 'Videocassette'],
		['🔍', 'Magnifying Glass Tilted Left'],
		['🔎', 'Magnifying Glass Tilted Right'],
		['🕯️', 'Candle'],
		['💡', 'Light Bulb'],
		['🔦', 'Flashlight'],
		['🏮', 'Red Paper Lantern'],
		['🪔', 'Diya Lamp'],
		['📔', 'Notebook with Decorative Cover'],
		['📕', 'Closed Book'],
		['📖', 'Open Book'],
		['📗', 'Green Book'],
		['📘', 'Blue Book'],
		['📙', 'Orange Book'],
		['📚', 'Books'],
		['📓', 'Notebook'],
		['📒', 'Ledger'],
		['📃', 'Page with Curl'],
		['📜', 'Scroll'],
		['📄', 'Page Facing Up'],
		['📰', 'Newspaper'],
		['🗞️', 'Rolled-Up Newspaper'],
		['📑', 'Bookmark Tabs'],
		['🔖', 'Bookmark'],
		['🏷️', 'Label'],
		['💰', 'Money Bag'],
		['💴', 'Yen Banknote'],
		['💵', 'Dollar Banknote'],
		['💶', 'Euro Banknote'],
		['💷', 'Pound Banknote'],
		['💸', 'Money with Wings'],
		['💳', 'Credit Card'],
		['🧾', 'Receipt'],
		['✉️', 'Envelope'],
		['📧', 'E-Mail'],
		['📨', 'Incoming Envelope'],
		['📩', 'Envelope with Arrow'],
		['📤', 'Outbox Tray'],
		['📥', 'Inbox Tray'],
		['📦', 'Package'],
		['📫', 'Closed Mailbox with Raised Flag'],
		['📪', 'Closed Mailbox with Lowered Flag'],
		['📬', 'Open Mailbox with Raised Flag'],
		['📭', 'Open Mailbox with Lowered Flag'],
		['📮', 'Postbox'],
		['🗳️', 'Ballot Box with Ballot'],
		['✏️', 'Pencil'],
		['✒️', 'Black Nib'],
		['🖋️', 'Fountain Pen'],
		['🖊️', 'Pen'],
		['🖌️', 'Paintbrush'],
		['🖍️', 'Crayon'],
		['📝', 'Memo'],
		['📁', 'File Folder'],
		['📂', 'Open File Folder'],
		['🗂️', 'Card Index Dividers'],
		['📅', 'Calendar'],
		['📆', 'Tear-Off Calendar'],
		['🗒️', 'Spiral Notepad'],
		['🗓️', 'Spiral Calendar'],
		['📇', 'Card Index'],
		['📈', 'Chart Increasing'],
		['📉', 'Chart Decreasing'],
		['📊', 'Bar Chart'],
		['📋', 'Clipboard'],
		['📌', 'Pushpin'],
		['📍', 'Round Pushpin'],
		['📎', 'Paperclip'],
		['🖇️', 'Linked Paperclips'],
		['📏', 'Straight Ruler'],
		['📐', 'Triangular Ruler'],
		['✂️', 'Scissors'],
		['🗃️', 'Card File Box'],
		['🗄️', 'File Cabinet'],
		['🗑️', 'Wastebasket'],
		['🔒', 'Locked'],
		['🔓', 'Unlocked'],
		['🔏', 'Locked with Pen'],
		['🔐', 'Locked with Key'],
		['🔑', 'Key'],
		['🗝️', 'Old Key'],
		['🔨', 'Hammer'],
		['🪓', 'Axe'],
		['⛏️', 'Pick'],
		['⚒️', 'Hammer and Pick'],
		['🛠️', 'Hammer and Wrench'],
		['🗡️', 'Dagger'],
		['⚔️', 'Crossed Swords'],
		['🔫', 'Pistol'],
		['🛡️', 'Shield'],
		['🔧', 'Wrench'],
		['🔩', 'Nut and Bolt'],
		['⚙️', 'Gear'],
		['🗜️', 'Clamp'],
		['⚖️', 'Balance Scale'],
		['🦯', 'White Cane'],
		['🔗', 'Link'],
		['⛓️', 'Chains'],
		['🧰', 'Toolbox'],
		['🧲', 'Magnet'],
		['⚗️', 'Alembic'],
		['🧪', 'Test Tube'],
		['🧫', 'Petri Dish'],
		['🧬', 'DNA'],
		['🔬', 'Microscope'],
		['🔭', 'Telescope'],
		['📡', 'Satellite Antenna'],
		['💉', 'Syringe'],
		['🩸', 'Drop of Blood'],
		['💊', 'Pill'],
		['🩹', 'Adhesive Bandage'],
		['🩺', 'Stethoscope'],
		['🚪', 'Door'],
		['🛏️', 'Bed'],
		['🛋️', 'Couch and Lamp'],
		['🪑', 'Chair'],
		['🚽', 'Toilet'],
		['🚿', 'Shower'],
		['🛁', 'Bathtub'],
		['🪒', 'Razor'],
		['🧴', 'Lotion Bottle'],
		['🧷', 'Safety Pin'],
		['🧹', 'Broom'],
		['🧺', 'Basket'],
		['🧻', 'Roll of Paper'],
		['🧼', 'Soap'],
		['🧽', 'Sponge'],
		['🧯', 'Fire Extinguisher'],
		['🛒', 'Shopping Cart'],
		['🚬', 'Cigarette'],
		['⚰️', 'Coffin'],
		['⚱️', 'Funeral Urn'],
		['🗿', 'Moai'],
		['🚰', 'Potable Water'],
	],
	Symbols: [
		['💘', 'Heart with Arrow'],
		['💝', 'Heart with Ribbon'],
		['💖', 'Sparkling Heart'],
		['💗', 'Growing Heart'],
		['💓', 'Beating Heart'],
		['💞', 'Revolving Hearts'],
		['💕', 'Two Hearts'],
		['💟', 'Heart Decoration'],
		['❣️', 'Heart Exclamation'],
		['💔', 'Broken Heart'],
		['❤️', 'Red Heart'],
		['🧡', 'Orange Heart'],
		['💛', 'Yellow Heart'],
		['💚', 'Green Heart'],
		['💙', 'Blue Heart'],
		['💜', 'Purple Heart'],
		['🤎', 'Brown Heart'],
		['🖤', 'Black Heart'],
		['🤍', 'White Heart'],
		['💯', 'Hundred Points'],
		['💢', 'Anger Symbol'],
		['💬', 'Speech Balloon'],
		['👁️‍🗨️', 'Eye in Speech Bubble'],
		['🗨️', 'Left Speech Bubble'],
		['🗯️', 'Right Anger Bubble'],
		['💭', 'Thought Balloon'],
		['💤', 'Zzz'],
		['💮', 'White Flower'],
		['♨️', 'Hot Springs'],
		['💈', 'Barber Pole'],
		['🛑', 'Stop Sign'],
		['🕛', 'Twelve O’Clock'],
		['🕧', 'Twelve-Thirty'],
		['🕐', 'One O’Clock'],
		['🕜', 'One-Thirty'],
		['🕑', 'Two O’Clock'],
		['🕝', 'Two-Thirty'],
		['🕒', 'Three O’Clock'],
		['🕞', 'Three-Thirty'],
		['🕓', 'Four O’Clock'],
		['🕟', 'Four-Thirty'],
		['🕔', 'Five O’Clock'],
		['🕠', 'Five-Thirty'],
		['🕕', 'Six O’Clock'],
		['🕡', 'Six-Thirty'],
		['🕖', 'Seven O’Clock'],
		['🕢', 'Seven-Thirty'],
		['🕗', 'Eight O’Clock'],
		['🕣', 'Eight-Thirty'],
		['🕘', 'Nine O’Clock'],
		['🕤', 'Nine-Thirty'],
		['🕙', 'Ten O’Clock'],
		['🕥', 'Ten-Thirty'],
		['🕚', 'Eleven O’Clock'],
		['🕦', 'Eleven-Thirty'],
		['🌀', 'Cyclone'],
		['♠️', 'Spade Suit'],
		['♥️', 'Heart Suit'],
		['♦️', 'Diamond Suit'],
		['♣️', 'Club Suit'],
		['🃏', 'Joker'],
		['🀄', 'Mahjong Red Dragon'],
		['🎴', 'Flower Playing Cards'],
		['🔇', 'Muted Speaker'],
		['🔈', 'Speaker Low Volume'],
		['🔉', 'Speaker Medium Volume'],
		['🔊', 'Speaker High Volume'],
		['📢', 'Loudspeaker'],
		['📣', 'Megaphone'],
		['📯', 'Postal Horn'],
		['🔔', 'Bell'],
		['🔕', 'Bell with Slash'],
		['🎵', 'Musical Note'],
		['🎶', 'Musical Notes'],
		['💹', 'Chart Increasing with Yen'],
		['🛗', 'Elevator'],
		['🏧', 'ATM Sign'],
		['🚮', 'Litter in Bin Sign'],
		['🚰', 'Potable Water'],
		['♿', 'Wheelchair Symbol'],
		['🚹', 'Men’s Room'],
		['🚺', 'Women’s Room'],
		['🚻', 'Restroom'],
		['🚼', 'Baby Symbol'],
		['🚾', 'Water Closet'],
		['⚠️', 'Warning'],
		['🚸', 'Children Crossing'],
		['⛔', 'No Entry'],
		['🚫', 'Prohibited'],
		['🚳', 'No Bicycles'],
		['🚭', 'No Smoking'],
		['🚯', 'No Littering'],
		['🚱', 'Non-Potable Water'],
		['🚷', 'No Pedestrians'],
		['📵', 'No Mobile Phones'],
		['🔞', 'No One Under Eighteen'],
		['☢️', 'Radioactive'],
		['☣️', 'Biohazard'],
		['⬆️', 'Up Arrow'],
		['↗️', 'Up-Right Arrow'],
		['➡️', 'Right Arrow'],
		['↘️', 'Down-Right Arrow'],
		['⬇️', 'Down Arrow'],
		['↙️', 'Down-Left Arrow'],
		['⬅️', 'Left Arrow'],
		['↖️', 'Up-Left Arrow'],
		['↕️', 'Up-Down Arrow'],
		['↔️', 'Left-Right Arrow'],
		['↩️', 'Right Arrow Curving Left'],
		['↪️', 'Left Arrow Curving Right'],
		['⤴️', 'Right Arrow Curving Up'],
		['⤵️', 'Right Arrow Curving Down'],
		['🔃', 'Clockwise Vertical Arrows'],
		['🔄', 'Counterclockwise Arrows Button'],
		['🔙', 'Back Arrow'],
		['🔚', 'End Arrow'],
		['🔛', 'On! Arrow'],
		['🔜', 'Soon Arrow'],
		['🔝', 'Top Arrow'],
		['🛐', 'Place of Worship'],
		['⚛️', 'Atom Symbol'],
		['🕉️', 'Om'],
		['✡️', 'Star of David'],
		['☸️', 'Wheel of Dharma'],
		['☯️', 'Yin Yang'],
		['✝️', 'Latin Cross'],
		['☦️', 'Orthodox Cross'],
		['☪️', 'Star and Crescent'],
		['☮️', 'Peace Symbol'],
		['🕎', 'Menorah'],
		['🔯', 'Dotted Six-Pointed Star'],
		['♈', 'Aries'],
		['♉', 'Taurus'],
		['♊', 'Gemini'],
		['♋', 'Cancer'],
		['♌', 'Leo'],
		['♍', 'Virgo'],
		['♎', 'Libra'],
		['♏', 'Scorpio'],
		['♐', 'Sagittarius'],
		['♑', 'Capricorn'],
		['♒', 'Aquarius'],
		['♓', 'Pisces'],
		['⛎', 'Ophiuchus'],
		['🔀', 'Shuffle Tracks Button'],
		['🔁', 'Repeat Button'],
		['🔂', 'Repeat Single Button'],
		['▶️', 'Play Button'],
		['⏩', 'Fast-Forward Button'],
		['⏭️', 'Next Track Button'],
		['⏯️', 'Play or Pause Button'],
		['◀️', 'Reverse Button'],
		['⏪', 'Fast Reverse Button'],
		['⏮️', 'Last Track Button'],
		['🔼', 'Upwards Button'],
		['⏫', 'Fast Up Button'],
		['🔽', 'Downwards Button'],
		['⏬', 'Fast Down Button'],
		['⏸️', 'Pause Button'],
		['⏹️', 'Stop Button'],
		['⏺️', 'Record Button'],
		['⏏️', 'Eject Button'],
		['🎦', 'Cinema'],
		['🔅', 'Dim Button'],
		['🔆', 'Bright Button'],
		['📶', 'Antenna Bars'],
		['📳', 'Vibration Mode'],
		['📴', 'Mobile Phone Off'],
		['♀️', 'Female Sign'],
		['♂️', 'Male Sign'],
		['✖️', 'Multiply'],
		['➕', 'Plus'],
		['➖', 'Minus'],
		['➗', 'Divide'],
		['♾️', 'Infinity'],
		['‼️', 'Double Exclamation Mark'],
		['⁉️', 'Exclamation Question Mark'],
		['❓', 'Question Mark'],
		['❔', 'White Question Mark'],
		['❕', 'White Exclamation Mark'],
		['❗', 'Exclamation Mark'],
		['〰️', 'Wavy Dash'],
		['💱', 'Currency Exchange'],
		['💲', 'Heavy Dollar Sign'],
		['⚕️', 'Medical Symbol'],
		['♻️', 'Recycling Symbol'],
		['⚜️', 'Fleur-de-lis'],
		['🔱', 'Trident Emblem'],
		['📛', 'Name Badge'],
		['🔰', 'Japanese Symbol for Beginner'],
		['⭕', 'Hollow Red Circle'],
		['✅', 'Check Mark Button'],
		['☑️', 'Check Box with Check'],
		['✔️', 'Check Mark'],
		['❌', 'Cross Mark'],
		['❎', 'Cross Mark Button'],
		['➰', 'Curly Loop'],
		['➿', 'Double Curly Loop'],
		['〽️', 'Part Alternation Mark'],
		['✳️', 'Eight-Spoked Asterisk'],
		['✴️', 'Eight-Pointed Star'],
		['❇️', 'Sparkle'],
		['©️', 'Copyright'],
		['®️', 'Registered'],
		['™️', 'Trade Mark'],
		['#️⃣', 'Keycap Number Sign'],
		['*️⃣', 'Keycap Asterisk'],
		['0️⃣', 'Keycap Digit Zero'],
		['1️⃣', 'Keycap Digit One'],
		['2️⃣', 'Keycap Digit Two'],
		['3️⃣', 'Keycap Digit Three'],
		['4️⃣', 'Keycap Digit Four'],
		['5️⃣', 'Keycap Digit Five'],
		['6️⃣', 'Keycap Digit Six'],
		['7️⃣', 'Keycap Digit Seven'],
		['8️⃣', 'Keycap Digit Eight'],
		['9️⃣', 'Keycap Digit Nine'],
		['🔟', 'Keycap: 10'],
		['🔠', 'Input Latin Uppercase'],
		['🔡', 'Input Latin Lowercase'],
		['🔢', 'Input Numbers'],
		['🔣', 'Input Symbols'],
		['🔤', 'Input Latin Letters'],
		['🅰️', 'A Button (Blood Type)'],
		['🆎', 'AB Button (Blood Type)'],
		['🅱️', 'B Button (Blood Type)'],
		['🆑', 'CL Button'],
		['🆒', 'Cool Button'],
		['🆓', 'Free Button'],
		['ℹ️', 'Information'],
		['🆔', 'ID Button'],
		['Ⓜ️', 'Circled M'],
		['🆕', 'New Button'],
		['🆖', 'NG Button'],
		['🅾️', 'O Button (Blood Type)'],
		['🆗', 'OK Button'],
		['🅿️', 'P Button'],
		['🆘', 'SOS Button'],
		['🆙', 'Up! Button'],
		['🆚', 'Vs Button'],
		['🈁', 'Japanese “Here” Button'],
		['🈂️', 'Japanese “Service Charge” Button'],
		['🈷️', 'Japanese “Monthly Amount” Button'],
		['🈶', 'Japanese “Not Free of Charge” Button'],
		['🈯', 'Japanese “Reserved” Button'],
		['🉐', 'Japanese “Bargain” Button'],
		['🈹', 'Japanese “Discount” Button'],
		['🈚', 'Japanese “Free of Charge” Button'],
		['🈲', 'Japanese “Prohibited” Button'],
		['🉑', 'Japanese “Acceptable” Button'],
		['🈸', 'Japanese “Application” Button'],
		['🈴', 'Japanese “Passing Grade” Button'],
		['🈳', 'Japanese “Vacancy” Button'],
		['㊗️', 'Japanese “Congratulations” Button'],
		['㊙️', 'Japanese “Secret” Button'],
		['🈺', 'Japanese “Open for Business” Button'],
		['🈵', 'Japanese “No Vacancy” Button'],
		['🔴', 'Red Circle'],
		['🟠', 'Orange Circle'],
		['🟡', 'Yellow Circle'],
		['🟢', 'Green Circle'],
		['🔵', 'Blue Circle'],
		['🟣', 'Purple Circle'],
		['🟤', 'Brown Circle'],
		['⚫', 'Black Circle'],
		['⚪', 'White Circle'],
		['🟥', 'Red Square'],
		['🟧', 'Orange Square'],
		['🟨', 'Yellow Square'],
		['🟩', 'Green Square'],
		['🟦', 'Blue Square'],
		['🟪', 'Purple Square'],
		['🟫', 'Brown Square'],
		['⬛', 'Black Large Square'],
		['⬜', 'White Large Square'],
		['◼️', 'Black Medium Square'],
		['◻️', 'White Medium Square'],
		['◾', 'Black Medium-Small Square'],
		['◽', 'White Medium-Small Square'],
		['▪️', 'Black Small Square'],
		['▫️', 'White Small Square'],
		['🔶', 'Large Orange Diamond'],
		['🔷', 'Large Blue Diamond'],
		['🔸', 'Small Orange Diamond'],
		['🔹', 'Small Blue Diamond'],
		['🔺', 'Red Triangle Pointed Up'],
		['🔻', 'Red Triangle Pointed Down'],
		['💠', 'Diamond with a Dot'],
		['🔘', 'Radio Button'],
		['🔳', 'White Square Button'],
		['🔲', 'Black Square Button'],
	],
};

export default emoji;
