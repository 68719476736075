import { timesSvg } from 'icons';
import React from 'react';
import styles from './CloseButton.module.css';

function CloseButton({ onClick }) {
	return (
		<button type={'button'} className={styles.closeButton} onClick={onClick}>
			<img src={timesSvg} className={styles.img} alt={'close popup'} />
		</button>
	);
}

export default CloseButton;
