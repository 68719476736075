import React from 'react';
import styles from './Title.module.css';

function Title({ children }) {
	return (
		<h1 className={styles.h1}>
			<span className={styles.h1Text}>{children}</span>
		</h1>
	);
}

export default Title;
