import React from 'react';
import styles from './Footer.module.css';
import {
	logoSvg,
	FacebookSocialSvg,
	InstagramSocialSvg,
	TiktokSocialSvg,
	TwitterSocialSvg,
	LinkedInSocialSvg,
} from 'icons';

function Footer() {
	return (
		<section className={styles.footer}>
			<div className={styles.left}>
				<img src={logoSvg} alt={'TipStory Logo'} className={styles.logo} />
				<div className={styles.social}>
					{socialIcons.map(({ name, svg, link }) => (
						<a
							key={name}
							target={'_blank'}
							rel={'noreferrer'}
							href={link}
							className={styles.svgLink}
						>
							{svg}
						</a>
					))}
				</div>
			</div>
			<div className={styles.right}>
				<div className={styles.linksColumn}>
					{mainLinks.map(({ name, link }) => (
						<a key={name} href={link} className={styles.link}>
							{name}
						</a>
					))}
				</div>
				<div className={styles.linksColumn}>
					{otherLinks.map(({ name, link }) => (
						<a key={name} href={link} className={styles.link}>
							{name}
						</a>
					))}
				</div>
			</div>
		</section>
	);
}

const mainLinks = [
	{ name: '🔥Popular Learnings', link: '/popular' },
	{ name: '❤️‍Latest Learnings', link: '/latest' },
	{ name: '📝Scholar Application', link: '/scholar-application' },
	{ name: '🎓Scholar Portal', link: '/scholar' },
	{ name: '✨Landing Page', link: '/landing' },
];

const otherLinks = [
	{ name: '💬Feedback', link: '/feedback' },
	{ name: '💡About', link: '/about' },
	{ name: '📃Terms', link: '/terms' },
	{ name: '🔒Privacy', link: '/privacy' },
];

const socialIcons = [
	{
		name: 'instagram',
		svg: <InstagramSocialSvg />,
		link: 'https://www.instagram.com/tipstorylearn',
	},
	{
		name: 'tiktok',
		svg: <TiktokSocialSvg />,
		link: 'https://www.tiktok.com/@tipstorylearn',
	},
	{
		name: 'linkedin',
		svg: <LinkedInSocialSvg />,
		link: 'https://www.linkedin.com/company/tipstory',
	},
	{
		name: 'twitter',
		svg: <TwitterSocialSvg />,
		link: 'https://twitter.com/TipStoryLearn',
	},
	{
		name: 'facebook',
		svg: <FacebookSocialSvg />,
		link: 'https://www.facebook.com/tipstorylearn',
	},
];

export default Footer;
