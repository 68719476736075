import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetDrafts } from 'graphql/customQueries';
import { useUser } from 'utils';

/**
 * useGetDrafts hook gets user's draft learnings.
 *
 * @param {Object}
 */
const useGetDrafts = ({ setDrafts }) => {
	const [user] = useUser();

	useEffect(() => {
		// Skip if user is not login
		if (!user.isLogin) return;

		const getDrafts = async () => {
			const variables = {
				userID: user.userID,
			};
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetDrafts, variables),
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				const drafts = res.data.getLearningByChapter.items;
				setDrafts(drafts);
			} catch (error) {
				console.log('Error getting drafts: ', error);
			}
		};
		getDrafts();
	}, [user.isLogin, user.userID, setDrafts]);
};

export default useGetDrafts;
