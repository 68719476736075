import React from 'react';
import styles from './WhatStudentsDo.module.css';

function WhatStudentsDo() {
	return (
		<section className={styles.whatStudentsDo}>
			<h1 className={styles.heading}>What do students do here?</h1>
			<div className={styles.panels}>
				{PANEL_CONFIGS.map((item, idx) => (
					<Panel key={idx} {...item} />
				))}
			</div>
		</section>
	);
}

const PANEL_CONFIGS = [
	{
		emoji: '💭',
		heading: 'Reflect',
		text: 'Million things come to our minds every day. Reflect and document helpful and interesting lessons you have learned on TipStory.',
	},
	{
		emoji: '💡',
		heading: 'Teach',
		text: 'Teaching helps us learn. Improve your communication and writing skills by teaching others  topics that interest you.',
	},
	{
		emoji: '👀',
		heading: 'Explore',
		text: 'Everyone has different interests, strengths, and experiences. Explore tips and form connections with other like minded students.',
	},
];

function Panel({ emoji, heading, text }) {
	return (
		<div className={styles.panel}>
			<h2 className={styles.panelHeading}>
				<span className={styles.headingEmoji}>{emoji}</span>
				{heading}
			</h2>
			<div>{text}</div>
		</div>
	);
}

export default WhatStudentsDo;
