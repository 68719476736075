import React from 'react';
import styles from './FollowCard.module.css';
import { getProfileLink, useLogin, useUser } from 'utils';
import { profileSvg, ReadActiveSvg, TipSvg } from 'icons';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import { FollowButton } from 'components/Button';
import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationCreateFollow,
	mutationDeleteFollow,
} from 'graphql/customMutations';
import update from 'immutability-helper';
import { getScholarMeta } from 'components/Home/ScholarPortal/utils';
import ScholarBadge, { getScholarBadges } from 'components/ScholarBadge';

function FollowCard(props) {
	const { profile, setProfiles } = props;

	return (
		<div className={styles.followCard}>
			<Top profile={profile} />
			<Bottom profile={profile} setProfiles={setProfiles} />
		</div>
	);
}

function Top({ profile }) {
	const {
		profileURL,
		profileURLIdx,
		firstName,
		lastName,
		headline,
		profileImg,
	} = profile;
	const scholarMeta = getScholarMeta(profile);
	const scholarBadges = getScholarBadges(scholarMeta).slice(0, 2);
	return (
		<Link className={styles.top} to={getProfileLink(profileURL, profileURLIdx)}>
			<div>
				<img
					src={profileImg || profileSvg}
					className={styles.img}
					alt={'profile'}
				/>
			</div>
			<div className={styles.nameScholar}>
				<div>
					{firstName} {lastName}
				</div>
				{scholarBadges.map(({ type, year }, idx) => (
					<ScholarBadge
						key={idx}
						width={18}
						type={type}
						year={year}
						className={styles.scholarBadge}
					/>
				))}
			</div>
			<div className={styles.headline}>
				<TextTruncate
					element={'span'}
					truncateText={'...'}
					line={3}
					text={headline}
				/>
			</div>
		</Link>
	);
}

function Bottom({ profile, setProfiles }) {
	const { userID, numLearning, numReadMark, numReadReceive, followed } =
		profile;
	const [user, setUser] = useUser();
	const isAuthor = user.userID === userID;
	const [, setLogin] = useLogin();

	const handleClick = async () => {
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'login' });
			return;
		}
		if (followed) {
			await unfollowProfile({
				followUserID: userID,
				userID: user.userID,
				setUser,
				setProfiles,
			});
		} else {
			await followProfile({
				followUserID: userID,
				userID: user.userID,
				setUser,
				setProfiles,
			});
		}
	};
	return (
		<div className={styles.bottom}>
			<div className={styles.svgDiv}>
				<div
					className={styles.svgInnerDiv}
					title={'Total number of shared learnings'}
				>
					<TipSvg className={styles.svg} />
					<span className={styles.svgText}>{numLearning || 0}</span>
				</div>
				<div
					className={styles.svgInnerDiv}
					title={`Total number of reads marked (${
						numReadMark || 0
					}) and received (${numReadReceive || 0})`}
				>
					<ReadActiveSvg className={styles.svg} />
					<span className={styles.svgText}>
						{(numReadMark || 0) + (numReadReceive || 0)}
					</span>
				</div>
			</div>
			{!isAuthor && (
				<div className={styles.followBtnDiv}>
					<FollowButton followed={followed} onClick={handleClick} />
				</div>
			)}
		</div>
	);
}

// Unfollow profile
const unfollowProfile = async ({
	followUserID,
	userID,
	setUser,
	setProfiles,
}) => {
	const input = {
		followUserID,
		userID,
	};
	try {
		await API.graphql(graphqlOperation(mutationDeleteFollow, { input }));
		setUser((prev) => update(prev, { numFollowing: { $apply: (n) => n - 1 } }));
		setProfiles((prev) => {
			const idx = prev.findIndex((item) => item.userID === followUserID);
			return update(prev, { [idx]: { followed: { $set: false } } });
		});
	} catch (error) {
		console.log('Error unfollowing profile: ', error);
	}
};

const followProfile = async ({
	followUserID,
	userID,
	setUser,
	setProfiles,
}) => {
	const input = {
		followUserID,
		userID,
	};
	try {
		await API.graphql(graphqlOperation(mutationCreateFollow, { input }));
		setUser((prev) => update(prev, { numFollowing: { $apply: (n) => n + 1 } }));
		setProfiles((prev) => {
			const idx = prev.findIndex((item) => item.userID === followUserID);
			return update(prev, { [idx]: { followed: { $set: true } } });
		});
	} catch (error) {
		console.log('Error following profile: ', error);
	}
};

export default FollowCard;
