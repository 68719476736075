import React, { useState } from 'react';
import styles from './User.module.css';
import { profileSvg } from 'icons';
import PrimaryButton from 'components/Button';
import { useUser } from 'utils';
import EditProfileModal from '../EditProfileModal';
import FollowButton from './FollowButton';
import IconGroup from './IconGroup';
import { getScholarMeta } from 'components/Home/ScholarPortal/utils';
import ScholarBadge, { getScholarBadges } from 'components/ScholarBadge';

function User({ profile, setProfile }) {
	const { firstName, lastName, profileImg, headline } = profile;
	const scholarMeta = getScholarMeta(profile);
	const scholarBadges = getScholarBadges(scholarMeta);
	return (
		<div className={styles.user}>
			<div>
				<img
					src={profileImg || profileSvg}
					className={styles.img}
					alt={'Profile'}
				/>
			</div>
			<div className={styles.profile}>
				<div className={styles.name}>
					<div className={styles.nameScholar}>
						<h1 className={styles.nameH1}>
							{firstName} {lastName}
						</h1>
						{scholarBadges.map(({ type, year }, idx) => (
							<ScholarBadge
								key={idx}
								type={type}
								year={year}
								className={styles.scholarBadge}
							/>
						))}
					</div>
					<ProfileButton profile={profile} setProfile={setProfile} />
				</div>
				<div className={styles.headline}>{headline}</div>
				<IconGroup profile={profile} />
			</div>
		</div>
	);
}

function ProfileButton({ profile, setProfile }) {
	const { userID } = profile;
	const [user] = useUser();
	const isAuthor = user.userID === userID;
	return (
		<div className={styles.profileButton}>
			{isAuthor ? (
				<EditButton />
			) : (
				<FollowButton profile={profile} setProfile={setProfile} />
			)}
		</div>
	);
}

function EditButton() {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<PrimaryButton
				children={'Edit Profile'}
				onClick={() => setIsOpen(true)}
			/>
			{isOpen && <EditProfileModal setIsOpen={setIsOpen} />}
		</>
	);
}

export default User;
