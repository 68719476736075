import classNames from 'classnames';
import React, { useState } from 'react';
// import { useEffect } from 'react';
// import { useHistory } from 'react-router';
import { useUser } from 'utils';
import MonthlyTask from './MonthlyTask';
import { getCurrentYear } from './MonthlyTask/utils';
import OneTimeTask from './OneTimeTask';
import styles from './ScholarPortal.module.css';
import SharingOverview from './SharingOverview';
import { getScholarMeta } from './utils';

// const scholarMeta = {
// 	oneTimeTaskDone: true,
// };

const year = getCurrentYear();

function ScholarPortal() {
	const [user] = useUser();
	const [learningsWrite, setLearningsWrite] = useState([]);
	if (user.isLoading) return null;
	// Show SignIn if not login
	if (!user.isLogin) return <SignIn />;
	// Show PendingScholar if application is pending
	if (user.scholarStatus === 'pending') return <PendingScholar />;
	// Show ScholarPortal if is scholar or just approved
	if (user.scholar || user.scholarStatus === 'approved') {
		return (
			<div>
				<div className={styles.containerBlock}>
					<h1 className={styles.h1}>🎓{year} TipStory Scholar Portal</h1>
					<p className={styles.p}>
						Welcome back, {user.firstName}! Check below to see your learning and
						sharing progress.
					</p>
				</div>
				<OneTimeTask />
				<MonthlyTask
					learningsWrite={learningsWrite}
					setLearningsWrite={setLearningsWrite}
				/>
				<SharingOverview learningsWrite={learningsWrite} />
				<Instruction />
			</div>
		);
	}
	// Not a scholar
	return <NotAScholar />;
}

function SignIn() {
	return (
		<div className={styles.containerBlock}>
			<h1 className={styles.h1}>🎓{year} TipStory Scholar Portal</h1>
			<p className={styles.p}>
				You haven't logined yet. Please login to see your Scholar Portal.
			</p>
		</div>
	);
}

function PendingScholar() {
	return (
		<div className={styles.containerBlock}>
			<h1 className={styles.h1}>🎓{year} TipStory Scholar Portal</h1>
			<div className={styles.p}>
				Thank you for your interest in being a TipStory Scholar. We have
				received your application and it is currently under review. We will
				contact you within a week or two if your application is approved via the
				email you provided.
			</div>
			<br />
			<p className={styles.p}>
				If you have any question in regards to your application status, please
				send us an email at{' '}
				<a
					className={styles.link}
					href='mailto:scholar@tipstory.org?subject=Inquiry - TipStory Scholar Application Status'
				>
					scholar@tipstory.org
				</a>
				.
			</p>
		</div>
	);
}

function NotAScholar() {
	return (
		<div className={styles.containerBlock}>
			<h1 className={styles.h1}>🎓{year} TipStory Scholar Portal</h1>
			<p className={styles.p}>
				Sorry, only TipStory Scholar has access to the Scholar Portal. To learn
				more about TipStory Scholar, check out the{' '}
				<a className={styles.link} href='/scholar-application'>
					application
				</a>
				.
			</p>
		</div>
	);
}

function Instruction() {
	const [user] = useUser();
	const scholarMeta = getScholarMeta(user);
	const inactive = !scholarMeta.oneTimeTaskDone;
	const displayHour = user.volunteerHour !== 'no';
	return (
		<div
			className={classNames(styles.containerBlock, {
				[styles.blur]: inactive,
			})}
		>
			<p className={styles.instructionP}>
				*Monthly Task is reset on the last day of the month at 8PM EST (5PM PST)
				during Daylight Saving Time months (Mar-Oct) or 7PM EST (4PM PST) during
				other months (Nov-Feb).
			</p>
			{displayHour && (
				<p className={styles.instructionP}>
					**Volunteer hours may be lower than writing hours because you can earn
					up to a maximum of 5 volunteer hours per learning post and a maximum
					of 10 volunteer hours on any given month.
				</p>
			)}
			<p className={styles.instructionP}>
				To review the {year} TipStory Scholar Application, visit{' '}
				<a
					className={styles.link}
					href='https://www.tipstory.org/scholar-application'
				>
					www.tipstory.org/scholar-application
				</a>
				.
			</p>
			<p className={styles.instructionP}>
				If you have any question or encounter any issue, please send us an email
				at{' '}
				<a className={styles.link} href='mailto:scholar@tipstory.org'>
					scholar@tipstory.org
				</a>
				.
			</p>
		</div>
	);
}

export default ScholarPortal;
