import React, { useState, useEffect } from 'react';
import styles from './DeleteChapter.module.css';
import { getNewOrder, useUser } from 'utils';
import { queryGetLearningsByChapter } from 'graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationUpdateUser,
	mutationDeleteLearning,
} from 'graphql/customMutations';
import CloseButton from '../CloseButton';
import ErrorMsg from '../../ErrorMsg';
import PrimaryButton from 'components/Button';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import getNewChapterName from 'utils/getNewChapterName';
import update from 'immutability-helper';
import { useParams } from 'react-router-dom';

function DeleteChapter({ modal, setIsOpenFalse, setJourney }) {
	const { isOpen, title, id: learningID } = modal;
	const [chapterContent, setChapterContent] = useState({
		loading: true,
		empty: false,
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [user, setUser] = useUser();
	const { withinChapterID = 'home' } = useParams();

	// Check if chapterContent is empty
	useEffect(() => {
		const getChapterInfo = async () => {
			if (isOpen && chapterContent.loading) {
				try {
					const variables = {
						userID: user.userID,
						withinChapterID: { eq: learningID },
					};
					const res = await API.graphql(
						graphqlOperation(queryGetLearningsByChapter, variables)
					);
					if (res.data.getLearningByChapter.items.length === 0) {
						setChapterContent({ loading: false, empty: true });
					} else {
						setChapterContent({ loading: false, empty: false });
					}
				} catch (error) {
					console.log('Error getting chapter info: ', error);
					setError(GENERAL_ERROR_MSG);
				}
			}
		};
		getChapterInfo();
	}, [isOpen, chapterContent.loading, user.userID, learningID]);

	const handleDelete = async () => {
		if (!chapterContent.empty) return;
		if (loading) return;
		setLoading(true);
		setError('');
		const success = await deleteChapter({
			learningID,
			user,
			setUser,
			withinChapterID,
			setJourney,
		});
		if (success) setIsOpenFalse();
		else {
			setLoading(false);
			setError(GENERAL_ERROR_MSG);
		}
	};

	const displayConfirmation = !chapterContent.loading && chapterContent.empty;
	const displayInfo = !chapterContent.loading && !chapterContent.empty;

	return (
		<div className={styles.deleteChapter}>
			<CloseButton onClick={setIsOpenFalse} />
			{error && <ErrorMsg error={error} />}
			{displayConfirmation && (
				<Confirmation
					title={title}
					setIsOpenFalse={setIsOpenFalse}
					handleDelete={handleDelete}
					loading={loading}
				/>
			)}
			{displayInfo && <Info setIsOpenFalse={setIsOpenFalse} />}
		</div>
	);
}

function Confirmation({ title, setIsOpenFalse, handleDelete, loading }) {
	return (
		<>
			<h1 className={styles.title}>Delete Chapter Confirmation</h1>
			<p className={styles.text}>
				Are you sure you want to delete chapter <b>{title}</b>?
			</p>
			<div className={styles.buttonGroup}>
				<PrimaryButton
					primary={false}
					onClick={setIsOpenFalse}
					children={'No, hold on to it'}
				/>
				<PrimaryButton
					className={styles.deleteButton}
					onClick={handleDelete}
					loading={loading}
					children={'Yes, please delete it'}
				/>
			</div>
		</>
	);
}

function Info({ setIsOpenFalse }) {
	return (
		<>
			<p className={styles.infoText}>
				You can only delete a chapter that is empty.
			</p>
			<div className={styles.infoButton}>
				<PrimaryButton
					primary={false}
					onClick={setIsOpenFalse}
					children={'Alrighty'}
				/>
			</div>
		</>
	);
}

// delete chapter, return true on success
const deleteChapter = async ({
	learningID,
	user,
	setUser,
	withinChapterID,
	setJourney,
}) => {
	// 1. Delete Chapter in database
	try {
		const input = {
			learningID,
			type: 'chapter',
		};
		await API.graphql(graphqlOperation(mutationDeleteLearning, { input }));
	} catch (error) {
		console.log('Error deleting chapter: ', error);
		return false;
	}
	// 2. Delete Chapter in chapterOrder and chapterName
	const userInput = {
		userID: user.userID,
	};
	const [newChapterOrder, deleteOrderSuccess] = getNewOrder(
		user.chapterOrder,
		withinChapterID,
		learningID,
		'delete'
	);
	if (deleteOrderSuccess) userInput['chapterOrder'] = newChapterOrder;
	const [newChapterName, deleteNameSuccess] = getNewChapterName(
		user.chapterName,
		learningID,
		'',
		'delete'
	);
	if (deleteNameSuccess) userInput['chapterName'] = newChapterName;
	if (deleteOrderSuccess || deleteNameSuccess) {
		try {
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input: userInput })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log('Error updating chapterOrder & chapterName: ', error);
			// ignore error
		}
	}
	// 3. Delete Chapter locally
	const key = `${user.userID}-${withinChapterID}`;
	setJourney((prev) => {
		const idx = prev[key].findIndex((item) => item.learningID === learningID);
		return update(prev, { [key]: { $splice: [[idx, 1]] } });
	});
	return true;
};

export default DeleteChapter;
