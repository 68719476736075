import { useEffect, useRef } from 'react';
import { getDecodedUrl, useUser } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningsByTag } from 'graphql/customQueries';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { useParams } from 'react-router-dom';
import update from 'immutability-helper';

/**
 * useGetLearningsByTag hooks query and set learnings from tag.
 * It also refetches new learnings when user scrolls to bottom.
 *
 * @param {Object}
 */
const useGetLearningsByTag = ({
	active,
	isBottom,
	setIsBottom,
	setLearnings,
	setError,
}) => {
	const [user] = useUser();
	const { tag } = useParams();
	const searchTag = tag && getDecodedUrl(tag).toLowerCase();
	const isFirstFetch = useRef(true);
	const nextToken = useRef(null);
	const loading = useRef(false);
	const lastFetchTag = useRef('');

	// Reset state when not active and when tag changes
	useEffect(() => {
		if (!active || searchTag !== lastFetchTag.current) {
			setLearnings([]);
			setError('');
			isFirstFetch.current = true;
			nextToken.current = null;
			loading.current = false;
			lastFetchTag.current = '';
		}
	}, [active, searchTag, setLearnings, setError]);

	// Effect to fetch and set
	useEffect(() => {
		if (!active) return;
		if (!searchTag) return;
		if (user.isLoading) return;

		const variables = {
			tag: searchTag,
		};

		// If not first fetch, refetch with nextToken
		// Return if nextToken is null or not at bottom
		if (!isFirstFetch.current) {
			if (!nextToken.current) return;
			if (!isBottom) return;
			variables.nextToken = nextToken.current;
		}

		if (loading.current) return;

		const getLearningsByTag = async () => {
			lastFetchTag.current = searchTag;
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetLearningsByTag, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const items = res.data.getTagByTagByDate.items;
				if (isFirstFetch.current) {
					if (items.length === 0) {
						setLearnings([]);
						setError(
							'No learnings can be found with this tag. Try another one 😉'
						);
						isFirstFetch.current = false;
						return;
					}
					const finalLearnings = items.map((item) => item.connectLearning);
					setLearnings(finalLearnings);
				} else {
					const finalLearnings = items.map((item) => item.connectLearning);
					setLearnings((prev) => update(prev, { $push: finalLearnings }));
				}
				nextToken.current = res.data.getTagByTagByDate.nextToken;
				setIsBottom(false);
				if (isFirstFetch.current) isFirstFetch.current = false;
				setError('');
			} catch (error) {
				console.log('Error getting tag learning: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};

		loading.current = true;
		getLearningsByTag();
		loading.current = false;
	}, [
		active,
		user.isLoading,
		isBottom,
		setIsBottom,
		searchTag,
		user.userID,
		user.isLogin,
		setLearnings,
		setError,
	]);
};

export default useGetLearningsByTag;
