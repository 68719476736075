import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LearningCard from './LearningCard';
import Chapter from './Chapter';
import styles from './Journey.module.css';
import ContextMenu from './ContextMenu';

// @refresh reset
function Journey({
	chapters,
	learnings,
	setChapters,
	setLearnings,
	readStatus,
}) {
	return (
		<DndProvider backend={HTML5Backend}>
			<div className={styles.journey} tabIndex={'0'} id={'journey'}>
				<div className={styles.journeyContainer}>
					<>
						<h1 className={styles.chapterTitle}>Chapters</h1>
						<div className={styles.chapterContainer}>
							{chapters.map((item, index) => (
								<Chapter
									key={item.learningID}
									chapter={item}
									index={index}
									setLearnings={setLearnings}
									setChapters={setChapters}
								/>
							))}
						</div>
					</>
					<>
						<h1 className={styles.learningTitle}>Learnings</h1>
						<div className={styles.chapterContainer}>
							{learnings.map((item, index) => (
								<LearningCard
									key={item.learningID}
									learning={item}
									index={index}
									readStatus={item.learningID === 'main' ? readStatus : []}
									setLearnings={setLearnings}
								/>
							))}
						</div>
					</>
				</div>
			</div>
			<ContextMenu setLearnings={setLearnings} setChapters={setChapters} />
		</DndProvider>
	);
}

export default Journey;
