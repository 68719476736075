/**
 * getIsMobile returns whether current device is in mobile view, i.e.
 * if screen width is less than 500px.
 *
 * @returns {Boolean}
 */
const getIsMobile = () => {
	return window.matchMedia('only screen and (max-width: 500px)').matches;
};

export default getIsMobile;
