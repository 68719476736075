import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearningsAndReadsByChapter } from 'graphql/customQueries';
import update from 'immutability-helper';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { useUser } from 'utils';
import { useParams } from 'react-router-dom';

/**
 * useGetJourney hook gets learnings and chapters of user.
 * It also gets read status of each learnings for login user.
 *
 * @param {Object}
 */
const useGetJourney = ({
	profile,
	journey,
	setJourney,
	setError,
	setReadList,
}) => {
	const [user] = useUser();
	const { profileSection = 'chapter', withinChapterID = 'home' } = useParams();

	useEffect(() => {
		// Skip if user is loading
		if (user.isLoading) return;

		// Skip if profile.userID is empty
		if (!profile.userID) return;

		// Skip if profileSection is not chapter
		if (profileSection !== 'chapter') return;

		// Skip query if found in cache
		const key = `${profile.userID}-${withinChapterID}`;
		if (journey[key]) return;

		const getLearningsInChapter = async () => {
			const variables = {
				userID: profile.userID,
				withinChapterID,
				visitorUserID: user.userID,
				isLogin: user.isLogin,
			};
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetLearningsAndReadsByChapter, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const learningsChapters = res.data.getLearningByChapter.items;
				const finalLearningsChapters = [];
				learningsChapters.forEach((item) => {
					if (item.connectSaveLearning) {
						const finalItem = update(item.connectSaveLearning, {
							$merge: { parentLearningID: item.learningID, type: item.type },
						});
						finalLearningsChapters.push(finalItem);
					} else finalLearningsChapters.push(item);
				});
				setJourney((prev) =>
					update(prev, { [key]: { $set: finalLearningsChapters } })
				);

				// Set readList only for login user
				if (user.isLogin) {
					const learnings = finalLearningsChapters.filter(
						(i) => i.type === 'learning' || i.type === 'saveLearning'
					);
					const readList = {};
					learnings.forEach((learning) => {
						const connectRead = learning.connectRead.items;
						const readStatus = [];
						connectRead.forEach((item) => readStatus.push(item.type));
						readList[learning.learningID] = readStatus;
					});
					setReadList((prev) => update(prev, { $merge: readList }));
				}
			} catch (error) {
				console.log('Error getting learning list: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};
		getLearningsInChapter();
	}, [
		user.isLoading,
		profile.userID,
		profileSection,
		withinChapterID,
		journey,
		user.userID,
		user.isLogin,
		setJourney,
		setError,
		setReadList,
	]);
};

export default useGetJourney;
