import {
	BrowseSvg,
	ExploreSvg,
	FeedbackSvg,
	HomeSvg,
	LatestSvg,
	PopularSvg,
	PrivacySvg,
	ResourceSvg,
	ScholarCapSvg,
	StorySvg,
} from 'icons';
import React, { useRef, useState } from 'react';
import { useCloseDropdown, useUser } from 'utils';
import styles from './DropdownInput.module.css';
import { useHistory, useLocation } from 'react-router-dom';

const DROPDOWN_LIST = [
	{ name: 'Popular', svg: <PopularSvg />, link: '/popular' },
	{ name: 'Following', svg: <HomeSvg />, link: '/home' },
	{ name: 'Latest', svg: <LatestSvg />, link: '/latest' },
	{ name: 'Explore', svg: <ExploreSvg />, link: '/explore' },
	{ name: 'Tag Search', svg: <BrowseSvg />, link: '/tag' },
	{ name: 'About TipStory', svg: <StorySvg />, link: '/about' },
	{ name: 'Scholar Portal', svg: <ScholarCapSvg />, link: '/scholar' },
	{
		name: 'Scholar App.',
		svg: <ScholarCapSvg />,
		link: '/scholar-application',
	},
	{
		name: 'Resource',
		svg: <ResourceSvg />,
		link: '/resource',
	},
	{
		name: 'Feedback',
		svg: <FeedbackSvg />,
		link: '/feedback',
	},
	{ name: 'Privacy Policy', svg: <PrivacySvg />, link: '/privacy' },
];

const getSvg = (name) => {
	const idx = DROPDOWN_LIST.findIndex((item) => item.name === name);
	return DROPDOWN_LIST[idx].svg;
};

function DropdownInput() {
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });

	const handleSelectClick = () => setIsOpen((prev) => !prev);

	// Selection default to Popular, it changes based on link
	let selection = 'Popular';
	const location = useLocation().pathname;
	DROPDOWN_LIST.forEach((item) => {
		if (item.name === 'Popular') return;
		if (location.startsWith(item.link)) selection = item.name;
	});

	const history = useHistory();
	const handleItemClick = (link) => {
		setIsOpen(false);
		history.push(link);
	};

	return (
		<nav className={styles.nav} ref={node}>
			<SelectButton selection={selection} onClick={handleSelectClick} />
			{isOpen && <DropdownList onClick={handleItemClick} />}
		</nav>
	);
}

function SelectButton({ selection, onClick }) {
	return (
		<button type={'button'} className={styles.selectButton} onClick={onClick}>
			<div className={styles.svgDiv}>{getSvg(selection)}</div>
			<span className={styles.selectText}>{selection}</span>
		</button>
	);
}

function DropdownList({ onClick }) {
	const [user] = useUser();
	const isScholar = user.scholar || user.scholarStatus === 'approved';
	const finalList = isScholar
		? DROPDOWN_LIST.filter((item) => item.name !== 'Scholar App.')
		: DROPDOWN_LIST.filter((item) => item.name !== 'Scholar Portal');
	return (
		<div className={styles.dropdownList}>
			{finalList.map(({ name, svg, link }) => (
				<DropdownItem
					key={name}
					name={name}
					svg={svg}
					onClick={() => onClick(link)}
				/>
			))}
		</div>
	);
}

function DropdownItem({ name, svg, onClick }) {
	return (
		<button type={'button'} className={styles.dropdownItem} onClick={onClick}>
			<div className={styles.svgDiv}>{svg}</div>
			<span className={styles.dropdownText}>{name}</span>
		</button>
	);
}

export default DropdownInput;
