import { LOCAL_STORAGE_TAG_HISTORY } from 'utils/constants';

/**
 * saveTagHistory saves tagHistory in localStorage
 *
 * @param {Array} newTagHistory
 */
const saveTagHistory = (newTagHistory) => {
	try {
		localStorage.setItem(
			LOCAL_STORAGE_TAG_HISTORY,
			JSON.stringify(newTagHistory)
		);
	} catch (error) {
		console.log('Error setting tagHistory: ', error);
		return false;
	}
	return true;
};

export default saveTagHistory;
