import React from 'react';
import styles from './Header.module.css';
import classNames from 'classnames';
import { GetSourceSvg } from 'components/DropdownInput';
import { getEncodedUrl, getTagList } from 'utils';
import LinkCard from './LinkCard';
import { BookmarkSvg } from 'icons';

function Header({ learning }) {
	return (
		<section className={styles.section}>
			<Save {...learning} />
			<Source {...learning} />
			<TagList {...learning} />
			<LinkCard {...learning} />
		</section>
	);
}

function Save(props) {
	const { learningID, parentLearningID } = props;
	if (!parentLearningID) return null;
	const openOriginalLink = () => {
		window.open(`/learning/${learningID}`, '_blank');
	};
	return (
		<div className={styles.save}>
			<BookmarkSvg className={styles.bookmarkSvg} />
			<span>Saved</span>
			<button
				type={'button'}
				onClick={openOriginalLink}
				className={styles.originalLink}
			>
				(Open original)
			</button>
		</div>
	);
}

function Source(props) {
	const { source } = props;
	return (
		<div className={styles.source}>
			{GetSourceSvg({ source, className: styles.svgIcon })}
			<span>{source}</span>
		</div>
	);
}

function TagList(props) {
	const { tag } = props;
	const tagList = getTagList(tag);
	if (tagList.length === 0) return null;
	const lastTagIdx = tagList.length - 1;

	return (
		<div className={styles.tagList}>
			{tagList.map((item, idx) => (
				<a
					key={idx}
					href={`/tag/${getEncodedUrl(item.toLowerCase())}`}
					className={classNames(styles.tag, {
						[styles.lastTag]: idx === lastTagIdx,
					})}
				>
					{item}
				</a>
			))}
		</div>
	);
}

export default Header;
