import React from 'react';
import styles from './Schools.module.css';
import BrooklynTech from './image/BrooklynTech.jpg';
import TownsendHarris from './image/TownsendHarris.jpg';
import BronxSci from './image/BronxSci.jpg';
import StatenIslandTech from './image/StatenIslandTech.jpg';
import Stuyvesant from './image/Stuyvesant.jpg';
import BrooklynLatin from './image/BrooklynLatin.jpg';
import Preuss from './image/Preuss.jpg';
import Jericho from './image/Jericho.jpg';
import Staples from './image/Staples.jpg';
import AECHS from './image/AECHS.jpg';
import Weddington from './image/Weddington.jpg';
import PUCCommunity from './image/PUCCommunity.jpg';
import WestRanch from './image/WestRanch.jpg';
import Orthopaedic from './image/Orthopaedic.jpg';
import Fairfield from './image/Fairfield.jpg';
import CJCP from './image/CJCP.jpg';
import JamesMadison from './image/JamesMadison.jpg';
import JamesContant from './image/JamesContant.jpg';
import Kinnelon from './image/Kinnelon.jpg';
import Miami from './image/Miami.jpg';
import Woodstock from './image/Woodstock.jpg';
import { shuffleArray } from 'utils';

function Schools() {
	const schools = [
		TownsendHarris,
		Stuyvesant,
		BrooklynTech,
		BrooklynLatin,
		Jericho,
		Orthopaedic,
		Fairfield,
		PUCCommunity,
		CJCP,
		JamesContant,
		JamesMadison,
		WestRanch,
		Preuss,
		BronxSci,
		StatenIslandTech,
		Staples,
		AECHS,
		Weddington,
		Kinnelon,
		Miami,
		Woodstock,
	];
	shuffleArray(schools);
	return (
		<div className={styles.schoolsOverflow}>
			<div className={styles.schools}>
				{schools.map((src) => (
					<School src={src} />
				))}
			</div>
		</div>
	);
}

function School({ src }) {
	return <img className={styles.school} src={src} alt={''} />;
}
export default Schools;
