import React from 'react';
import styles from './LearningPanel.module.css';
import classNames from 'classnames';
import TextTruncate from 'react-text-truncate';
import { Link } from 'react-router-dom';
import { HelpfulActiveSvg, InterestingActiveSvg, profileSvg } from 'icons';
import { getFormatDateTimeSince } from 'utils';
import { GetSourceSvg } from 'components/DropdownInput';
import LearningPanelTop from './LearningPanelTop';

function LearningPanel(props) {
	const { learningID } = props;
	const articleLink = `/learning/${learningID}`;

	return (
		<article className={styles.article}>
			<Link className={styles.articleLink} to={articleLink} />
			<LearningPanelTop {...props} />
			<section className={styles.mainSection}>
				<Title {...props} />
				<Profile {...props} />
				<Text {...props} />
				<Icon {...props} />
			</section>
		</article>
	);
}

function Title(props) {
	const { source, title } = props;
	return (
		<div className={styles.title}>
			{GetSourceSvg({ source, className: styles.svg })}
			<h1 className={styles.h1}>{title}</h1>
		</div>
	);
}

function Profile(props) {
	const { description } = props;
	const createdAt = getFormatDateTimeSince(props.createdAt);
	const { firstName, lastName, profileImg, headline } = props.connectUser || {};
	return (
		<div className={styles.profile}>
			<div>
				<img
					src={profileImg || profileSvg}
					className={styles.img}
					alt={'Profile'}
				/>
			</div>
			<div className={styles.description}>
				<span className={styles.name}>
					{firstName} {lastName}
				</span>
				<span> · {createdAt}</span>
				<br />
				<span>{description || headline}</span>
			</div>
		</div>
	);
}

function Text(props) {
	const { learningBrief, numWords } = props;
	const numWordsDisplay =
		numWords < 100 ? numWords : Math.floor(numWords / 100) * 100;
	const text = `Read full learning (${numWordsDisplay} words)`;
	return (
		<TextTruncate
			line={4}
			element={'span'}
			truncateText={'...'}
			text={learningBrief}
			textTruncateChild={<span className={styles.truncateChild}>{text}</span>}
		/>
	);
}

function Icon(props) {
	const { numHelpful, numInteresting } = props;
	const icons = [
		{
			svg: <HelpfulActiveSvg />,
			title: 'Helpful',
			num: numHelpful,
			display: numHelpful > 0,
		},
		{
			svg: <InterestingActiveSvg />,
			title: 'Interestings',
			num: numInteresting,
			display: numInteresting > 0,
		},
	];

	const displayIcons = icons.filter((item) => item.display);
	if (displayIcons.length === 0) return null;

	return (
		<ul className={styles.icon}>
			{displayIcons.map((item, idx) => {
				const { title, svg, num } = item;
				return (
					<li
						key={title}
						className={classNames(styles.iconItem, {
							[styles.marginLeft0]: idx === 0,
						})}
					>
						<div className={styles.iconSvg}>{svg}</div>
						<span className={styles.iconLabel}>{num}</span>
					</li>
				);
			})}
		</ul>
	);
}

export default LearningPanel;
