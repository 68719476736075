import React, { useState } from 'react';
import styles from './SaveButton.module.css';
import { SaveSvg } from 'icons';
import Modal from './../Modal';
import { useLogin, useUser } from 'utils';

function SaveButton(props) {
	const { learningID } = props;
	const [modal, setModal] = useState({ isOpen: false, state: '' });
	const [user] = useUser();
	const [, setLogin] = useLogin();
	const handleClick = () => {
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'login' });
			return;
		}
		setModal({ isOpen: true, state: 'saveLearning', learningID });
	};
	return (
		<>
			<button type={'button'} className={styles.button} onClick={handleClick}>
				<div className={styles.buttonSvg}>
					<SaveSvg />
				</div>
				<span>Save</span>
			</button>
			{modal.isOpen && <Modal modal={modal} setModal={setModal} />}
		</>
	);
}

export default SaveButton;
