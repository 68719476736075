/**
 * Return hours based on numWords;
 * 250 words = 1 hr
 * Min 0.5 hour per learning
 * Max 5 hours per learning
 *
 * @param {Number} numWords
 */
const wordsPerHour = 250;
const getHours = (numWords, capHours = true) => {
	const numHours = roundHalf(numWords / wordsPerHour);
	// Min 0.5 hour per learning
	if (numHours < 0.5) return 0.5;
	// Max 5 hours per learning
	if (numHours > 5 && capHours) return 5;
	return numHours;
};

const roundHalf = (num) => Math.round(num * 2) / 2;

export default getHours;
