/**
 * Return an array that is the order of chapters
 * to sequence through before reaching currentChapterID.
 * (It is used to generate chapter breadcomb.)
 *
 * @param {String} chapterOrder
 * @param {String} currentChapterID
 * @returns {Array}
 */

const getChapterPathArray = (chapterOrder, currentChapterID) => {
	if (!chapterOrder) return ['home'];
	if (currentChapterID === 'home') return ['home'];
	try {
		const chapterOrderObj = JSON.parse(chapterOrder);
		if (typeof chapterOrderObj !== 'object')
			throw new Error(`Chapter is not object`);
		let numLoop = 0;
		let keepLooping = true;
		let chapterSeek = currentChapterID;
		const chapterPath = [chapterSeek];
		while (keepLooping) {
			for (const key in chapterOrderObj) {
				if (chapterOrderObj[key].includes(chapterSeek)) {
					if (key === 'home') {
						chapterPath.unshift('home');
						keepLooping = false;
					} else {
						chapterPath.unshift(key);
						chapterSeek = key;
					}
					break;
				}
			}
			numLoop = numLoop + 1;
			if (numLoop > 100) throw new Error(`Chapter can't be found`);
		}
		return chapterPath;
	} catch (error) {
		console.log('Error: ', error);
		return ['home'];
	}
};

export default getChapterPathArray;
