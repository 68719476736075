const getCodeSyntaxColor = (leaf = {}) => {
	let color = '';

	if (
		leaf.comment ||
		leaf['block-comment'] ||
		leaf.prolog ||
		leaf.doctype ||
		leaf.cdata
	)
		color = 'var(--theme-code-comment)';

	if (leaf.punctuation) color = 'var(--theme-code-punctuation)';

	if (leaf.tag || leaf.namespace || leaf.deleted)
		color = 'var(--theme-code-tag)';
	if (leaf['attr-name']) color = 'var(--theme-code-attr-name)';

	// Tomorrow night theme only
	if (leaf['function-name']) color = '#6196cc';

	if (leaf.boolean || leaf.number) color = 'var(--theme-code-boolean)';
	if (leaf.function) color = 'var(--theme-code-function)';

	if (leaf.property || leaf['class-name'] || leaf.constant || leaf.symbol)
		color = 'var(--theme-code-property)';

	if (leaf.selector || leaf.builtin || leaf.inserted)
		color = 'var(--theme-code-selector)';
	if (leaf.important) color = 'var(--theme-code-important)';
	if (leaf.atrule || leaf.keyword || leaf.key)
		color = 'var(--theme-code-keyword)';

	if (leaf.string || leaf.char) color = 'var(--theme-code-char)';
	if (leaf['attr-value']) color = 'var(--theme-code-attr-value)';
	if (leaf.regex || leaf.variable) color = 'var(--theme-code-variable)';

	if (leaf.operator || leaf.entity || leaf.url)
		color = 'var(--theme-code-operator)';

	return color;
};

export default getCodeSyntaxColor;

/* Custom VS Code style
	if (leaf.comment) color = '#008000'; //Pale Green

	// const a = 10;
	if (leaf.keyword) color = '#0000ff'; //Blue (const)
	if (leaf.customType) color = '#001080'; // (a)
	if (leaf.operator) color = '#000000'; //Black (=)
	if (leaf.number) color = '#098658'; //Green (10)
	if (leaf.punctuation) color = '#000000'; //Black (;)

	if (leaf.function) color = '#795e26'; //Brownish
	if (leaf['function-variable']) color = '#795e26'; //Brownish
	if (leaf.parameter) color = '#267f99';
*/
