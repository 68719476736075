import React from 'react';
import styles from './Navbar.module.css';
import { Link, useParams } from 'react-router-dom';
import { getPlural } from 'utils';

function NavBar({ profile }) {
	const { profileSection = 'chapter' } = useParams();
	const { numReadMark = 0, numFollower = 0, numFollowing = 0 } = profile;
	return (
		<nav className={styles.navBar}>
			<NavTab
				title={`Journey`}
				to={'/'}
				active={profileSection === 'chapter'}
			/>
			<NavTab
				title={`${numReadMark} Read${getPlural(numReadMark)}`}
				to={'/reads'}
				active={profileSection === 'reads'}
			/>
			<NavTab
				title={`${numFollower} Follower${getPlural(numFollower)}`}
				to={'/followers'}
				active={profileSection === 'followers'}
			/>
			<NavTab
				title={`${numFollowing} Following`}
				to={'/following'}
				active={profileSection === 'following'}
			/>
		</nav>
	);
}

function NavTab({ title, to, active }) {
	const { fullProfileURL } = useParams();
	const newTo = '/journey/' + fullProfileURL + to;
	const className = active
		? `${styles.navTab} ${styles.activeTab}`
		: styles.navTab;
	return (
		<Link to={newTo} className={className}>
			{title}
		</Link>
	);
}

export default NavBar;
