import React, { useState } from 'react';
import styles from './Comments.module.css';
import { useGetComments } from '../hooks';
import Comment from './Comment';
import PostComment from './PostComment';
import CommentsModal from './CommentsModal';

function Comments(props) {
	const { learning, setLearnings } = props;
	const { numComment, learningID } = learning;
	const [comments, setComments] = useState({});
	const [modal, setModal] = useState({ isOpen: false, state: '' });

	useGetComments({ learningID, comments, setComments });

	const learningComments = comments[learningID] || [];

	return (
		<section className={styles.section}>
			<h1 className={styles.h1}>
				{numComment} Comment{numComment > 1 ? 's' : ''}
			</h1>
			<PostComment {...props} setComments={setComments} />
			{learningComments.map((item) => (
				<Comment
					key={item.commentID}
					comment={item}
					setComments={setComments}
					setModal={setModal}
					{...props}
				/>
			))}
			<CommentsModal
				modal={modal}
				setModal={setModal}
				setLearnings={setLearnings}
				setComments={setComments}
			/>
		</section>
	);
}

export default Comments;
