import React, { useState } from 'react';
import { useGetPinLearning } from '../hooks';
import LearningArticle from './LearningArticle';
import styles from './PinLearning.module.css';

function PinLearning({ profile }) {
	const [pinLearning, setPinLearning] = useState({});
	useGetPinLearning({ learningID: profile.pinLearning, setPinLearning });
	return (
		<div className={styles.pinLearning}>
			<div className={styles.pinLearningContainer}>
				<LearningArticle pinLearning={pinLearning} />
			</div>
		</div>
	);
}

export default PinLearning;
