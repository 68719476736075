import React, { useState } from 'react';
import styles from './TimelineBadge.module.css';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationUpdateUser } from 'graphql/customMutations';
import update from 'immutability-helper';
import classNames from 'classnames';
import PrimaryButton from 'components/Button';
import { useUser } from 'utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { getNumMonthsActivatedByYear, getScholarMeta } from '../utils';
import { getCurrentYear, getYearsToCurrentYear } from '../MonthlyTask/utils';
import { useHistory } from 'react-router';
import ScholarBadge, { BADGE_TYPE } from 'components/ScholarBadge';

function TimelineBadge() {
	const [user] = useUser();
	const history = useHistory();
	const scholarMeta = getScholarMeta(user);
	const [year, setYear] = useState(getCurrentYear());
	const years = getYearsToCurrentYear();
	const numMonths = getNumMonthsActivatedByYear(scholarMeta, year);
	const handleOnClick = () => {
		if (numMonths < 2) return;
		history.push(`/scholar/certificate/${year}/${user.userID}`);
	};

	return (
		<div className={styles.timeline}>
			<h1 className={styles.h1}>Scholar Certificate & Badge</h1>
			<div className={styles.year}>
				<label>Year:</label>
				<select
					className={styles.yearSelect}
					value={year}
					onChange={(e) => setYear(e.target.value)}
				>
					{years.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</select>
			</div>
			<ul className={styles.ul}>
				{/* Beginning */}
				<li
					className={classNames(styles.li, {
						[styles.liActive]: numMonths > 0,
					})}
				>
					<div
						className={classNames(styles.liDiv, {
							[styles.liDivActive]: numMonths > 0 - 1,
						})}
					>
						Beginning
					</div>
					<ScholarBadge
						type={BADGE_TYPE.BEGINNER}
						width={33}
						className={styles.scholarBadge}
					/>
					<BadgeButton badge={'blueBadge'} monthsThreshold={0} />
				</li>
				{/* 1 Month */}
				<li
					className={classNames(styles.li, {
						[styles.liActive]: numMonths > 1,
					})}
				>
					<div
						className={classNames(styles.liDiv, {
							[styles.liDivActive]: numMonths > 1 - 1,
						})}
					>
						1 month
					</div>
					<div className={styles.svgDiv}>
						<div className={styles.svg} />
					</div>
				</li>
				{/* 2 Months */}
				<li
					className={classNames(styles.li, {
						[styles.liActive]: numMonths > 2,
					})}
				>
					<div
						className={classNames(styles.liDiv, {
							[styles.liDivActive]: numMonths > 2 - 1,
						})}
					>
						2 months
					</div>
					<ScholarBadge
						type={BADGE_TYPE.BRONZE}
						width={33}
						year={year}
						className={styles.scholarBadge}
					/>
				</li>
				{/* 3 Months */}
				<li
					className={classNames(styles.li, {
						[styles.liActive]: numMonths > 3,
					})}
				>
					<div
						className={classNames(styles.liDiv, {
							[styles.liDivActive]: numMonths > 3 - 1,
						})}
					>
						3 months
					</div>
					<div className={styles.svgDiv}>
						<div className={styles.svg} />
					</div>
				</li>
				{/* 4 Months */}
				<li
					className={classNames(styles.li, {
						[styles.liActive]: numMonths > 4,
					})}
				>
					<div
						className={classNames(styles.liDiv, {
							[styles.liDivActive]: numMonths > 4 - 1,
						})}
					>
						4 months
					</div>
					<ScholarBadge
						type={BADGE_TYPE.SILVER}
						width={33}
						year={year}
						className={styles.scholarBadge}
					/>
				</li>
				{/* 5 Months */}
				<li
					className={classNames(styles.li, {
						[styles.liActive]: numMonths > 5,
					})}
				>
					<div
						className={classNames(styles.liDiv, {
							[styles.liDivActive]: numMonths > 5 - 1,
						})}
					>
						5 months
					</div>
					<div className={styles.svgDiv}>
						<div className={styles.svg} />
					</div>
				</li>
				{/* 6 Months */}
				<li className={styles.lastLi}>
					<div
						className={classNames(styles.liDiv, {
							[styles.liDivActive]: numMonths > 6 - 1,
						})}
					>
						6 months
					</div>
					<ScholarBadge
						type={BADGE_TYPE.GOLD}
						width={33}
						year={year}
						className={styles.scholarBadge}
					/>
				</li>
			</ul>
			<PrimaryButton
				children={'Download Certificate'}
				onClick={handleOnClick}
				disable={numMonths < 2}
			/>
		</div>
	);
}

function BadgeButton({ badge = 'blueBadge', monthsThreshold = 0 }) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [user, setUser] = useUser();
	const scholarMeta = getScholarMeta(user);
	const year = getCurrentYear();
	const numMonths = getNumMonthsActivatedByYear(scholarMeta, year);

	const handleOnClick = async () => {
		if (!user.scholarMeta) return;
		if (scholarMeta[badge]) return;
		if (numMonths < monthsThreshold) return;
		if (user.numLearning < monthsThreshold) return;
		if (loading) return;
		setError('');
		setLoading(true);
		try {
			// Add badge to scholarMeta: blueBadge, bronzeBadge, silverBadge, goldBadge
			const finalScholarMeta = update(scholarMeta, {
				$merge: { [badge]: true },
			});
			const userInput = {
				userID: user.userID,
				scholar: badge,
				scholarMeta: JSON.stringify(finalScholarMeta),
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, {
					input: userInput,
					condition: {
						scholarMeta: { eq: JSON.stringify(scholarMeta) },
					},
				})
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log(`Activate ${badge} Error: `, error);
			setError(GENERAL_ERROR_MSG);
		}
		setLoading(false);
	};
	const buttonDisable = numMonths < monthsThreshold || scholarMeta[badge];
	return (
		<div className={styles.badgeButton}>
			<div className={styles.activateButtonDiv}>
				<PrimaryButton
					className={styles.activateButton}
					children={scholarMeta[badge] ? 'Activated😎' : 'Activate'}
					disable={buttonDisable}
					onClick={handleOnClick}
					loading={loading}
				/>
				{!buttonDisable && <div className={styles.activateButtonDot} />}
			</div>
			{error && <div className={styles.error}>{error}</div>}
		</div>
	);
}

export default TimelineBadge;
