const getChapters = (
	allChapters,
	chapterOrderObj,
	chapterName,
	chapterNestLevel
) => {
	const chapterArray = chapterOrderObj[chapterName];
	if (!chapterArray) return;
	if (!Array.isArray(chapterArray)) return;
	if (chapterArray.length === 0) return;
	chapterArray.forEach((chapter) => {
		const childrenChapterArray = chapterOrderObj[chapter];
		const hasChildren =
			!!childrenChapterArray &&
			Array.isArray(childrenChapterArray) &&
			childrenChapterArray.length > 0;
		const nestLevel = chapterNestLevel + 1;
		allChapters.push({
			id: chapter,
			nestLevel,
			hasChildren,
			parent: chapterName,
		});
		if (hasChildren)
			getChapters(allChapters, chapterOrderObj, chapter, nestLevel);
	});
};

/**
 * Return all of user's chapters in an array based on user's chapterOrder.
 *
 * @param {String} chapterOrder
 * @returns {Array}
 */

const getAllChapters = (chapterOrder) => {
	const defaultAllChapters = [
		{ id: 'home', nestLevel: 0, hasChildren: false, parent: '' },
	];
	if (!chapterOrder) return defaultAllChapters;
	try {
		const chapterOrderObj = JSON.parse(chapterOrder);
		if (typeof chapterOrderObj !== 'object')
			throw new Error(`Chapter is not object`);
		const homeChapterArray = chapterOrderObj['home'];
		if (!homeChapterArray) return defaultAllChapters;
		if (!Array.isArray(homeChapterArray)) return defaultAllChapters;
		if (homeChapterArray.length === 0) return defaultAllChapters;
		const allChapters = [
			{ id: 'home', nestLevel: 0, hasChildren: true, parent: '' },
		];
		getChapters(allChapters, chapterOrderObj, 'home', 0);
		return allChapters;
	} catch (error) {
		console.log('Error getting all chapters: ', error);
		return defaultAllChapters;
	}
};

export default getAllChapters;
