import DropdownInput from './../DropdownInput';
import { logoSvg } from 'icons';
import { Link } from 'react-router-dom';
import styles from './LeftNav.module.css';
import { useUser } from 'utils';

function LeftNav() {
	const [user] = useUser();
	return (
		<div className={styles.div}>
			<Link to={user.isLogin ? '/landing' : '/'}>
				<img src={logoSvg} alt={'TipStory Logo'} className={styles.logo} />
			</Link>
			<DropdownInput />
		</div>
	);
}

export default LeftNav;
