import React, { useState } from 'react';
import styles from './ReplyComment.module.css';
import { useUser } from 'utils';
import TextareaAutosize from 'react-textarea-autosize';
import PrimaryButton from 'components/Button';
import { profileSvg } from 'icons';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationCreateComment } from 'graphql/customMutations';
import update from 'immutability-helper';
import { useParams } from 'react-router';

function ReplyComment(props) {
	const {
		learning,
		setLearnings,
		comment: commentObj,
		setComments,
		setReplyOn,
		initialComment = '',
		receiverID,
	} = props;
	const [user] = useUser();
	const [comment, setComment] = useState(initialComment);
	const [loading, setLoading] = useState(false);
	// parentLearningID is used to fix bug in saved learning
	const { learningID: parentLearningID } = useParams();
	const defaultText = 'Add a reply';
	const handleChange = (e) => {
		const text = e.target.value;
		setComment(text);
	};
	const handleCancelClick = () => {
		setReplyOn(false);
	};

	// Create new comment
	const handleSubmitClick = async () => {
		if (comment.trim() === '') return;
		const input = {
			withinCommentID: commentObj.commentID,
			userID: user.userID,
			comment,
			learningID: learning.learningID,
			parentCommentCreatedAt: commentObj.createdAt,
			parentCommentUserID: commentObj.userID,
		};
		// Add receiverID if it is not current user
		if (receiverID !== user.userID) input.receiverID = receiverID;

		setLoading(true);
		const success = await handleCreateReplyComment(
			input,
			user,
			setComments,
			setLearnings,
			parentLearningID
		);
		setLoading(false);
		if (success) handleCancelClick();
	};

	return (
		<section className={styles.replyComment}>
			<div>
				<img
					src={user.profileImg || profileSvg}
					alt={'Your profile'}
					className={styles.profileImg}
				/>
			</div>
			<div className={styles.textAreaDiv}>
				<TextareaAutosize
					className={styles.textarea}
					placeholder={defaultText}
					value={comment}
					autoFocus={true}
					autoComplete={'off'}
					minRows={1}
					onChange={handleChange}
				/>
				<div className={styles.buttonGroup}>
					<PrimaryButton
						primary={false}
						children='Cancel'
						onClick={handleCancelClick}
					/>
					<PrimaryButton
						children={'Reply'}
						className={styles.marginLeft}
						loading={loading}
						disable={comment.trim() === ''}
						onClick={handleSubmitClick}
					/>
				</div>
			</div>
		</section>
	);
}

const handleCreateReplyComment = async (
	input,
	user,
	setComments,
	setLearnings,
	parentLearningID
) => {
	try {
		const res = await API.graphql(
			graphqlOperation(mutationCreateComment, { input })
		);
		const createdAt = res.data.customCreateComment.createdAt;
		const updatedAt = createdAt;
		const commentID = res.data.customCreateComment.commentID;
		const newComment = {
			...input,
			createdAt,
			updatedAt,
			commentID,
			numReply: 0,
		};
		newComment.connectUser = {
			profileURL: user.profileURL,
			profileURLIdx: user.profileURLIdx,
			firstName: user.firstName,
			lastName: user.lastName,
			profileImg: user.profileImg,
		};
		const { learningID, withinCommentID } = input;
		setComments((prev) => {
			const comments = prev[learningID];
			const commentIdx = comments.findIndex(
				(item) => item.commentID === withinCommentID
			);
			if (commentIdx === -1) return prev;
			const update1 = update(prev, {
				[learningID]: {
					[commentIdx]: { numReply: { $apply: (n) => n + 1 } },
				},
			});
			const update2 = update(update1, {
				[learningID]: {
					[commentIdx]: {
						connectComment: { items: { $push: [newComment] } },
					},
				},
			});
			return update2;
		});
		setLearnings((prev) =>
			update(prev, {
				[parentLearningID]: {
					numComment: { $apply: (n) => n + 1 },
				},
			})
		);
		return true;
	} catch (error) {
		console.log('Error creating comment: ', error);
	}
};

export default ReplyComment;
