import { MoonSvg, SunSvg } from 'icons';
import React, { useState } from 'react';
import getDefaultTheme from 'utils/getThemeDefault';
import styles from './ThemeButton.module.css';

function ThemeButton() {
	const [isLightTheme, setIsLightTheme] = useState(getDefaultTheme());

	const toggleTheme = () => {
		if (isLightTheme) {
			localStorage.setItem('theme', 'dark');
			document.documentElement.setAttribute('theme', 'dark');
			setIsLightTheme(false);
		} else {
			localStorage.setItem('theme', 'light');
			document.documentElement.setAttribute('theme', 'light');
			setIsLightTheme(true);
		}
	};

	return (
		<button type='button' className={styles.button} onClick={toggleTheme}>
			{isLightTheme ? (
				<MoonSvg className={styles.svg} />
			) : (
				<SunSvg className={styles.svg} />
			)}
		</button>
	);
}

export default ThemeButton;
