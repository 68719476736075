import React, { useState } from 'react';
import styles from './NewChapter.module.css';
import Input from './../Input';
import PrimaryButton from 'components/Button';
import { getNanoid, useEnterCallback } from 'utils';
import CloseButton from '../CloseButton';

function NewChapter({ modal, setIsOpenFalse, setChapters }) {
	const { isOpen } = modal;
	const [title, setTitle] = useState('');

	console.log(setChapters);
	const handleCreate = async () => {
		if (title === '') return;
		setChapters((prevChapters) => {
			const newChapters = [...prevChapters];
			newChapters.push({ learningID: getNanoid(), title });
			return newChapters;
		});
		setIsOpenFalse();
	};
	useEnterCallback({ isOpen, callback: handleCreate });

	return (
		<div className={styles.newChapter}>
			<CloseButton onClick={setIsOpenFalse} />
			<h1 className={styles.title}>New Chapter</h1>
			<Input
				value={title}
				setValue={setTitle}
				isOpen={isOpen}
				className={styles.input}
				placeholder={'Chapter Title'}
			/>
			<div className={styles.buttonGroup}>
				<PrimaryButton
					primary={false}
					onClick={setIsOpenFalse}
					children={'Cancel'}
				/>
				<PrimaryButton
					className={styles.createButton}
					disable={title === ''}
					onClick={handleCreate}
					children={'Create'}
				/>
			</div>
		</div>
	);
}

export default NewChapter;
