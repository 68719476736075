/**
 * Return number of months activated by year
 * @param {object} scholarMeta
 */
const getNumMonthsActivatedByYear = (scholarMeta, year = '2021') => {
	let numMonths = 0;
	// 2021 is special case
	if (year === '2021') {
		const monthsArray = [
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];
		monthsArray.forEach((item) => {
			if (scholarMeta[item]) numMonths++;
		});
	} else {
		Object.keys(scholarMeta).forEach((item) => {
			if (item.startsWith(year)) numMonths++;
		});
	}
	return numMonths;
};

export default getNumMonthsActivatedByYear;
