import { API, graphqlOperation } from 'aws-amplify';
import { mutationCreateLearning } from 'graphql/customMutations';
import { getNanoid, getTextFromSlateObj, getWordCount } from 'utils';
import update from 'immutability-helper';

const createDraftLearning = async ({
	newLearning,
	setNewLearning,
	withinChapterID,
	userID,
	setDrafts,
}) => {
	// Add 'draft-new-' to differentiate from edit learningID
	const learningID = `draft-new-${getNanoid()}`;
	// Add 'draft-new-' for draft query
	const finalWithinChapterID = `draft-new-${withinChapterID}`;
	// Create draft learning
	const { source, linkInfo, tag, title, description, learning } = newLearning;
	try {
		const learningText = getTextFromSlateObj(learning);
		const learningInput = {
			learningID,
			source,
			linkInfo: JSON.stringify(linkInfo),
			tag,
			title,
			description,
			learning: JSON.stringify(learning),
			learningBrief: learningText.slice(0, 500),
			userID,
			withinChapterID: finalWithinChapterID,
			numWords: getWordCount(learningText),
			// type: 'learning',
		};
		const newDraftLearning = await API.graphql(
			graphqlOperation(mutationCreateLearning, { input: learningInput })
		);
		const updatedAt = newDraftLearning.data.customCreateLearning.updatedAt;
		setNewLearning((learning) =>
			update(learning, { $merge: { learningID, updatedAt } })
		);
		learningInput.updatedAt = updatedAt;
		setDrafts((prev) => update(prev, { $push: [learningInput] }));
		return true;
	} catch (error) {
		console.log('Error creating draft: ', error);
		return;
	}
};

export default createDraftLearning;
