import { API, graphqlOperation } from 'aws-amplify';
import { mutationUpdateLearning } from 'graphql/customMutations';
import { getTextFromSlateObj, getWordCount } from 'utils';
import update from 'immutability-helper';

const updateDraftLearning = async ({ newLearning, setDrafts }) => {
	// Update draft learning
	const {
		learningID,
		source,
		linkInfo,
		tag,
		title,
		description,
		learning,
	} = newLearning;
	try {
		const learningText = getTextFromSlateObj(learning);
		const learningInput = {
			learningID,
			source,
			linkInfo: JSON.stringify(linkInfo),
			tag,
			title,
			description,
			learning: JSON.stringify(learning),
			learningBrief: learningText.slice(0, 500),
			// userID,
			// withinChapterID: finalWithinChapterID,
			numWords: getWordCount(learningText),
			// type: 'learning',
		};
		const updateDraftLearning = await API.graphql(
			graphqlOperation(mutationUpdateLearning, { input: learningInput })
		);
		const updatedAt = updateDraftLearning.data.updateLearning.updatedAt;
		learningInput.updatedAt = updatedAt;
		setDrafts((prev) => {
			const idx = prev.findIndex((item) => item.learningID === learningID);
			return update(prev, { [idx]: { $merge: learningInput } });
		});
		return true;
	} catch (error) {
		console.log('Error updateing draft: ', error);
		return;
	}
};

export default updateDraftLearning;
