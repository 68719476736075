import React, { useState } from 'react';
import styles from './CommentPanel.module.css';
import { getProfileLink, getFormatDateTimeSince } from 'utils';
import { ExternalLinkSvg, profileSvg } from 'icons';
import TextTruncate from 'react-text-truncate';
import { TextButton } from 'components/Button';
import { GetSourceSvg } from 'components/DropdownInput';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const uuidLength = 36;

function CommentPanel(props) {
	// Comment
	const { comment } = props;
	return (
		<section className={styles.commentPanel}>
			<Title {...comment} />
			<div className={styles.comment}>
				<Profile {...comment} />
				<div className={styles.commentDiv}>
					<Headline {...comment} />
					<CommentText {...comment} />
				</div>
			</div>
		</section>
	);
}

function Title(props) {
	const { source, title } = props.connectLearning || {};
	const { withinCommentID, learningID } = props;
	const articleLink = `/learning/${learningID}`;
	const isMyLearning = withinCommentID.length !== uuidLength;
	return (
		<Link
			to={articleLink}
			className={classNames(styles.titleLink, {
				[styles.blueTitleLink]: isMyLearning,
			})}
		>
			<div
				className={classNames(styles.title, {
					[styles.blueTitle]: isMyLearning,
				})}
			>
				{GetSourceSvg({ source, className: styles.svg })}
				<h1
					className={classNames(styles.h1, { [styles.blueH1]: isMyLearning })}
				>
					{title}
				</h1>
				<ExternalLinkSvg className={styles.linkSvg} />
			</div>
		</Link>
	);
}

function Profile(props) {
	const { profileURL, profileURLIdx, profileImg } = props.connectUser || {};
	const profileLink = getProfileLink(profileURL, profileURLIdx);
	return (
		<div>
			<a href={profileLink}>
				<img
					src={profileImg || profileSvg}
					alt={'Your profile'}
					className={styles.profileImg}
				/>
			</a>
		</div>
	);
}

function Headline(props) {
	const { withinCommentID, createdAt, connectUser = {} } = props;
	const { firstName, lastName } = connectUser;
	const name = `${firstName} ${lastName}`;
	const time = getFormatDateTimeSince(createdAt, false);
	const isMyLearning = withinCommentID.length !== uuidLength;
	const commentReply = isMyLearning ? 'commented' : 'replied';
	return (
		<div>
			<span className={styles.name}>{name}</span>
			<span className={styles.time}>{`${commentReply} ${time}`}</span>
		</div>
	);
}

function CommentText(props) {
	const { comment: commentText } = props;

	// Display full comment if commentText is shorter than 550 chars
	const isShortComment = commentText && commentText.length < 550;
	const [fullDisplay, setFullDisplay] = useState(isShortComment);
	return (
		<div className={styles.commentText}>
			{fullDisplay && (
				<>
					<div>{commentText}</div>
					{!isShortComment && (
						<TextButton
							children={'Show less'}
							className={styles.commentTextButton}
							onClick={() => setFullDisplay(false)}
						/>
					)}
				</>
			)}
			{!fullDisplay && (
				<>
					<TextTruncate line={5} element='div' text={commentText} />
					<TextButton
						children={'Read more'}
						className={styles.commentTextButton}
						onClick={() => setFullDisplay(true)}
					/>
				</>
			)}
		</div>
	);
}

export default CommentPanel;
