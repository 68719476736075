// New learning / save learning / chapter
export const LEARNING_ID_LENGTH = 15;
export const SAVE_LEARNING_ID_LENGTH = 18;
export const CHAPTER_ID_LENGTH = 17;
// Login Redirect
export const LOCAL_STORAGE_SAVE_PATHNAME = 'savePathname';
// Tag History
export const LOCAL_STORAGE_TAG_HISTORY = 'tagHistory';
// Theme
export const LOCAL_STORAGE_THEME = 'theme';
// Emoji History
export const LOCAL_STORAGE_EMOJI_HISTORY = 'emojiHistory';
// SEO
export const WEBSITE_TITLE = 'TipStory - Learning and Sharing';
export const WEBSITE_DESCRIPTION =
	'TipStory is the first collective learning community for students to learn and share learnings with each other😃';
// Error
export const GENERAL_ERROR_MSG = `An error encountered 🤯 Please try again later`;
// Share Button
export const FACEBOOK_APP_ID_PROD = `401978344300549`;
export const TWITTER_USERNAME = `TipStoryShare`;
