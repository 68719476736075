import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './Feedback.module.css';
import update from 'immutability-helper';
import PrimaryButton from 'components/Button';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { getProfileLink, useUser } from 'utils';

function Feedback() {
	return (
		<div>
			<div className={styles.header}>
				<h1 className={styles.h1}>💬TipStory Feedback Form</h1>
				<p className={styles.p}>
					TipStory dreams of making learning and guidance accessible to every
					student, and to empower every student to discover and pursue their
					passions, through collective peer learning and sharing🤝
				</p>
				<p className={styles.p}>
					As a strong advocate for collective power and community spirit,{' '}
					<b>
						we value your feedback and suggestions to help us improve the
						TipStory community and work toward making this dream a reality
						together❤️
					</b>
				</p>
				<p className={styles.p}>
					Whether it is a small bug you discover, a new feature you would like
					to use, or your desire to offer your skills and get more involved with
					the TipStory team, we would love to hear from you! The feedback form
					below is the easiest way for you to anonymously share your feedback,
					suggestions and eagerness with us💡
				</p>
				<FeedbackForm />
			</div>
			<div className={styles.header}>
				<p className={styles.p}>
					Fun Fact: the idea of this feedback form is brought up by one of you.
					We also have a{' '}
					<a href={'/learning/lXZZEhjptvAOlFm'} className={styles.link}>
						learning post
					</a>{' '}
					that recognizes many of your awesome feedback🎉
				</p>
			</div>
		</div>
	);
}

function FeedbackForm() {
	const [form, setForm] = useState({});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [msg, setMsg] = useState('');
	const [user] = useUser();

	const handleChange = async (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setForm((prev) => update(prev, { [name]: { $set: value } }));
	};

	const handleSubmit = async () => {
		// Return if loading
		if (loading) return;
		setMsg('');
		// Check form data
		if (!form.feedback) {
			setError('Please enter your feedback, suggestions & thoughts');
			return;
		}
		setLoading(true);
		setError('');
		try {
			const data = {
				...form,
				userAgent: navigator.userAgent,
			};
			if (user.userID) {
				data.scholarProfile = `www.tipstory.org${getProfileLink(
					user.profileURL,
					user.profileURLIdx
				)}`;
			}
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'text/plain',
				},
				body: JSON.stringify(data),
				redirect: 'follow',
			};

			const rawRes = await fetch(
				'https://script.google.com/macros/s/AKfycbwsbbKSKDJHgN8Nig-fSnWnPw8G6WbIpsRYQ92HJ-hdWj8l5Wc-YFtzSYL8yh2NbaLt/exec',
				requestOptions
			);
			const res = await rawRes.json();
			if (res.success) {
				setMsg(
					`Submitted successfully! Thank you for helping us improve the TipStory community together❤️`
				);
				setForm({});
			} else {
				throw new Error('Not success');
			}
		} catch (error) {
			console.log('Error: ', error);
			setError(GENERAL_ERROR_MSG);
		}
		setLoading(false);
	};

	return (
		<div className={styles.formDiv}>
			<ParagraphInput
				title={'Feedback, Suggestions & Thoughts'}
				name={'feedback'}
				value={form.feedback || ''}
				handleChange={handleChange}
			/>
			<Input
				title={'Name (Optional)'}
				name={'name'}
				value={form.name || ''}
				handleChange={handleChange}
			/>
			<Input
				title={'Email (Optional)'}
				name={'email'}
				value={form.email || ''}
				handleChange={handleChange}
			/>
			<div className={styles.buttonDiv}>
				<PrimaryButton
					className={styles.button}
					onClick={handleSubmit}
					loading={loading}
				>
					Submit
				</PrimaryButton>
			</div>
			{msg && <p className={styles.msg}>{msg}</p>}
			{error && <p className={styles.error}>{error}</p>}
		</div>
	);
}

function ParagraphInput({ title, name, value, handleChange }) {
	return (
		<div className={styles.inputDiv}>
			<label className={styles.label}>{title}</label>
			<TextareaAutosize
				type='text'
				name={name}
				value={value}
				autoComplete={'off'}
				minRows={2}
				onChange={handleChange}
				className={styles.input}
				autoFocus={true}
			/>
		</div>
	);
}

function Input({ title, name, value, handleChange }) {
	return (
		<div className={styles.inputDiv}>
			<label className={styles.label}>{title}</label>
			<input
				type='text'
				name={name}
				value={value}
				onChange={handleChange}
				className={styles.input}
				autoComplete={'off'}
			/>
		</div>
	);
}

export default Feedback;
