import React, { useEffect, useRef } from 'react';
import Url from 'url-parse';
import queryString from 'query-string';
import { useSelected, useFocused } from 'slate-react';
import classNames from 'classnames';
import styles from './Embed.module.css';

/**
 * Return iframe component from link
 * Support
 * 1. Git Gist
 * 		Format:   https://gist.github.com/{userId}/{gistId}
 * 		Example:  https://gist.github.com/ruffsl/f1abb7809a32bc54f2b4eabc9a629f58
 * 2. Codepen
 * 		Format:   https://codepen.io/{userId}/pen/{codepenId}
 * 		Example:  https://codepen.io/aaroniker/pen/rNMmZvq
 * 3. CodeSandbox
 * 		Format:   https://codesandbox.io/s/{sandboxId}
 * 		Example:  https://codesandbox.io/s/vigorous-cohen-kkbs1
 * 4. Youtube
 * 		Format:   https://www.youtube.com/watch?v={videoId} or https://youtu.be/{videoId}
 * 		Example:  https://www.youtube.com/watch?v=xZ3k7Fd6_eU or https://youtu.be/xZ3k7Fd6_eU
 * 5. Viemo
 * 		Format:   https://vimeo.com/{videoId} or https://vimeo.com/channels/{channelId}/{videoId}
 * 		Example:  https://vimeo.com/489084514 or https://vimeo.com/channels/staffpicks/489315569
 * 		Format:   https://player.vimeo.com/video/{videoId}
 * 		Example:  https://player.vimeo.com/video/26689853
 * 6. Google Map
 * 		Format:   https://www.google.com/maps/place/{place}/{location}/
 * 		Example:  https://www.google.com/maps/place/Brooklyn,+NY/@40.6451594,-74.0850821,11z/
 * 7. Instagram
 * 		Format:   https://www.instagram.com/p/{postId}/
 * 		Example:  https://www.instagram.com/p/CI4OkM_sEmY/
 * 8. Spotify
 * 		Format:   https://open.spotify.com/track/{songId}, https://open.spotify.com/artist/{artistId}
 * 		https://open.spotify.com/playlist/{playlistId}, https://open.spotify.com/album/{albumId}
 * 		Example:  https://open.spotify.com/track/0g8Dq6OpSmWengqtLrVr77, https://open.spotify.com/artist/69GGBxA162lTqCwzJG5jLp
 * 		https://open.spotify.com/playlist/06MtbfNH3yEkSXDGbbFFW9, https://open.spotify.com/album/01GR4NL5O5CZM51k0aejKD
 */

function Embed({ attributes, children, element }) {
	const selected = useSelected();
	const focused = useFocused();
	let { link } = element;
	if (link === '') return null;
	if (link.startsWith('www.')) link = `https://${link}`;
	const linkObj = Url(link);
	const linkObjPath = linkObj.pathname.split('/');
	let iframe = null;
	let userId, gistId, codepenId, sandboxId, videoId, place, location;
	let postId, songId, artistId, playlistId, albumId, CADId, slideId;
	let docId;
	let queryParse;
	switch (linkObj.hostname) {
		// 1. Git Gist      /{userId}/{gistId}
		case 'gist.github.com':
			gistId = linkObjPath[2];
			if (gistId) iframe = <Gist gistId={gistId} />;
			break;
		// 2. Codepen       /{userId}/pen/{codepenId}
		case 'codepen.io':
			userId = linkObjPath[1];
			codepenId = linkObjPath[3];
			if (userId && codepenId)
				iframe = <Codepen userId={userId} codepenId={codepenId} />;
			break;
		// 3. CodeSandbox   /s/{sandboxId}
		case 'codesandbox.io':
			sandboxId = linkObjPath[2];
			if (sandboxId) iframe = <CodeSandbox sandboxId={sandboxId} />;
			break;
		// 4. Youtube       /watch?v={videoId}
		case 'www.youtube.com':
			if (linkObjPath[1].startsWith('watch')) {
				queryParse = queryString.parse(linkObj.query);
				videoId = queryParse.v;
				if (videoId) iframe = <Youtube videoId={videoId} />;
			}
			break;
		//    Youtube       /{videoId}
		case 'youtu.be':
			videoId = linkObjPath[1];
			if (videoId) iframe = <Youtube videoId={videoId} />;
			break;
		// 5. Vimeo         /channels/{channelId}/{videoId}, /{videoId}
		case 'vimeo.com':
			if (linkObjPath[1] === 'channels') {
				videoId = linkObjPath[3];
				if (videoId) iframe = <Vimeo videoId={videoId} />;
			} else {
				videoId = linkObjPath[1];
				if (videoId) iframe = <Vimeo videoId={videoId} />;
			}
			break;
		// 					/video/{videoId}
		case 'player.vimeo.com':
			if (linkObjPath[1] === 'video') {
				videoId = linkObjPath[2];
				if (videoId) iframe = <Vimeo videoId={videoId} />;
			}
			break;
		// 6. Google Map
		case 'www.google.com':
			// /maps/place/{place}/{location}/
			if (linkObj.pathname.startsWith('/maps/place')) {
				place = linkObjPath[3];
				location = getGoogleMapLocation(linkObjPath[4]);
				if (place || location)
					iframe = <GoogleMap place={place} location={location} />;
			}
			// /maps/{streetviewObj}
			if (linkObj.pathname.startsWith('/maps/@')) {
				const streetviewObj = getGoogleMapStreetviewObj(linkObjPath[2]);
				iframe = <GoogleMap {...streetviewObj} />;
			}
			break;
		// 7. Instagram     /p/{postId}/
		case 'www.instagram.com':
			if (linkObjPath[1] === 'p') {
				postId = linkObjPath[2];
				if (postId) iframe = <Instagram postId={postId} />;
			}
			break;
		// 8. Spotify       /track/{songId},    /artist/{artistId}
		case 'open.spotify.com':
			if (linkObjPath[1] === 'track') {
				songId = linkObjPath[2];
				iframe = <Spotify songId={songId} />;
			}
			if (linkObjPath[1] === 'artist') {
				artistId = linkObjPath[2];
				iframe = <Spotify artistId={artistId} />;
			}
			if (linkObjPath[1] === 'playlist') {
				playlistId = linkObjPath[2];
				iframe = <Spotify playlistId={playlistId} />;
			}
			if (linkObjPath[1] === 'album') {
				albumId = linkObjPath[2];
				iframe = <Spotify albumId={albumId} />;
			}
			break;
		// 9. Fusion 360	/{userId}/g/shares/{CADId}
		case 'myhub.autodesk360.com':
			if (linkObjPath[2] === 'g' && linkObjPath[3] === 'shares') {
				userId = linkObjPath[1];
				CADId = linkObjPath[4];
				iframe = <Fusion userId={userId} CADId={CADId} />;
			}
			break;
		// 10. TinkerCAD 	/things/${CADId}-xxx-xxx-xxx/editel
		case 'www.tinkercad.com':
			if (linkObjPath[1] === 'things') {
				CADId = linkObjPath[2].split('-')[0];
				iframe = <TinkerCAD CADId={CADId} />;
			}
			break;
		case 'docs.google.com':
			// 11. Google Slide /presentation/d/e/${slideId}
			if (linkObj.pathname.startsWith('/presentation/d/e')) {
				slideId = linkObjPath[4];
				iframe = <GoogleSlide slideId={slideId} />;
			}
			// 12. Google Doc /document/d/e/${docId}
			if (linkObj.pathname.startsWith('/document/d/e')) {
				docId = linkObjPath[4];
				iframe = <GoogleDoc docId={docId} />;
			}
			// Google Doc /document/u/${userNum}/d/e/${docId}
			if (linkObj.pathname.startsWith('/document/u')) {
				if (linkObjPath[4] === 'd' && linkObjPath[5] === 'e') {
					docId = linkObjPath[6];
					iframe = <GoogleDoc docId={docId} />;
				}
			}
			// 13. Google Sheet /spreadsheets/d/e/${docId}
			if (linkObj.pathname.startsWith('/spreadsheets/d/e')) {
				docId = linkObjPath[4];
				iframe = <GoogleSheet docId={docId} />;
			}
			// Google Sheet /spreadsheets/u/${userNum}/d/e/${docId}
			if (linkObj.pathname.startsWith('/spreadsheets/u')) {
				if (linkObjPath[4] === 'd' && linkObjPath[5] === 'e') {
					docId = linkObjPath[6];
					iframe = <GoogleSheet docId={docId} />;
				}
			}
			break;
		default:
			iframe = null;
	}

	if (iframe)
		return (
			<div {...attributes}>
				<div
					contentEditable={false}
					className={classNames(styles.div, {
						[styles.selected]: selected && focused,
					})}
				>
					{iframe}
				</div>
				{children}
			</div>
		);
	return null;
}

// useIframe Hook
function useIframe(ref, styles, script, id) {
	useEffect(
		() => {
			if (ref && ref.current) {
				const iframe = ref.current;
				let doc = iframe.document;
				if (iframe.contentDocument) doc = iframe.contentDocument;
				else if (iframe.contentWindow) doc = iframe.contentWindow.document;
				const resizeScript = `onload="parent.document.getElementById('${id}').style.height=document.body.scrollHeight + 'px'"`;
				const iframeHtml = `<html><head><base target="_parent">${styles}</head><body ${resizeScript}>${script}</body></html>`;
				doc.open();
				doc.writeln(iframeHtml);
				doc.close();
			}
		},
		// eslint-disable-next-line
		[ref, ref.current]
	);
}

// 1. Git Gist iframe component (Using "React Gist")
// https://github.com/tleunen/react-gist/blob/master/src/index.js
function Gist({ gistId, file }) {
	const iframeRef = useRef(null);
	const gistLink = `https://gist.github.com/${gistId}.js`;
	const script = `<script type="text/javascript" src="${gistLink}"></script>`;
	const styles = '<style>*{font-size:12px;}</style>';
	const elementId = file ? `gist-${gistId}-${file}` : `gist-${gistId}`;
	useIframe(iframeRef, styles, script, elementId);
	return (
		<iframe
			title={elementId}
			ref={iframeRef}
			width='100%'
			frameBorder={0}
			id={elementId}
		/>
	);
}

// 2. CodePen iframe component
// (Default to 265px height, dark theme, shows html tab )
function Codepen({ userId, codepenId }) {
	return (
		<iframe
			title={`codepen-${userId}-${codepenId}`}
			height='265px'
			width='100%'
			scrolling='no'
			src={`https://codepen.io/${userId}/embed/${codepenId}?height=265&theme-id=dark&default-tab=html,result`}
			frameBorder={0}
			loading='lazy'
			allowtransparency='true'
			allowFullScreen={true}
		/>
	);
}

// 3. CodeSandbox iframe component
// (Default to 500px height, dark theme, split view)
function CodeSandbox({ sandboxId }) {
	return (
		<iframe
			title={`codesandbox-${sandboxId}`}
			src={`https://codesandbox.io/embed/${sandboxId}?fontsize=14&codemirror=1&view=split&theme=dark`}
			style={{
				width: '100%',
				height: '500px',
				border: '0',
				borderRadius: '4px',
				overflow: 'hidden',
			}}
			allow='accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking'
			sandbox='allow-forms allow-modals allow-popups allow-presentation allow-scripts'
		/>
	);
}

// 4. Youtube iframe component
// (Default to 560px width, 315px height)
function Youtube({ videoId }) {
	return (
		<iframe
			title={`youtube-${videoId}`}
			style={{ width: '100%', maxWidth: '640px', height: '360px' }}
			src={`https://www.youtube.com/embed/${videoId}`}
			frameBorder={'0'}
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
			allowFullScreen={true}
		/>
	);
}

// 5. Vimeo
function Vimeo({ videoId }) {
	return (
		<iframe
			title={`vimeo-${videoId}`}
			style={{ width: '100%', maxWidth: '640px', height: '360px' }}
			src={`https://player.vimeo.com/video/${videoId}`}
			frameBorder={'0'}
			allowFullScreen={true}
		/>
	);
}

// 6. GoogleMap
const GoogleMapAPIKey = 'AIzaSyCXINp5Bw8pehJGRSkD9qdm3dnab292u-U';
const GoogleMapEmbedLink = 'https://www.google.com/maps/embed/v1';
const getGoogleMapLocation = (str = '') => {
	// example str: @42.4426977,-76.5333944,13z
	const locationCopy = str.slice(1);
	const [latitude, longitude] = locationCopy.split(',');
	if (latitude && longitude) return `${latitude},${longitude}`;
	return '';
};
const getGoogleMapStreetviewObj = (str = '') => {
	// example str: @47.6160291,-122.3391403,3a,90y,213.16h,99.78t
	const locationCopy = str.slice(1);
	const [latitude, longitude, , fov, heading, pitch] = locationCopy.split(',');
	const streetviewObj = {};
	if (latitude && longitude)
		streetviewObj.location = `${latitude},${longitude}`;
	if (fov && fov.slice(-1) === 'y') streetviewObj.fov = fov.slice(0, -1);
	if (heading && heading.slice(-1) === 'h')
		streetviewObj.heading = heading.slice(0, -1);
	if (pitch && pitch.slice(-1) === 't') {
		const pitchNumber = Number(pitch.slice(0, -1));
		if (Number.isFinite(pitchNumber)) {
			streetviewObj.pitch = String(pitchNumber - 90);
		}
	}
	return streetviewObj;
};
function GoogleMap({ place, location, fov, heading, pitch }) {
	// Place view only
	if (place && !location) {
		return (
			<iframe
				title={`google-map-${place}`}
				style={{ width: '50%', height: '220px' }}
				src={`${GoogleMapEmbedLink}/place?key=${GoogleMapAPIKey}&q=${place}`}
				frameBorder={'0'}
				allowFullScreen={true}
			/>
		);
	}

	// Location view
	let streetViewSource = `${GoogleMapEmbedLink}/streetview?key=${GoogleMapAPIKey}&location=${location}`;
	if (fov) streetViewSource += `&fov=${fov}`;
	if (heading) streetViewSource += `&heading=${heading}`;
	if (pitch) streetViewSource += `&pitch=${pitch}`;
	if (location) {
		return (
			<>
				<iframe
					title={`google-map-view-${location}`}
					style={{ width: '50%', height: '220px' }}
					src={`${GoogleMapEmbedLink}/view?key=${GoogleMapAPIKey}&center=${location}&zoom=16`}
					frameBorder={'0'}
					allowFullScreen={true}
				/>
				<iframe
					title={`google-map-streetview-${location}`}
					style={{ width: '50%', height: '220px' }}
					src={streetViewSource}
					frameBorder={'0'}
					allowFullScreen={true}
				/>
			</>
		);
	}

	return null;
}

// 7. Instagram
function Instagram({ postId }) {
	return (
		<iframe
			title={`instagram-${postId}`}
			width='320'
			height='450'
			autoflow='auto'
			src={`http://instagram.com/p/${postId}/embed`}
			frameBorder={'0'}
			allowtransparency='true'
		/>
	);
}

// 8. Spotify
function Spotify({ songId, artistId, playlistId, albumId }) {
	let link;
	if (songId) link = `/track/${songId}`;
	if (artistId) link = `/artist/${artistId}`;
	if (playlistId) link = `/playlist/${playlistId}`;
	if (albumId) link = `/album/${albumId}`;
	return (
		<iframe
			title={`spotify-${link}`}
			src={`https://open.spotify.com/embed${link}`}
			width='300'
			height={songId ? '80' : '380'}
			frameBorder={'0'}
			allowtransparency='true'
			allow='encrypted-media'
		/>
	);
}

// 9. Fusion 360
function Fusion({ userId, CADId }) {
	return (
		<iframe
			title={`fusion-${userId}-${CADId}`}
			src={`https://myhub.autodesk360.com/${userId}/shares/public/${CADId}?mode=embed`}
			style={{ width: '100%', maxWidth: '640px', height: '360px' }}
			allowFullScreen={true}
			frameBorder={'0'}
		/>
	);
}

// 10. TinkerCAD
function TinkerCAD({ CADId }) {
	return (
		<iframe
			title={`TinkerCAD-${CADId}`}
			src={`https://www.tinkercad.com/embed/${CADId}?editbtn=1`}
			style={{ width: '100%', maxWidth: '640px', height: '360px' }}
			frameBorder={'0'}
			scrolling={'no'}
		/>
	);
}

// 11. Google Slide
function GoogleSlide({ slideId }) {
	return (
		<iframe
			title={`GoogleSlide-${slideId}`}
			src={`https://docs.google.com/presentation/d/e/${slideId}/embed?start=false&loop=false&delayms=30000`}
			style={{ width: '100%', maxWidth: '576px', height: '360px' }}
			frameBorder={'0'}
			allowFullScreen={true}
		/>
	);
}

// 12. Google Doc
function GoogleDoc({ docId }) {
	return (
		<iframe
			title={`GoogleDoc-${docId}`}
			src={`https://docs.google.com/document/d/e/${docId}/pub?embedded=true`}
			style={{
				width: '100%',
				height: 'calc(100vh - 100px)',
				minHeight: '360px',
			}}
		/>
	);
}

// 13. Google Sheet
function GoogleSheet({ docId }) {
	return (
		<iframe
			title={`GoogleSheet-${docId}`}
			src={`https://docs.google.com/spreadsheets/d/e/${docId}/pubhtml?widget=true&amp;headers=false`}
			style={{ width: '100%', height: '360px' }}
		/>
	);
}
export default Embed;
