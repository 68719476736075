import React, { useState } from 'react';
import styles from './NewChapter.module.css';
import Input from './../Input';
import PrimaryButton from 'components/Button';
import { getNanoid, getNewOrder, useEnterCallback, useUser } from 'utils';
import CloseButton from '../CloseButton';
import ErrorMsg from '../../ErrorMsg';
import { CHAPTER_ID_LENGTH, GENERAL_ERROR_MSG } from 'utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationUpdateUser,
	mutationCreateLearning,
} from 'graphql/customMutations';
import getNewChapterName from 'utils/getNewChapterName';
import { useParams } from 'react-router-dom';
import update from 'immutability-helper';

function NewChapter({ modal, setIsOpenFalse, setJourney }) {
	const { isOpen } = modal;
	const [title, setTitle] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [user, setUser] = useUser();
	const { withinChapterID = 'home' } = useParams();

	const handleCreate = async () => {
		if (title === '') return;
		if (loading) return;
		setLoading(true);
		setError('');
		const success = await newChapter({
			title,
			user,
			setUser,
			withinChapterID,
			setJourney,
		});
		if (success) setIsOpenFalse();
		else {
			setLoading(false);
			setError(GENERAL_ERROR_MSG);
		}
	};
	useEnterCallback({ isOpen, callback: handleCreate });

	return (
		<div className={styles.newChapter}>
			<CloseButton onClick={setIsOpenFalse} />
			{error && <ErrorMsg error={error} />}
			<h1 className={styles.title}>New Chapter</h1>
			<Input
				value={title}
				setValue={setTitle}
				isOpen={isOpen}
				className={styles.input}
				placeholder={'Chapter Title'}
			/>
			<div className={styles.buttonGroup}>
				<PrimaryButton
					primary={false}
					onClick={setIsOpenFalse}
					children={'Cancel'}
				/>
				<PrimaryButton
					className={styles.createButton}
					disable={title === ''}
					onClick={handleCreate}
					loading={loading}
					children={'Create'}
				/>
			</div>
		</div>
	);
}

// create new chapter, return true on success
const newChapter = async ({
	title,
	user,
	setUser,
	withinChapterID,
	setJourney,
}) => {
	// 1. Create Chapter in database
	const learningID = getNanoid(CHAPTER_ID_LENGTH);
	const chapterInput = {
		learningID,
		title,
		userID: user.userID,
		withinChapterID,
		type: 'chapter',
	};
	try {
		await API.graphql(
			graphqlOperation(mutationCreateLearning, { input: chapterInput })
		);
	} catch (error) {
		console.log('Error creating chapter: ', error);
		return false;
	}
	// 2. Add Chapter to local order and local name
	const userInput = {
		userID: user.userID,
	};
	const [newChapterOrder, addOrderSuccess] = getNewOrder(
		user.chapterOrder,
		withinChapterID,
		learningID
	);
	if (addOrderSuccess) userInput['chapterOrder'] = newChapterOrder;
	const [newChapterName, addNameSuccess] = getNewChapterName(
		user.chapterName,
		learningID,
		title
	);
	if (addNameSuccess) userInput['chapterName'] = newChapterName;
	if (addOrderSuccess || addNameSuccess) {
		try {
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input: userInput })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log('Error updating chapter order and name: ', error);
			// ignore error
		}
	}
	// 3. Add Chapter locally
	const key = `${user.userID}-${withinChapterID}`;
	setJourney((prev) =>
		update(prev, {
			[key]: {
				$push: [chapterInput],
			},
		})
	);
	return true;
};

export default NewChapter;
