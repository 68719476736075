import React from 'react';
import styles from './TipStoryScholar.module.css';
import { ScholarBronzeSvg, ScholarSilverSvg, ScholarGoldSvg } from 'icons';
import classNames from 'classnames';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import Schools from './Schools';

function TipStoryScholar() {
	const history = useHistory();
	return (
		<section className={styles.tipStoryScholar}>
			<div className={styles.headingDiv}>
				<div className={styles.badgeList}>
					<ScholarBronzeSvg className={classNames(styles.svg, styles.bronze)} />
					<ScholarSilverSvg className={classNames(styles.svg, styles.silver)} />
					<ScholarGoldSvg className={classNames(styles.svg, styles.gold)} />
				</div>
				<h1 className={styles.heading}>TipStory Scholar</h1>
			</div>
			<div className={styles.text}>
				Students who are committed to learning and sharing learnings can apply
				to be TipStory Scholar to receive scholar badge, certificate and service
				hours for formal recognition.
			</div>
			<Button
				className={styles.button}
				onClick={() => {
					history.push('scholar-application');
				}}
			>
				🎓 Apply For TipStory Scholar
			</Button>
			<Schools />
			<div className={styles.bottomText}>
				We are proud to honor scholars from hundreds of schools!
			</div>
		</section>
	);
}

export default TipStoryScholar;
