import { useEffect } from 'react';
import { useUser } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetTagsByDate } from 'graphql/customQueries';
import { GENERAL_ERROR_MSG } from 'utils/constants';
// import testWords from './testWords'; //Testing purpose

/**
 * useGetTagList hook fetches and sets latest tagList.
 *
 * @param {Object}
 */
const useGetTagList = ({ tagListRef, setTagList, setError }) => {
	const [user] = useUser();

	useEffect(() => {
		if (user.isLoading) return;

		const variables = {
			type: 'firstTag',
			limit: 150,
		};

		const getNewTagList = async () => {
			try {
				const tagRes = await API.graphql({
					...graphqlOperation(queryGetTagsByDate, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const tagArray = tagRes.data.getTagByDate.items;
				// const tagArray = testWords;
				tagListRef.current = JSON.parse(JSON.stringify(tagArray));
				const finalTagArray = tagArray.map((item) => ({
					...item,
					visibility: 'hidden',
					opacity: 0,
				}));
				setTagList(finalTagArray);
			} catch (error) {
				console.log('Error getting tags: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};

		getNewTagList();
	}, [user.isLoading, user.isLogin, tagListRef, setTagList, setError]);
};

export default useGetTagList;
