import React from 'react';
import styles from './Profile.module.css';
import {
	getFormatDate,
	getPlural,
	getProfileLink,
	useLogin,
	useUser,
} from 'utils';
import { profileSvg } from 'icons';
import PrimaryButton from 'components/Button';
import {
	mutationCreateFollow,
	mutationDeleteFollow,
} from 'graphql/customMutations';
import { API, graphqlOperation } from 'aws-amplify';
import update from 'immutability-helper';
import { getScholarMeta } from 'components/Home/ScholarPortal/utils';
import ScholarBadge, { getScholarBadges } from 'components/ScholarBadge';

function Profile(props) {
	return (
		<div className={styles.profile}>
			<ProfileIcon {...props} />
			<Description {...props} />
			<FollowButton {...props} />
		</div>
	);
}

function ProfileIcon(props) {
	const { profileURL, profileURLIdx, profileImg } = props.connectUser || {};
	const profileLink = getProfileLink(profileURL, profileURLIdx);
	return (
		<div className={styles.profileIcon}>
			<a href={profileLink}>
				<img
					src={profileImg || profileSvg}
					className={styles.img}
					alt='Profile'
				/>
			</a>
		</div>
	);
}

function Description(props) {
	const { createdAt, numWords = 0, description } = props;
	const connectUser = props.connectUser || {};
	const { firstName = '', lastName = '', headline = '' } = connectUser;
	const name = `${firstName} ${lastName}`;
	const formatDate = getFormatDate(createdAt);
	const scholarMeta = getScholarMeta(connectUser);
	const scholarBadges = getScholarBadges(scholarMeta);
	return (
		<div className={styles.description}>
			<div className={styles.topLine}>
				<div>{name}</div>
				{scholarBadges.map(({ type, year }, idx) => (
					<ScholarBadge
						key={idx}
						width={20}
						type={type}
						year={year}
						className={styles.scholarBadge}
					/>
				))}
				<div className={styles.dot}>{` · `}</div>
				<div>{formatDate}</div>
				<div className={styles.dot}>{` · `}</div>
				<div>{`${numWords} word${getPlural(numWords)}`}</div>
			</div>
			<div>{description || headline}</div>
		</div>
	);
}

function FollowButton(props) {
	const { userID: authorID } = props.connectUser || {};
	const [user, setUser] = useUser();
	const [, setLogin] = useLogin();
	const isAuthor = user.userID === authorID;
	if (isAuthor) return null;

	const { userFollowed, setUserFollowed } = props;
	const followed = userFollowed[authorID];

	const handleFollowClick = async () => {
		// Prompt login screen if not login
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'login' });
			return;
		}
		if (!authorID || !user.userID) return;
		const input = {
			followUserID: authorID,
			userID: user.userID,
		};
		// Follow user if not followed
		if (!followed) {
			try {
				await API.graphql(graphqlOperation(mutationCreateFollow, { input }));
				setUserFollowed((prev) => update(prev, { [authorID]: { $set: true } }));
				setUser((prev) =>
					update(prev, { numFollowing: { $apply: (n) => n + 1 } })
				);
			} catch (error) {
				console.log('Error following user: ', error);
			}
		}
		// Unfollow user
		else {
			try {
				await API.graphql(graphqlOperation(mutationDeleteFollow, { input }));
				setUserFollowed((prev) =>
					update(prev, { [authorID]: { $set: false } })
				);
				setUser((prev) =>
					update(prev, { numFollowing: { $apply: (n) => n - 1 } })
				);
			} catch (error) {
				console.log('Error unfollowing user: ', error);
			}
		}
	};

	return (
		<div className={styles.followButton}>
			{!followed ? (
				<PrimaryButton onClick={handleFollowClick} children={'Follow'} />
			) : (
				<PrimaryButton
					primary={false}
					onClick={handleFollowClick}
					children={'Following'}
				/>
			)}
		</div>
	);
}

export default Profile;
