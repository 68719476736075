import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetLearning } from 'graphql/customQueries';
import queryString from 'query-string';
import update from 'immutability-helper';

/**
 * useGetEditLearning hook gets and sets learning for edit.
 * @param {Object}
 */
const useGetEditLearning = ({ active, setNewLearning }) => {
	const [user] = useUser();
	const history = useHistory();
	const params = queryString.parse(history.location.search);
	const paramsLearningID = params.learning;

	useEffect(() => {
		// Return if not active (not in editMode)
		if (!active) return;

		// Return if user not login
		if (!user.isLogin) return;

		// Missing learningID
		if (!paramsLearningID) {
			history.push('/new');
			return;
		}

		const getSetLearning = async () => {
			const variables = {
				learningID: paramsLearningID,
			};
			try {
				const res = await API.graphql(
					graphqlOperation(queryGetLearning, variables)
				);
				const learningObj = res.data.getLearning;

				// Go to new if learning doesn't exist or not current user
				if (!learningObj) throw new Error("Learning doesn't exist");
				if (learningObj.userID !== user.userID)
					throw new Error('Not learning author');

				const temp = {
					linkInfo: JSON.parse(learningObj.linkInfo),
					learning: JSON.parse(learningObj.learning),
				};
				const finalLearningObj = update(learningObj, { $merge: temp });
				setNewLearning(finalLearningObj);
			} catch (error) {
				console.log('Error querying learning: ', error);
				history.push('/new');
			}
		};
		getSetLearning();
	}, [
		active,
		user.isLogin,
		paramsLearningID,
		history,
		user.userID,
		setNewLearning,
	]);
};

export default useGetEditLearning;
