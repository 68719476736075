/**
 * getNewChapterName is used to obtain new chapterName.
 * It returns an array where
 * the first index is the new chapter name in JSON string and
 * the second index is success in boolean.
 *
 * @param {String | Boolean} currentChapterName
 * @param {String} id
 * @param {String} name
 * @param {String} type
 */
const getNewChapterName = (currentChapterName, id, name, type = 'new') => {
	const { parse, stringify } = JSON;
	// Add or replace chapter name
	if (type === 'new' || type === 'replace') {
		if (!currentChapterName) return [stringify({ [id]: name }), true];
		try {
			const currentChapterNameObj = parse(currentChapterName);
			if (typeof currentChapterNameObj !== 'object')
				throw new Error(`ChapterName is not object`);
			currentChapterNameObj[id] = name;
			return [stringify(currentChapterNameObj), true];
		} catch (error) {
			console.log('Error: ', error);
			return [false, false];
		}
	}
	// Delete chapter name
	if (type === 'delete') {
		try {
			if (!currentChapterName) throw new Error(`ChapterName can't be null`);
			const currentChapterNameObj = parse(currentChapterName);
			if (typeof currentChapterNameObj !== 'object')
				throw new Error(`ChapterName is not object`);
			delete currentChapterNameObj[id];
			return [stringify(currentChapterNameObj), true];
		} catch (error) {
			console.log('Error: ', error);
			return [false, false];
		}
	}
};

export default getNewChapterName;
