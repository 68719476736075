import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

const localRedirect = 'http://localhost:3000/';
// const prodRedirect = 'https://main.d1hsvlpishin6i.amplifyapp.com/';
const prodRedirect = 'https://www.tipstory.org/';

const updatedAwsConfig = {
	...awsconfig,
	oauth: {
		...awsconfig.oauth,
		redirectSignIn: isLocalhost ? localRedirect : prodRedirect,
		redirectSignOut: isLocalhost ? localRedirect : prodRedirect,
	},
};

Amplify.configure(updatedAwsConfig);

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
