import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from './Chapter.module.css';
import classNames from 'classnames';
import { FolderSvg } from 'icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getIsMobile, getNewOrder, useUser } from 'utils';
import update from 'immutability-helper';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationUpdateUser } from 'graphql/customMutations';
import { dropInChapter } from '../utils';

function Chapter({ chapter, index, isAuthor, chapters, setJourney }) {
	const { learningID: id, title } = chapter;

	// Store this chapter in a ref
	const ref = useRef(null);

	// dropSide is used to displays the resulting position of item when drag over
	// chapter, it can be one of the three values: left, center, right
	const [dropSide, setDropSide] = useState('');

	// chapter can be dragged if person is author and not on mobile view
	const isMobile = getIsMobile();
	const canDrag = isAuthor && !isMobile;
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'chapter', id, index },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		canDrag: () => canDrag,
	});

	// drop and hover action
	const [user, setUser] = useUser();
	const { withinChapterID = 'home' } = useParams();
	const moveChapterWrapper = async (dragIndex, currentIdx) => {
		await moveChapter({
			dragIndex,
			currentIdx,
			chapters,
			withinChapterID,
			user,
			setUser,
		});
	};
	const [{ isOver }, drop] = useDrop({
		accept: ['learningCard', 'chapter'],
		drop: async (item, monitor) => {
			if (!ref.current) return;
			// chapter can be dropped to the left/center/right of a chapter
			if (item.type === 'chapter') {
				// Return if drop to the same chapter
				const dragItemIndex = item.index;
				if (dragItemIndex === index) return;
				// Get left and right bound of chapter being hovered
				const boundingRect = ref.current.getBoundingClientRect();
				const horizontalWidth = boundingRect.right - boundingRect.left;
				const horizontalWidthAThird = horizontalWidth / 3;
				const leftBound = boundingRect.left + horizontalWidthAThird;
				const rightBound = leftBound + horizontalWidthAThird;
				// Get drag item's X value
				const dragItemX = monitor.getClientOffset().x;
				// On the left side of chapter
				if (dragItemX <= leftBound) {
					// Return if chapter is 1 chapter on the right of dragged chapter
					if (dragItemIndex + 1 === index) return;
					if (dragItemIndex < index)
						await moveChapterWrapper(dragItemIndex, index - 1);
					else if (dragItemIndex > index)
						await moveChapterWrapper(dragItemIndex, index);
				}
				// On the right side of chapter
				else if (dragItemX >= rightBound) {
					// Return if chapter is 1 chapter on the left of dragged chapter
					if (dragItemIndex - 1 === index) return;
					if (dragItemIndex < index)
						await moveChapterWrapper(dragItemIndex, index);
					else if (dragItemIndex > index)
						await moveChapterWrapper(dragItemIndex, index + 1);
				}
				// On the center of chapter
				else {
					const type = 'chapter';
					const learningID = item.id;
					const newWithinChapterID = id;
					await dropInChapter({
						type,
						learningID,
						newWithinChapterID,
						withinChapterID,
						user,
						setUser,
						setJourney,
					});
				}
			}
			// learning can only be dropped center of a chapter
			if (item.type === 'learningCard') {
				const type = 'learning';
				const learningID = item.parentLearningID || item.id;
				const newWithinChapterID = id;
				await dropInChapter({
					type,
					learningID,
					newWithinChapterID,
					withinChapterID,
					user,
					setUser,
					setJourney,
				});
			}
		},
		hover: (item, monitor) => {
			if (!ref.current) return;
			// Reset drop side
			setDropSide('');
			// chapter can be dropped to the left/center/right of a chapter
			if (item.type === 'chapter') {
				// Return if drop to the same chapter
				const dragItemIndex = item.index;
				if (dragItemIndex === index) return;
				// Get left and right bound of chapter being hovered
				const boundingRect = ref.current.getBoundingClientRect();
				const horizontalWidth = boundingRect.right - boundingRect.left;
				const horizontalWidthAThird = horizontalWidth / 3;
				const leftBound = boundingRect.left + horizontalWidthAThird;
				const rightBound = leftBound + horizontalWidthAThird;
				// Get drag item's X value
				const dragItemX = monitor.getClientOffset().x;
				// On the left side of chapter
				if (dragItemX <= leftBound) {
					// Return if chapter is 1 chapter on the right of dragged chapter
					if (dragItemIndex + 1 === index) return;
					setDropSide('left');
				}
				// On the right side of chapter
				else if (dragItemX >= rightBound) {
					// Return if chapter is 1 chapter on the left of dragged chapter
					if (dragItemIndex - 1 === index) return;
					setDropSide('right');
				}
				// On the center of chapter
				else {
					setDropSide('center');
				}
			}
			// learning can only be dropped center of a chapter
			if (item.type === 'learningCard') {
				setDropSide('center');
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
	});

	drop(drag(ref));

	const dropLeft = isOver && dropSide === 'left';
	const dropRight = isOver && dropSide === 'right';
	const dropCenter = isOver && dropSide === 'center';

	// if canDrag, double click opens chapter
	const { fullProfileURL } = useParams();
	const to = `/journey/${fullProfileURL}/chapter/${id}`;
	const history = useHistory();
	const handleOnDoubleClick = () => {
		if (canDrag) history.push(to);
	};

	return (
		<div
			className={classNames(styles.chapter, {
				[styles.dropLeft]: dropLeft,
				[styles.dropRight]: dropRight,
				[styles.dropCenter]: dropCenter,
			})}
			ref={ref}
		>
			{/* Show Button for canDrag, show Link otherwise */}
			{canDrag ? (
				<button
					type={'button'}
					id={`chapter-${id}`}
					title={title}
					className={classNames(styles.button, {
						[styles.isDragging]: isDragging,
					})}
					onDoubleClick={handleOnDoubleClick}
				>
					<Content title={title} />
				</button>
			) : (
				<Link
					to={to}
					id={`chapter-${id}`}
					title={title}
					className={`${styles.button} ${styles.link}`}
				>
					<Content title={title} />
				</Link>
			)}
		</div>
	);
}

function Content({ title }) {
	return (
		<>
			<div className={styles.svgDiv}>
				<FolderSvg className={styles.svg} />
			</div>
			<h1 className={styles.title}>{title}</h1>
		</>
	);
}

/**
 * moveChapter updates chapterOrder.
 *
 * @param {Object}
 */
const moveChapter = async ({
	dragIndex,
	currentIdx,
	chapters,
	withinChapterID,
	user,
	setUser,
}) => {
	// Get new chapter arrays
	const dragChapter = chapters[dragIndex];
	const newChapters = update(chapters, {
		$splice: [
			[dragIndex, 1],
			[currentIdx, 0, dragChapter],
		],
	});
	// Get new chapter orders in current chapterID
	const newChaptersMap = newChapters.map((item) => item.learningID);
	const [newChapterOrder, success] = getNewOrder(
		user.chapterOrder,
		withinChapterID,
		newChaptersMap,
		'replace'
	);
	// Update order
	if (success) {
		try {
			const input = {
				userID: user.userID,
				chapterOrder: newChapterOrder,
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log('Error updating chapter order: ', error);
			// ignore error
		}
	}
};

export default Chapter;
