import { DeleteSvg, EditSvg, OpenFolderSvg } from 'icons';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Item from '../Item';

function ChapterMenu({ menu, setModal }) {
	return (
		<>
			<OpenChapter menu={menu} />
			<RenameChapter menu={menu} setModal={setModal} />
			{/* <Item name={'moveChapter'} svg={<MoveToSvg />} text={'Move to'} /> */}
			<DeleteChapter menu={menu} setModal={setModal} />
		</>
	);
}

function OpenChapter({ menu }) {
	const history = useHistory();
	const { fullProfileURL } = useParams();
	const handleOnClick = () => {
		history.push(`/journey/${fullProfileURL}/chapter/${menu.id}`);
	};
	return <Item svg={<OpenFolderSvg />} text={'Open'} onClick={handleOnClick} />;
}

function RenameChapter({ menu, setModal }) {
	const handleOnClick = () => {
		setModal({
			isOpen: true,
			state: 'renameChapter',
			title: menu.title,
			id: menu.id,
		});
	};
	return <Item svg={<EditSvg />} text={'Rename'} onClick={handleOnClick} />;
}

function DeleteChapter({ menu, setModal }) {
	const handleOnClick = () => {
		setModal({
			isOpen: true,
			state: 'deleteChapter',
			title: menu.title,
			id: menu.id,
		});
	};
	return <Item svg={<DeleteSvg />} text={'Delete'} onClick={handleOnClick} />;
}

export default ChapterMenu;
