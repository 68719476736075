import React from 'react';
import styles from './Item.module.css';

function Item({ svg, text, onClick }) {
	return (
		<button type='button' className={styles.item} onMouseDown={onClick}>
			<div className={styles.itemSvg}>{svg}</div>
			<span className={styles.itemText}>{text}</span>
		</button>
	);
}

export default Item;
