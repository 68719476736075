import React, { useState } from 'react';
import styles from './Home.module.css';
import {
	useAutoExternalSignIn,
	useAutoExternalSignInRedirect,
	useGetLearningsByPopular,
	useGetLearningsByFollowing,
	useSetScrollToBottom,
	useGetLearningsByTag,
} from './hooks';
import Meta from './Meta';
import Sidebar from './Sidebar';
import LearningPanel from './LearningPanel';
import TagCloud from './TagCloud';
import useGetLearningsByLatest from './hooks/useGetLearningsByLatest';
import TagSearch from './TagSearch';
import About from './About';
import ScholarApplication from './ScholarApplication';
import ScholarPortal from './ScholarPortal';
import ScholarCertificate from './ScholarCertificate';
import VolunteeringCertificate from './VolunteeringCertificate';
import Resource from './Resource';
import Feedback from './Feedback';
import Landing from './Landing';

function Home({ state = 'landing' }) {
	const [learnings, setLearnings] = useState([]);
	// const [tagList, setTagList] = useState([]);
	const [error, setError] = useState('');
	const displayLearning =
		learnings.length !== 0 &&
		(state === 'popular' ||
			state === 'home' ||
			state === 'latest' ||
			state === 'tag');

	// Auto sign in hook for first time user using external account
	useAutoExternalSignIn();
	useAutoExternalSignInRedirect();

	// Hook for infinite scroll refetch
	const [isBottom, setIsBottom] = useState(false);
	useSetScrollToBottom({ active: learnings.length !== 0, setIsBottom });

	// Hooks to get learnings in Popular Page
	useGetLearningsByPopular({
		active: state === 'popular',
		isBottom,
		setIsBottom,
		setLearnings,
		setError,
	});

	// Hooks to get learnings in Home Page
	useGetLearningsByFollowing({
		active: state === 'home',
		setLearnings,
		setError,
	});

	// Hooks to get learnings in Latest Page
	useGetLearningsByLatest({
		active: state === 'latest',
		isBottom,
		setIsBottom,
		setLearnings,
		setError,
	});

	// Explore Page
	const displayTagCloud = state === 'explore';

	// Hooks to get learnings in Tag Page
	useGetLearningsByTag({
		active: state === 'tag',
		isBottom,
		setIsBottom,
		setLearnings,
		setError,
	});

	// TagSearch Page
	const displayTagSearch = state === 'tag';

	// About Page
	const displayAbout = state === 'about';

	// ScholarApplication & Scholar Page
	const displayScholarApplication = state === 'scholarApplication';
	const displayScholarPortal = state === 'scholarPortal';
	// ScholarCertificate & VolunteerCertificate
	const displayScholarCertificate = state === 'scholarCertificate';
	const displayVolunteerCertificate = state === 'volunteerCertificate';

	// Resource Page
	const displayResource = state === 'resource';

	// Feedback Page
	const displayFeedback = state === 'feedback';

	// Landing Page
	const displayLanding = state === 'landing';

	return (
		<div className={styles.home}>
			<Meta state={state} />
			{/* Landing Page */}
			{displayLanding && (
				<main className={styles.mainLanding}>
					<Landing />
				</main>
			)}
			{!displayLanding && (
				<>
					<main className={styles.main}>
						{error && <ErrorMsg error={error} />}
						{displayTagCloud && <TagCloud />}
						{displayTagSearch && <TagSearch />}
						{displayAbout && <About />}
						{displayScholarApplication && <ScholarApplication />}
						{displayScholarPortal && <ScholarPortal />}
						{displayScholarCertificate && <ScholarCertificate />}
						{displayVolunteerCertificate && <VolunteeringCertificate />}
						{displayResource && <Resource />}
						{displayFeedback && <Feedback />}
						{displayLearning &&
							learnings.map((item) => (
								<LearningPanel {...item} key={item.learningID} />
							))}
					</main>
					<Sidebar />
				</>
			)}
		</div>
	);
}

function ErrorMsg({ error }) {
	return (
		<div className={styles.error}>
			<h1 className={styles.errorH1}>{error}</h1>
		</div>
	);
}

export default Home;
