import React from 'react';
import styles from './Sidebar.module.css';
import classNames from 'classnames';
import {
	HomeSvg,
	ExploreSvg,
	PopularSvg,
	LatestSvg,
	BrowseSvg,
	StorySvg,
	ScholarCapSvg,
	ResourceSvg,
	FeedbackSvg,
} from 'icons';
import { Link } from 'react-router-dom';
import { useUser } from 'utils';

function Sidebar() {
	const [user] = useUser();
	const isScholar = user.scholar || user.scholarStatus === 'approved';
	return (
		<div className={styles.div}>
			<nav className={styles.nav}>
				<SidebarRow {...ROWS[0]} />
				<SidebarRow {...ROWS[1]} />
				<SidebarRow {...ROWS[2]} />
				<SidebarRow {...ROWS[3]} />
				<SidebarRow {...ROWS[4]} />
				<SidebarRow {...ROWS[5]} />
				{isScholar ? <ScholarPortalSidebarRow /> : <SidebarRow {...ROWS[6]} />}
				<SidebarRow {...ROWS[7]} />
				<SidebarRow {...ROWS[8]} lastRow={true} />
			</nav>
			<TikTokIntern />
		</div>
	);
}

const ROWS = [
	{ svg: <PopularSvg />, title: 'Popular', to: '/' },
	{ svg: <HomeSvg />, title: 'Following', to: '/home' },
	{ svg: <LatestSvg />, title: 'Latest', to: '/latest' },
	{ svg: <ExploreSvg />, title: 'Explore', to: '/explore' },
	{ svg: <BrowseSvg />, title: 'Tag Search', to: '/tag' },
	{ svg: <StorySvg />, title: 'About TipStory', to: '/about' },
	{
		svg: <ScholarCapSvg />,
		title: 'Scholar Application',
		to: '/scholar-application',
	},
	{ svg: <ResourceSvg />, title: 'Resource', to: '/resource' },
	{ svg: <FeedbackSvg />, title: 'Feedback', to: '/feedback' },
];

function SidebarRow({ svg, title, to, lastRow }) {
	return (
		<Link
			className={classNames(styles.navRow, { [styles.lastNavRow]: lastRow })}
			to={to}
		>
			<div className={styles.svg}>{svg}</div>
			{title}
		</Link>
	);
}

function ScholarPortalSidebarRow() {
	const [user] = useUser();
	const displayRedDot =
		(user.scholarStatus === 'approved' || user.scholar) && !user.scholarMeta;
	return (
		<Link className={styles.navRow} to={'/scholar'}>
			<div className={styles.svg}>
				<ScholarCapSvg />
			</div>
			<div>Scholar Portal</div>
			{!displayRedDot && <div className={styles.redDot} />}
		</Link>
	);
}

function TikTokIntern() {
	const currentTime = new Date();
	const endTime = new Date('2021-07-18T23:59:59.147Z');
	if (currentTime > endTime) return null;
	return (
		<a
			className={styles.tikTokIntern}
			href={
				'https://tipstory.breezy.hr/p/b8f74d1ed86f-tiktok-marketing-intern-remote'
			}
			target={'_blank'}
			rel={'noreferrer'}
		>
			📢 TikTok Marketing Intern Opportunity
		</a>
	);
}

export default Sidebar;
