import React, { useState } from 'react';
import styles from './ButtonGroup.module.css';
import PrimaryButton from 'components/Button';
import { useLogin, useUser } from 'utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { createLearning, updateLearning } from './utils';
import SaveDraft from './SaveDraft';

function ButtonGroup({ newLearning, setNewLearning, editMode }) {
	const text = !editMode ? 'Post Learning' : 'Update Learning';
	const [user, setUser] = useUser();
	const [, setLogin] = useLogin();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const history = useHistory();
	const params = queryString.parse(history.location.search);
	const withinChapterID = params.chapterID || 'home';

	const handleOnClick = async () => {
		// Return if user is not login
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'login' });
			return;
		}
		// Return if loading
		if (loading) return;

		// Return if title is empty
		if (newLearning.title.trim() === '') {
			setError('Empty title is not supported 😉');
			return;
		}

		setLoading(true);
		setError('');
		let success;
		// createLearning or updateLearning
		if (!editMode) {
			success = await createLearning({
				newLearning,
				withinChapterID,
				user,
				setUser,
				history,
			});
		} else {
			success = await updateLearning({
				newLearning,
				history,
				user,
			});
		}
		if (!success) {
			setLoading(false);
			setError(GENERAL_ERROR_MSG);
		}
	};

	return (
		<div className={styles.buttonGroup}>
			{editMode ? (
				<div />
			) : (
				<SaveDraft
					editMode={editMode}
					newLearning={newLearning}
					setNewLearning={setNewLearning}
					withinChapterID={withinChapterID}
				/>
			)}
			<div className={styles.postDiv}>
				{error && <div className={styles.error}>{error}</div>}
				<PrimaryButton
					children={text}
					loading={loading}
					onClick={handleOnClick}
				/>
			</div>
		</div>
	);
}

export default ButtonGroup;
