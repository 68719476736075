import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	Fragment,
} from 'react';
import ReactDOM from 'react-dom';
import isHotkey from 'is-hotkey';
import { ReactEditor, Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Range, Text } from 'slate';
import { withHistory } from 'slate-history';
import {
	BoldSvg,
	BulletListSvg,
	CodeBlockSvg,
	CodeSvg,
	CppSvg,
	CsharpSvg,
	CssSvg,
	CSvg,
	EditSvg,
	EmbedSvg,
	EmojiSvg,
	FileUploadSvg,
	FontSvg,
	GoSvg,
	HighlighterSvg,
	HtmlSvg,
	ImageSvg,
	ItalicSvg,
	JavascriptSvg,
	JavaSvg,
	JsonSvg,
	LinkSvg,
	MatlabSvg,
	NumberListSvg,
	ParagraphSvg,
	PhpSvg,
	PythonSvg,
	QuoteSvg,
	ReactSvg,
	RedoSvg,
	RSvg,
	RustSvg,
	SqlSvg,
	SwiftSvg,
	TypescriptSvg,
	UnderlineSvg,
	UndoSvg,
	UnlinkSvg,
	WebsiteSvg,
	YamlSvg,
} from 'icons';
import styles from './Editor.module.css';
import classNames from 'classnames';
import {
	colorPalette,
	emoji,
	isImageUrl,
	isReactElementNull,
	isUrl,
	useCloseDropdown,
	resizeAndUploadImage,
	useEnterCallback,
	getCodeSyntaxColor,
} from 'utils';
import PrimaryButton, { SvgButton } from 'components/Button';
import Embed from './Embed';
import Modal from './Modal';
import Image from './Image';
import CodeBlock from './CodeBlock';
import Prism from 'prismjs';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-matlab';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-r';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-rust';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-yaml';
import { LOCAL_STORAGE_EMOJI_HISTORY } from 'utils/constants';

Transforms.deselect = () => {};

const markHotkeys = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
	'mod+`': 'code',
};

const blockHotkeys = {
	'mod+k': 'link',
	'mod+shift+7': 'numberList',
	'mod+shift+8': 'bulletList',
	'mod+shift+9': 'blockquote',
};

// DefaultSelection
const defaultSelection = {
	anchor: {
		path: [0, 0],
		offset: 0,
	},
	focus: {
		path: [0, 0],
		offset: 0,
	},
};

// Set links to be inline block
const withLinks = (editor) => {
	const { isInline } = editor;
	editor.isInline = (element) => {
		if (element.type === 'link') return true;
		return isInline(element);
	};
	return editor;
};

const insertImage = (editor, link) => {
	Transforms.insertNodes(editor, {
		type: 'image',
		link: link,
		children: [{ text: '' }],
	});
};

// Add images by file paste or link paste
const withImages = (editor) => {
	const { insertData, isVoid } = editor;
	editor.isVoid = (element) => {
		if (element.type === 'image') return true;
		return isVoid(element);
	};

	editor.insertData = async (data) => {
		const text = data.getData('text/plain');
		const { files } = data;
		if (files && files.length > 0) {
			for (const file of files) {
				if (file.type && file.type.startsWith('image')) {
					const imgLink = await resizeAndUploadImage(file);
					if (imgLink) insertImage(editor, imgLink);
				}
			}
		} else if (isImageUrl(text)) {
			insertImage(editor, text);
		} else {
			insertData(data);
		}
	};

	return editor;
};

// Add embed by link paste
const withEmbeds = (editor) => {
	const { insertText, isVoid } = editor;
	editor.isVoid = (element) => {
		if (element.type === 'embed') return true;
		return isVoid(element);
	};
	editor.insertText = (text) => {
		if (text && isUrl(text)) {
			const Iframe = <Embed element={{ link: text }} />;
			const isIframeNull = isReactElementNull(Iframe);
			if (!isIframeNull) {
				Transforms.insertNodes(editor, {
					type: 'embed',
					link: text,
					children: [{ text: '' }],
				});
				Transforms.collapse(editor, { edge: 'end' });
				Transforms.insertNodes(editor, {
					type: 'paragraph',
					children: [{ text: '' }],
				});
			} else {
				insertText(text);
			}
		} else {
			insertText(text);
		}
	};
	return editor;
};

// Add codeBlock / blockquote by inserting text not inserting node
const withCodePaste = (editor) => {
	const { insertData } = editor;
	editor.insertData = (data) => {
		if (
			isBlockActive(editor, 'blockquote') ||
			isBlockActive(editor, 'codeBlock')
		) {
			const text = data.getData('text/plain');
			Transforms.insertText(editor, text);
		} else {
			insertData(data);
		}
	};
	return editor;
};

const getTokenLength = (token) => {
	if (typeof token === 'string') {
		return token.length;
	} else if (typeof token.content === 'string') {
		return token.content.length;
	} else {
		return token.content.reduce((l, t) => l + getTokenLength(t), 0);
	}
};

const insertNewParagraph = (editor) => {
	Transforms.collapse(editor, { edge: 'end' });
	Transforms.insertNodes(editor, {
		type: 'paragraph',
		children: [{ text: '' }],
	});
};

const initialValue = [
	{
		type: 'paragraph',
		children: [{ text: '' }],
	},
];

// TipStoryEditor (Disable React fast refresh)
// @refresh reset
const TipStoryEditor = ({
	value = initialValue,
	setValue = () => {},
	readOnly = false,
}) => {
	const renderElement = useCallback((props) => <Element {...props} />, []);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
	const editor = useMemo(
		() =>
			withCodePaste(
				withEmbeds(
					withImages(withLinks(withHistory(withReact(createEditor()))))
				)
			),
		[]
	);
	const [linkButtonOn, setLinkButtonOn] = useState(false);
	const toggleLink = () => {
		const isActive = isBlockActive(editor, 'link');
		if (isActive) {
			removeLink(editor);
		} else {
			setLinkButtonOn(true);
		}
	};
	const [modal, setModal] = useState({ isOpen: false, state: '' });

	// Use decorate to auto highligh codeBlock syntax
	const decorate = useCallback(
		([node, path]) => {
			const ranges = [];
			if (Text.isText(node)) {
				const [aboveNode] = Editor.above(editor, { at: path });
				if (aboveNode.type === 'codeBlock') {
					const language = aboveNode.language;
					const tokens = Prism.tokenize(node.text, Prism.languages[language]);
					let start = 0;
					for (const token of tokens) {
						const length = getTokenLength(token);
						const end = start + length;
						if (typeof token !== 'string') {
							ranges.push({
								[token.type]: true,
								anchor: { path, offset: start },
								focus: { path, offset: end },
							});
						}
						start = end;
					}
				}
			}
			return ranges;
		},
		[editor]
	);

	return (
		<>
			<Slate
				editor={editor}
				value={value}
				onChange={(value) => setValue(value)}
			>
				{/* Toolbar */}
				{!readOnly && (
					<>
						<div className={styles.toolbarDiv}>
							<IconButton format='bold' type='mark' title='Bold (Ctrl+B)' />
							<IconButton format='italic' type='mark' title='Italic (Ctrl+I)' />
							<IconButton
								format='underline'
								type='mark'
								title='Underline (Ctrl+U)'
							/>
							<IconButton format='fontColor' type='mark' title='Text Color' />
							<IconButton
								format='highlight'
								type='mark'
								title='Highlight Color'
							/>
							<IconButton format='code' type='mark' title='Code (Ctrl+`)' />
							<div className={styles.verticalDiv}></div>
							<IconButton
								format='link'
								type='block'
								title='Insert Link (Ctrl+K)'
								setLinkButtonOn={setLinkButtonOn}
							/>
							<IconButton format='emoji' type='insert' title='Add Emoji' />
							<IconButton
								format='image'
								type='insert'
								title='Add Image'
								setModal={setModal}
							/>
							<IconButton
								format='embed'
								type='insert'
								title='Embed Content'
								setModal={setModal}
							/>
							<div className={styles.verticalDiv}></div>
							<IconButton format='codeBlock' type='block' title='Code Block' />
							<IconButton
								format='blockquote'
								type='block'
								title='Quote Block (Ctrl+Shift+9)'
							/>
							<IconButton
								format='paragraph'
								type='insert'
								title='New Paragraph (Ctrl+Enter)'
							/>
							<div className={styles.verticalDiv}></div>
							<IconButton
								format='numberList'
								type='block'
								title='Number List (Ctrl+Shift+7)'
							/>
							<IconButton
								format='bulletList'
								type='block'
								title='Bullet List (Ctrl+Shift+8)'
							/>
							<div className={styles.verticalDiv}></div>
							<IconButton format='undo' type='insert' title='Undo (Ctrl+Z)' />
							<IconButton format='redo' type='insert' title='Redo (Ctrl+Y)' />
						</div>
						{/* Hovering Toolbar for Link */}
						<HoveringToolbar
							linkButtonOn={linkButtonOn}
							setLinkButtonOn={setLinkButtonOn}
						/>
						{/* Image/Embed Modal */}
						{modal.isOpen && <Modal modal={modal} setModal={setModal} />}
					</>
				)}
				{/* Editor Space */}
				<Editable
					readOnly={readOnly}
					className={styles.editorDiv}
					decorate={decorate}
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					placeholder={
						readOnly
							? ''
							: 'Write all the helpful or interesting things you have learned :)'
					}
					spellCheck={true}
					onKeyDown={(event) => {
						for (const hotkey in markHotkeys) {
							if (isHotkey(hotkey, event)) {
								event.preventDefault();
								const mark = markHotkeys[hotkey];
								toggleMark(editor, mark);
							}
						}
						for (const hotkey in blockHotkeys) {
							if (isHotkey(hotkey, event)) {
								event.preventDefault();
								const format = blockHotkeys[hotkey];
								if (format === 'link') toggleLink();
								else toggleBlock(editor, format);
							}
						}
						// Insert Tab
						if (event.key === 'Tab') {
							event.preventDefault();
							editor.insertText('\t');
						}
						// Insert new paragraph at embed/image block
						if (
							isHotkey('enter', event) &&
							(isBlockActive(editor, 'embed') || isBlockActive(editor, 'image'))
						) {
							event.preventDefault();
							insertNewParagraph(editor);
						}
						// Delete embed/image block
						if (
							event.key === 'Backspace' &&
							(isBlockActive(editor, 'embed') || isBlockActive(editor, 'image'))
						) {
							event.preventDefault();
							Transforms.removeNodes(editor);
						}
						if (
							isBlockActive(editor, 'codeBlock') ||
							isBlockActive(editor, 'blockquote')
						) {
							// Insert new line at codeBlock/blockquote
							if (isHotkey('enter', event)) {
								event.preventDefault();
								editor.insertText('\n');
							}
							// Select within block
							if (
								isHotkey('mod+a', event) &&
								Range.isCollapsed(editor.selection)
							) {
								event.preventDefault();
								selectAboveNode(editor, editor.selection);
							}
						}
						if (isHotkey('mod+enter', event)) {
							event.preventDefault();
							insertNewParagraph(editor);
						}
					}}
					onBlur={() => {
						editor.saveSelection = editor.selection;
					}}
				/>
			</Slate>
		</>
	);
};

// Block Operation
const LIST_TYPES = ['numberList', 'bulletList'];

const isBlockActive = (editor, format) => {
	const [match] = Editor.nodes(editor, {
		match: (n) => n.type === format,
	});
	return !!match;
};

const selectAboveNode = (editor, selection) => {
	const [, abovePath] = Editor.above(editor, selection);
	const points = Editor.edges(editor, abovePath);
	Transforms.select(editor, { anchor: points[0], focus: points[1] });
};

const toggleBlock = (editor, format, option) => {
	const isActive = isBlockActive(editor, format);

	Transforms.unwrapNodes(editor, {
		match: (n) => LIST_TYPES.includes(n.type),
		split: true,
	});

	if (!isActive) {
		switch (format) {
			case 'numberList':
			case 'bulletList':
				Transforms.setNodes(editor, { type: 'listItem' });
				Transforms.wrapNodes(editor, { type: format, children: [] });
				break;
			case 'blockquote':
				Transforms.collapse(editor, { edge: 'end' });
				selectAboveNode(editor, editor.selection);
				Transforms.setNodes(editor, { type: format });
				Transforms.setNodes(editor, { italic: true }, { match: Text.isText });
				Transforms.collapse(editor, { edge: 'end' });
				break;
			case 'codeBlock':
				if (option) {
					Transforms.setNodes(editor, { type: format, language: option });
					ReactEditor.focus(editor);
				}
				break;
			default:
				Transforms.setNodes(editor, { type: format });
		}
	} else {
		switch (format) {
			case 'blockquote':
				// Reset italic
				Transforms.collapse(editor, { edge: 'end' });
				selectAboveNode(editor, editor.selection);
				Transforms.setNodes(editor, { type: 'paragraph' });
				Transforms.setNodes(editor, { italic: null }, { match: Text.isText });
				Transforms.collapse(editor, { edge: 'end' });
				break;
			case 'codeBlock':
				if (option)
					Transforms.setNodes(editor, { type: format, language: option });
				else Transforms.setNodes(editor, { type: 'paragraph' });
				ReactEditor.focus(editor);
				break;
			default:
				Transforms.setNodes(editor, { type: 'paragraph' });
		}
	}
};

// Link Operations
const unwrapLink = (editor) => {
	Transforms.unwrapNodes(editor, {
		match: (n) => n.type === 'link',
	});
};

const wrapLink = (editor, text, link) => {
	if (editor.selection) {
		const active = isBlockActive(editor, 'link');
		if (active) unwrapLink(editor);
		const { selection } = editor;
		const isCollapsed = selection && Range.isCollapsed(selection);
		const linkObj = {
			type: 'link',
			link,
			children: isCollapsed
				? [{ text, underline: true, fontColor: '#4262ff' }]
				: [],
		};
		if (isCollapsed) {
			Transforms.insertNodes(editor, linkObj);
		} else {
			Editor.addMark(editor, 'underline', true);
			Editor.addMark(editor, 'fontColor', '#4262ff');
			Transforms.wrapNodes(editor, linkObj, { split: true });
		}
		Transforms.collapse(editor, { edge: 'end' });
		Transforms.move(editor, { distance: '1', unit: 'offset' });
		ReactEditor.focus(editor);
	}
};

const removeLink = (editor) => {
	const [parentNode, parentPath] = Editor.parent(editor, editor.selection);
	if (parentNode.type === 'link') {
		const points = Editor.edges(editor, parentPath);
		Transforms.select(editor, { anchor: points[0], focus: points[1] });
		Editor.removeMark(editor, 'underline');
		Editor.removeMark(editor, 'fontColor');
		unwrapLink(editor);
		Transforms.collapse(editor, { edge: 'end' });
		ReactEditor.focus(editor);
	}
};

const changeLink = (editor, link) => {
	const [parentNode, parentPath] = Editor.parent(editor, editor.selection);
	if (parentNode.type === 'link') {
		Transforms.setNodes(editor, { link }, { at: parentPath });
		Transforms.collapse(editor, { edge: 'end' });
		ReactEditor.focus(editor);
	}
};

// Mark Opeartion
const getMark = (editor, format) => {
	const marks = Editor.marks(editor);
	return marks && marks[format];
};

const isMarkActive = (editor, format) => {
	let marks;
	try {
		marks = Editor.marks(editor);
	} catch (error) {
		Transforms.setSelection(editor, {
			anchor: { path: [0, 0], offset: 0 },
			focus: { path: [0, 0], offset: 0 },
		});
		marks = Editor.marks(editor);
	}
	return marks && marks[format] ? true : false;
};

const toggleMark = (editor, format, value = true) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, value);
	}
};

const allMarks = ['bold', 'italic', 'underline', 'fontColor', 'highlight'];

const turnoffAllMarks = (editor) => {
	allMarks.forEach((format) => {
		if (isMarkActive(editor, format)) Editor.removeMark(editor, format);
	});
};

const Element = (props) => {
	const { attributes, children, element } = props;
	switch (element.type) {
		case 'blockquote':
			return <blockquote {...attributes}>{children}</blockquote>;
		case 'bulletList':
			return <ul {...attributes}>{children}</ul>;
		case 'listItem':
			return <li {...attributes}>{children}</li>;
		case 'numberList':
			return <ol {...attributes}>{children}</ol>;
		case 'link':
			return (
				<a
					{...attributes}
					href={element.link}
					target='_blank'
					rel='noopener noreferrer nofollow ugc'
				>
					{children}
				</a>
			);
		case 'embed':
			return <Embed {...props} />;
		case 'image':
			return <Image {...props} />;
		case 'codeBlock':
			return <CodeBlock {...props} />;
		case 'paragraph':
		default:
			return <p {...attributes}>{children}</p>;
	}
};

const Leaf = ({ attributes, children, leaf }) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	if (leaf.code) {
		children = (
			<code spellCheck={false} className={styles.code}>
				{children}
			</code>
		);
	}

	let style = {};
	if (leaf.fontColor) style.color = leaf.fontColor;

	const codeSyntaxColor = getCodeSyntaxColor(leaf);
	if (codeSyntaxColor) style.color = codeSyntaxColor;

	if (leaf.highlight) style.backgroundColor = leaf.highlight;

	return (
		<span {...attributes} style={style}>
			{children}
		</span>
	);
};

function IconButton({ format, type, setLinkButtonOn, setModal, ...props }) {
	const editor = useSlate();
	let active;
	switch (type) {
		case 'block':
			active = isBlockActive(editor, format);
			break;
		case 'mark':
			active = isMarkActive(editor, format);
			break;
		default:
			active = false;
	}

	let handleOnMouseDown = (event) => {
		event.preventDefault();
		if (type === 'block') toggleBlock(editor, format);
		else toggleMark(editor, format);
	};

	let renderSvg;
	switch (format) {
		case 'bold': // Mark
			renderSvg = <BoldSvg />;
			break;
		case 'italic': // Mark
			renderSvg = <ItalicSvg />;
			break;
		case 'underline': // Mark
			renderSvg = <UnderlineSvg />;
			break;
		case 'fontColor': // Mark
		case 'highlight': // Mark
			return <DropdownColor active={active} format={format} {...props} />;
		case 'code': // Mark
			renderSvg = <CodeSvg />;
			break;
		case 'link': // Block
			handleOnMouseDown = (event) => {
				event.preventDefault();
				if (active) {
					removeLink(editor);
				} else {
					setLinkButtonOn(true);
				}
			};
			renderSvg = <LinkSvg />;
			break;
		case 'emoji': // Insert
			return <DropdownEmoji {...props} />;
		case 'image': // Block/Insert
			return <DropdownImage setModal={setModal} {...props} />;
		case 'embed': // Block/Insert
			handleOnMouseDown = (event) => {
				event.preventDefault();
				setModal({ isOpen: true, state: 'embed' });
			};
			renderSvg = <EmbedSvg />;
			break;
		case 'codeBlock': // Block
			return <DropdownCodeBlock {...props} />;
		case 'blockquote': // Block
			renderSvg = <QuoteSvg />;
			break;
		case 'paragraph': // Insert
			handleOnMouseDown = (event) => {
				event.preventDefault();
				insertNewParagraph(editor);
			};
			renderSvg = <ParagraphSvg />;
			break;
		case 'numberList': // Block
			renderSvg = <NumberListSvg />;
			break;
		case 'bulletList': // Block
			renderSvg = <BulletListSvg />;
			break;
		case 'undo': //Insert
			handleOnMouseDown = (event) => {
				event.preventDefault();
				editor.undo();
			};
			renderSvg = <UndoSvg />;
			break;
		case 'redo': // Insert
			handleOnMouseDown = (event) => {
				event.preventDefault();
				editor.redo();
			};
			renderSvg = <RedoSvg />;
			break;
		default:
			renderSvg = null;
	}
	return (
		<div>
			<button
				type={'button'}
				className={classNames(styles.svgButton, {
					[styles.svgButtonActive]: active,
				})}
				onMouseDown={handleOnMouseDown}
				{...props}
			>
				{renderSvg}
			</button>
		</div>
	);
}

function DropdownColor({ active, format = 'fontColor', ...props }) {
	const editor = useSlate();
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });
	const itemOnMouseDown = (event, value) => {
		event.preventDefault();
		setIsOpen(false);
		Transforms.select(editor, editor.saveSelection || defaultSelection);
		if (value === 'none') Editor.removeMark(editor, format);
		else Editor.addMark(editor, format, value);
		ReactEditor.focus(editor);
	};

	let currentColor,
		style = {},
		svg;
	switch (format) {
		case 'fontColor':
			currentColor = getMark(editor, format);
			style = currentColor && { fill: currentColor };
			svg = <FontSvg />;
			break;
		case 'highlight':
			currentColor = getMark(editor, format);
			style = currentColor && {
				backgroundColor: currentColor,
			};
			svg = <HighlighterSvg />;
			break;
		default:
			break;
	}

	return (
		<div className={styles.dropdownDiv} ref={node}>
			<button
				type='button'
				style={style}
				className={classNames(styles.svgButton, {
					[styles.svgButtonActive]: active || isOpen,
				})}
				onMouseDown={() => setIsOpen((prev) => !prev)}
				{...props}
			>
				{svg}
			</button>
			<div
				className={classNames(styles.itemDiv, {
					[styles.itemDivOpen]: isOpen,
				})}
			>
				{colorPalette.map((item, idx) => (
					<button
						key={idx}
						type='button'
						style={{
							backgroundColor: item,
							color: item === currentColor ? '#ffffff' : item,
						}}
						className={styles.item}
						onMouseDown={(event) => itemOnMouseDown(event, item)}
					>
						✓
					</button>
				))}
				<button
					type='button'
					className={styles.topItem}
					onMouseDown={(event) => itemOnMouseDown(event, 'none')}
				>
					Default Color {!currentColor ? ' ✓' : ''}
				</button>
			</div>
		</div>
	);
}

function DropdownEmoji({ ...props }) {
	const editor = useSlate();
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });
	const [recentEmoji, setRecentEmoji] = useState(
		JSON.parse(localStorage.getItem(LOCAL_STORAGE_EMOJI_HISTORY)) || []
	);
	const itemOnMouseDown = (event, item) => {
		event.preventDefault();
		setIsOpen(false);
		Transforms.select(editor, editor.saveSelection || defaultSelection);
		Transforms.insertText(editor, item[0]);
		ReactEditor.focus(editor);
		setRecentEmoji((prev) => {
			const newList = [...prev];
			const existingIdx = newList.findIndex((i) => i[1] === item[1]);
			if (existingIdx > -1) newList.splice(existingIdx, 1);
			newList.unshift(item);
			localStorage.setItem(
				LOCAL_STORAGE_EMOJI_HISTORY,
				JSON.stringify(newList)
			);
			return newList;
		});
	};

	const OtherEmojis = useMemo(
		() => {
			return Object.keys(emoji).map((emojiKey) => {
				const emojiList = emoji[emojiKey];
				return (
					<Fragment key={emojiKey}>
						<div className={styles.emojiText}>{emojiKey}</div>
						{emojiList.map((item) => (
							<button
								type='button'
								key={item[1]}
								className={styles.item}
								onMouseDown={(event) => itemOnMouseDown(event, item)}
							>
								{item[0]}
							</button>
						))}
						<div className={styles.emojiDivider} />
					</Fragment>
				);
			});
		},
		// eslint-disable-next-line
		[]
	);

	return (
		<div className={styles.dropdownDiv} ref={node}>
			<button
				type='button'
				className={classNames(styles.svgButton, {
					[styles.svgButtonActive]: isOpen,
				})}
				onMouseDown={() => setIsOpen((prev) => !prev)}
				{...props}
			>
				<EmojiSvg />
			</button>
			<div
				className={classNames(styles.emojiDiv, {
					[styles.itemDivOpen]: isOpen,
				})}
			>
				<div className={styles.eachEmojiDiv}>
					{/* Recent Emojis */}
					{recentEmoji.length !== 0 && (
						<>
							<div className={styles.emojiText}>Recently Used</div>
							{recentEmoji.map((item) => (
								<button
									type='button'
									key={item[1]}
									className={styles.item}
									onMouseDown={(event) => itemOnMouseDown(event, item)}
								>
									{item[0]}
								</button>
							))}
							<div className={styles.emojiDivider} />
						</>
					)}
					{/* Other Emojis */}
					{OtherEmojis}
				</div>
			</div>
		</div>
	);
}

function DropdownImage({ setModal, ...props }) {
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });
	const itemOnMouseDown = (event) => {
		const name = event.currentTarget.name;
		event.preventDefault();
		setIsOpen(false);
		setModal({ isOpen: true, state: name });
	};
	return (
		<div className={styles.dropdownDiv} ref={node}>
			<button
				type='button'
				className={classNames(styles.svgButton, {
					[styles.svgButtonActive]: isOpen,
				})}
				onMouseDown={() => setIsOpen((prev) => !prev)}
				{...props}
			>
				<ImageSvg />
			</button>
			<div
				className={classNames(styles.imageDiv, {
					[styles.imageDivOpen]: isOpen,
				})}
			>
				<button
					name={'imageLink'}
					type='button'
					className={styles.imageItem}
					onMouseDown={itemOnMouseDown}
				>
					<LinkSvg className={styles.itemSvg} />
					<span className={styles.itemText}>By link</span>
				</button>
				<button
					name={'imageFile'}
					type='button'
					className={styles.imageItem}
					onMouseDown={itemOnMouseDown}
				>
					<FileUploadSvg className={styles.itemSvg} />
					<span className={styles.itemText}>From file</span>
				</button>
			</div>
		</div>
	);
}

const languageList = [
	{
		language: 'HTML',
		svg: <HtmlSvg className={styles.itemSvg} />,
		prismCode: 'html',
	},
	{
		language: 'CSS',
		svg: <CssSvg className={styles.itemSvg} />,
		prismCode: 'css',
	},
	{
		language: 'JavaScript',
		svg: <JavascriptSvg className={styles.itemSvg} />,
		prismCode: 'javascript',
	},
	{
		language: 'Python',
		svg: <PythonSvg className={styles.itemSvg} />,
		prismCode: 'python',
	},
	{
		language: 'MATLAB',
		svg: <MatlabSvg className={styles.itemSvg} />,
		prismCode: 'matlab',
	},
	{
		language: 'Java',
		svg: <JavaSvg className={styles.itemSvg} />,
		prismCode: 'java',
	},
	{
		language: 'C',
		svg: <CSvg className={styles.itemSvg} />,
		prismCode: 'c',
	},
	{
		language: 'C++',
		svg: <CppSvg className={styles.itemSvg} />,
		prismCode: 'cpp',
	},
	{
		language: 'C#',
		svg: <CsharpSvg className={styles.itemSvg} />,
		prismCode: 'csharp',
	},
	{
		language: 'Go',
		svg: <GoSvg className={styles.itemSvg} />,
		prismCode: 'go',
	},
	{
		language: 'JSON',
		svg: <JsonSvg className={styles.itemSvg} />,
		prismCode: 'json',
	},
	{
		language: 'PHP',
		svg: <PhpSvg className={styles.itemSvg} />,
		prismCode: 'php',
	},
	{
		language: 'R',
		svg: <RSvg className={styles.itemSvg} />,
		prismCode: 'r',
	},
	{
		language: 'React',
		svg: <ReactSvg className={styles.itemSvg} />,
		prismCode: 'jsx',
	},
	{
		language: 'Rust',
		svg: <RustSvg className={styles.itemSvg} />,
		prismCode: 'rust',
	},
	{
		language: 'SQL',
		svg: <SqlSvg className={styles.itemSvg} />,
		prismCode: 'sql',
	},
	{
		language: 'Swift',
		svg: <SwiftSvg className={styles.itemSvg} />,
		prismCode: 'swift',
	},
	{
		language: 'TypeScript',
		svg: <TypescriptSvg className={styles.itemSvg} />,
		prismCode: 'typescript',
	},
	{
		language: 'YAML',
		svg: <YamlSvg className={styles.itemSvg} />,
		prismCode: 'yaml',
	},
	{
		language: 'Other',
		svg: <></>,
		prismCode: 'clike',
	},
	{
		language: 'None',
		svg: <></>,
		prismCode: '',
	},
];

function DropdownCodeBlock({ ...props }) {
	const editor = useSlate();
	const active = isBlockActive(editor, 'codeBlock');
	let currentLangugage = '';
	if (active && Range.isCollapsed(editor.selection)) {
		const [aboveNode] = Editor.above(editor, editor.selection);
		if (aboveNode && aboveNode.type === 'codeBlock')
			currentLangugage = aboveNode.language;
	}
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });
	const itemOnMouseDown = (event) => {
		const prismCode = event.currentTarget.name;
		event.preventDefault();
		setIsOpen(false);
		toggleBlock(editor, 'codeBlock', prismCode);
		turnoffAllMarks(editor);
	};
	return (
		<div className={styles.dropdownDiv} ref={node}>
			<button
				type='button'
				className={classNames(styles.svgButton, {
					[styles.svgButtonActive]: active || isOpen,
				})}
				onMouseDown={() => setIsOpen((prev) => !prev)}
				{...props}
			>
				<CodeBlockSvg />
			</button>
			<div
				className={classNames(styles.imageDiv, {
					[styles.imageDivOpen]: isOpen,
				})}
			>
				<div className={styles.eachLanguageDiv}>
					{languageList.map((item) => (
						<button
							key={item.language}
							name={item.prismCode}
							type='button'
							className={styles.imageItem}
							onMouseDown={itemOnMouseDown}
						>
							{item.svg}
							<span className={styles.itemText}>
								{item.language}
								{currentLangugage === item.prismCode ? ' ✓' : ''}
							</span>
						</button>
					))}
				</div>
			</div>
		</div>
	);
}

function HoveringToolbar({ linkButtonOn, setLinkButtonOn }) {
	const hoverbarRef = useRef(null);
	const inputRef = useRef(null);
	const editor = useSlate();
	const [link, setLink] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [openMode, setOpenMode] = useState('');
	const [editOn, setEditOn] = useState(true);
	const handleOnChange = (event) => {
		const value = event.target.value;
		setLink(value);
	};

	// Open hover bar effect
	// 1. Open if linkButton is pressed (Add link or insert link)
	// 2. Open if stopping at link node
	useEffect(
		() => {
			const el = hoverbarRef.current;
			if (!el) return;
			if (!isOpen) {
				const setElLocation = () => {
					const domSelection = window.getSelection();
					const domRange = domSelection.getRangeAt(0);
					const rect = domRange.getBoundingClientRect();
					el.style.top = `${rect.bottom + window.pageYOffset}px`;
					el.style.left = `${rect.left + window.pageXOffset}px`;
				};
				// 1. Open if linkButton is clicked
				if (linkButtonOn) {
					setElLocation();
					setIsOpen(true);
					setOpenMode('linkButton');
					setEditOn(true);
					return;
				}
				// 2. Open if stopping at link node
				if (
					editor.selection &&
					ReactEditor.isFocused(editor) &&
					Range.isCollapsed(editor.selection) &&
					isBlockActive(editor, 'link')
				) {
					setElLocation();
					setIsOpen(true);
					setOpenMode('cursorDetection');
					const [parentNode] = Editor.parent(editor, editor.selection);
					if (parentNode.type === 'link') setLink(parentNode.link);
					setEditOn(false);
					return;
				}
			} else {
				if (
					editor.selection &&
					ReactEditor.isFocused(editor) &&
					Range.isCollapsed(editor.selection) &&
					!isBlockActive(editor, 'link') &&
					isOpen
				) {
					setIsOpen(false);
					removeHoverBar();
				}
			}
		},
		// eslint-disable-next-line
		[editor.selection, linkButtonOn]
	);

	// Effect 2. Close hover bar when click outside range
	const removeHoverBar = () => {
		const el = hoverbarRef.current;
		if (!el) return;
		setLinkButtonOn(false);
		el.removeAttribute('style');
		setLink('');
	};
	useCloseDropdown({
		node: hoverbarRef,
		isOpen,
		setIsOpen,
		callback: removeHoverBar,
	});

	const handleSaveOnClick = () => {
		if (editOn) {
			// Extract link and add it
			if (link === '') return;
			let newLink;
			if (link.startsWith('mailto:')) {
				newLink = link;
			} else if (!link.startsWith('http://') && !link.startsWith('https://')) {
				newLink = `https://${link}`;
			} else {
				newLink = link;
			}
			if (openMode === 'linkButton') wrapLink(editor, link, newLink);
			if (openMode === 'cursorDetection') changeLink(editor, newLink);
			// Turn off trigger before turning off open
			setIsOpen(false);
			removeHoverBar();
		}
	};

	useEnterCallback({ isOpen: isOpen, callback: handleSaveOnClick });

	const displayLink = link.length > 25 ? `${link.slice(0, 22)}...` : link;

	return ReactDOM.createPortal(
		<div ref={hoverbarRef} className={styles.hoverbar}>
			{openMode === 'cursorDetection' && editOn === false && (
				<div className={styles.linkViewDiv}>
					<div className={styles.displayFlexCenter}>
						<SvgButton children={<WebsiteSvg />} disable={true} title='Link' />
						<a
							href={link}
							target='_blank'
							rel='noopener noreferrer nofollow ugc'
							className={styles.linkA}
							title={link}
						>
							{displayLink}
						</a>
					</div>
					<div className={styles.displayFlexCenter}>
						<SvgButton
							children={<EditSvg />}
							title='Edit'
							onClick={() => {
								setEditOn(true);
							}}
						/>
						<SvgButton
							children={<UnlinkSvg />}
							title='Remove link'
							onClick={() => {
								removeLink(editor);
								setIsOpen(false);
								removeHoverBar();
							}}
						/>
					</div>
				</div>
			)}
			{editOn === true && (
				<div>
					<input
						type='text'
						placeholder='Enter a link'
						ref={inputRef}
						value={link}
						onChange={handleOnChange}
						className={styles.linkInput}
					/>
					<PrimaryButton
						onClick={handleSaveOnClick}
						className={styles.linkSaveButton}
						disable={link === ''}
					>
						Save
					</PrimaryButton>
				</div>
			)}
		</div>,
		document.body
	);
}

export default TipStoryEditor;
