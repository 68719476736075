import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

/**
 * saveNominator hook saves nominator's name
 */
const useSaveNominator = () => {
	const history = useHistory();
	const params = queryString.parse(history.location.search);
	const nominator = params.nominate;
	if (nominator) {
		localStorage.setItem(
			`TipStoryScholarNominator`,
			`Nominated by TipStory Scholar ${atob(nominator)}`
		);
		history.replace({
			search: '',
		});
	}
};

export default useSaveNominator;
