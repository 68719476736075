import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './EditProfileModal.module.css';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { timesSvg, profileSvg } from 'icons';
import { mutationUpdateUser } from 'graphql/customMutations';
import Resizer from 'react-image-file-resizer';
import { getNanoid, useEnterCallback, useUser } from 'utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import update from 'immutability-helper';
import PrimaryButton from 'components/Button';

function EditProfileModal({ isOpen, setIsOpen }) {
	const [user, setUser] = useUser();
	const [form, setForm] = useState({
		userID: user.userID,
		profileImg: user.profileImg || '',
		firstName: user.firstName || '',
		lastName: user.lastName || '',
		headline: user.headline || '',
		instagram: user.instagram || '',
		newImgBlob: '',
	});

	// Effects to revoke imgObject to avoid memory leaks at unmount
	const imgObject = useRef('');
	useEffect(() => {
		return () => {
			if (imgObject.current) URL.revokeObjectURL(imgObject.current);
		};
	}, []);
	// Close modal when press escape key
	const setIsOpenFalse = () => setIsOpen(false);
	useEnterCallback({ isOpen, callback: setIsOpenFalse, key: 'Escape' });

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const handleChange = async (e) => {
		const name = e.target.name;
		const value = e.target.value;
		if (name === 'profileImg') {
			const file = e.target.files[0];
			if (file) {
				// Resize image to 300 x 300 jpeg blob
				try {
					const newImgBlob = await resizeFile(file);
					// Revoke old objectURL before creating new one
					if (imgObject.current) URL.revokeObjectURL(imgObject.current);
					const objectURL = URL.createObjectURL(file);
					imgObject.current = objectURL;
					// Set newImgBlob
					setForm((prev) => update(prev, { newImgBlob: { $set: newImgBlob } }));
				} catch (error) {
					console.log('Error resizing image', error);
				}
			}
		} else {
			setForm((prev) => update(prev, { [name]: { $set: value } }));
		}
	};

	const handleSubmit = async () => {
		if (loading) return;
		if (form.firstName === '') {
			setError('Empty first name is not supported 😉');
			return;
		}
		if (form.lastName === '') {
			setError('Empty last name is not supported 😉');
			return;
		}
		setLoading(true);
		setError('');
		const success = await updateProfile({
			form,
			setError,
			setUser,
		});
		setLoading(false);
		if (success) setIsOpenFalse();
	};

	return ReactDOM.createPortal(
		<section className={styles.section}>
			<div className={styles.div}>
				<CloseButton setIsOpenFalse={setIsOpenFalse} />
				{error && <p className={styles.error}>{error}</p>}
				<ImgInput
					src={imgObject.current || form.profileImg || profileSvg}
					handleChange={handleChange}
				/>
				<Input
					title={'First Name'}
					name={'firstName'}
					value={form.firstName || ''}
					handleChange={handleChange}
				/>
				<Input
					title={'Last Name'}
					name={'lastName'}
					value={form.lastName || ''}
					handleChange={handleChange}
				/>
				<Input
					title={'Headline / Interests'}
					name={'headline'}
					value={form.headline || ''}
					handleChange={handleChange}
					autoFocus={true}
				/>
				<Input
					title={'Instagram Username'}
					name={'instagram'}
					value={form.instagram || ''}
					handleChange={handleChange}
				/>
				<div className={styles.submitDiv}>
					<PrimaryButton
						primary={false}
						children={'Cancel'}
						className={styles.cancelButton}
						onClick={setIsOpenFalse}
					/>
					<PrimaryButton
						children={'Save'}
						className={styles.saveButton}
						loading={loading}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</section>,
		document.body
	);
}

function CloseButton({ setIsOpenFalse }) {
	return (
		<button
			className={styles.closeButton}
			onClick={() => {
				setIsOpenFalse();
			}}
		>
			<img src={timesSvg} className={styles.closeSvg} alt={'close'} />
		</button>
	);
}

function ImgInput({ src, handleChange }) {
	const imageInputRef = useRef('');
	const title = 'Profile Image';
	const name = 'profileImg';
	return (
		<div className={styles.imgInput}>
			<label className={styles.imgLabel}>{title}</label>
			<input
				type='file'
				name={name}
				accept='image/*'
				ref={imageInputRef}
				onChange={handleChange}
				className={styles.imageInputRef}
			/>
			<button
				className={styles.imgButton}
				type='button'
				onClick={() => imageInputRef.current.click()}
			>
				<img src={src} className={styles.img} alt={'Profile'} />
			</button>
		</div>
	);
}

function Input({ title, name, value, handleChange, autoFocus = false }) {
	return (
		<div className={styles.inputDiv}>
			<label className={styles.label}>{title}</label>
			<input
				autoFocus={autoFocus}
				type='text'
				name={name}
				value={value}
				onChange={handleChange}
				className={styles.input}
			/>
		</div>
	);
}

const updateProfile = async ({ form, setError, setUser }) => {
	const { newImgBlob, ...input } = form;
	// If new image is added, upload file to bucket
	if (newImgBlob) {
		const newProfileImg = `profile_${getNanoid(10)}.jpeg`;
		try {
			await Storage.put(newProfileImg, newImgBlob, {
				acl: 'public-read',
				level: 'protected',
				contentType: 'image/jpeg',
			});
			// Get and
			const fullImgLink = await Storage.get(newProfileImg, {
				level: 'protected',
			});
			const idx = fullImgLink.indexOf(newProfileImg);
			const newProfileImgWithoutSignature = fullImgLink.slice(
				0,
				idx + newProfileImg.length
			);
			input.profileImg = newProfileImgWithoutSignature;
		} catch (error) {
			setError(GENERAL_ERROR_MSG);
			console.log('Error uploading profile image: ', error);
			return;
		}
		// Delete other profile files
		try {
			const profileFiles = await Storage.list('profile', {
				level: 'protected',
			});
			profileFiles.forEach(async (item) => {
				if (item.key !== newProfileImg) {
					try {
						Storage.remove(item.key, { level: 'protected' });
					} catch (error) {
						console.log(`Error deleting ${item.key}: `, error);
					}
				}
			});
		} catch (error) {
			console.log('Error listing all profile images: ', error);
		}
	}
	// Update user profile
	try {
		const newUserProfile = await API.graphql(
			graphqlOperation(mutationUpdateUser, { input })
		);
		const data = newUserProfile.data.updateUser;
		setUser((prev) => update(prev, { $merge: data }));
		return true;
	} catch (error) {
		setError(GENERAL_ERROR_MSG);
		console.log('Error updating profile', error);
		return;
	}
};

/**
 * Return a max size 300 x 300 jpeg image.
 *
 * @return {Blob}
 */
const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			300,
			300,
			'JPEG',
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			'blob'
		);
	});

export default EditProfileModal;
