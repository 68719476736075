import React from 'react';
import styles from './LinkInfo.module.css';
import { SOURCE_LIST } from '..';
import TextareaAutosize from 'react-textarea-autosize';
import update from 'immutability-helper';
import TextTruncate from 'react-text-truncate';
import { timesSvg } from 'icons';
import classNames from 'classnames';
import useGetLinkCard from './useGetLinkCard';

function LinkInfo({ newLearning, setNewLearning }) {
	const { source, linkInfo } = newLearning;

	// Hook to get linkCard info
	useGetLinkCard({ linkInfo, setNewLearning });

	const displayLinkInfo =
		source !== SOURCE_LIST[0] &&
		source !== SOURCE_LIST[1] &&
		source !== SOURCE_LIST[2];
	if (!displayLinkInfo) return null;

	const placeholderSource = source !== 'Other' ? `${source}` : `Source`;
	const placeholder = `${placeholderSource} Link (optional)`;

	const handleOnChange = async (event) => {
		const newLink = event.target.value.trim();
		setNewLearning((prev) =>
			update(prev, { linkInfo: { link: { $set: newLink } } })
		);
	};

	const { link } = linkInfo;

	return (
		<div className={styles.linkInfo}>
			<h1 className={styles.linkInfoH1}>
				Link <span className={styles.optional}> (optional)</span>
			</h1>
			<TextareaAutosize
				className={styles.linkInfoInput}
				autoComplete={'off'}
				placeholder={placeholder}
				maxLength={300}
				value={link}
				minRows={1}
				name={'link'}
				onChange={handleOnChange}
			/>
			<LinkCard linkInfo={linkInfo} setNewLearning={setNewLearning} />
		</div>
	);
}

function LinkCard({ linkInfo, setNewLearning }) {
	const {
		displayLinkCard,
		ogLink,
		videoLink,
		imageLink,
		title,
		description,
	} = linkInfo;
	if (!displayLinkCard) return null;

	const deleteLinkCard = () => {
		setNewLearning((prev) =>
			update(prev, {
				linkInfo: {
					$unset: [
						'displayLinkCard',
						'ogLink',
						'videoLink',
						'imageLink',
						'title',
						'description',
					],
				},
			})
		);
	};

	const displayVideo = !!videoLink;
	const displayImage = !displayVideo && imageLink;

	return (
		<div className={styles.linkCard}>
			<button
				type={'button'}
				className={styles.deleteButton}
				onClick={deleteLinkCard}
			>
				<img
					src={timesSvg}
					className={styles.deleteButtonSvg}
					alt='delete card'
				/>
			</button>
			{displayVideo && (
				<iframe
					title={title}
					src={videoLink}
					frameBorder={'0'}
					className={styles.linkCardEmbed}
					allowFullScreen={true}
				/>
			)}
			{displayImage && (
				<img src={imageLink} alt={title} className={styles.linkCardEmbed} />
			)}
			<div className={styles.linkCardTextDiv}>
				<TextTruncate
					line={1}
					element={'div'}
					text={ogLink}
					className={styles.linkCardLink}
				/>
				<TextTruncate
					line={2}
					element={'div'}
					text={title}
					className={classNames(styles.linkCardText, styles.bold)}
				/>
				<TextTruncate
					line={1}
					element={'div'}
					text={description}
					className={styles.linkCardText}
				/>
			</div>
		</div>
	);
}

export default LinkInfo;
