import { FolderSvg, NoteSvg } from 'icons';
import React from 'react';
import { getNanoid } from 'utils';
import Item from '../Item';

// New Learning, New Chapter
function JourneyMenu({ setModal, setLearnings }) {
	return (
		<>
			<NewLearning setLearnings={setLearnings} />
			<NewChapter setModal={setModal} />
		</>
	);
}

function NewLearning({ setLearnings }) {
	const handleOnClick = () => {
		setLearnings((prevLearnings) => {
			const newLearnings = [...prevLearnings];
			const randNum = Math.floor(Math.random() * 10000);
			newLearnings.push({
				learningID: getNanoid(),
				tag: 'TipStory',
				source: 'Learning Note',
				title: `Learning Note ${randNum}`,
				numWords: randNum,
			});
			return newLearnings;
		});
	};

	return (
		<Item
			name={'newLearning'}
			svg={<NoteSvg />}
			text={'New Learning'}
			onClick={handleOnClick}
		/>
	);
}

function NewChapter({ setModal }) {
	const handleOnClick = () => {
		setModal({ isOpen: true, state: 'newChapter' });
	};

	return (
		<Item
			name={'newChapter'}
			svg={<FolderSvg />}
			text={'New Chapter'}
			onClick={handleOnClick}
		/>
	);
}

export default JourneyMenu;
