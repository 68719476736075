import React from 'react';
import styles from './Resource.module.css';
import ResourcePanel from './ResourcePanel';
import opportuniteens from './Images/opportuniteens.jpg';
import theMedicaZone from './Images/theMedicaZone.jpg';
import striveForCollege from './Images/striveForCollege.png';
import upchieve from './Images/upchieve.png';
import roadToUni from './Images/roadToUni.png';
import roundPIer from './Images/roundPier.jpg';
import womenInUnion from './Images/womenInUnion.jpg';

const resources = [
	{
		name: 'OpportuniTeens',
		img: opportuniteens,
		description:
			'Complie and share fantastic opportunities for high school students, e.g. internship, volunteering, scholarship, and other enrichment programs',
		link: 'https://opportuniteens.org/opportunities/',
		tagList: ['Opportunity', 'Internship', 'Scholarship'],
	},
	{
		name: 'The Medica Zone',
		img: theMedicaZone,
		description:
			'A google classroom community that empowers students to be doctors by providing lessons on medical topics and hosting presentations of doctors',
		link: 'https://www.themedicazone.net/',
		tagList: ['Medicine', 'Learning Community'],
	},
	{
		name: 'Road to Uni',
		img: roadToUni,
		description:
			'An instagram page that shares helpful information, advice and scholarships to support students navigate the college admissions process',
		link: 'https://www.instagram.com/road_to_uni/',
		tagList: ['College Application', 'Scholarship'],
	},
	{
		name: 'RoundPier',
		img: roundPIer,
		description:
			'A community platform and resource center for students to discover extracurricular activities, projects, competitions, internships and more',
		link: 'https://www.roundpier.com/',
		tagList: ['Extracurricular Activity', 'Opportunity'],
	},
	{
		name: 'Strive For College',
		img: striveForCollege,
		description:
			'Provide students with free 1-to-1 mentoring and help students navigate college application, financial aid, jobs and career',
		link: 'https://striveforcollege.org/',
		tagList: ['College Counseling', 'Career'],
	},
	{
		name: 'UPchieve',
		img: upchieve,
		description:
			'Provide students with free 24/7 1-to-1 tutoring on 15+ STEM courses',
		link: 'https://upchieve.org/',
		tagList: ['Tutoring', 'STEM'],
	},
	{
		name: 'Women in Union',
		img: womenInUnion,
		description:
			'A student run instagram page that shares knowledges and empowers women',
		link: 'https://www.instagram.com/womeninunion/',
		tagList: ['Community', 'Women'],
	},
	// {
	// 	name: 'Daily Dose',
	// 	img: dailyDose,
	// 	description:
	// 		'A student run instagram page that shares knowledges on mental health',
	// 	link: 'https://www.instagram.com/a.dailydoseofeverything/',
	// 	tagList: ['Mental Health'],
	// },
	// {
	// 	name: 'Eclipse Generation',
	// 	img: eclipseGeneration,
	// 	description:
	// 		'A student run instagram page that promotes college, careers and financial literacy',
	// 	link: 'https://www.instagram.com/eclipsegenerationinc/',
	// 	tagList: ['College', 'Career'],
	// },
	// {
	// 	name: 'Career Bound',
	// 	img: careerBound,
	// 	description:
	// 		'Provide students with opportunities to discover potential career paths via workshops, webinars and more',
	// 	link: 'https://www.instagram.com/career_.bound',
	// 	tagList: ['Career'],
	// },
	// {
	// 	name: 'Gen Z: We Are The Future',
	// 	img: genZ,
	// 	description:
	// 		'Inspire and educate students to work towards a more united future and solve global issues',
	// 	link: 'https://www.instagram.com/genzwearethefuture',
	// 	tagList: ['Education'],
	// },
];

function Resource() {
	return (
		<div className={styles.resource}>
			<div className={styles.resourcePanelDiv}>
				{resources.map((item, idx) => (
					<ResourcePanel key={idx} resource={item} />
				))}
			</div>
			<PartnershipText />
		</div>
	);
}

function PartnershipText() {
	return (
		<div className={styles.partnershipText}>
			<p className={styles.p}>
				❤️TipStory is committed to support students and make them aware of many
				awesome resources avaliable. If your organziation supports students,
				please contact{' '}
				<a href={'mailto:partnership@tipstory.org'} className={styles.link}>
					partnership@tipstory.org
				</a>{' '}
				and we will include your offer in this page🤝
			</p>
		</div>
	);
}

export default Resource;
