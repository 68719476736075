import {
	ScholarBronzeSvg,
	ScholarGoldSvg,
	ScholarSilverSvg,
	ScholarSvg,
} from 'icons';
import { BADGE_TYPE } from '.';
import styles from './ScholarBadge.module.css';
import cx from 'classnames';
function ScholarBadge({ width = 33, type, year, className }) {
	if (type === BADGE_TYPE.BEGINNER) width = width * 0.9;
	const height = width;
	return (
		<div
			className={cx(styles.scholarBadge, className)}
			title={getTitle(type, year)}
			style={{ width, height }}
		>
			<Badge width={width} height={height} type={type} />
			<Year
				width={width}
				height={height}
				type={type}
				year={year}
				className={styles.year}
			/>
		</div>
	);
}

const getTitle = (type, year) => {
	const capitalize = (str) =>
		`${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`;
	if (type === BADGE_TYPE.BEGINNER) return 'TipStory Scholar';
	else {
		return `${year} TipStory Scholar (${capitalize(type)} Honor)`;
	}
};

function Year(props) {
	let { width, height, type, year, className } = props;
	if (!year) return null;
	switch (type) {
		case BADGE_TYPE.BRONZE: {
			className = cx(styles.bronzeYear, className);
			break;
		}
		case BADGE_TYPE.SILVER: {
			className = cx(styles.silverYear, className);
			break;
		}
		case BADGE_TYPE.GOLD: {
			className = cx(styles.goldYear, className);
			break;
		}
		default: {
			return null;
		}
	}
	width = width + 1;
	height = height * 0.42;
	if (width <= 21) {
		year = year.slice(2);
	}
	return (
		<svg width={width} height={height} className={className}>
			<text x='50%' y='50%' textAnchor='middle'>
				{year}
			</text>
		</svg>
	);
}

function Badge(props) {
	const { width, type, className } = props;
	switch (type) {
		case BADGE_TYPE.BRONZE: {
			return (
				<ScholarBronzeSvg
					width={width}
					height={width}
					className={cx(styles.bronze, className)}
				/>
			);
		}
		case BADGE_TYPE.SILVER: {
			return (
				<ScholarSilverSvg
					width={width}
					height={width}
					className={cx(styles.silver, className)}
				/>
			);
		}
		case BADGE_TYPE.GOLD: {
			return (
				<ScholarGoldSvg
					width={width}
					height={width}
					className={cx(styles.gold, className)}
				/>
			);
		}
		default: {
			return (
				<ScholarSvg
					width={width}
					height={width}
					className={cx(styles.beginner, className)}
				/>
			);
		}
	}
}

export default ScholarBadge;
