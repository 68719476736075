import {
	FacebookSocialSvg,
	InstagramSocialSvg,
	TiktokSocialSvg,
	TwitterSocialSvg,
	LinkedInSocialSvg,
	DownSvg,
} from 'icons';
import React, { useState } from 'react';
import styles from './OneTimeTask.module.css';
import classNames from 'classnames';
import { getProfileLink, useUser } from 'utils';
import PrimaryButton from 'components/Button';
import TaskCheckbox from '../TaskCheckbox';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationUpdateUser } from 'graphql/customMutations';
import update from 'immutability-helper';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { getScholarMeta } from '../utils';

function OneTimeTask() {
	const [user, setUser] = useUser();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	// Hide content by default if scholarMeta.oneTimeTaskDone is true
	const scholarMeta = getScholarMeta(user);
	const [hideContent, setHideContent] = useState(scholarMeta.oneTimeTaskDone);

	// 4 one time tasks to keep track of
	const updateProfileChecked = checkUpdateProfile(user.profileImg);
	const updateHeadlineChecked = !!user.headline;
	const [iconClicks, setIconClicks] = useState({});
	const numIconClicks = Object.keys(iconClicks).length;
	const checkoutSocialMediaChecked =
		numIconClicks >= 3 ||
		!!localStorage.getItem(`${user.userID}-checkoutSocialMedia`);
	if (numIconClicks === 3) {
		localStorage.setItem(`${user.userID}-checkoutSocialMedia`, 'done');
	}
	const [isNominateClicked, setIsNominateClicked] = useState(false);
	const shareWithFriendsChecked =
		isNominateClicked ||
		!!localStorage.getItem(`${user.userID}-shareWithFriends`);
	const done =
		updateProfileChecked &&
		updateHeadlineChecked &&
		checkoutSocialMediaChecked &&
		shareWithFriendsChecked;

	const buttonDisable = !done || scholarMeta.oneTimeTaskDone;

	const handleDone = async () => {
		if (buttonDisable) return;
		if (loading) return;
		setLoading(true);
		setError('');
		try {
			// 1. Update profile with scholarMeta
			const scholarMeta = {
				oneTimeTaskDone: true,
			};
			const userInput = {
				userID: user.userID,
				scholarMeta: JSON.stringify(scholarMeta),
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input: userInput })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
			localStorage.removeItem(`${user.userID}-checkoutSocialMedia`);
			localStorage.removeItem(`${user.userID}-shareWithFriends`);
		} catch (error) {
			console.log('Error: ', error);
			setError(GENERAL_ERROR_MSG);
		}
		setLoading(false);
	};

	return (
		<div className={styles.oneTimeTask}>
			<div className={styles.h1Div}>
				<h1 className={styles.h1}>One Time Task</h1>
				<button
					type={'button'}
					className={styles.arrowSvgButton}
					onClick={() => setHideContent((prev) => !prev)}
				>
					<DownSvg className={styles.arrowSvg} />
				</button>
			</div>
			{!hideContent && (
				<div className={styles.contentDiv}>
					<UpdateProfile
						checked={updateProfileChecked || scholarMeta.oneTimeTaskDone}
					/>
					<UpdateHeadline
						checked={updateHeadlineChecked || scholarMeta.oneTimeTaskDone}
					/>
					<CheckoutSocialMedia
						iconClicks={iconClicks}
						setIconClicks={setIconClicks}
						checked={checkoutSocialMediaChecked || scholarMeta.oneTimeTaskDone}
					/>
					<ShareWithFriends
						checked={shareWithFriendsChecked || scholarMeta.oneTimeTaskDone}
						setIsNominateClicked={setIsNominateClicked}
					/>
					<MissionAccomplished
						checked={scholarMeta.oneTimeTaskDone}
						error={error}
						children={
							<div className={styles.missionAccomplishedButton}>
								<PrimaryButton
									children={'Mission Accomplished😎'}
									disable={buttonDisable}
									loading={loading}
									onClick={handleDone}
								/>
								{!buttonDisable && (
									<div className={styles.missionAccomplishedButtonDot} />
								)}
							</div>
						}
					/>
				</div>
			)}
		</div>
	);
}

const checkUpdateProfile = (link) => {
	if (link && link.startsWith('https://tipstory')) return true;
	return false;
};

function UpdateProfile({ checked = false }) {
	const [user] = useUser();
	const { profileURL, profileURLIdx } = user;
	const profileLink = getProfileLink(profileURL, profileURLIdx);
	return (
		<div className={styles.taskDiv}>
			<TaskCheckbox checked={checked} />
			<div className={styles.taskRightDiv}>
				<div className={classNames({ [styles.checkedTask]: checked })}>
					Update profile picture in{' '}
					<a
						href={profileLink}
						className={styles.link}
						target='_blank'
						rel='noreferrer'
					>
						profile page
					</a>
				</div>
				<blockquote className={styles.blockquote}>
					Your profile picture would appear in the certificate👨‍🎓👩‍🎓 It also helps
					us recognize you if we repost your learnings on social media
				</blockquote>
			</div>
		</div>
	);
}

function UpdateHeadline({ checked = false }) {
	const [user] = useUser();
	const { profileURL, profileURLIdx } = user;
	const profileLink = getProfileLink(profileURL, profileURLIdx);
	return (
		<div className={styles.taskDiv}>
			<TaskCheckbox checked={checked} />
			<div className={styles.taskRightDiv}>
				<div className={classNames({ [styles.checkedTask]: checked })}>
					Update headline / interests in{' '}
					<a
						href={profileLink}
						className={styles.link}
						target='_blank'
						rel='noreferrer'
					>
						profile page
					</a>
				</div>
				<blockquote className={styles.blockquote}>
					Adding your interests helps others learn more about you📚🔢🔬🌎💻
				</blockquote>
			</div>
		</div>
	);
}

function CheckoutSocialMedia({ iconClicks, setIconClicks, checked }) {
	return (
		<div className={styles.taskDiv}>
			<TaskCheckbox checked={checked} />
			<div className={styles.taskRightDiv}>
				<div className={classNames({ [styles.checkedTask]: checked })}>
					Check out 3 TipStory social media pages (click the icons below)
				</div>
				<div className={styles.social}>
					{socialIcons.map(({ name, svg, link }) => (
						<button
							key={name}
							className={classNames(styles.svgLink, {
								[styles.svgLinkActive]: iconClicks[name],
							})}
							onClick={() => {
								setIconClicks((prev) => ({ ...prev, [name]: true }));
								window.open(link, '_blank', 'noreferrer');
							}}
						>
							{svg}
						</button>
					))}
				</div>
				<blockquote className={styles.blockquote}>
					We would love your support and a +1 to our followers❤️💙 And you won't
					miss our latest updates and announcements
				</blockquote>
			</div>
		</div>
	);
}

const validateEmail = (email) => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
};

function ShareWithFriends({ checked = false, setIsNominateClicked }) {
	const [user] = useUser();
	const [form, setForm] = useState({});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [msg, setMsg] = useState('');

	const handleOnClick = async () => {
		// Return if loading
		if (loading) return;
		setMsg('');
		// Check form data
		if (!form.name) {
			setError("Please enter your friend's name");
			return;
		}
		if (!form.email) {
			setError("Please enter your friend's email");
			return;
		}
		if (!validateEmail(form.email)) {
			setError('Please enter a valid email');
			return;
		}
		if (!form.funFact) {
			setError('Please enter a fun fact about your friend');
			return;
		}
		setLoading(true);
		setError('');

		try {
			const data = JSON.stringify({
				scholarFirstName: user.firstName,
				scholarLastName: user.lastName,
				scholarProfile: `www.tipstory.org${getProfileLink(
					user.profileURL,
					user.profileURLIdx
				)}`,
				scholarProfileImg: user.profileImg,
				friendName: form.name,
				friendEmail: form.email,
				funFact: form.funFact,
			});
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'text/plain',
				},
				body: data,
				redirect: 'follow',
			};

			const rawRes = await fetch(
				'https://script.google.com/macros/s/AKfycbySphuzD-QzLD1INg0ihUJI6DwswNTn6nksK-YuY0E-Py9IXbmj01f7qgf9Pd844cmtZg/exec',
				requestOptions
			);
			const res = await rawRes.json();
			if (res.success) {
				localStorage.setItem(`${user.userID}-shareWithFriends`, 'done');
				setMsg(
					`Nominated successfully, thank you! We will send an invitation to ${form.name} soon😉`
				);
				setForm({});
			}
		} catch (error) {
			console.log('Error: ', error);
		}
		setLoading(false);
		setIsNominateClicked(true);
	};

	const handleChange = async (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setForm((prev) => update(prev, { [name]: { $set: value } }));
	};

	return (
		<div className={styles.taskDiv}>
			<TaskCheckbox checked={checked} />
			<div className={styles.taskRightDiv}>
				<div className={classNames({ [styles.checkedTask]: checked })}>
					Nominate a friend to be a TipStory Scholar
				</div>
				<blockquote className={styles.blockquote}>
					It is more fun with friends👯‍♂️👯‍♀️ Plus, you can motivate each other to
					learn, share, and earn the honorary certificate & badge
				</blockquote>
				<div className={styles.nominateForm}>
					<div className={styles.formDiv}>
						<Input
							title={"Friend's Name"}
							name={'name'}
							value={form.name || ''}
							handleChange={handleChange}
						/>
						<Input
							title={"Friend's Email"}
							name={'email'}
							value={form.email || ''}
							handleChange={handleChange}
						/>
						<Input
							title={'Fun Fact(s) about Your Friend'}
							name={'funFact'}
							value={form.funFact || ''}
							handleChange={handleChange}
						/>
						<div className={styles.buttonDiv}>
							<PrimaryButton onClick={handleOnClick} loading={loading}>
								Nominate
							</PrimaryButton>
						</div>
						{msg && <p className={styles.msg}>{msg}</p>}
						{error && <p className={styles.error}>{error}</p>}
					</div>
				</div>
			</div>
		</div>
	);
}

function Input({ title, name, value, handleChange }) {
	return (
		<div className={styles.inputDiv}>
			<label className={styles.label}>{title}</label>
			<input
				type='text'
				name={name}
				value={value}
				onChange={handleChange}
				className={styles.input}
				autoComplete={'off'}
			/>
		</div>
	);
}

function MissionAccomplished({ checked = false, children }) {
	return (
		<div className={styles.taskDiv}>
			<TaskCheckbox checked={checked} />
			<div className={styles.taskRightDiv}>
				<div className={classNames({ [styles.checkedTask]: checked })}>
					Click 'Mission Accomplished' button and unlock the rest of the portal
				</div>
				{children}
			</div>
		</div>
	);
}

const socialIcons = [
	{
		name: 'instagram',
		svg: <InstagramSocialSvg />,
		link: 'https://www.instagram.com/tipstorylearn',
	},
	{
		name: 'tiktok',
		svg: <TiktokSocialSvg />,
		link: 'https://www.tiktok.com/@tipstorylearn',
	},
	{
		name: 'linkedin',
		svg: <LinkedInSocialSvg />,
		link: 'https://www.linkedin.com/company/tipstory',
	},
	{
		name: 'twitter',
		svg: <TwitterSocialSvg />,
		link: 'https://twitter.com/TipStoryLearn',
	},
	{
		name: 'facebook',
		svg: <FacebookSocialSvg />,
		link: 'https://www.facebook.com/tipstorylearn',
	},
];
export default OneTimeTask;
