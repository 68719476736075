import React from 'react';
import { getFormatDate } from 'utils';
import {
	Page,
	Text,
	View,
	Document,
	Image,
	Link,
	Font,
} from '@react-pdf/renderer';
import tipStoryLogoImg from '../assets/TipStoryLogo.png';
import signatureImg from '../assets/Signature.png';
import milliardFont from '../assets/MilliardBook.woff';
import milliardBoldFont from '../assets/MilliardBold.woff';
Font.register({
	family: 'Milliard',
	fonts: [{ src: milliardFont }, { src: milliardBoldFont, fontWeight: 700 }],
});

const date = new Date().toDateString().split(' ');
const dateText = `${date[1]} ${date[2]}, ${date[3]}\n`;

const introText = `
To whom it may concern:

TipStory is the first collective learning community for students to learn and share learnings with each other. Our mission is to make learning and guidance accessible to every student through peer sharing and support.

`;

const linkBreakText = `
`;

const signatureText = `Xitang Zhao
Founder & Head of community`;

// Create Certificate
function TipStoryCertificate({
	scholar,
	scholarLearnings,
	totalVolunteerHours = 0,
	year,
}) {
	const { firstName, lastName, userID } = scholar;
	const numLearnings = scholarLearnings.length;

	const vertificationLink = `www.tipstory.org/scholar/volunteer/${year}/${userID}`;
	return (
		<Document
			title={'TipStory Scholar Volunteering Certificate'}
			author={'TipStory'}
			creator={'TipStory'}
			producer={'TipStory'}
			pdfVersion={'1.0'}
		>
			<Page
				style={{
					border: '5px solid rgb(5, 151, 242)',
					padding: '20px 55px',
					fontSize: '11pt',
					fontFamily: 'Milliard',
				}}
			>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						paddingBottom: '10px',
					}}
				>
					<Image style={{ width: '150px' }} src={tipStoryLogoImg} />
				</View>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						paddingBottom: '10px',
						borderBottom: '5px solid rgb(5, 151, 242)',
					}}
				>
					<Text
						style={{
							fontSize: '20pt',
							color: 'rgb(5, 151, 242)',
							fontWeight: 'bold',
						}}
					>
						TipStory Scholar Volunteering Certificate
					</Text>
				</View>

				<View
					style={{
						marginTop: '20px',
					}}
				>
					<Text>{dateText}</Text>
					<Text>{introText}</Text>
					<Text>
						{firstName} {lastName} has been an active scholar member at TipStory
						and volunteered a total of {totalVolunteerHours} hours in {year}.{' '}
						{firstName} was a tremendous help to students in the community by
						providing constructive feedback and suggestions to their writings.
						Additionally, {firstName} wrote and shared a total of {numLearnings}{' '}
						helpful learning posts for other students to learn from, as listed
						below:
					</Text>
					<Text>{linkBreakText}</Text>
					{scholarLearnings.map((item, idx) => (
						<LearningPost learning={item} idx={idx} key={item.learningID} />
					))}
					<Text>
						We are truly grateful for {firstName}’s outstanding contributions of
						sharing knowledge and supporting other students in TipStory, and
						would welcome {firstName} back anytime.
					</Text>
					<Text>{linkBreakText}</Text>
					<Text>
						If you have any questions, please feel free to contact us at{' '}
						<Link
							src={'mailto:scholar@tipstory.org'}
							style={{ textDecoration: 'underline' }}
						>
							scholar@tipstory.org
						</Link>
						.
					</Text>
				</View>
				<View
					style={{
						marginTop: '20px',
					}}
				>
					<Text>Sincerely,</Text>
					<Image
						style={{ width: '75px', marginTop: '5px', marginBottom: '5px' }}
						src={signatureImg}
					/>
					<Text>{signatureText}</Text>
					<Text>{linkBreakText}</Text>
					<Text>{linkBreakText}</Text>
					<Text>This certificate can be verified at:</Text>
					<Text>
						<Link src={vertificationLink}>{vertificationLink}</Link>
					</Text>
				</View>
			</Page>
		</Document>
	);
}

// Certificate LearningPost
function LearningPost({ learning, idx }) {
	const { learningID, title, numWords, createdAt } = learning;
	const formatDate = getFormatDate(createdAt).split(',')[0];
	const link = `www.tipstory.org/learning/${learningID}`;
	return (
		<View
			style={{
				marginBottom: '7px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'flex-start',
			}}
		>
			<View style={{ width: '20px' }}>
				<Text>{idx + 1}.</Text>
			</View>
			<View>
				<Text style={{ fontWeight: 'bold' }}>{title}</Text>
				<Text>
					{formatDate} · {numWords} words · <Link src={link}>{link}</Link>
				</Text>
			</View>
		</View>
	);
}

export default TipStoryCertificate;
