import React from 'react';
import { TaskCheckedSvg, TaskUncheckedSvg } from 'icons';
import styles from './TaskCheckbox.module.css';

function TaskCheckbox({ checked = false }) {
	if (checked)
		return (
			<div className={styles.svgDiv}>
				<TaskCheckedSvg className={`${styles.svg} ${styles.svgChecked}`} />
			</div>
		);
	return (
		<div className={styles.svgDiv}>
			<TaskUncheckedSvg className={`${styles.svg} ${styles.svgUnchecked}`} />
		</div>
	);
}

export default TaskCheckbox;
