import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationCreateLearning,
	mutationUpdateUser,
	mutationCreateTag,
	mutationDeleteLearning,
} from 'graphql/customMutations';
import {
	getNanoid,
	getNewOrder,
	getTagList,
	getTextFromSlateObj,
	getWordCount,
} from 'utils';
import update from 'immutability-helper';

const createLearning = async ({
	newLearning,
	withinChapterID,
	user,
	setUser,
	history,
}) => {
	const learningID = getNanoid();
	const {
		source,
		linkInfo,
		tag,
		title,
		description,
		learning,
		learningID: draftLearningID,
	} = newLearning;
	// 1. Create learning
	try {
		const learningText = getTextFromSlateObj(learning);
		const learningInput = {
			learningID,
			source,
			linkInfo: JSON.stringify(linkInfo),
			tag,
			title,
			description,
			learning: JSON.stringify(learning),
			learningBrief: learningText.slice(0, 500),
			userID: user.userID,
			withinChapterID,
			numWords: getWordCount(learningText),
			type: 'learning',
		};
		await API.graphql(
			graphqlOperation(mutationCreateLearning, { input: learningInput })
		);
	} catch (error) {
		console.log('Error creating learning: ', error);
		return;
	}

	// 2. Add learning to learningOrder
	const [learningOrder, success] = getNewOrder(
		user.learningOrder,
		withinChapterID,
		learningID
	);
	if (success) {
		try {
			const userInput = {
				userID: user.userID,
				learningOrder,
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input: userInput })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log('Error updating order array: ', error);
			// Currently, don't handle order array failure
		}
	}

	// 3. Create tags
	const tagList = getTagList(tag);
	if (tagList.length > 0) {
		tagList.forEach(async (tag, idx) => {
			try {
				const tagInput = {
					learningID,
					tag: tag.toLowerCase(),
					userID: user.userID,
				};
				if (idx === 0) tagInput.type = 'firstTag';
				await API.graphql(
					graphqlOperation(mutationCreateTag, { input: tagInput })
				);
			} catch (error) {
				console.log('Error creating tag: ', tag);
			}
		});
	}

	// 4. Delete draft
	if (draftLearningID && draftLearningID.startsWith('draft-new')) {
		try {
			const input = {
				learningID: draftLearningID,
			};
			await API.graphql(graphqlOperation(mutationDeleteLearning, { input }));
		} catch (error) {
			console.log('Error deleting draft: ', error);
		}
	}

	// 5. Go to learning
	const learningLink = `/learning/${learningID}`;
	history.push(learningLink);
	return true;
};

export default createLearning;
