import React from 'react';
import styles from './ProfileButton.module.css';
import { profileSvg } from 'icons';
import { getProfileLink, useUser } from 'utils';
import { Link } from 'react-router-dom';

function ProfileButton() {
	const [user] = useUser();
	const profileLink = getProfileLink(user.profileURL, user.profileURLIdx);

	return (
		<Link to={profileLink} className={styles.link}>
			<img
				src={user.profileImg || profileSvg}
				className={styles.img}
				alt={'Profile'}
			/>
		</Link>
	);
}

export default ProfileButton;
