import React from 'react';
import styles from './IconGroup.module.css';
import {
	TipSvg,
	ReadActiveSvg,
	HelpfulActiveSvg,
	InterestingActiveSvg,
} from 'icons';
import classNames from 'classnames';

function IconGroup() {
	const numLearning = 327;
	const numReadMark = 0;
	const numReadReceive = 1138;
	const numHelpfulMark = 0;
	const numHelpfulReceive = 1138;
	const numInterestingMark = 0;
	const numInterestingReceive = 1138;

	const icons = [
		{
			type: 'learning',
			svg: <TipSvg />,
			title: 'Total number of shared learnings',
			total: numLearning,
		},
		{
			type: 'read',
			svg: <ReadActiveSvg />,
			title: `Total number of reads marked (${numReadMark}) and received (${numReadReceive})`,
			total: numReadMark + numReadReceive,
		},
		{
			type: 'helpful',
			svg: <HelpfulActiveSvg />,
			title: `Total number of helpfuls marked (${numHelpfulMark}) and received (${numHelpfulReceive})`,
			total: numHelpfulMark + numHelpfulReceive,
		},
		{
			type: 'interesting',
			svg: <InterestingActiveSvg />,
			title: `Total number of interestings marked (${numInterestingMark}) and received (${numInterestingReceive})`,
			total: numInterestingMark + numInterestingReceive,
		},
	];

	return (
		<div className={styles.iconGroup}>
			{icons.map((item, idx, arr) => {
				return (
					<div
						key={item.type}
						className={classNames(styles.icon, {
							[styles.notLastIcon]: idx !== arr.length - 1,
						})}
						title={item.title}
					>
						<div className={styles.svg}>{item.svg}</div>
						<span>{item.total}</span>
					</div>
				);
			})}
		</div>
	);
}

export default IconGroup;
