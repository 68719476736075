import { API } from 'aws-amplify';
/**
 * Call /linkInfo Rest Endpoint, which uses 'open-graph-scraper'
 * to parse url and returns an object with fields error and result.
 *
 * @param {String} link
 * @returns {Object}
 */
const getLinkInfo = async (link) => {
	try {
		const res = await API.get('tipstoryRestResource', '/linkInfo', {
			queryStringParameters: {
				link: link,
			},
		});
		return res;
	} catch (error) {
		return { error: true };
	}
};

export default getLinkInfo;
