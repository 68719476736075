/**
 * If input = false, return current month in 2 digit number string -> '05'.
 * If input = true, return current month in three letters format -> 'May'.
 */
const getCurrentMonth = (returnLetters = false) => {
	const currentDate = new Date().toISOString();
	const currentMonth = currentDate.slice(5, 7);
	if (!returnLetters) return currentMonth;
	return monthLookUp[currentMonth];
};

export const monthLookUp = {
	'01': 'Jan',
	'02': 'Feb',
	'03': 'Mar',
	'04': 'Apr',
	'05': 'May',
	'06': 'Jun',
	'07': 'Jul',
	'08': 'Aug',
	'09': 'Sep',
	10: 'Oct',
	11: 'Nov',
	12: 'Dec',
};

export default getCurrentMonth;
