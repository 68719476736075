import React, { useState } from 'react';
import styles from './NestedComment.module.css';
import {
	getProfileLink,
	useUser,
	getFormatDateTimeSince,
	useLogin,
} from 'utils';
import classNames from 'classnames';
import { profileSvg } from 'icons';
import TextTruncate from 'react-text-truncate';
import { TextButton } from 'components/Button';
import EditComment from '../EditComment';
import ReplyComment from '../ReplyComment';

function NestedComment(props) {
	// EditComment
	const [editOn, setEditOn] = useState(false);
	if (editOn)
		return <EditComment {...props} setEditOn={setEditOn} nested={true} />;

	// Comment
	const { comment } = props;
	return (
		<section className={styles.comment}>
			<Profile {...comment} />
			<div className={styles.commentDiv}>
				<Headline {...comment} />
				<CommentText {...comment} />
				<ButtonGroup {...props} setEditOn={setEditOn} />
			</div>
		</section>
	);
}

function Profile(props) {
	const { profileURL, profileURLIdx, profileImg } = props.connectUser || {};
	const profileLink = getProfileLink(profileURL, profileURLIdx);
	return (
		<div>
			<a href={profileLink}>
				<img
					src={profileImg || profileSvg}
					alt={'Your profile'}
					className={styles.profileImg}
				/>
			</a>
		</div>
	);
}

function Headline(props) {
	const { createdAt, updatedAt, connectUser = {} } = props;
	const { firstName, lastName } = connectUser;
	const name = `${firstName} ${lastName}`;
	const time = getFormatDateTimeSince(createdAt, false);
	const edited = createdAt !== updatedAt ? ' (edited)' : '';
	return (
		<div>
			<span className={styles.name}>{name}</span>
			<span className={styles.time}>{`${time}${edited}`}</span>
		</div>
	);
}

function CommentText(props) {
	const { comment: commentText } = props;
	// Display full comment if commentText is shorter than 470 chars
	const isShortComment = commentText && commentText.length < 470;
	const [fullDisplay, setFullDisplay] = useState(isShortComment);
	return (
		<div className={styles.commentText}>
			{fullDisplay && (
				<>
					<div>{commentText}</div>
					{!isShortComment && (
						<TextButton
							children={'Show less'}
							className={styles.commentTextButton}
							onClick={() => setFullDisplay(false)}
						/>
					)}
				</>
			)}
			{!fullDisplay && (
				<>
					<TextTruncate line={5} element='div' text={commentText} />
					<TextButton
						children={'Read more'}
						className={styles.commentTextButton}
						onClick={() => setFullDisplay(true)}
					/>
				</>
			)}
		</div>
	);
}

function ButtonGroup(props) {
	// Reply Button
	const [user] = useUser();
	const [, setLogin] = useLogin();
	const [replyOn, setReplyOn] = useState(false);
	const handleReplyClick = () => {
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'login' });
			return;
		}
		setReplyOn(true);
	};

	// Delete button
	const { comment, parentComment, setModal, setEditOn } = props;
	const { userID } = comment;
	const isAuthor = user.userID === userID;

	const handleDelete = () => {
		const { withinCommentID, createdAt, commentID } = comment;
		const input = {
			withinCommentID,
			createdAt,
			userID,
			commentID,
			learningID: parentComment.withinCommentID,
			parentCommentCreatedAt: parentComment.createdAt,
			parentCommentUserID: parentComment.userID,
		};
		setModal({
			isOpen: true,
			state: 'deleteComment',
			deleteInput: input,
			nested: true,
			parentCommentID: parentComment.commentID,
		});
	};

	return (
		<>
			<div className={styles.buttonGroup}>
				<TextButton
					children={'Reply'}
					className={styles.textButton}
					onClick={handleReplyClick}
				/>
				{isAuthor && (
					<>
						<TextButton
							children={'Edit'}
							className={classNames(
								styles.textButton,
								styles.textButtonMarginLeft
							)}
							onClick={() => setEditOn(true)}
						/>
						<TextButton
							children={'Delete'}
							className={classNames(
								styles.textButton,
								styles.textButtonMarginLeft
							)}
							onClick={handleDelete}
						/>
					</>
				)}
			</div>
			{replyOn && (
				<ReplyComment
					{...props}
					setReplyOn={setReplyOn}
					comment={parentComment}
					initialComment={`@${comment.connectUser.firstName} ${comment.connectUser.lastName} `}
					receiverID={userID}
				/>
			)}
		</>
	);
}

export default NestedComment;
