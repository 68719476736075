import React from 'react';
import styles from './IconGroup.module.css';
import {
	TipSvg,
	ReadActiveSvg,
	HelpfulActiveSvg,
	InterestingActiveSvg,
	InstagramSocialSvg,
} from 'icons';

function IconGroup({ profile }) {
	const {
		numLearning = 0,
		numReadMark = 0,
		numReadReceive = 0,
		numHelpfulMark = 0,
		numHelpfulReceive = 0,
		numInterestingMark = 0,
		numInterestingReceive = 0,
	} = profile;
	const instagramLink = `https://www.instagram.com/${profile.instagram}`;

	const icons = [
		{
			type: 'learning',
			svg: <TipSvg />,
			title: 'Total number of shared learnings',
			total: numLearning,
		},
		{
			type: 'read',
			svg: <ReadActiveSvg />,
			title: `Total number of reads marked (${numReadMark}) and received (${numReadReceive})`,
			total: numReadMark + numReadReceive,
		},
		{
			type: 'helpful',
			svg: <HelpfulActiveSvg />,
			title: `Total number of helpfuls marked (${numHelpfulMark}) and received (${numHelpfulReceive})`,
			total: numHelpfulMark + numHelpfulReceive,
		},
		{
			type: 'interesting',
			svg: <InterestingActiveSvg />,
			title: `Total number of interestings marked (${numInterestingMark}) and received (${numInterestingReceive})`,
			total: numInterestingMark + numInterestingReceive,
		},
	];

	return (
		<div className={styles.iconGroup}>
			{icons.map((item) => {
				return (
					<div key={item.type} className={styles.icon} title={item.title}>
						<div className={styles.svg}>{item.svg}</div>
						<span>{item.total}</span>
					</div>
				);
			})}
			{profile.instagram && (
				<div className={styles.instagramSvg}>
					<a
						target={'_blank'}
						rel={'noopener noreferrer nofollow'}
						href={instagramLink}
						className={styles.instagramLink}
					>
						<InstagramSocialSvg />
					</a>
				</div>
			)}
		</div>
	);
}

export default IconGroup;
