/**
 * getNewOrder is used to obtain new learningOrder/chapterOrder.
 * It returns an array where
 * the first index is the new order in JSON string and
 * the second index is success in boolean.
 *
 * @param {String | Boolean} currentOrder
 * @param {String} withinChapterID
 * @param {String | Array} id
 * @param {String} type
 * @returns {Array}
 */
const getNewOrder = (currentOrder, withinChapterID, id, type = 'new') => {
	const { parse, stringify } = JSON;
	switch (type) {
		// Replace entire learning / chapter with new array
		case 'replace':
			const idArray = id;
			try {
				if (!currentOrder) throw new Error(`currentOrder can't be null`);
				if (!Array.isArray(idArray)) throw new Error(`idArray is not an array`);
				const currentOrderObj = parse(currentOrder);
				if (typeof currentOrderObj !== 'object')
					throw new Error(`Chapter is not object`);
				currentOrderObj[withinChapterID] = idArray;
				return [stringify(currentOrderObj), true];
			} catch (error) {
				console.log('Error: ', error);
				return [false, false];
			}
		// Delete a learning / chapter
		case 'delete':
			try {
				if (!currentOrder) throw new Error(`currentOrder can't be null`);
				const currentOrderObj = parse(currentOrder);
				if (typeof currentOrderObj !== 'object')
					throw new Error(`Chapter is not object`);
				const currentOrderArray = currentOrderObj[withinChapterID];
				if (!currentOrderArray) throw new Error(`Chapter doesn't exist`);
				if (!Array.isArray(currentOrderArray)) throw new Error('Not an array');
				const newOrderArray = currentOrderArray.filter((item) => item !== id);
				currentOrderObj[withinChapterID] = newOrderArray;
				if (newOrderArray.length === 0) delete currentOrderObj[withinChapterID];
				return [stringify(currentOrderObj), true];
			} catch (error) {
				console.log('Error: ', error);
				return [false, false];
			}
		// Add new learning / chapter
		case 'new':
		default:
			if (!currentOrder) return [stringify({ [withinChapterID]: [id] }), true];
			try {
				const currentOrderObj = parse(currentOrder);
				if (typeof currentOrderObj !== 'object')
					throw new Error(`Chapter is not object`);
				const currentOrderArray = currentOrderObj[withinChapterID];
				if (currentOrderArray) {
					if (!Array.isArray(currentOrderArray))
						throw new Error('Not an array');
					currentOrderArray.push(id);
					return [stringify(currentOrderObj), true];
				} else {
					currentOrderObj[withinChapterID] = [id];
					return [stringify(currentOrderObj), true];
				}
			} catch (error) {
				console.log('Error: ', error);
				return [false, false];
			}
	}
};

export default getNewOrder;
