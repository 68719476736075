import { useContext } from 'react';
import { LoginContext } from 'App';

/**
 * useLogin hook returns an array, in which the zero index is login,
 * and the first index is setLogin.
 *
 * login is an object that contains the properties visibility (Boolean)
 * and state (String) of the login modal.
 *
 * setLogin can be called to change fields in login.
 *
 * @returns {Array} [login, setLogin]
 */

const useLogin = () => {
	return useContext(LoginContext);
};

export default useLogin;
