import React from 'react';
import styles from './SidebarLearning.module.css';
import {
	HelpfulActiveSvg,
	InterestingActiveSvg,
	ReadInactiveAltSvg,
	ReadActiveSvg,
	BookmarkSvg,
} from 'icons';
import { getTagList, getFormatDate, useUser, getPlural } from 'utils';
import { GetSourceSvg } from 'components/DropdownInput';
import { Link } from 'react-router-dom';

function SidebarLearning(props) {
	const { learningID, parentLearningID } = props;

	return (
		<Link to={`/learning/${parentLearningID || learningID}`}>
			<div className={styles.sidebarRow}>
				<Header {...props} />
				<Title {...props} />
				<Meta {...props} />
			</div>
		</Link>
	);
}

function Header(props) {
	const { tag } = props;
	const tagList = getTagList(tag);
	if (tagList.length === 0) return null;
	const firstTag = tagList[0];
	return (
		<header className={styles.header}>
			<span className={styles.tag}>{firstTag}</span>
		</header>
	);
}

function Title(props) {
	const { source, title, parentLearningID } = props;
	return (
		<div className={styles.title}>
			<div className={styles.leftTitle}>
				<div className={styles.titleSvg}>
					{GetSourceSvg({ source, className: styles.sidebarSvg })}
				</div>
				<h1 className={styles.titleH1}>{title}</h1>
			</div>
			{parentLearningID && (
				<div>
					<BookmarkSvg className={styles.bookmarkSvg} />
				</div>
			)}
		</div>
	);
}

function Meta(props) {
	const { userID: authorID, numWords, createdAt, readStatus = [] } = props;
	const displayNumWords =
		numWords < 100 ? numWords : Math.floor(numWords / 10) * 10;
	const displayDate = getFormatDate(createdAt).split(',')[0];

	const [user] = useUser();
	const isAuthor = user.userID === authorID;
	const readActive = readStatus.includes('read');
	const helpfulActive = readStatus.includes('helpful');
	const interestingActive = readStatus.includes('interesting');

	return (
		<div className={styles.meta}>
			<div className={styles.metaDes}>
				{displayDate} · {displayNumWords} word{getPlural(numWords)}
			</div>
			{!isAuthor && (
				<div className={styles.metaIcons}>
					{helpfulActive && <HelpfulActiveSvg className={styles.metaSvg} />}
					{interestingActive && (
						<InterestingActiveSvg className={styles.metaSvg} />
					)}
					{readActive ? (
						<ReadActiveSvg className={styles.metaSvg} />
					) : (
						<ReadInactiveAltSvg className={styles.metaSvg} />
					)}
				</div>
			)}
		</div>
	);
}

export default SidebarLearning;
