// useCreateUser
export const mutationCreateUser = /* GraphQL */ `
	mutation CreateUser(
		$input: CreateUserInput!
		$condition: ModelUserConditionInput
	) {
		createUser(input: $input, condition: $condition) {
			userID
			profileURL
			profileURLIdx
			firstName
			lastName
			headline
			profileImg
			instagram
			pinLearning
			chapterOrder
			learningOrder
			chapterName
			numLearning
			numReadMark
			numHelpfulMark
			numInterestingMark
			numReadReceive
			numHelpfulReceive
			numInterestingReceive
			numFollowing
			numFollower
		}
	}
`;

// EditProfile.js | Profile.js | NewLearning.js | SubmissionForm.js
export const mutationUpdateUser = /* GraphQL */ `
	mutation UpdateUser(
		$input: UpdateUserInput!
		$condition: ModelUserConditionInput
	) {
		updateUser(input: $input, condition: $condition) {
			userID
			profileURL
			profileURLIdx
			firstName
			lastName
			headline
			profileImg
			instagram
			pinLearning
			chapterOrder
			learningOrder
			chapterName
			numLearning
			numReadMark
			numHelpfulMark
			numInterestingMark
			numReadReceive
			numHelpfulReceive
			numInterestingReceive
			numFollowing
			numFollower
			scholar
			scholarStatus
			scholarMeta
			volunteerHour
		}
	}
`;

// Learning.js
export const mutationCreateRead = /* GraphQL */ `
	mutation CustomCreateRead($input: customCreateReadInput!) {
		customCreateRead(input: $input) {
			learningID
			userID
			type
		}
	}
`;
export const mutationDeleteRead = /* GraphQL */ `
	mutation CustomDeleteRead($input: customDeleteReadInput!) {
		customDeleteRead(input: $input) {
			learningID
			userID
			type
		}
	}
`;
export const mutationCreateComment = /* GraphQL */ `
	mutation CustomCreateComment($input: customCreateCommentInput!) {
		customCreateComment(input: $input) {
			withinCommentID
			createdAt
			userID
			commentID
			comment
		}
	}
`;
export const mutationDeleteComment = /* GraphQL */ `
	mutation CustomDeleteComment($input: customDeleteCommentInput!) {
		customDeleteComment(input: $input) {
			withinCommentID
			createdAt
			userID
		}
	}
`;
export const mutationUpdateComment = /* GraphQL */ `
	mutation UpdateComment(
		$input: UpdateCommentInput!
		$condition: ModelCommentConditionInput
	) {
		updateComment(input: $input, condition: $condition) {
			withinCommentID
			createdAt
			userID
		}
	}
`;

// NewLearning.js
export const mutationCreateLearning = /* GraphQL */ `
	mutation CustomCreateLearning($input: customCreateLearningInput!) {
		customCreateLearning(input: $input) {
			learningID
			updatedAt
		}
	}
`;

export const mutationCreateTag = /* GraphQL */ `
	mutation CreateTag(
		$input: CreateTagInput!
		$condition: ModelTagConditionInput
	) {
		createTag(input: $input, condition: $condition) {
			learningID
			tag
		}
	}
`;

export const mutationUpdateLearning = /* GraphQL */ `
	mutation UpdateLearning(
		$input: UpdateLearningInput!
		$condition: ModelLearningConditionInput
	) {
		updateLearning(input: $input, condition: $condition) {
			learningID
			updatedAt
		}
	}
`;

// DeleteLearning.js
export const mutationDeleteLearning = /* GraphQL */ `
	mutation CustomDeleteLearning($input: customDeleteLearningInput!) {
		customDeleteLearning(input: $input) {
			learningID
		}
	}
`;

export const mutationDeleteTag = /* GraphQL */ `
	mutation DeleteTag(
		$input: DeleteTagInput!
		$condition: ModelTagConditionInput
	) {
		deleteTag(input: $input, condition: $condition) {
			learningID
			tag
		}
	}
`;

// Profile.js
export const mutationCreateFollow = /* GraphQL */ `
	mutation CustomCreateFollow($input: customCreateFollowInput!) {
		customCreateFollow(input: $input) {
			followUserID
			userID
		}
	}
`;

export const mutationDeleteFollow = /* GraphQL */ `
	mutation CustomDeleteFollow($input: customDeleteFollowInput!) {
		customDeleteFollow(input: $input) {
			followUserID
			userID
		}
	}
`;

// SubmissionForm.js
export const mutationCreateScholar = /* GraphQL */ `
	mutation CreateScholar(
		$input: CreateScholarInput!
		$condition: ModelScholarConditionInput
	) {
		createScholar(input: $input, condition: $condition) {
			userID
		}
	}
`;
