import React, { useState } from 'react';
import styles from './PostComment.module.css';
import { useLogin, useUser } from 'utils';
import TextareaAutosize from 'react-textarea-autosize';
import PrimaryButton from 'components/Button';
import { profileSvg } from 'icons';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationCreateComment } from 'graphql/customMutations';
import update from 'immutability-helper';
import { useParams } from 'react-router';

function PostComment(props) {
	const { learning, setLearnings, setComments } = props;
	const { learningID, userID: receiverID } = learning || {};
	// parentLearningID is used to fix bug in saved learning
	const { learningID: parentLearningID } = useParams();
	const [user] = useUser();
	const [, setLogin] = useLogin();
	const [comment, setComment] = useState('');
	const [displayButton, setDisplayButton] = useState(false);
	const [loading, setLoading] = useState(false);
	const defaultText = 'Comment on things you find helpful or interesting';
	const handleChange = (e) => {
		const text = e.target.value;
		setComment(text);
	};
	const handleCancelClick = () => {
		setComment('');
		setDisplayButton(false);
	};

	// Create new comment
	const handleSubmitClick = async () => {
		if (comment.trim() === '') return;
		const input = {
			withinCommentID: learningID,
			userID: user.userID,
			comment,
			learningID,
		};
		// Add receiverID if it is not current user
		if (receiverID !== user.userID) input.receiverID = receiverID;

		setLoading(true);
		const success = await handleCreateComment(
			input,
			user,
			setComments,
			setLearnings,
			parentLearningID
		);
		setLoading(false);
		if (success) handleCancelClick();
	};

	return (
		<section className={styles.postComment}>
			<div>
				<img
					src={user.profileImg || profileSvg}
					alt={'Your profile'}
					className={styles.profileImg}
				/>
			</div>
			<div className={styles.textAreaDiv}>
				<TextareaAutosize
					className={styles.textarea}
					placeholder={defaultText}
					value={comment}
					autoComplete={'off'}
					minRows={1}
					onChange={handleChange}
					onFocus={() => {
						if (!user.isLogin) {
							setLogin({ visible: true, state: 'login' });
							return;
						}
						setDisplayButton(true);
					}}
				/>
				{displayButton && (
					<div className={styles.buttonGroup}>
						<PrimaryButton
							primary={false}
							children='Cancel'
							onClick={handleCancelClick}
						/>
						<PrimaryButton
							children={'Comment'}
							className={styles.marginLeft}
							loading={loading}
							disable={comment.trim() === ''}
							onClick={handleSubmitClick}
						/>
					</div>
				)}
			</div>
		</section>
	);
}

const handleCreateComment = async (
	input,
	user,
	setComments,
	setLearnings,
	parentLearningID
) => {
	try {
		const res = await API.graphql(
			graphqlOperation(mutationCreateComment, { input })
		);
		const createdAt = res.data.customCreateComment.createdAt;
		const updatedAt = createdAt;
		const commentID = res.data.customCreateComment.commentID;
		const newComment = {
			...input,
			createdAt,
			updatedAt,
			commentID,
			numReply: 0,
		};
		newComment.connectUser = {
			profileURL: user.profileURL,
			profileURLIdx: user.profileURLIdx,
			firstName: user.firstName,
			lastName: user.lastName,
			profileImg: user.profileImg,
		};
		newComment.connectComment = {
			items: [],
		};
		const learningID = input.learningID;
		setComments((prev) =>
			update(prev, { [learningID]: { $unshift: [newComment] } })
		);
		setLearnings((prev) =>
			update(prev, {
				[parentLearningID]: {
					numComment: { $apply: (n) => n + 1 },
				},
			})
		);
		return true;
	} catch (error) {
		console.log('Error creating comment: ', error);
	}
};

export default PostComment;
