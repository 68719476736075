import { useEffect } from 'react';

/**
 * useCloseMenu hook is activated when a menu opens (isOpen is true).
 * It automatically closes menu (by calling setIsOpen with false) when
 * user clicks anywhere with the mouse or when escape key is pressed.
 *
 * @param {Object}
 */

const useCloseMenu = ({ isOpen, setIsOpen }) => {
	useEffect(() => {
		const handleClick = () => {
			setIsOpen(false);
		};
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				setIsOpen(false);
			}
		};
		if (isOpen) {
			document.addEventListener('mousedown', handleClick);
			document.addEventListener('keydown', handleKeyDown);
		} else {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('keydown', handleKeyDown);
		}
		return () => {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, setIsOpen]);
};

export default useCloseMenu;
