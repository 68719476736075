import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Auth } from 'aws-amplify';

const autoExternalSignIn = async (provider) => {
	try {
		await Auth.federatedSignIn({ provider: provider });
	} catch (error) {
		console.log('Error auto sign in: ', error);
	}
};

/**
 * useAutoExternalSignIn hook attempts to auto sign in user after users register
 * an account with Google / Facebook external provider.
 *
 * When user first registers an account using Google / Facebook, a native user is created
 * in AWS Cognito and an error is throwed to the front end when the process is succeeded.
 * https://github.com/aws-amplify/amplify-cli/issues/4427#issuecomment-718549881
 */

const useAutoExternalSignIn = () => {
	const numRetry = useRef(0);
	const history = useHistory();
	const params = queryString.parse(history.location.search);
	const errorDes = params.error_description;
	useEffect(() => {
		if (numRetry.current < 2 && errorDes) {
			if (errorDes.includes('LINKED_EXTERNAL_USER_Facebook')) {
				autoExternalSignIn('Facebook');
				numRetry.current += 1;
			}
			if (errorDes.includes('LINKED_EXTERNAL_USER_Google')) {
				autoExternalSignIn('Google');
				numRetry.current += 1;
			}
		}
	}, [errorDes]);
};

export default useAutoExternalSignIn;
