import { DeleteSvg, EditSvg, NoteSvg, PinSvg } from 'icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'utils';
import Item from '../Item';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationUpdateUser } from 'graphql/customMutations';
import update from 'immutability-helper';

// Open, Edit, Move to, Share, Delete
function LearningMenu({ menu, setModal }) {
	return (
		<>
			<OpenLearning menu={menu} />
			<EditLearning menu={menu} />
			<PinLearning menu={menu} />
			{/* <Item name={'moveLearning'} svg={<MoveToSvg />} text={'Move to'} /> */}
			{/* <Item name={'shareLearning'} svg={<ShareSvg />} text={'Share'} /> */}
			<DeleteLearning menu={menu} setModal={setModal} />
		</>
	);
}

function OpenLearning({ menu }) {
	const handleOnClick = () => {
		window.open(`/learning/${menu.id}`, '_blank');
	};
	return <Item svg={<NoteSvg />} text={'Open'} onClick={handleOnClick} />;
}

function EditLearning({ menu }) {
	const history = useHistory();
	const handleOnClick = () => history.push(`/edit?learning=${menu.id}`);
	return <Item svg={<EditSvg />} text={'Edit'} onClick={handleOnClick} />;
}

function PinLearning({ menu }) {
	const [user, setUser] = useUser();
	const isPinned = user.pinLearning === menu.id;
	const text = isPinned ? 'Unpin Learning' : 'Pin Learning';
	const handleOnClick = async () => {
		try {
			const input = {
				userID: user.userID,
				pinLearning: isPinned ? '' : menu.id,
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log(`Error ${text}`);
		}
	};
	return <Item svg={<PinSvg />} text={text} onClick={handleOnClick} />;
}

function DeleteLearning({ menu, setModal }) {
	const handleOnClick = () => {
		setModal({
			isOpen: true,
			state: 'deleteLearning',
			title: menu.title,
			id: menu.id,
		});
	};
	return <Item svg={<DeleteSvg />} text={'Delete'} onClick={handleOnClick} />;
}

export default LearningMenu;
