import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetScholar } from 'graphql/customQueries';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { useUser } from 'utils';

/**
 * useGetScholar hook gets profile of scholar.
 *
 * @param {Object} { setScholar, setError }
 */
const useGetScholar = ({ setScholar, setError }) => {
	const [user] = useUser();
	const { userID } = useParams();

	useEffect(() => {
		// Skip if user is loading
		if (user.isLoading) return;

		// Skip if userID is missing
		if (!userID) return;

		// Skip if user.userID === userID
		if (userID === user.userID) {
			setScholar(user);
			return;
		}

		const getScholar = async () => {
			const variables = { userID };
			try {
				const userProfile = await API.graphql({
					...graphqlOperation(queryGetScholar, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const data = userProfile.data.getUser;
				if (data) {
					setScholar(data);
				} else {
					setScholar({});
					setError('Page not found🤯');
				}
			} catch (error) {
				console.log('Error getting profile: ', error);
				setScholar({});
				setError(GENERAL_ERROR_MSG);
			}
		};
		getScholar();
	}, [
		user.isLoading,
		user.userID,
		user,
		user.isLogin,
		userID,
		setScholar,
		setError,
	]);
};

export default useGetScholar;
