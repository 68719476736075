import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './NewLearning.module.css';
import TopSection from './TopSection';
import MainSection from './MainSection';
import { useShowLogin } from 'utils';
import useGetEditLearning from './hooks';

const DEFAULT_NEW_LEARNING = {
	learningID: '',
	source: 'Learning Note',
	linkInfo: { link: '' },
	tag: '',
	title: '',
	description: '',
	learning: [
		{
			type: 'paragraph',
			children: [{ text: '' }],
		},
	],
};

function NewLearning({ editMode = false }) {
	const [newLearning, setNewLearning] = useState(DEFAULT_NEW_LEARNING);

	// Show login box hook
	useShowLogin();

	// Reset learning in new
	useEffect(() => {
		if (!editMode) setNewLearning(DEFAULT_NEW_LEARNING);
	}, [editMode]);

	// Get learning in editMode
	useGetEditLearning({ active: editMode, setNewLearning });

	const pageTitle = editMode
		? 'Edit your learning | TipStory'
		: 'Create a new learning | TipStory';

	const props = { newLearning, setNewLearning, editMode };
	return (
		<main className={styles.main}>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name='description' content={pageTitle} />
			</Helmet>
			<TopSection {...props} />
			<MainSection {...props} />
		</main>
	);
}

export default NewLearning;
