import React, { useCallback, useEffect, useState } from 'react';
import styles from './SaveDraft.module.css';
import { SaveLearningSvg, DraftSvg } from 'icons';
import DraftModal from './DraftModal';
import {
	createDraftLearning,
	sortWithUpdatedAt,
	updateDraftLearning,
} from './utils';
import useGetDrafts from './hooks';
import isHotkey from 'is-hotkey';
import { useLogin, useUser } from 'utils';

function SaveDraft({ editMode, newLearning, setNewLearning, withinChapterID }) {
	// Drafts
	const [drafts, setDrafts] = useState([]);
	const sortedDrafts = sortWithUpdatedAt(drafts);
	useGetDrafts({ setDrafts });
	const [draftMsg, setDraftMsg] = useState('');

	const [user] = useUser();
	const [, setLogin] = useLogin();

	// Handle Drafts Save
	const handleSaveClick = useCallback(async () => {
		// Only save new draft
		if (editMode) return;

		// Return if user is not login
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'login' });
			return;
		}
		// Return if loading
		if (draftMsg === 'Saving') return;

		setDraftMsg('Saving');
		let success;
		// Create draft for new learning
		if (!newLearning.learningID) {
			success = await createDraftLearning({
				newLearning,
				setNewLearning,
				withinChapterID,
				userID: user.userID,
				setDrafts,
			});
		}
		// Update draft for new learning
		else if (newLearning.learningID.startsWith('draft-new')) {
			success = await updateDraftLearning({
				newLearning,
				setDrafts,
			});
		}
		if (success) {
			setDraftMsg('Saved');
		} else {
			setDraftMsg('Draft failed to save');
		}
		setInterval(() => setDraftMsg(''), 3000);
	}, [
		draftMsg,
		editMode,
		newLearning,
		setLogin,
		setNewLearning,
		user.isLogin,
		user.userID,
		withinChapterID,
	]);

	// useEffect hook for save key listener (ctrl+s)
	useEffect(() => {
		if (editMode) return;
		const shortcutSave = async (event) => {
			if (isHotkey('mod+s', event)) {
				event.preventDefault();
				await handleSaveClick();
			}
		};
		document.addEventListener('keydown', shortcutSave);
		return () => document.removeEventListener('keydown', shortcutSave);
	}, [editMode, handleSaveClick]);

	return (
		<div className={styles.saveDraftDiv}>
			{drafts.length !== 0 && (
				<DraftButton
					drafts={sortedDrafts}
					setDrafts={setDrafts}
					newLearning={newLearning}
					setNewLearning={setNewLearning}
				/>
			)}
			<SaveButton onClick={handleSaveClick} />
			{draftMsg && <div className={styles.draftMsg}>{draftMsg}</div>}
		</div>
	);
}

function SaveButton({ onClick }) {
	return (
		<div>
			<button
				type={'button'}
				className={styles.svgButton}
				title={'Save (Ctrl+S)'}
				onClick={onClick}
			>
				<SaveLearningSvg className={styles.svg} />
			</button>
		</div>
	);
}

function DraftButton(props) {
	const { drafts } = props;
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div>
			<button
				type={'button'}
				className={styles.svgButton}
				title={'Draft'}
				onClick={() => setIsOpen(true)}
			>
				<DraftSvg className={styles.svg} />
				<span className={styles.svgText}>{`Draft (${drafts.length})`}</span>
			</button>
			{isOpen && (
				<DraftModal isOpen={isOpen} setIsOpen={setIsOpen} {...props} />
			)}
		</div>
	);
}

export default SaveDraft;
