import React from 'react';
import {
	getFormatDate,
	getTextFromSlateObj,
	getWordCount,
	removeNewLineChars,
	useUser,
} from 'utils';
import styles from './Background.module.css';
import { profileSvg } from 'icons';
import TextareaAutosize from 'react-textarea-autosize';
import update from 'immutability-helper';

function Background({ newLearning, setNewLearning, editMode }) {
	const { description, learning, createdAt } = newLearning;
	// TopDescription
	const [user] = useUser();
	const fullName = `${user.firstName || ''} ${user.lastName || ''}`;
	const date = editMode ? getFormatDate(createdAt) : 'Today';
	const numWords = getWordCount(getTextFromSlateObj(learning));
	const topDescription = `${fullName} · ${date} · ${numWords} words`;

	const handleOnChange = (event) => {
		const newDescription = removeNewLineChars(event.target.value);
		setNewLearning((prev) =>
			update(prev, { description: { $set: newDescription } })
		);
	};

	return (
		<div className={styles.background}>
			<div>
				<img
					src={user.profileImg || profileSvg}
					className={styles.img}
					alt={'Profile'}
				/>
			</div>
			<div className={styles.profile}>
				<div className={styles.topDescription}>{topDescription}</div>
				<TextareaAutosize
					autoComplete={'off'}
					className={styles.backgroundInput}
					placeholder={
						'Any special background/interest about you on this learning (optional)'
					}
					maxLength={200}
					value={description}
					minRows={1}
					onChange={handleOnChange}
				/>
			</div>
		</div>
	);
}

export default Background;
