/**
 * Return scholarMeta of user.
 *
 * scholarMeta is an object that contains properties:
 * oneTimeTaskDone
 * May, Jun, Jul, Aug --- Dec (months)
 * blueBadge, bronzeBadge, silverBadge, goldBadge
 *
 * @param {Object} user
 * @returns {Object}
 */
const getScholarMeta = (user) => {
	if (!user.scholarMeta) {
		// Monkey patch for early scholar
		if (user.scholar === 'blueBadge') return { blueBadge: true };
		else return {};
	}
	try {
		return JSON.parse(user.scholarMeta);
	} catch (error) {
		console.log('Error parsing scholarMeta: ', error);
		return {};
	}
};

export default getScholarMeta;
