import React, { useState } from 'react';
import styles from './EditComment.module.css';
import { useUser } from 'utils';
import TextareaAutosize from 'react-textarea-autosize';
import PrimaryButton from 'components/Button';
import { profileSvg } from 'icons';
import { API, graphqlOperation } from 'aws-amplify';
import { mutationUpdateComment } from 'graphql/customMutations';
import update from 'immutability-helper';

function EditComment(props) {
	const {
		learning,
		comment: commentObj,
		setComments,
		setEditOn,
		nested,
	} = props;
	const [user] = useUser();
	const [comment, setComment] = useState(commentObj.comment);
	const [loading, setLoading] = useState(false);
	const defaultText = 'Comment on things you find helpful or interesting';

	const handleChange = (e) => {
		const text = e.target.value;
		setComment(text);
	};
	// Cancel Button - Close Edit
	const handleCancelClick = () => {
		setEditOn(false);
	};
	// Save Button - Update commnet
	const handleSaveClick = async () => {
		if (comment.trim() === '') return;
		const input = {
			withinCommentID: commentObj.withinCommentID,
			createdAt: commentObj.createdAt,
			userID: commentObj.userID,
			comment,
		};
		setLoading(true);
		const success = await handleUpdateComment(
			input,
			learning.learningID,
			commentObj.commentID,
			setComments,
			nested
		);
		setLoading(false);
		if (success) handleCancelClick();
	};

	return (
		<section className={styles.editComment}>
			<div>
				<img
					src={user.profileImg || profileSvg}
					alt={'Your profile'}
					className={nested ? styles.nestedProfileImg : styles.profileImg}
				/>
			</div>
			<div className={styles.textAreaDiv}>
				<TextareaAutosize
					className={styles.textarea}
					placeholder={defaultText}
					value={comment}
					autoFocus={true}
					autoComplete={'off'}
					minRows={1}
					onChange={handleChange}
				/>
				<div className={styles.buttonGroup}>
					<PrimaryButton
						primary={false}
						children='Cancel'
						onClick={handleCancelClick}
					/>
					<PrimaryButton
						children={'Save'}
						className={styles.marginLeft}
						loading={loading}
						disable={comment.trim() === ''}
						onClick={handleSaveClick}
					/>
				</div>
			</div>
		</section>
	);
}

const handleUpdateComment = async (
	input,
	learningID,
	commentID,
	setComments,
	nested
) => {
	try {
		await API.graphql(graphqlOperation(mutationUpdateComment, { input }));
		if (!nested) {
			setComments((prev) => {
				const idx = prev[learningID].findIndex(
					(item) => item.commentID === commentID
				);
				return update(prev, {
					[learningID]: {
						[idx]: {
							$merge: {
								comment: input.comment,
								updatedAt: new Date().toISOString(),
							},
						},
					},
				});
			});
		} else {
			setComments((prev) => {
				const parentIdx = prev[learningID].findIndex(
					(item) => item.commentID === input.withinCommentID
				);
				const idx = prev[learningID][parentIdx].connectComment.items.findIndex(
					(item) => item.commentID === commentID
				);
				return update(prev, {
					[learningID]: {
						[parentIdx]: {
							connectComment: {
								items: {
									[idx]: {
										$merge: {
											comment: input.comment,
											updatedAt: new Date().toISOString(),
										},
									},
								},
							},
						},
					},
				});
			});
		}
		return true;
	} catch (error) {
		console.log('Error updating comment: ', error);
	}
};

export default EditComment;
