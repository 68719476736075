import { useEffect } from 'react';
import { useUser } from 'utils';
import update from 'immutability-helper';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetComments } from 'graphql/customQueries';

/**
 * useGetComments hook gets comments and nested comments based on learningID
 *
 * @param {Object}
 */
const useGetComments = ({ learningID, comments, setComments }) => {
	const [user] = useUser();
	useEffect(() => {
		// Skip if learningID is empty
		if (!learningID) return;

		// Skip query if found in cache
		if (comments[learningID]) return;

		// Define and call getLearning
		const variables = {
			withinCommentID: learningID,
		};

		const getCommentsByLearningID = async () => {
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetComments, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const commentsItem = res.data.listComments.items;
				setComments((prev) =>
					update(prev, { [learningID]: { $set: commentsItem } })
				);
			} catch (error) {
				console.log('Error getting comments: ', error);
			}
		};
		getCommentsByLearningID();
	}, [learningID, comments, user.isLogin, setComments]);
};

export default useGetComments;
