import React from 'react';
import classNames from 'classnames';
import styles from './Image.module.css';
import { useSelected, useFocused } from 'slate-react';
function Image({ attributes, children, element }) {
	const selected = useSelected();
	const focused = useFocused();
	let { link } = element;
	if (link === '') return null;

	return (
		<div {...attributes}>
			<div contentEditable={false} className={styles.div}>
				<img
					src={link}
					className={classNames(styles.img, {
						[styles.selected]: selected && focused,
					})}
					alt=''
				/>
			</div>
			{children}
		</div>
	);
}

export default Image;
