import React, { useRef } from 'react';
import styles from './BreadcombTab.module.css';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { useUser } from 'utils';
import { dropInChapter } from '../../utils';

function BreadcombTab({ chapterID, name, setJourney }) {
	const ref = useRef(null);
	const { fullProfileURL, withinChapterID = 'home' } = useParams();
	const [user, setUser] = useUser();
	const [{ isOver }, drop] = useDrop({
		accept: ['learningCard', 'chapter'],
		drop: async (item, monitor) => {
			if (!ref.current) return;
			// Simply return if current chapter is chapter to be droped
			if (withinChapterID === chapterID) return;
			const type = item.type === 'learningCard' ? 'learning' : 'chapter';
			const learningID = item.parentLearningID || item.id;
			const newWithinChapterID = chapterID;
			await dropInChapter({
				type,
				learningID,
				newWithinChapterID,
				withinChapterID,
				user,
				setUser,
				setJourney,
			});
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
	});
	drop(ref);

	const to =
		chapterID === 'home'
			? `/journey/${fullProfileURL}`
			: `/journey/${fullProfileURL}/chapter/${chapterID}`;

	return (
		<div
			className={classNames(styles.tab, {
				[styles.tabOver]: isOver,
			})}
			ref={ref}
		>
			<Link className={styles.link} to={to}>
				{name}
			</Link>
		</div>
	);
}

export default BreadcombTab;
