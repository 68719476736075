import React, { useState } from 'react';
import ErrorMsg from '../../ErrorMsg';
import CloseButton from '../CloseButton';
import styles from './RenameChapter.module.css';
import PrimaryButton from 'components/Button';
import { useEnterCallback, useUser } from 'utils';
import Input from './../Input';
import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationUpdateUser,
	mutationUpdateLearning,
} from 'graphql/customMutations';
import getNewChapterName from 'utils/getNewChapterName';
import { useParams } from 'react-router-dom';
import update from 'immutability-helper';
import { GENERAL_ERROR_MSG } from 'utils/constants';

function RenameChapter({ modal, setIsOpenFalse, setJourney }) {
	const { isOpen, title: prevTitle, id: learningID } = modal;
	const [title, setTitle] = useState(prevTitle);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [user, setUser] = useUser();
	const { withinChapterID = 'home' } = useParams();

	const handleRename = async () => {
		if (title === '') return;
		if (loading) return;
		setLoading(true);
		setError('');
		const success = await renameChapter({
			learningID,
			title,
			user,
			setUser,
			withinChapterID,
			setJourney,
		});
		if (success) setIsOpenFalse();
		else {
			setLoading(false);
			setError(GENERAL_ERROR_MSG);
		}
	};
	useEnterCallback({ isOpen, callback: handleRename });

	return (
		<div className={styles.renameChapter}>
			<CloseButton onClick={setIsOpenFalse} />
			{error && <ErrorMsg error={error} />}
			<h1 className={styles.title}>Rename Chapter</h1>
			<Input
				value={title}
				setValue={setTitle}
				isOpen={isOpen}
				className={styles.input}
				placeholder={'Chapter Title'}
			/>
			<div className={styles.buttonGroup}>
				<PrimaryButton
					primary={false}
					onClick={setIsOpenFalse}
					children={'Cancel'}
				/>
				<PrimaryButton
					className={styles.renameButton}
					disable={title === ''}
					onClick={handleRename}
					loading={loading}
					children={'Rename'}
				/>
			</div>
		</div>
	);
}

// rename chapter, return true on success
const renameChapter = async ({
	learningID,
	title,
	user,
	setUser,
	withinChapterID,
	setJourney,
}) => {
	// 1. Update Chapter in database
	try {
		const input = {
			learningID,
			title,
		};
		await API.graphql(graphqlOperation(mutationUpdateLearning, { input }));
	} catch (error) {
		console.log('Error updating chapter: ', error);
		return false;
	}
	// 2. Update Chapter Name in database
	const [newChapterName, addNameSuccess] = getNewChapterName(
		user.chapterName,
		learningID,
		title,
		'replace'
	);
	if (addNameSuccess) {
		try {
			const userInput = {
				userID: user.userID,
				chapterName: newChapterName,
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input: userInput })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log('Error updating chapterName: ', error);
			// ignore error
		}
	}
	// 3. Update Chapter locally
	const key = `${user.userID}-${withinChapterID}`;
	setJourney((prev) => {
		const idx = prev[key].findIndex((item) => item.learningID === learningID);
		return update(prev, { [key]: { [idx]: { title: { $set: title } } } });
	});
	return true;
};

export default RenameChapter;
