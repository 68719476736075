import { useEffect } from 'react';

/**
 * useSetScrollToBottom hooks sets isBottom to true when user
 * scrolls 100px close to bottom.
 *
 * @param {Object}
 */

const useSetScrollToBottom = ({ active, setIsBottom }) => {
	useEffect(() => {
		const handleScroll = () => {
			const scrollHeight = document.documentElement.scrollHeight;
			const scrollTop = document.documentElement.scrollTop;
			const winderHeight = window.innerHeight;
			if (scrollHeight - scrollTop - winderHeight <= 100) {
				setIsBottom(true);
			}
		};
		if (active) window.addEventListener('scroll', handleScroll);
		else {
			window.removeEventListener('scroll', handleScroll);
			setIsBottom(false);
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
			setIsBottom(false);
		};
	}, [active, setIsBottom]);
};

export default useSetScrollToBottom;
