import { LOCAL_STORAGE_TAG_HISTORY } from 'utils/constants';
/**
 * getTagHistory gets tagHistory from localStorage.
 *
 */
const getTagHistory = () => {
	let tagHistory = [];
	try {
		const tagHistoryJSON = localStorage.getItem(LOCAL_STORAGE_TAG_HISTORY);
		if (tagHistoryJSON) {
			tagHistory = JSON.parse(tagHistoryJSON);
		}
	} catch (error) {
		console.log('Error getting tagHistory: ', error);
	}
	return tagHistory;
};

export default getTagHistory;
