import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { getTextFromSlateObjJSON } from 'utils';

function Meta({ learning }) {
	const { title: learningTitle = '', connectUser = {} } = learning;
	const { firstName = '', lastName = '' } = connectUser;
	const title = `${learningTitle} | by ${firstName} ${lastName} | TipStory`;
	const description = useMemo(
		() => getTextFromSlateObjJSON(learning.learning).slice(0, 200),
		[learning.learning]
	);
	// Set up Google SEO 'Article' structured data
	const {
		createdAt,
		updatedAt,
		numWords,
		learningID = '',
		numComment,
		tag,
	} = learning;
	const article = {
		'@context': 'https://schema.org',
		'@type': 'BlogPosting',
		dateModified: updatedAt,
		datePublished: createdAt,
		headline: learningTitle && learningTitle.slice(0, 110),
		wordCount: numWords,
		mainEntityOfPage: {
			'@type': 'WebPage',
			'@id': `https://www.tipstory.org/learning/${learningID}`,
		},
		author: {
			'@type': 'Person',
			name: `${firstName} ${lastName}`,
		},
		commentCount: numComment,
		keywords: tag,
	};
	return (
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta
				property='og:url'
				content={`https://www.tipstory.org/learning/${learningID}`}
			/>
			<meta property='og:type' content='article' />
			<meta property='og:title' content={title} />
			<meta property='og:description' content={description} />
			<script type='application/ld+json'>{JSON.stringify(article)}</script>
		</Helmet>
	);
}

export default Meta;
