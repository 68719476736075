import React, { useState, useRef } from 'react';
import styles from './DropdownInput.module.css';
import classNames from 'classnames';
import { useCloseDropdown } from 'utils';
import {
	NoteSvg,
	WebsiteSvg,
	VideoSvg,
	BookSvg,
	PodcastSvg,
	MusicSvg,
	ImageSvg,
	OtherSvg,
	StorySvg,
	TutorialSvg,
	ProjectSvg,
} from 'icons';

function DropdownInput({ list = [], showIcon = false, onClick, value }) {
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });

	const itemOnClick = (event) => {
		setIsOpen(false);
		onClick(event);
	};

	return (
		<div className={styles.div} ref={node}>
			<button
				type='button'
				className={classNames(styles.button)}
				onClick={() => setIsOpen((prev) => !prev)}
			>
				{showIcon && GetSourceSvg({ source: value, className: styles.topSvg })}
				<span className={styles.itemText}>{value}</span>
			</button>
			<div
				className={classNames(styles.itemDiv, {
					[styles.itemDivOpen]: isOpen,
				})}
			>
				{list.map((item) => (
					<button
						key={item}
						name={item}
						type='button'
						className={styles.item}
						onClick={itemOnClick}
					>
						{showIcon &&
							GetSourceSvg({ source: item, className: styles.itemSvg })}
						<span className={styles.itemText}>{item}</span>
					</button>
				))}
			</div>
		</div>
	);
}

export const GetSourceSvg = ({ source = '', className = '' }) => {
	switch (source) {
		case 'Learning Note':
			return <NoteSvg className={className} />;
		case 'Reflection Story':
			return <StorySvg className={className} />;
		case 'Project':
			return <ProjectSvg className={className} />;
		case 'Tutorial':
			return <TutorialSvg className={className} />;
		case 'Website':
			return <WebsiteSvg className={className} />;
		case 'Video':
			return <VideoSvg className={className} />;
		case 'Book':
			return <BookSvg className={className} />;
		case 'Podcast':
			return <PodcastSvg className={className} />;
		case 'Music':
			return <MusicSvg className={className} />;
		case 'Image':
			return <ImageSvg className={className} />;
		case 'Other':
			return <OtherSvg className={className} />;
		default:
			return <NoteSvg className={className} />;
	}
};

export default DropdownInput;
