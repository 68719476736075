import { useEffect } from 'react';
import { useUser, useLogin } from 'utils';

/**
 * useShowLogin hook display login box for non-login user.
 */
const useShowLogin = () => {
	const [user] = useUser();
	const [, setLogin] = useLogin();

	useEffect(() => {
		if (user.isLoading) return;
		if (!user.isLogin) setLogin({ visible: true, state: 'login' });
		else setLogin({ visible: false, state: 'login' });
	}, [user.isLoading, user.isLogin, setLogin]);
};

export default useShowLogin;
