const INITIAL_VALUE = [
	{
		type: 'bulletList',
		children: [
			{
				type: 'listItem',
				children: [
					{
						text: 'color font',
						fontColor: '#0fa7ff',
						bold: true,
					},
				],
			},
			{
				type: 'listItem',
				children: [
					{
						text: 'emojis👋😜🎓🚀',
					},
				],
			},
			{
				type: 'listItem',
				children: [
					{
						text: '',
					},
					{
						type: 'link',
						link: 'https://www.tipstory.org',
						children: [
							{
								text: 'link',
								underline: true,
								fontColor: '#4262ff',
							},
						],
					},
					{
						text: '',
					},
				],
			},
			{
				type: 'listItem',
				children: [
					{
						text: 'inline highlight',
						code: true,
					},
				],
			},
			{
				type: 'listItem',
				children: [
					{
						text: 'code block',
					},
				],
			},
		],
	},
	{
		type: 'codeBlock',
		children: [
			{
				text: '# Python\nprint("Hello World")',
			},
		],
		language: 'python',
	},
	{
		type: 'bulletList',
		children: [
			{
				type: 'listItem',
				children: [
					{
						text: 'embed of ',
					},
					{
						text: 'Google Slide',
						code: true,
					},
					{
						text: ', ',
					},
					{
						text: 'Tinkercad',
						code: true,
					},
					{
						text: ', ',
					},
					{
						text: 'YouTube',
						code: true,
					},
					{
						text: ', and many more (click ➕ to try embed)',
					},
				],
			},
		],
	},
];

export default INITIAL_VALUE;
