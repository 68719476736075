import { Node } from 'slate';

/**
 * Return a string from a slate node object.
 *
 * @param {Object} slateObj
 * @return {String}
 */

const getTextFromSlateObj = (slateObj) => {
	return slateObj.map((n) => Node.string(n)).join('\n');
};

/**
 * Return a string from a slate node object json.
 *
 * @param {String} slateObjJSON
 * @return {String}
 */

export const getTextFromSlateObjJSON = (slateObjJSON = '[]') => {
	try {
		const slateObj = JSON.parse(slateObjJSON);
		return slateObj.map((n) => Node.string(n)).join('\n');
	} catch (error) {
		console.log('Error: ', error);
		return 'Error';
	}
};

export default getTextFromSlateObj;
