import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getProfileURLAndIdx, useUser } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetUserByProfileUrl } from 'graphql/customQueries';
import { GENERAL_ERROR_MSG } from 'utils/constants';

/**
 * useGetProfile hook gets profile and followed status of user.
 *
 * @param {Object} { profile, setProfile, setError }
 */
const useGetProfile = ({ profile, setProfile, setError }) => {
	const [user] = useUser();
	const { fullProfileURL } = useParams();

	useEffect(() => {
		// Skip if user is loading
		if (user.isLoading) return;

		// Throw error if profileURL is missing
		if (!fullProfileURL) {
			setError(`Profile ID is missing 😲`);
			return;
		}

		// Skip query profile if it is the current user
		const variables = getProfileURLAndIdx(fullProfileURL);
		if (
			variables.profileURL === user.profileURL &&
			variables.profileURLIdx === user.profileURLIdx
		) {
			setProfile(user);
			return;
		}

		// Skip query profile if it has been query
		if (
			variables.profileURL === profile.profileURL &&
			variables.profileURLIdx === profile.profileURLIdx
		)
			return;

		const getProfile = async () => {
			variables.visitorUserID = user.userID;
			variables.isLogin = user.isLogin;
			try {
				const userRes = await API.graphql({
					...graphqlOperation(queryGetUserByProfileUrl, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const profile = userRes.data.getUserByProfileURL.items[0];
				if (!profile) {
					setError(`User doesn't exist 😲`);
					return;
				}
				if (user.isLogin && profile.connectFollow.items.length > 0) {
					profile.followed = true;
				} else {
					profile.followed = false;
				}
				setProfile(profile);
			} catch (error) {
				console.log('Error getting profile: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};

		getProfile();
	}, [
		fullProfileURL,
		user,
		profile.profileURL,
		profile.profileURLIdx,
		setError,
		setProfile,
	]);
};

export default useGetProfile;
