import React from 'react';
import styles from './ButtonGroup.module.css';
import { ExternalLinkSvg } from 'icons';
import { Link } from 'react-router-dom';

function ButtonGroup(props) {
	return (
		<div className={styles.buttonGroup}>
			<OpenButton {...props} />
		</div>
	);
}

function OpenButton(props) {
	const { learningID } = props;
	const linkToLearning = `/learning/${learningID}`;
	return (
		<Link className={styles.button} to={linkToLearning}>
			<div className={styles.buttonSvg}>
				<ExternalLinkSvg />
			</div>
			<span>Open Learning</span>
		</Link>
	);
}

export default ButtonGroup;
