/**
 * getSortedLearnings return a new learning/chapter array that is sorted
 * based on user's learningOrder/chapterOrder and chapterID.
 *
 * @param {String} currentOrder
 * @param {String} withinChapterID
 * @param {Array} inputArray
 */
const getSortedLearnings = (currentOrder, withinChapterID, inputArray) => {
	const { parse, stringify } = JSON;
	const newArray = parse(stringify(inputArray));
	if (!currentOrder) return newArray;
	try {
		const currentOrderObj = parse(currentOrder);
		const currentOrderArray = currentOrderObj[withinChapterID];
		if (!currentOrderArray && !Array.isArray(currentOrderArray))
			return inputArray;
		if (currentOrderArray.length === 0) return inputArray;
		newArray.sort((a, b) => {
			// saveLearning has parentLearningID
			const aID = a.parentLearningID || a.learningID;
			const bID = b.parentLearningID || b.learningID;
			// Return -1 means a to the left of b
			const aIdx = currentOrderArray.indexOf(aID);
			const bIdx = currentOrderArray.indexOf(bID);
			if (aIdx === -1 && bIdx === -1) return 0;
			if (aIdx === -1) return 1;
			if (bIdx === -1) return -1;
			if (aIdx < bIdx) return -1;
			return 1;
		});
		return newArray;
	} catch (error) {
		console.log('Error: ', error);
		return newArray;
	}
};

export default getSortedLearnings;
