import { useEffect } from 'react';
import getDefaultTheme from './getThemeDefault';

/**
 * useTheme hook sets the default theme mode (light or dark).
 */

const useTheme = () => {
	useEffect(() => {
		const isLightTheme = getDefaultTheme();
		if (isLightTheme) document.documentElement.setAttribute('theme', 'light');
		else document.documentElement.setAttribute('theme', 'dark');
	}, []);
};

export default useTheme;
