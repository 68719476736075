import { InboxSvg } from 'icons';
import React from 'react';
import { useHistory } from 'react-router';
import styles from './InboxButton.module.css';

function InboxButton() {
	const history = useHistory();
	const handleOnClick = () => {
		history.push('/inbox');
	};

	return (
		<button type='button' className={styles.button} onClick={handleOnClick}>
			<InboxSvg className={styles.svg} />
		</button>
	);
}

export default InboxButton;
