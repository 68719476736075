import { profileSvg } from 'icons';
import React from 'react';
import styles from './ExamplesLearnings.module.css';
import LEARNINGS_CONFIG from './learningsConfig';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import { shuffleArray } from 'utils';

function ExamplesLearnings() {
	const history = useHistory();
	shuffleArray(LEARNINGS_CONFIG);
	return (
		<section className={styles.examplesLearnings}>
			<h1 className={styles.heading}>Examples of Learnings</h1>
			<div className={styles.learningCards}>
				{LEARNINGS_CONFIG.slice(0, 6).map((item) => (
					<LearningCard {...item} key={item.link} />
				))}
			</div>
			<Button
				className={styles.button}
				onClick={() => {
					history.push('/popular');
				}}
			>
				🔎 See More Learnings
			</Button>
		</section>
	);
}

function LearningCard({ img, title, author, authorImg, link }) {
	return (
		<a href={link} className={styles.learningCard}>
			<div className={styles.cardTop}>
				<img className={styles.cardImg} src={img} alt={''} />
			</div>
			<div className={styles.cardBottom}>
				<div className={styles.title}>{title}</div>
				<div className={styles.authorContainer}>
					<img
						className={styles.authorImg}
						src={authorImg || profileSvg}
						alt={'Author'}
					/>
					<div className={styles.author}>{author}</div>
				</div>
			</div>
		</a>
	);
}

export default ExamplesLearnings;
