import React from 'react';
import styles from './CodeBlock.module.css';

function CodeBlock({ attributes, children, element }) {
	return (
		<pre {...attributes} spellCheck={false} className={styles.pre}>
			<code className={styles.code}>{children}</code>
		</pre>
	);
}

export default CodeBlock;
