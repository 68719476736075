import React, { useState } from 'react';
import ErrorMsg from '../../ErrorMsg';
import CloseButton from '../CloseButton';
import styles from './DeleteLearning.module.css';
import PrimaryButton from 'components/Button';
import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationUpdateUser,
	mutationDeleteLearning,
	mutationDeleteTag,
} from 'graphql/customMutations';
import { queryGetTagsByLearning } from 'graphql/customQueries';
import { useParams } from 'react-router-dom';
import update from 'immutability-helper';
import { getNewOrder, useUser } from 'utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';

function DeleteLearning({ modal, setIsOpenFalse, setJourney }) {
	const { title, id: learningID } = modal;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [user, setUser] = useUser();
	const { withinChapterID = 'home' } = useParams();

	const handleDelete = async () => {
		if (loading) return;
		setLoading(true);
		setError('');
		const success = await deleteLearning({
			learningID,
			user,
			setUser,
			withinChapterID,
			setJourney,
		});
		if (success) setIsOpenFalse();
		else {
			setLoading(false);
			setError(GENERAL_ERROR_MSG);
		}
	};

	return (
		<div className={styles.deleteLearning}>
			<CloseButton onClick={setIsOpenFalse} />
			{error && <ErrorMsg error={error} />}
			<h1 className={styles.title}>Delete Learning Confirmation</h1>
			<p className={styles.text}>
				Are you sure you want to delete learning <b>{title}</b>?
			</p>
			<div className={styles.buttonGroup}>
				<PrimaryButton
					primary={false}
					onClick={setIsOpenFalse}
					children={'No, hold on to it'}
				/>
				<PrimaryButton
					className={styles.deleteButton}
					onClick={handleDelete}
					loading={loading}
					children={'Yes, please delete it'}
				/>
			</div>
		</div>
	);
}

// delete learning, return true on success
const deleteLearning = async ({
	learningID,
	user,
	setUser,
	withinChapterID,
	setJourney,
}) => {
	// 1. Delete Learning in database
	try {
		const input = {
			learningID,
			type: 'learning',
		};
		await API.graphql(graphqlOperation(mutationDeleteLearning, { input }));
	} catch (error) {
		console.log('Error deleting learning: ', error);
		return false;
	}
	// 2. Delete Learning in learningOrder
	const [newLearningOrder, success] = getNewOrder(
		user.learningOrder,
		withinChapterID,
		learningID,
		'delete'
	);
	if (success) {
		try {
			const input = {
				userID: user.userID,
				learningOrder: newLearningOrder,
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
		} catch (error) {
			console.log('Error updating learningOrder: ', error);
			// ignore error
		}
	}
	// 3. Delete learning locally and in cache
	const key = `${user.userID}-${withinChapterID}`;
	setJourney((prev) => {
		const idx = prev[key].findIndex((item) => item.learningID === learningID);
		return update(prev, { [key]: { $splice: [[idx, 1]] } });
	});
	// 4. Delete Learning Tag
	try {
		const variables = {
			learningID,
		};
		const res = await API.graphql({
			...graphqlOperation(queryGetTagsByLearning, variables),
			authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
		});
		const tagsItems = res.data.listTags.items;
		tagsItems.forEach(async (input) => {
			try {
				await API.graphql(graphqlOperation(mutationDeleteTag, { input }));
			} catch (error) {
				console.log('Error deleting tag: ', input);
				// ignore error
			}
		});
	} catch (error) {
		console.log('Error getting tags: ', error);
		// ignore error
	}
	return true;
};

export default DeleteLearning;
