import styles from './PageNotFound.module.css';
import PrimaryButton from 'components/Button';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import EmojiArt from './EmojiArt';

const TITLE = 'TipStory - Page Not Found';
function PageNotFound() {
	const [numClick, setNumClick] = useState(0);
	const history = useHistory();
	const displayGo = numClick >= 5;
	return (
		<div className={styles.pageNotFound}>
			<Helmet>
				<title>{TITLE}</title>
				<meta name='description' content={TITLE} />
				<meta property='og:title' content={TITLE} />
				<meta property='og:description' content={TITLE} />
			</Helmet>
			<EmojiArt />
			<PrimaryButton
				primary={!displayGo}
				onClick={() => setNumClick((prev) => prev + 1)}
				children={'Oops, 404 Page not found 😶'}
				className={styles.button}
			/>
			{displayGo && (
				<PrimaryButton
					onClick={() => history.push('/learning/YrihGZn6dZZ0wh7')}
					children={'Learn how to build this page 😃'}
					className={styles.button}
				/>
			)}
		</div>
	);
}

export default PageNotFound;
