import React, { useState } from 'react';
import styles from './SubmissionForm.module.css';
import PrimaryButton from 'components/Button';
import update from 'immutability-helper';
import { useUser } from 'utils';
import TextareaAutosize from 'react-textarea-autosize';
import { API, graphqlOperation } from 'aws-amplify';
import {
	mutationCreateScholar,
	mutationUpdateUser,
} from 'graphql/customMutations';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import classNames from 'classnames';

function SubmissionForm({ setIsSubmitted, year }) {
	const [user, setUser] = useUser();
	const [form, setForm] = useState({
		userID: user.userID,
		firstName: user.firstName || '',
		lastName: user.lastName || '',
		email: '',
		schoolName: '',
		schoolAddress: '',
		schoolYear: '',
		schoolYearOther: '',
		topic: '',
		drawMost: '',
		volunteerHour: '',
		hearAbout: localStorage.getItem('TipStoryScholarNominator') || '',
		anythingElse: '',
		status: 'pending',
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const handleChange = async (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setForm((prev) => update(prev, { [name]: { $set: value } }));
		if (name === 'schoolYearOther' && form.schoolYear !== 'Other') {
			setForm((prev) => update(prev, { schoolYear: { $set: 'Other' } }));
		}
	};

	const handleApply = async () => {
		// Return if not login
		if (!user.isLogin) {
			setError('Please login to complete your application');
			return;
		}
		// Return if error
		if (checkError(form, setError)) return;
		// Return if loading
		if (loading) return;

		// Start Submission
		setLoading(true);
		setError('');
		try {
			// 1. Create Scholar Application
			form.localCreatedAt = new Date().toISOString();
			form.profileLink = `${user.profileURL}-${user.profileURLIdx}`;
			await API.graphql(
				graphqlOperation(mutationCreateScholar, { input: form })
			);
			// 2. Update profile with scholarStatus = 'pending'
			const userInput = {
				userID: user.userID,
				scholarStatus: 'pending',
				volunteerHour: form.volunteerHour,
			};
			const newUserProfile = await API.graphql(
				graphqlOperation(mutationUpdateUser, { input: userInput })
			);
			const newUser = newUserProfile.data.updateUser;
			setUser((prev) => update(prev, { $merge: newUser }));
			// Remove nominator
			localStorage.removeItem('TipStoryScholarNominator');
		} catch (error) {
			console.log('Error: ', error);
			setError(GENERAL_ERROR_MSG);
		}
		// Done submitting
		setLoading(false);
		setIsSubmitted(true);
	};

	return (
		<div
			className={classNames(styles.scholarForm, {
				[styles.blur]: !user.isLogin,
			})}
		>
			{user.isLogin && (
				<>
					<h2 className={styles.h2}>
						I am so down and ready to be a TipStory Scholar
					</h2>
					<p className={styles.p}>
						Fantastic! Now fill out the application and apply to be a TipStory
						Scholar😃
					</p>
				</>
			)}
			{!user.isLogin && (
				<h2 className={styles.h2}>🎓{year} TipStory Scholar Application</h2>
			)}
			<div className={styles.formDiv}>
				<Input
					title={'First name'}
					name={'firstName'}
					value={form.firstName || ''}
					handleChange={handleChange}
				/>
				<Input
					title={'Last name'}
					name={'lastName'}
					value={form.lastName || ''}
					handleChange={handleChange}
				/>
				<Input
					title={'Email'}
					name={'email'}
					value={form.email || ''}
					handleChange={handleChange}
				/>
				<Input
					title={'Name of your high school / college'}
					name={'schoolName'}
					value={form.schoolName || ''}
					handleChange={handleChange}
				/>
				<Input
					title={'Address of your high school / college'}
					name={'schoolAddress'}
					value={form.schoolAddress || ''}
					handleChange={handleChange}
				/>
				<SchoolYearInput
					title={'Your school year'}
					name={'schoolYear'}
					value={form.schoolYear || ''}
					handleChange={handleChange}
					other={form.schoolYearOther || ''}
				/>
				<ParagraphInput
					title={'What topics and learnings you plan to write about and share?'}
					name={'topic'}
					value={form.topic || ''}
					handleChange={handleChange}
				/>
				<ParagraphInput
					title={'What draws you most to the TipStory Scholar program?'}
					name={'drawMost'}
					value={form.drawMost || ''}
					handleChange={handleChange}
				/>
				<VolunteerHourInput
					title={
						'Some students are not interested in receiving volunteer hours. Do you want to receive the Volunteering Certificate indicating your volunteer hours?'
					}
					name={'volunteerHour'}
					value={form.volunteerHour || ''}
					handleChange={handleChange}
				/>
				<Input
					title={'How did you hear about TipStory Scholar?'}
					name={'hearAbout'}
					value={form.hearAbout || ''}
					handleChange={handleChange}
				/>
				<ParagraphInput
					title={'Anything else you want to share with us?'}
					name={'anythingElse'}
					value={form.anythingElse || ''}
					handleChange={handleChange}
				/>
				<div className={styles.buttonDiv}>
					<PrimaryButton
						className={styles.button}
						onClick={handleApply}
						loading={loading}
					>
						Apply Now
					</PrimaryButton>
				</div>
				{error && <p className={styles.error}>{error}</p>}
			</div>
		</div>
	);
}

function Input({ title, name, value, handleChange }) {
	const [user] = useUser();
	return (
		<div className={styles.inputDiv}>
			<label className={styles.label}>{title}</label>
			<input
				type='text'
				name={name}
				value={value}
				autoComplete={'off'}
				onChange={handleChange}
				className={styles.input}
				disabled={!user.isLogin}
			/>
		</div>
	);
}

function SchoolYearInput({ title, name, value, handleChange, other }) {
	const [user] = useUser();
	const schoolYears = ['Freshman', 'Sophomore', 'Junior', 'Senior', 'Other'];
	return (
		<div className={styles.inputDiv}>
			<label className={styles.label}>{title}</label>
			<div className={styles.schoolYearLabelDiv}>
				{schoolYears.map((item) => (
					<label className={styles.schoolYearLabel} key={item}>
						<input
							type='radio'
							name={name}
							value={item}
							checked={value === item}
							onChange={handleChange}
							disabled={!user.isLogin}
						/>
						<div className={styles.schoolYearLabelItem}>{item}</div>
						{item === 'Other' && (
							<input
								type='text'
								name={'schoolYearOther'}
								value={other}
								autoComplete={'off'}
								onChange={handleChange}
								className={`${styles.input} ${styles.otherInput}`}
								disabled={!user.isLogin}
							/>
						)}
					</label>
				))}
			</div>
		</div>
	);
}

function ParagraphInput({ title, name, value, handleChange }) {
	const [user] = useUser();
	return (
		<div className={styles.inputDiv}>
			<label className={styles.label}>{title}</label>
			<TextareaAutosize
				type='text'
				name={name}
				value={value}
				autoComplete={'off'}
				minRows={1}
				onChange={handleChange}
				className={styles.input}
				disabled={!user.isLogin}
			/>
		</div>
	);
}

function VolunteerHourInput({ title, name, value, handleChange }) {
	const [user] = useUser();
	const volunteerHours = [
		{
			choice: 'yes',
			text: `Yes, please. I'd love to receive the Volunteering Certificate indicating my volunteer hours.`,
		},
		{
			choice: 'no',
			text: `No, thanks. I don't need to receive volunteer hours.`,
		},
	];
	return (
		<div className={styles.inputDiv}>
			<label className={styles.label}>{title}</label>
			<div className={styles.schoolYearLabelDiv}>
				{volunteerHours.map(({ choice, text }) => (
					<label className={styles.schoolYearLabel} key={choice}>
						<div>
							<input
								type='radio'
								name={name}
								value={choice}
								checked={value === choice}
								onChange={handleChange}
								disabled={!user.isLogin}
							/>
						</div>
						<div className={styles.schoolYearLabelItem}>{text}</div>
					</label>
				))}
			</div>
		</div>
	);
}

const validateEmail = (email) => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
};

const checkError = (form, setError) => {
	if (!form.firstName) {
		setError('Please enter your first name');
		return true;
	}
	if (!form.lastName) {
		setError('Please enter your last name');
		return true;
	}
	if (!form.email) {
		setError('Please enter your email');
		return true;
	}
	if (!validateEmail(form.email)) {
		setError('Please enter a valid email');
		return true;
	}
	if (!form.schoolName) {
		setError('Please enter the name of your school');
		return true;
	}
	if (!form.schoolAddress) {
		setError('Please enter the address of your school');
		return true;
	}
	if (!form.schoolYear) {
		setError('Please select your school year');
		return true;
	}
	if (!form.topic) {
		setError(
			'Please share some topics you are interested in and would like to write about and share'
		);
		return true;
	}
	if (!form.drawMost) {
		setError(
			'Please share the reason that draws you to be part of this program'
		);
		return true;
	}
	if (!form.volunteerHour) {
		setError(
			'Please select if you would like to receive volunteer hours or not'
		);
		return true;
	}
	if (!form.hearAbout) {
		setError('Please share how you hear about the program');
		return true;
	}
	return false;
};

export default SubmissionForm;
