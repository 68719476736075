import React from 'react';
import styles from './ReadButton.module.css';
import {
	HelpfulInactiveSvg,
	HelpfulActiveSvg,
	InterestingInactiveSvg,
	InterestingActiveSvg,
	ReadInactiveSvg,
	ReadActiveSvg,
} from 'icons';
import {
	mutationCreateRead,
	mutationDeleteRead,
} from 'graphql/customMutations';
import { API, graphqlOperation } from 'aws-amplify';
import update from 'immutability-helper';
import { useLogin, useUser } from 'utils';
import { useParams } from 'react-router';

function ReadButton(props) {
	return <Button {...props} type='read' />;
}

export function HelpfulButton(props) {
	return <Button {...props} type='helpful' />;
}

export function InterestingButton(props) {
	return <Button {...props} type='interesting' />;
}

function Button(props) {
	const { learningID, readList, type } = props;
	const readStatus = readList[learningID] || [];
	const active = readStatus.includes(type);
	let svg, activeStyle, text;
	switch (type) {
		case 'helpful':
			svg = active ? <HelpfulActiveSvg /> : <HelpfulInactiveSvg />;
			activeStyle = active ? styles.helpfulActive : '';
			text = 'Helpful';
			break;
		case 'interesting':
			svg = active ? <InterestingActiveSvg /> : <InterestingInactiveSvg />;
			activeStyle = active ? styles.interestingActive : '';
			text = 'Interesting';
			break;
		case 'read':
		default:
			svg = active ? <ReadActiveSvg /> : <ReadInactiveSvg />;
			activeStyle = active ? styles.readActive : '';
			text = 'Read';
	}

	const [user, setUser] = useUser();
	const [, setLogin] = useLogin();
	const { connectUser, dailyPopularLastReset, setReadList, setLearnings } =
		props;
	const { userID: authorID } = connectUser || {};
	// parentLearningID is used to fix bug in saved learning
	const { learningID: parentLearningID } = useParams();

	const handleClick = () => {
		if (!user.isLogin) {
			setLogin({ visible: true, state: 'login' });
			return;
		}
		const input = {
			learningID,
			userID: user.userID,
			authorID,
			dailyPopularLastReset,
		};
		handleStatusChange({
			learningID,
			readStatus,
			input,
			type,
			setLearnings,
			setUser,
			setReadList,
			parentLearningID,
		});
	};

	return (
		<button type={'button'} className={styles.button} onClick={handleClick}>
			<div className={styles.buttonSvg}>{svg}</div>
			<span className={activeStyle}>{text}</span>
		</button>
	);
}

const handleStatusChange = async ({
	learningID,
	readStatus,
	input,
	type,
	setLearnings,
	setUser,
	setReadList,
	parentLearningID,
}) => {
	const readIsActive = readStatus.includes('read');
	const helpfulIsActive = readStatus.includes('helpful');
	const interestingIsActive = readStatus.includes('interesting');
	const readIsClick = type === 'read';
	const helpfulIsClick = type === 'helpful';
	const interestingIsClick = type === 'interesting';

	const newReadStatus = [...readStatus];

	const addToReadStatus = async (type) => {
		const firstSuccess = await createRead(input, type);
		if (!firstSuccess) {
			if (type !== 'read') return;
			// Patch due to dailyPopularLastReset with read
			const newInput = {
				...input,
				dailyPopularLastReset: '2999-01-01T00:00:00.000Z',
			};
			const secondSuccess = await createRead(newInput, type);
			if (!secondSuccess) return;
		}
		newReadStatus.push(type);
		const incr = 1;
		addToIconNum({
			type,
			incr,
			learningID,
			setLearnings,
			setUser,
			parentLearningID,
		});
	};

	const deleteFromReadStatus = async (type) => {
		const firstSuccess = await deleteRead(input, type);
		if (!firstSuccess) {
			if (type !== 'read') return;
			// Patch due to dailyPopularLastReset with read
			const newInput = {
				...input,
				dailyPopularLastReset: '2999-01-01T00:00:00.000Z',
			};
			const secondSuccess = await deleteRead(newInput, type);
			if (!secondSuccess) return;
		}
		const idx = newReadStatus.indexOf(type);
		newReadStatus.splice(idx, 1);
		const incr = -1;
		addToIconNum({
			type,
			incr,
			setLearnings,
			setUser,
			parentLearningID,
		});
	};

	if (helpfulIsClick) {
		if (!helpfulIsActive) {
			await addToReadStatus('helpful');
			if (!readIsActive) await addToReadStatus('read');
		} else {
			await deleteFromReadStatus('helpful');
		}
	}

	if (interestingIsClick) {
		if (!interestingIsActive) {
			await addToReadStatus('interesting');
			if (!readIsActive) await addToReadStatus('read');
		} else {
			await deleteFromReadStatus('interesting');
		}
	}

	if (readIsClick) {
		if (!readIsActive) {
			await addToReadStatus('read');
		} else {
			await deleteFromReadStatus('read');
			if (helpfulIsActive) await deleteFromReadStatus('helpful');
			if (interestingIsActive) await deleteFromReadStatus('interesting');
		}
	}

	setReadList((prev) =>
		update(prev, { [learningID]: { $set: newReadStatus } })
	);
};

const createRead = async (input, type) => {
	const finalInput = { ...input, type };
	try {
		await API.graphql(
			graphqlOperation(mutationCreateRead, { input: finalInput })
		);
		return true;
	} catch (error) {
		console.log('Error creating read: ', error);
		return false;
	}
};

const deleteRead = async (input, type) => {
	const finalInput = { ...input, type };
	try {
		await API.graphql(
			graphqlOperation(mutationDeleteRead, { input: finalInput })
		);
		return true;
	} catch (error) {
		console.log('Error deleting read: ', error);
		return false;
	}
};

// Add +1 / -1 to numHelpful / numInterestsing / numRead
const addToIconNum = ({
	type,
	incr,
	setLearnings,
	setUser,
	parentLearningID,
}) => {
	if (type === 'helpful') {
		setLearnings((prev) =>
			update(prev, {
				[parentLearningID]: {
					numHelpful: { $apply: (n) => n + incr },
				},
			})
		);
		setUser((prev) =>
			update(prev, { numHelpfulMark: { $apply: (n) => n + incr } })
		);
	}
	if (type === 'interesting') {
		setLearnings((prev) =>
			update(prev, {
				[parentLearningID]: {
					numInteresting: { $apply: (n) => n + incr },
				},
			})
		);
		setUser((prev) =>
			update(prev, {
				numInterestingMark: { $apply: (n) => n + incr },
			})
		);
	}
	if (type === 'read') {
		setUser((prev) =>
			update(prev, {
				numReadMark: { $apply: (n) => n + incr },
			})
		);
	}
};

export default ReadButton;
