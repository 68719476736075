import { DeleteSvg } from 'icons';
import React from 'react';
import Item from '../Item';

function ChapterMenu({ menu, setModal }) {
	return (
		<>
			<DeleteChapter menu={menu} setModal={setModal} />
		</>
	);
}

function DeleteChapter({ menu, setModal }) {
	const handleOnClick = () => {
		setModal({
			isOpen: true,
			state: 'deleteChapter',
			title: menu.title,
			id: menu.id,
		});
	};
	return <Item svg={<DeleteSvg />} text={'Delete'} onClick={handleOnClick} />;
}

export default ChapterMenu;
