import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './Profile.module.css';
import { CHAPTER_ID_LENGTH } from 'utils/constants';
import Reads from './Reads';
import FollowContainer from './FollowContainer';
import Meta from './Meta';
import User from './User';
import NavBar from './NavBar';
import Journey from './Journey';
import { useGetProfile } from './hooks';
import PinLearning from './PinLearning';

function Profile() {
	// Profile
	const [profile, setProfile] = useState({});
	const [error, setError] = useState('');

	// Direct to learning home for invalid chapterID (length 17)
	const {
		fullProfileURL,
		profileSection = 'chapter',
		withinChapterID = 'home',
	} = useParams();
	const history = useHistory();
	if (
		withinChapterID !== 'home' &&
		withinChapterID.length !== CHAPTER_ID_LENGTH
	) {
		history.push(`/journey/${fullProfileURL}`);
	}

	// Get profile hook
	useGetProfile({ profile, setProfile, setError });

	const displayJourney = !error && profileSection === 'chapter';
	const displayReads = !error && profileSection === 'reads';
	const displayFollowContainer =
		!error &&
		(profileSection === 'followers' || profileSection === 'following');
	const displayPinLearning =
		!error && profileSection === 'chapter' && profile.pinLearning;

	return (
		<>
			<Meta profile={profile} />
			<main className={styles.main}>
				{error && <h1 className={styles.error}>{error}</h1>}
				<User profile={profile} setProfile={setProfile} />
				<NavBar profile={profile} />
				{displayJourney && <Journey profile={profile} />}
				{displayReads && <Reads profile={profile} />}
				{displayFollowContainer && <FollowContainer profile={profile} />}
				{displayPinLearning && <PinLearning profile={profile} />}
			</main>
		</>
	);
}

export default Profile;
