import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { getDecodedUrl } from 'utils';
import { WEBSITE_DESCRIPTION, WEBSITE_TITLE } from 'utils/constants';

const ORGANIZATION = {
	'@context': 'https://schema.org',
	'@type': 'Organization',
	url: 'https://www.tipstory.org',
	logo: 'https://www.tipstory.org/logo512.png',
};

function Meta({ state }) {
	const { tag } = useParams();
	const searchTag = tag && getDecodedUrl(tag);
	let title;
	switch (state) {
		case 'popular':
			title = WEBSITE_TITLE;
			break;
		case 'home':
			title = 'TipStory - Following';
			break;
		case 'latest':
			title = 'TipStory - Latest';
			break;
		case 'explore':
			title = 'TipStory - Explore';
			break;
		case 'tag':
			title = `${searchTag || 'Search'} - TipStory Tag`;
			break;
		default:
			title = WEBSITE_TITLE;
	}
	return (
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={WEBSITE_DESCRIPTION} />
			<meta property='og:url' content={window.location.href} />
			<meta property='og:title' content={title} />
			<meta property='og:description' content={WEBSITE_DESCRIPTION} />
			{state === 'popular' && (
				<script type='application/ld+json'>
					{JSON.stringify(ORGANIZATION)}
				</script>
			)}
		</Helmet>
	);
}

export default Meta;
