/**
 * Encode % with %25, + with %2B, / with %2F, space with +
 * It is used for tag url encoding
 *
 * @param {String} url
 */

const getEncodedUrl = (url = '') => {
	return url
		.replace(/%/g, '%25')
		.replace(/\+/g, '%2B')
		.replace(/\//g, '%2F')
		.replace(/ /g, '+');
};

/**
 * Decode + with space, %2F with /, %2B with +, %25 with %
 * It is used for tag url decoding
 *
 * @param {String} url
 */

export const getDecodedUrl = (url = '') => {
	return url
		.replace(/\+/g, ' ')
		.replace(/%2F/g, '/')
		.replace(/%2B/g, '+')
		.replace(/%25/g, '%');
};

export default getEncodedUrl;
