/**
 * getPlural returns 's' if n is > 1
 * and returns '' otherwises.
 *
 * @param {Number} n
 * @returns {String}
 * @example
 * // return 's'
 * const num = 3;
 * getPlural(num);
 */
const getPlural = (n) => {
	return n > 1 ? 's' : '';
};

export default getPlural;
