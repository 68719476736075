const colors = [
	// [10, 59, 56],
	// [28, 71, 54],
	// [39, 84, 57],
	// [49, 100, 55],
	// [85, 52, 60],
	// [162, 35, 66],
	// [199, 43, 56],
	// [222, 67, 61],
	// [251, 45, 56],
	// [318, 29, 47],
	// New set
	[0, 80, 50],
	[0, 100, 55],
	[23, 100, 65],
	[48, 100, 67],
	[102, 63, 59],
	[182, 73, 63],
	[202, 100, 61],
	[230, 100, 55],
	[260, 100, 66],
	[323, 100, 70],
];
const lightnessDegree = [0, 3, 2, 1, -1, -2, -3];
const lightnessGap = 8;
const colorPalette = lightnessDegree
	.map((degree) =>
		colors.map((color) => {
			const h = color[0];
			const s = color[1];
			const l = color[2] + degree * lightnessGap;
			return hslToHex(h, s, l);
		})
	)
	.flat();

// https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
// by icl7126
function hslToHex(h, s, l) {
	l /= 100;
	const a = (s * Math.min(l, 1 - l)) / 100;
	const f = (n) => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color)
			.toString(16)
			.padStart(2, '0'); // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
}
export default colorPalette;
