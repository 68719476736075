import React, { useState } from 'react';
import styles from './ScholarApplication.module.css';
import { useLogin, useUser } from 'utils';
import PrimaryButton from 'components/Button';
import SubmissionForm from './SubmissionForm';
import Description from './Description';
import { ScholarBronzeSvg, ScholarSilverSvg, ScholarGoldSvg } from 'icons';
import { useSaveNominator } from './hooks';

const year = new Date().toISOString().slice(0, 4);

function ScholarApplication() {
	const [isSubmitted, setIsSubmitted] = useState(false);
	// Save nominator query parameters
	useSaveNominator();
	return (
		<div>
			{!isSubmitted && <Description year={year} />}
			<BottomPanel setIsSubmitted={setIsSubmitted} />
		</div>
	);
}

function BottomPanel({ setIsSubmitted }) {
	const [user] = useUser();
	// Show SignUpForm if not login
	if (!user.isLogin) return <SignUpForm />;
	// Show ScholarWelcome if is a sholar
	if (user.scholar || user.scholarStatus === 'approved')
		return <ScholarWelcome />;
	// Show Pending if it is waiting
	if (user.scholarStatus && user.scholarStatus === 'pending')
		return <ScholarSubmitted />;
	return <SubmissionForm setIsSubmitted={setIsSubmitted} year={year} />;
}

function SignUpForm() {
	const [, setLogin] = useLogin();
	return (
		<>
			<div className={styles.containerBlock}>
				<h1 className={styles.h1}>
					I am so down and ready to be a TipStory Scholar
				</h1>
				<p className={styles.p}>
					Fantastic! Now click the sign up button to create a TipStory account.
					Then fill out the application and apply to be a TipStory scholar😃
					<br />
					<br />
					⚠️<u>We highly recommend you sign up with a personal email</u> instead
					of school email, because many schools take away your school email
					access upon graduation.
				</p>
				<div className={styles.buttonDiv}>
					<PrimaryButton
						className={styles.button}
						onClick={() => setLogin({ visible: true, state: 'signUp' })}
					>
						Sign Up Now
					</PrimaryButton>
				</div>
			</div>
			<SubmissionForm year={year} />
		</>
	);
}

function ScholarSubmitted() {
	return (
		<div className={styles.containerBlock}>
			<h1 className={styles.h1}>🙌Application Submitted</h1>
			<div className={styles.p}>
				Thank you for your interest in being a TipStory Scholar. We have
				received your application and it is currently under review. We will
				contact you within a week or two if your application is approved via the
				email you provided in this application. (This email will come from
				tipstory.org and may go to your spam or promotions folder so keep an eye
				out)
			</div>
			<br />
			<p className={styles.p}>
				If you have any question in regards to your application status, please
				send us an email at{' '}
				<a className={styles.link} href='mailto:scholar@tipstory.org'>
					scholar@tipstory.org
				</a>
				.
			</p>
		</div>
	);
}

function ScholarWelcome() {
	const [user] = useUser();
	return (
		<div className={styles.containerBlock}>
			<h1 className={styles.h1}>🎉TipStory Scholar Welcome</h1>
			<div className={styles.p}>
				Congratulations, {user.firstName}! We have approved your application as
				a 🎓{year} TipStory Scholar! We look forward to what you will learn and
				grow, as well as the positive impact you will have by supporting and
				sharing knowledge with your peers🤝
				<br />
				<br />
				You now have access to the{' '}
				<a href={'/scholar'} className={styles.link}>
					TipStory Scholar Portal
				</a>{' '}
				where you can track your monthly tasks status and your progress to earn
				the next level of badge.
				<div className={styles.badgeList}>
					<ScholarBronzeSvg className={`${styles.svg} ${styles.bronze}`} />
					<ScholarSilverSvg className={`${styles.svg} ${styles.silver}`} />
					<ScholarGoldSvg className={`${styles.svg} ${styles.gold}`} />
				</div>
			</div>
		</div>
	);
}

export default ScholarApplication;
