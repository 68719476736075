import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from './Chapter.module.css';
import classNames from 'classnames';
import { FolderSvg } from 'icons';
import update from 'immutability-helper';

function Chapter({ chapter, index, setLearnings, setChapters }) {
	const { learningID: id, title } = chapter;

	// Store this chapter in a ref
	const ref = useRef(null);

	// dropSide is used to displays the resulting position of item when drag over
	// chapter, it can be one of the three values: left, center, right
	const [dropSide, setDropSide] = useState('');

	// chapter can be dragged if person is author and not on mobile view
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'chapter', id, index },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});

	// drop and hover action
	const moveChapterWrapper = (dragIndex, currentIdx) => {
		setChapters((preChapters) => {
			const dragChapter = preChapters[dragIndex];
			const newChapters = update(preChapters, {
				$splice: [
					[dragIndex, 1],
					[currentIdx, 0, dragChapter],
				],
			});
			return newChapters;
		});
	};

	const dropInChapter = ({ type, learningID }) => {
		if (type === 'learning')
			setLearnings((items) =>
				items.filter((item) => item.learningID !== learningID)
			);
		else
			setChapters((items) =>
				items.filter((item) => item.learningID !== learningID)
			);
	};

	const [{ isOver }, drop] = useDrop({
		accept: ['learningCard', 'chapter'],
		drop: async (item, monitor) => {
			if (!ref.current) return;
			// chapter can be dropped to the left/center/right of a chapter
			if (item.type === 'chapter') {
				// Return if drop to the same chapter
				const dragItemIndex = item.index;
				if (dragItemIndex === index) return;
				// Get left and right bound of chapter being hovered
				const boundingRect = ref.current.getBoundingClientRect();
				const horizontalWidth = boundingRect.right - boundingRect.left;
				const horizontalWidthAThird = horizontalWidth / 3;
				const leftBound = boundingRect.left + horizontalWidthAThird;
				const rightBound = leftBound + horizontalWidthAThird;
				// Get drag item's X value
				const dragItemX = monitor.getClientOffset().x;
				// On the left side of chapter
				if (dragItemX <= leftBound) {
					// Return if chapter is 1 chapter on the right of dragged chapter
					if (dragItemIndex + 1 === index) return;
					if (dragItemIndex < index)
						moveChapterWrapper(dragItemIndex, index - 1);
					else if (dragItemIndex > index)
						moveChapterWrapper(dragItemIndex, index);
				}
				// On the right side of chapter
				else if (dragItemX >= rightBound) {
					// Return if chapter is 1 chapter on the left of dragged chapter
					if (dragItemIndex - 1 === index) return;
					if (dragItemIndex < index) moveChapterWrapper(dragItemIndex, index);
					else if (dragItemIndex > index)
						moveChapterWrapper(dragItemIndex, index + 1);
				}
				// On the center of chapter
				else {
					const type = 'chapter';
					const learningID = item.id;
					dropInChapter({
						type,
						learningID,
					});
				}
			}
			// learning can only be dropped center of a chapter
			if (item.type === 'learningCard') {
				const type = 'learning';
				const learningID = item.parentLearningID || item.id;
				dropInChapter({
					type,
					learningID,
				});
			}
		},
		hover: (item, monitor) => {
			if (!ref.current) return;
			// Reset drop side
			setDropSide('');
			// chapter can be dropped to the left/center/right of a chapter
			if (item.type === 'chapter') {
				// Return if drop to the same chapter
				const dragItemIndex = item.index;
				if (dragItemIndex === index) return;
				// Get left and right bound of chapter being hovered
				const boundingRect = ref.current.getBoundingClientRect();
				const horizontalWidth = boundingRect.right - boundingRect.left;
				const horizontalWidthAThird = horizontalWidth / 3;
				const leftBound = boundingRect.left + horizontalWidthAThird;
				const rightBound = leftBound + horizontalWidthAThird;
				// Get drag item's X value
				const dragItemX = monitor.getClientOffset().x;
				// On the left side of chapter
				if (dragItemX <= leftBound) {
					// Return if chapter is 1 chapter on the right of dragged chapter
					if (dragItemIndex + 1 === index) return;
					setDropSide('left');
				}
				// On the right side of chapter
				else if (dragItemX >= rightBound) {
					// Return if chapter is 1 chapter on the left of dragged chapter
					if (dragItemIndex - 1 === index) return;
					setDropSide('right');
				}
				// On the center of chapter
				else {
					setDropSide('center');
				}
			}
			// learning can only be dropped center of a chapter
			if (item.type === 'learningCard') {
				setDropSide('center');
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
	});

	drop(drag(ref));

	const dropLeft = isOver && dropSide === 'left';
	const dropRight = isOver && dropSide === 'right';
	const dropCenter = isOver && dropSide === 'center';

	return (
		<div
			className={classNames(styles.chapter, {
				[styles.dropLeft]: dropLeft,
				[styles.dropRight]: dropRight,
				[styles.dropCenter]: dropCenter,
			})}
			ref={ref}
		>
			{/* Show Button for canDrag, show Link otherwise */}
			<button
				type={'button'}
				id={`chapter-${id}`}
				title={title}
				className={classNames(styles.button, {
					[styles.isDragging]: isDragging,
				})}
			>
				<Content title={title} />
			</button>
		</div>
	);
}

function Content({ title }) {
	return (
		<>
			<div className={styles.svgDiv}>
				<FolderSvg className={styles.svg} />
			</div>
			<h1 className={styles.title}>{title}</h1>
		</>
	);
}

export default Chapter;
