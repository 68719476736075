import { useContext } from 'react';
import { UserContext } from 'App';

/**
 * useUser hook returns an array, in which the zero index is user,
 * and the first index is setUser.
 *
 * user is an object that contains properties isLogin, isLoading, userID
 * and other fields defined in User Schema.
 * (user is first processed in useCreateUser())
 *
 * setUser can be called to change fields in user.
 *
 * @returns {Array} [user, setUser]
 */

const useUser = () => {
	return useContext(UserContext);
};

export default useUser;
