import React from 'react';
import styles from './MainSection.module.css';
import TextareaAutosize from 'react-textarea-autosize';
import update from 'immutability-helper';
import { removeNewLineChars } from 'utils';
import Background from './Background';
import Editor from 'components/Editor';
import ButtonGroup from './ButtonGroup.js';

function MainSection(props) {
	return (
		<section className={styles.mainSection}>
			<Title {...props} />
			<Background {...props} />
			<Learning {...props} />
			<ButtonGroup {...props} />
		</section>
	);
}

function Title({ newLearning, setNewLearning, editMode }) {
	const { title } = newLearning;
	const handleOnChange = (event) => {
		const newTitle = removeNewLineChars(event.target.value);
		setNewLearning((prev) => update(prev, { title: { $set: newTitle } }));
	};
	return (
		<TextareaAutosize
			autoFocus={editMode ? true : false}
			autoComplete={'off'}
			className={styles.title}
			placeholder={'Give a title to this learning'}
			maxLength={200}
			value={title}
			minRows={1}
			onChange={handleOnChange}
		/>
	);
}

function Learning({ newLearning, setNewLearning }) {
	const { learning } = newLearning;
	const setLearning = (newLearning) => {
		setNewLearning((prev) => update(prev, { learning: { $set: newLearning } }));
	};

	return <Editor value={learning} setValue={setLearning} />;
}

export default MainSection;
