function timeSince(date) {
	const seconds = Math.floor((new Date().getTime() - date) / 1000);

	let interval;

	interval = seconds / 31536000;
	if (interval >= 1) {
		if (Math.floor(interval) === 1) return '1 year ago';
		return `${Math.floor(interval)} years ago`;
	}

	interval = seconds / 2592000;
	if (interval >= 1) {
		if (Math.floor(interval) === 1) return '1 month ago';
		if (Math.floor(interval) === 12) return '11 months ago';
		return `${Math.floor(interval)} months ago`;
	}

	interval = seconds / 86400;
	if (interval >= 1) {
		if (Math.floor(interval) === 1) return '1 day ago';
		return `${Math.floor(interval)} days ago`;
	}

	interval = seconds / 3600;
	if (interval >= 1) {
		if (Math.floor(interval) === 1) return '1 hour ago';
		return `${Math.floor(interval)} hours ago`;
	}

	interval = seconds / 60;
	if (interval >= 1) {
		if (Math.floor(interval) === 1) return '1 min ago';
		return `${Math.floor(interval)} mins ago`;
	}

	if (seconds < 2) return `${seconds} second ago`;

	return `${seconds} seconds ago`;
}

/**
 * For a ISO 8601 date string, if todayAccuracy is true, return
 * whether it is Today or how long has time past since today.
 * If todayAccuracy is false, return time since in seconds accuracy.
 *
 * @param {String} str
 * @param {Boolean} todayAccuracy
 * @return {String}
 */

export const getFormatDateTimeSince = (
	str = new Date().toISOString(),
	todayAccuracy = true
) => {
	const date = new Date(str).toDateString();
	const now = new Date().toDateString();

	if (date === now && todayAccuracy) {
		return 'Today';
	} else {
		return timeSince(new Date(str).getTime());
	}
};

/**
 * For a ISO 8601 date string, return whether it is Today
 * or its date format MMM DD, YYYY.
 *
 * @param {String} str
 * @return {String}
 */

export const getFormatDate = (str = new Date().toISOString()) => {
	const date = new Date(str).toDateString();
	const now = new Date().toDateString();

	if (date === now) {
		return 'Today';
	} else {
		const dateArr = date.split(' ');
		const dateString = `${dateArr[1]} ${Number(dateArr[2])}, ${dateArr[3]}`;
		return dateString;
	}
};

export default timeSince;
