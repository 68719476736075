import { FolderSvg, NoteSvg } from 'icons';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Item from '../Item';

// New Learning, New Chapter
function JourneyMenu({ setModal }) {
	return (
		<>
			<NewLearning />
			<NewChapter setModal={setModal} />
		</>
	);
}

function NewLearning() {
	const history = useHistory();
	const { withinChapterID } = useParams();
	const handleOnClick = () => {
		if (!withinChapterID) history.push('/new');
		else history.push(`/new?chapterID=${withinChapterID}`);
	};

	return (
		<Item
			name={'newLearning'}
			svg={<NoteSvg />}
			text={'New Learning'}
			onClick={handleOnClick}
		/>
	);
}

function NewChapter({ setModal }) {
	const handleOnClick = () => {
		setModal({ isOpen: true, state: 'newChapter' });
	};

	return (
		<Item
			name={'newChapter'}
			svg={<FolderSvg />}
			text={'New Chapter'}
			onClick={handleOnClick}
		/>
	);
}

export default JourneyMenu;
