import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from 'utils';
import { GENERAL_ERROR_MSG } from 'utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import { queryGetFollowingByUserId } from 'graphql/customQueries';

/**
 * useGetFollowing hook gets following of profile user.
 *
 * @param {Object}
 */
const useGetFollowing = ({ profile, setProfiles, setError }) => {
	const [user] = useUser();
	const { profileSection } = useParams();

	useEffect(() => {
		// Skip if user is loading
		if (user.isLoading) return;

		// Skip if profile.userID is empty
		if (!profile.userID) return;

		// Skip if profileSection is not following
		if (profileSection !== 'following') return;

		const getFollowingByUserID = async () => {
			const variables = {
				userID: profile.userID,
				visitorUserID: user.userID,
				isLogin: user.isLogin,
			};
			try {
				const res = await API.graphql({
					...graphqlOperation(queryGetFollowingByUserId, variables),
					authMode: user.isLogin ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
				});
				const followingItems = res.data.getFollowingByUserID.items;
				const followingItemsProcessed = [];
				followingItems.forEach((item) => {
					const connectFollowUser = item.connectFollowUser;
					if (connectFollowUser) {
						const { connectFollow, ...props } = connectFollowUser;
						const followed = connectFollow && connectFollow.items.length > 0;
						followingItemsProcessed.push({ ...props, followed });
					}
				});
				setProfiles(followingItemsProcessed);
			} catch (error) {
				console.log('Error getting following: ', error);
				setError(GENERAL_ERROR_MSG);
			}
		};
		getFollowingByUserID();
	}, [
		user.isLoading,
		profile.userID,
		user.userID,
		profileSection,
		setProfiles,
		setError,
		user.isLogin,
	]);
};

export default useGetFollowing;
