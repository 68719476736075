import React from 'react';
import styles from './Sidebar.module.css';
import { FolderSvg } from 'icons';
import { getChapterPathArray, getSortedLearnings } from 'utils';
import Breadcomb from './Breadcomb';
import update from 'immutability-helper';
import SidebarLearning from './SidebarLearning';

function Sidebar({
	sidebarLearning,
	readList,
	sidebarVariable,
	setSidebarVariable,
	desktopStyle = true,
}) {
	const { firstName = '' } = sidebarVariable;
	const dispalySidebarLearnings = sidebarLearning.length !== 0;
	// Sort learning
	const { withinChapterID, learningOrder, chapterOrder } = sidebarVariable;
	const unsortedChapters = sidebarLearning.filter((i) => i.type === 'chapter');
	const unsortedLearnings = sidebarLearning.filter(
		(i) => i.type === 'learning' || i.type === 'saveLearning'
	);
	const sortedChapters = getSortedLearnings(
		chapterOrder,
		withinChapterID,
		unsortedChapters
	);
	const sortedLearnings = getSortedLearnings(
		learningOrder,
		withinChapterID,
		unsortedLearnings
	);

	const handleChapterClick = (chapterID) => {
		setSidebarVariable((prev) =>
			update(prev, { withinChapterID: { $set: chapterID } })
		);
	};

	const className = desktopStyle
		? `${styles.sidebar} ${styles.hideInMobile}`
		: `${styles.mobileSidebar} ${styles.hideInDesktop}`;

	return (
		<section className={className}>
			<h1 className={styles.sidebarTitle}>{firstName}'s Learning Journey</h1>
			<BreadcombNav
				sidebarVariable={sidebarVariable}
				handleChapterClick={handleChapterClick}
			/>
			{dispalySidebarLearnings && (
				<nav>
					{sortedChapters.map((item) => (
						<SidebarChapter
							key={item.learningID}
							title={item.title}
							onClick={() => handleChapterClick(item.learningID)}
						/>
					))}
					{sortedLearnings.map((item) => (
						<SidebarLearning
							key={item.parentLearningID || item.learningID}
							readStatus={readList[item.learningID]}
							{...item}
						/>
					))}
				</nav>
			)}
			{!dispalySidebarLearnings && (
				<div className={styles.emptyMsg}>This chapter is a little empty 😕</div>
			)}
		</section>
	);
}

function BreadcombNav({ sidebarVariable, handleChapterClick }) {
	const { withinChapterID, chapterOrder, chapterName } = sidebarVariable;
	// Get chapter path for breadcomb
	const chapterPathArray = getChapterPathArray(chapterOrder, withinChapterID);

	// ChapterNameObj matches chapterID to name
	let chapterNameObj = {};
	if (chapterName) {
		try {
			chapterNameObj = JSON.parse(chapterName);
		} catch (error) {
			console.log('Error parsing chapterName: ', error);
		}
	}

	// breadcombList contains an array of chapter, each item is an object
	// that contains id (chapterID) and text (chapter name)
	const breadcombList = chapterPathArray.map((item) => {
		let name;
		if (item === 'home') name = 'Home';
		else name = chapterNameObj[item] || 'Untitled Chapter';
		return { id: item, text: name };
	});

	return (
		<Breadcomb
			list={breadcombList}
			onClick={handleChapterClick}
			className={styles.breadcomb}
		/>
	);
}

function SidebarChapter({ title, onClick }) {
	return (
		<button type='button' className={styles.sidebarChapter} onClick={onClick}>
			<div className={styles.sidebarChapterRow}>
				<div className={styles.sidebarSvgDiv}>
					<FolderSvg className={styles.sidebarSvg} />
				</div>
				<h1 className={styles.chapterH1}>{title}</h1>
			</div>
		</button>
	);
}

export default Sidebar;
