import React from 'react';
import { ScholarBronzeSvg, ScholarSilverSvg, ScholarGoldSvg } from 'icons';
import classNames from 'classnames';
import styles from './Description.module.css';

function Description({ year }) {
	return (
		<div>
			{/* 🎓TipStory Scholar Application */}
			<div className={styles.containerBlock}>
				<h1 className={styles.h1}>🎓{year} TipStory Scholar Application</h1>
				<p className={styles.p}>
					TipStory is now accepting students who love learning and sharing their
					learnings as {year} TipStory Scholars
				</p>
				<div className={styles.badgeList}>
					<ScholarBronzeSvg className={classNames(styles.svg, styles.bronze)} />
					<ScholarSilverSvg className={classNames(styles.svg, styles.silver)} />
					<ScholarGoldSvg className={classNames(styles.svg, styles.gold)} />
				</div>
			</div>
			{/* Who */}
			<div className={styles.containerBlock}>
				<h1 className={styles.h1}>Who</h1>
				<p className={styles.p}>
					For any student age 13 or older who is curious, caring and also
					passionate about learning and sharing what they have learned
				</p>
			</div>
			{/* What */}
			<div className={styles.containerBlock}>
				<h1 className={styles.h1}>What will you do</h1>
				<ol className={styles.p}>
					<li>
						Write and share at least 1 learning post per month on any topics
						that interest you or might be helpful to others📝
						<blockquote className={styles.p}>
							Learning post can be a topic of your favorite class (e.g. math,
							history, science, English), a project, a tutorial, an event, a
							reflection, and many more… <br />
							To see some examples👀, check out{' '}
							<a
								target='_blank'
								rel='noreferrer'
								href='https://www.tipstory.org/learning/xGEFAIU1RKZuKsP'
								className={styles.link}
							>
								Moe’s tutorial on a math topic🔢
							</a>{' '}
							or{' '}
							<a
								target='_blank'
								rel='noreferrer'
								href='https://www.tipstory.org/learning/0pvL1GFZVRPRLQy'
								className={styles.link}
							>
								Christine’s reflection on her first choreograph project👯‍♀️
							</a>
						</blockquote>
					</li>
					<li>Read and learn 2 learning posts shared by others per month📖</li>
					<li>
						Write and share 2 comments to learning posts shared by others per
						month💬
					</li>
				</ol>
			</div>
			{/* Why */}
			<div className={styles.containerBlock}>
				<h1 className={styles.h1}>Why</h1>
				<ol className={styles.p}>
					<li>
						Earn a TipStory Scholar Honorary Certificate for your accomplishment
						and a Volunteering Certificate indicating your volunteer hours for
						sharing learnings📜
					</li>
					<li>
						Develop better writing skills and deeper understanding of your
						thoughts💭
					</li>
					<li>
						Share learnings that might positively benefit your peers and
						others🌟 <br />
						*TipStory keeps track of your sharing impact with the number of
						helpful and interesting you received, which are metrics you can list
						on your resume or college application
					</li>
					<li>
						Learn and explore different interests, topics, and experiences from
						each other✨
					</li>
					<li>
						Join the most supportive learning community and meet curious and
						caring students from hundreds of schools that all share the passion
						to learn, grow, support, and share with each other🚀
					</li>
				</ol>
			</div>
			{/* Additional Q&A */}
			<div className={styles.containerBlock}>
				<h1 className={styles.h1}>Additional Q&As</h1>
				<div className={styles.p}>
					<b>How do I earn the TipStory Scholar Honorary Certificate?</b>
					<br />
					📜The Honorary Certificate has 3 honorary levels: Bronze, Silver and
					Gold, which is awarded based on the number of months of monthly tasks
					you complete, as listed in the “What will you do” section above. You
					will earn
					<br />
					<div className={styles.badge}>
						<div>
							<ScholarBronzeSvg
								className={classNames(styles.svg, styles.bronze)}
							/>
						</div>
						<div>
							A Bronze Honorary Certificate & Badge by completing 2 months of
							tasks
						</div>
					</div>
					<div className={styles.badge}>
						<div>
							<ScholarSilverSvg
								className={classNames(styles.svg, styles.silver)}
							/>
						</div>
						<div>
							A Silver Honorary Certificate & Badge by completing 4 months of
							tasks
						</div>
					</div>
					<div className={styles.badge}>
						<div>
							<ScholarGoldSvg className={classNames(styles.svg, styles.gold)} />
						</div>
						<div>
							A Gold Honorary Certificate & Badge (highest honorary level) by
							completing 6 months of tasks
						</div>
					</div>
				</div>
				<br />
				<div className={styles.p}>
					<b>How do I earn volunteer hours for sharing my learnings?</b>
					<br />
					Volunteer hours are awarded based on the word counts of your learning
					post. On average, it takes students 1 hour to think, compose and edit
					250 words of quality writing, so you would earn 1 volunteer hour for
					every 250 words of your learning post (rounded to the nearest 0.5 hour
					per post). <br /> ⚠️There are two restrictions you need to beware of:
					<ol>
						<li>
							For each learning post, you can earn up to a maximum of 5
							volunteer hours.
							<blockquote>
								For example, if you share a learning post that exceeds 1250
								words and has 2000 words, you would only earn 5 volunteer hours
								instead of 8.
							</blockquote>
						</li>
						<li>
							For any given month, you can earn up to a maximum of 10 volunteer
							hours.
							<blockquote>
								For example, if you share 3 learning posts in May and each post
								has 1000 words, you would only earn 10 volunteer hours instead
								of 12.
							</blockquote>
						</li>
					</ol>
				</div>
				<br />
				<div className={styles.p}>
					<b>
						How do I receive the Honorary Certificate and the Volunteering
						Certificate?
					</b>
					<br />
					You will have access to the{' '}
					<a href='/scholar' className={styles.link}>
						TipStory Scholar Portal
					</a>{' '}
					once your application is approved. Inside the Scholar Portal, you can
					track your progress toward various levels of Honorary Certificate and
					you can download your Honorary Certificate once you complete their
					requirements. Similarly, you can track your volunteer hours in the
					Portal and download your Volunteering Certificate once you share at
					least two learning posts. We design the process to be fully
					self-service and convenient for students😉
				</div>
				<br />
				<p className={styles.p}>
					If you have additional question, please send us an email at{' '}
					<a className={styles.link} href='mailto:scholar@tipstory.org'>
						scholar@tipstory.org
					</a>
					.
				</p>
			</div>
		</div>
	);
}

export default Description;
