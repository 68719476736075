import {
	Page,
	Text,
	View,
	Document,
	Image,
	Link,
	Font,
} from '@react-pdf/renderer';
import BronzeImg from '../certificate/Bronze.png';
import SilverImg from '../certificate/Silver.png';
import GoldImg from '../certificate/Gold.png';
import loraBIFont from '../certificate/LoraBI.ttf';
import loraIFont from '../certificate/LoraI.ttf';
Font.register({ family: 'LoraBI', src: loraBIFont });
Font.register({ family: 'LoraI', src: loraIFont });

function TipStoryCertificate({ numMonths, scholar, year }) {
	// Fix name size
	const name = `${scholar.firstName} ${scholar.lastName}`;
	let fontSize = '50px';
	if (name.length > 18) {
		fontSize = '40px';
	}

	// Display different honor
	let imgSrc = BronzeImg;
	if (numMonths >= 6) {
		imgSrc = GoldImg;
	} else if (numMonths >= 4) {
		imgSrc = SilverImg;
	}

	// Verification link
	const vertificationLink = `www.tipstory.org/scholar/certificate/${year}/${scholar.userID}`;

	return (
		<Document
			title={'TipStory Scholar Honorary Certificate'}
			author={'TipStory'}
			creator={'TipStory'}
			producer={'TipStory'}
			pdfVersion={'1.0'}
		>
			<Page size={'LEGAL'} orientation={'landscape'}>
				<View>
					<Image src={imgSrc} />
					<View
						style={{
							position: 'absolute',
							top: '235px',
							left: '297px',
							width: '612px',
							height: '162px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontSize,
								color: 'rgb(5, 151, 242)',
								fontFamily: 'LoraBI',
							}}
						>
							{name}
						</Text>
					</View>
					<View
						style={{
							position: 'absolute',
							top: '432px',
							left: '612px',
							width: '50px',
							height: '20px',
						}}
					>
						<Text
							style={{
								fontSize: '15px',
								fontFamily: 'LoraI',
							}}
						>
							{year}
						</Text>
					</View>
					<View
						style={{
							position: 'absolute',
							top: '465px',
							left: '297px',
							width: '612px',
							height: '20px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontSize: '10px',
								fontFamily: 'LoraI',
							}}
						>
							Certificate verification link:{' '}
							<Link src={vertificationLink}>{vertificationLink}</Link>
						</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
}

export default TipStoryCertificate;
