import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import LoginModal from 'components/LoginModal';
import LayoutHeader from 'components/LayoutHeader';
import Profile from 'components/Profile';
import NewLearning from 'components/NewLearning';
import Home from 'components/Home';
import Learning from 'components/Learning';
import Terms, { Privacy } from 'components/TermsPrivacy';
import PageNotFound from 'components/PageNotFound';
import { useCreateUser, useTheme } from 'utils';
import Inbox from 'components/Inbox';
const userDefault = { isLogin: false, isLoading: true };
export const UserContext = React.createContext(userDefault);
const loginDefault = { visible: false, state: 'signUp' };
export const LoginContext = React.createContext(loginDefault);

function App() {
	const userArray = useCreateUser(userDefault);
	const loginArray = useState(loginDefault);
	useTheme();
	const user = userArray[0];
	return (
		<LoginContext.Provider value={loginArray}>
			<UserContext.Provider value={userArray}>
				<LoginModal />
				<LayoutHeader />
				<Switch>
					<Route exact path='/'>
						{user.isLogin ? (
							<Home state={'popular'} />
						) : (
							<Home state={'landing'} />
						)}
					</Route>
					<Route exact path='/popular'>
						<Home state={'popular'} />
					</Route>
					<Route exact path='/landing'>
						<Home state={'landing'} />
					</Route>
					<Route exact path='/home'>
						<Home state={'home'} />
					</Route>
					<Route exact path='/latest'>
						<Home state={'latest'} />
					</Route>
					<Route exact path='/explore'>
						<Home state={'explore'} />
					</Route>
					<Route path='/tag/:tag'>
						<Home state={'tag'} />
					</Route>
					<Route path='/tag'>
						<Home state={'tag'} />
					</Route>
					<Route path='/about'>
						<Home state={'about'} />
					</Route>
					<Route path='/scholar-application'>
						<Home state={'scholarApplication'} />
					</Route>
					<Route path='/scholar/certificate/:year/:userID'>
						<Home state={'scholarCertificate'} />
					</Route>
					<Route path='/scholar/volunteer/:year/:userID'>
						<Home state={'volunteerCertificate'} />
					</Route>
					<Route path='/scholar'>
						<Home state={'scholarPortal'} />
					</Route>
					<Route path='/resource'>
						<Home state={'resource'} />
					</Route>
					<Route path='/feedback'>
						<Home state={'feedback'} />
					</Route>
					<Route path='/edit'>
						<NewLearning editMode={true} />
					</Route>
					<Route path='/new'>
						<NewLearning editMode={false} />
					</Route>
					<Route path='/learning/:learningID'>
						<Learning />
					</Route>
					<Route path='/journey/:fullProfileURL/chapter/:withinChapterID'>
						<Profile />
					</Route>
					<Route path='/journey/:fullProfileURL/:profileSection'>
						<Profile />
					</Route>
					<Route path='/journey/:fullProfileURL'>
						<Profile />
					</Route>
					<Route path='/inbox'>
						<Inbox />
					</Route>
					<Route path='/terms'>
						<Terms />
					</Route>
					<Route path='/privacy'>
						<Privacy />
					</Route>
					<Route path='*'>
						<PageNotFound />
					</Route>
				</Switch>
			</UserContext.Provider>
		</LoginContext.Provider>
	);
}

export default App;
