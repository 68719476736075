import React from 'react';
import styles from './ReadCard.module.css';
import { GetSourceSvg } from 'components/DropdownInput';
import { getPlural } from 'utils';
import { Link } from 'react-router-dom';
import { HelpfulActiveSvg, InterestingActiveSvg } from 'icons';

function ReadCard({ learning }) {
	const { learningID, type = [], source, tag = '', title, numWords } = learning;
	const firstTag = tag.split(',')[0].trim();
	const footer = ` · ${numWords} word${getPlural(numWords)}`;

	return (
		<div className={styles.readCard}>
			<Link className={styles.link} to={`/learning/${learningID}`}>
				<div className={styles.top}>
					{firstTag && <div className={styles.tag}>{firstTag}</div>}
				</div>
				<div className={styles.bottom}>
					<h1 className={styles.title}>{title}</h1>
					<footer className={styles.footer}>
						<div className={styles.leftFooter}>
							{GetSourceSvg({ source: source, className: styles.svg })}
							<span>{footer}</span>
						</div>
						<IconGroup type={type} />
					</footer>
				</div>
			</Link>
		</div>
	);
}

function IconGroup({ type }) {
	const helpfulActive = type.includes('helpful');
	const interestingActive = type.includes('interesting');
	// if (!helpfulActive && !interestingActive) return null;

	return (
		<div className={styles.iconGroup}>
			{helpfulActive && <HelpfulActiveSvg className={styles.icon} />}
			{interestingActive && <InterestingActiveSvg className={styles.icon} />}
		</div>
	);
}

export default ReadCard;
