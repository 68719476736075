import React from 'react';
import styles from './DeleteChapter.module.css';
import CloseButton from '../CloseButton';
import PrimaryButton from 'components/Button';

function DeleteChapter({ modal, setIsOpenFalse, setChapters }) {
	const { title, id: learningID } = modal;
	const handleDelete = async () => {
		setChapters((items) =>
			items.filter((item) => item.learningID !== learningID)
		);
		setIsOpenFalse();
	};

	return (
		<div className={styles.deleteChapter}>
			<CloseButton onClick={setIsOpenFalse} />
			<h1 className={styles.title}>Delete Chapter Confirmation</h1>
			<p className={styles.text}>
				Are you sure you want to delete chapter <b>{title}</b>?
			</p>
			<div className={styles.buttonGroup}>
				<PrimaryButton
					primary={false}
					onClick={setIsOpenFalse}
					children={'No, hold on to it'}
				/>
				<PrimaryButton
					className={styles.deleteButton}
					onClick={handleDelete}
					children={'Yes, please delete it'}
				/>
			</div>
		</div>
	);
}

export default DeleteChapter;
