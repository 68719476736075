import React from 'react';
import Title from '../Title';
import Journey from './Journey';
import styles from './OrganizeLearnings.module.css';

function OrganizeLearnings(props) {
	return (
		<div>
			<div className={styles.header}>
				<Title>
					4. <span className='emoji'>📁</span>Organize learnings intuitively
				</Title>
				<p className={styles.p}>
					TipStory Journey lets you organize learnings intuitively with drag and
					drop. You can also right click to create chapters and group learnings
					by topics.
				</p>
			</div>
			<Journey {...props} />
		</div>
	);
}
export default OrganizeLearnings;
