import { DownSvg, InboxSvg, SignOutSvg, WriteSvg } from 'icons';
import React, { useRef, useState } from 'react';
import { useCloseDropdown } from 'utils';
import styles from './DropdownIcon.module.css';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function DropdownIcon() {
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });

	const handleSelectClick = () => setIsOpen((prev) => !prev);
	const closeDropdown = () => setIsOpen(false);

	return (
		<nav className={styles.nav} ref={node}>
			<SelectButton onClick={handleSelectClick} />
			{isOpen && <DropdownList closeDropdown={closeDropdown} />}
		</nav>
	);
}

function SelectButton({ onClick }) {
	return (
		<button type={'button'} className={styles.selectButton} onClick={onClick}>
			<DownSvg className={styles.svg} />
		</button>
	);
}

function DropdownList({ closeDropdown }) {
	const history = useHistory();
	const visitInbox = () => {
		history.push('/inbox');
		closeDropdown();
	};
	const visitNewLearning = () => {
		history.push('/new');
		closeDropdown();
	};
	const signOut = async () => {
		try {
			await Auth.signOut();
			closeDropdown();
		} catch (error) {
			console.log('Error signing out: ', error);
		}
	};
	return (
		<div className={styles.dropdownList}>
			<DropdownItem name={'Inbox'} svg={<InboxSvg />} onClick={visitInbox} />
			<DropdownItem
				name={'New Learning'}
				svg={<WriteSvg />}
				onClick={visitNewLearning}
			/>
			<div className={styles.divider} />
			<DropdownItem name={'Sign Out'} svg={<SignOutSvg />} onClick={signOut} />
		</div>
	);
}

function DropdownItem({ name, svg, onClick }) {
	return (
		<button type={'button'} className={styles.dropdownItem} onClick={onClick}>
			<div className={styles.svg}>{svg}</div>
			<span className={styles.dropdownText}>{name}</span>
		</button>
	);
}

export default DropdownIcon;
