import React from 'react';
import styles from './LayoutHeader.module.css';
import LeftNav from './LeftNav';
import RightNav from './RightNav';

function LayoutHeader() {
	return (
		<header className={styles.header}>
			<nav className={styles.nav}>
				<LeftNav />
				<RightNav />
			</nav>
		</header>
	);
}

export default LayoutHeader;
