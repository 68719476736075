import React from 'react';
import styles from './Breadcomb.module.css';
import { RightSvg } from 'icons';
import { Fragment } from 'react';
import classNames from 'classnames';

function BreadcombButton({ id, text, onClick }) {
	return (
		<button type='button' className={styles.button} onClick={() => onClick(id)}>
			{text}
		</button>
	);
}

function Right() {
	return (
		<div className={styles.svgDiv}>
			<RightSvg className={styles.svgIcon} />
		</div>
	);
}

function Breadcomb({ list, onClick, className }) {
	return (
		<nav className={classNames(styles.nav, { [className]: !!className })}>
			{list.map((item, idx) => {
				return (
					<Fragment key={item.id}>
						{idx > 0 && <Right />}
						<BreadcombButton id={item.id} text={item.text} onClick={onClick} />
					</Fragment>
				);
			})}
		</nav>
	);
}

export default Breadcomb;
