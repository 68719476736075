import React from 'react';
import styles from './Landing.module.css';
import StudentGroupImg from './StudentGroup.png';
import JoinUsGif from './JoinUs.gif';
import PrimaryButton from 'components/Button';
import { useHistory } from 'react-router';
import WhatStudentsDo from './WhatStudentsDo';
import ExamplesLearnings from './ExamplesLearnings';
import TipStoryScholar from './TipStoryScholar';
import Footer from './Footer';

function Landing() {
	return (
		<main className={styles.main}>
			<div className={styles.landing}>
				<TopLanding />
				<WhatStudentsDo />
				<ExamplesLearnings />
				<TipStoryScholar />
				<Footer />
			</div>
		</main>
	);
}

function TopLanding() {
	const history = useHistory();
	const visitStartReading = () => history.push('/popular');
	const visitStartSharing = () => history.push('/new');

	return (
		<section className={styles.topLanding}>
			<div className={styles.imgDiv}>
				<img src={JoinUsGif} className={styles.joinUsGif} alt={'Join Us'} />
				<img
					src={StudentGroupImg}
					className={styles.studentGroupImg}
					alt={'Students learning in a group'}
				/>
			</div>
			<div className={styles.textDiv}>
				<div className={styles.headline}>
					Collective Learning <br /> Community For Students
				</div>
				<div className={styles.subheadline}>
					Read and share learnings to help and support each other🤝
				</div>
				<div className={styles.buttonGroup}>
					<PrimaryButton
						className={styles.buttonLeft}
						children={'📖 Start Reading'}
						onClick={visitStartReading}
					/>
					<PrimaryButton
						className={styles.buttonRight}
						children={'✏️ Start Sharing'}
						onClick={visitStartSharing}
					/>
				</div>
			</div>
		</section>
	);
}

export default Landing;
