import React, { useState, useRef } from 'react';
import styles from './Button.module.css';
import classNames from 'classnames';
import { useCloseDropdown } from 'utils';

// Primary Button
function Button({
	children = '',
	loading = false,
	disable = false,
	className = '',
	primary = true,
	...props
}) {
	const btn = primary ? styles.btnPrimary : styles.btnNormal;
	const btnDisable = primary
		? styles.btnDisablePrimary
		: styles.btnDisableNormal;
	return (
		<button
			type={'button'}
			className={classNames(btn, {
				[btnDisable]: disable,
				[styles.btnLoading]: loading,
				[className]: !!className,
			})}
			{...props}
		>
			{children}
		</button>
	);
}

export function TextButton({ children = '', className = '', ...props }) {
	return (
		<button
			type={'button'}
			className={classNames(styles.btnText, {
				[className]: !!className,
			})}
			{...props}
		>
			{children}
		</button>
	);
}

export function SvgButton({
	children = '',
	disable = false,
	className = '',
	...props
}) {
	return (
		<button
			type={'button'}
			className={classNames(styles.btnSvg, {
				[styles.btnSvgDisable]: disable,
				[className]: !!className,
			})}
			{...props}
		>
			{children}
		</button>
	);
}

// list: array of objects contains src, text, onClick
export function DropdownButton({ list = [], children = null, ...props }) {
	const [isOpen, setIsOpen] = useState(false);
	const node = useRef();
	useCloseDropdown({ node, isOpen, setIsOpen });

	const listLength = list.length;

	return (
		<nav className={styles.dropdown} ref={node}>
			<button
				type='button'
				className={classNames(styles.dropdownBtnIcon, {
					[styles.dropdownBtnIconOpen]: isOpen,
				})}
				onClick={() => setIsOpen((prev) => !prev)}
				{...props}
			>
				{children}
			</button>
			<div
				className={classNames(styles.dropdownList, {
					[styles.dropdownDisplay]: isOpen,
				})}
			>
				{list.map((item, idx) => {
					const onClick = () => {
						setIsOpen(false);
						item.onClick();
					};
					return (
						<React.Fragment key={item.text}>
							<button
								type='button'
								className={styles.dropdownItem}
								onClick={onClick}
							>
								<div className={styles.dropdownSvg}>{item.svg}</div>
								<span className={styles.dropdownText}>{item.text}</span>
							</button>
							{idx !== listLength - 1 && <div className={styles.divider} />}
						</React.Fragment>
					);
				})}
			</div>
		</nav>
	);
}

export function FollowButton({ followed = false, ...props }) {
	const { className } = props;
	if (!followed) return <Button {...props}>Follow</Button>;
	return (
		<Button
			primary={false}
			{...props}
			className={classNames(styles.followingBtn, { [className]: !!className })}
		>
			Following
		</Button>
	);
}

export default Button;
