import { useSetScrollToBottom } from 'components/Home/hooks';
import React, { useState } from 'react';
import CommentPanel from './CommentPanel';
import { useGetCommentsByReceiverID } from './hooks';
import styles from './Inbox.module.css';

function Inbox() {
	const [comments, setComments] = useState([]);
	const [error, setError] = useState('');

	// Hook for infinite scroll refetch
	const [isBottom, setIsBottom] = useState(false);
	useSetScrollToBottom({ setIsBottom });

	// Hook to get inbox comments
	useGetCommentsByReceiverID({ setComments, setError, isBottom, setIsBottom });

	return (
		<div className={styles.inbox}>
			<div className={styles.main}>
				{error && <ErrorMsg error={error} />}
				{comments.map((comment) => (
					<CommentPanel comment={comment} key={comment.commentID} />
				))}
			</div>
		</div>
	);
}

function ErrorMsg({ error }) {
	return (
		<div className={styles.error}>
			<h1 className={styles.errorH1}>{error}</h1>
		</div>
	);
}

export default Inbox;
