import React, { Fragment } from 'react';
import styles from './Breadcomb.module.css';
import { RightSvg } from 'icons';
import { useParams } from 'react-router-dom';
import { getChapterPathArray } from 'utils';
import BreadcombTab from './BreadcombTab';

function Breadcomb({ profile, setJourney }) {
	const { withinChapterID = 'home' } = useParams();
	const { chapterOrder, chapterName } = profile;
	const breadcombList = getBreadcombList(
		chapterOrder,
		chapterName,
		withinChapterID
	);

	return (
		<nav className={styles.breadcomb}>
			{breadcombList.map((item, idx) => {
				return (
					<Fragment key={item.chapterID}>
						{idx > 0 && <RightArrow />}
						<BreadcombTab
							chapterID={item.chapterID}
							name={item.name}
							setJourney={setJourney}
						/>
					</Fragment>
				);
			})}
		</nav>
	);
}

function RightArrow() {
	return (
		<div className={styles.rightArrow}>
			<RightSvg className={styles.rightArrowSvg} />
		</div>
	);
}

// Return an array that is used to generate breadcomb
const getBreadcombList = (chapterOrder, chapterName, withinChapterID) => {
	const chapterPathArray = getChapterPathArray(chapterOrder, withinChapterID);
	let chapterNameObj = {};
	if (chapterName) {
		try {
			chapterNameObj = JSON.parse(chapterName);
		} catch (error) {
			console.log('Error parsing chapterName: ', error);
		}
	}
	const breadcombList = chapterPathArray.map((chapterID) => {
		let name;
		if (chapterID === 'home') name = 'Home';
		else name = chapterNameObj[chapterID] || 'Untitled Chapter';
		return { chapterID, name };
	});
	return breadcombList;
};

export default Breadcomb;
