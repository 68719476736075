import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './Input.module.css';

function Input({ value, setValue, className, isOpen, ...props }) {
	const inputRef = useRef(null);
	const handleOnChange = (event) => {
		const value = event.target.value;
		setValue(value);
	};

	// Autofocus
	useEffect(() => {
		if (isOpen && inputRef.current) {
			setTimeout(() => {
				inputRef.current.focus();
			}, 200);
		}
	}, [isOpen]);

	return (
		<input
			ref={inputRef}
			type='text'
			value={value}
			onChange={handleOnChange}
			className={classNames(styles.input, { [className]: !!className })}
			{...props}
		/>
	);
}

export default Input;
