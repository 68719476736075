// useCreateUser.js
export const queryGetUser = /* GraphQL */ `
	query GetUser($userID: String!) {
		getUser(userID: $userID) {
			userID
			profileURL
			profileURLIdx
			firstName
			lastName
			headline
			profileImg
			instagram
			pinLearning
			chapterOrder
			learningOrder
			chapterName
			numLearning
			numReadMark
			numHelpfulMark
			numInterestingMark
			numReadReceive
			numHelpfulReceive
			numInterestingReceive
			numFollowing
			numFollower
			scholar
			scholarStatus
			scholarMeta
			volunteerHour
			# createdAt
			# updatedAt
		}
	}
`;
export const queryGetLastProfileURLIdx = /* GraphQL */ `
	query GetUserByProfileUrl($profileURL: String) {
		getUserByProfileURL(
			profileURL: $profileURL
			sortDirection: DESC
			limit: 1
		) {
			items {
				profileURLIdx
			}
		}
	}
`;

// useGetScholar.js
export const queryGetScholar = /* GraphQL */ `
	query GetUser($userID: String!) {
		getUser(userID: $userID) {
			userID
			profileURL
			profileURLIdx
			firstName
			lastName
			scholar
			scholarStatus
			scholarMeta
		}
	}
`;

// Profile.js
export const queryGetUserByProfileUrl = /* GraphQL */ `
	query GetUserByProfileUrl(
		$profileURL: String
		$profileURLIdx: Int
		$visitorUserID: String
		$isLogin: Boolean!
	) {
		getUserByProfileURL(
			profileURL: $profileURL
			profileURLIdx: { eq: $profileURLIdx }
		) {
			items {
				userID
				profileURL
				profileURLIdx
				firstName
				lastName
				headline
				profileImg
				instagram
				pinLearning
				chapterOrder
				learningOrder
				chapterName
				numLearning
				numReadMark
				numHelpfulMark
				numInterestingMark
				numReadReceive
				numHelpfulReceive
				numInterestingReceive
				numFollowing
				numFollower
				scholar
				scholarMeta
				# createdAt
				# updatedAt
				connectFollow(userID: { eq: $visitorUserID }) @include(if: $isLogin) {
					items {
						userID
					}
				}
			}
		}
	}
`;

// Home.js
export const queryGetLearningsByPopular = /* GraphQL */ `
	query GetLearningByPopular(
		$dailyPopularScore: ModelIntKeyConditionInput
		$limit: Int
		$nextToken: String
	) {
		getLearningByPopular(
			type: "learning"
			dailyPopularScore: $dailyPopularScore
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				learningID
				source
				linkInfo
				tag
				title
				description
				learningBrief
				userID
				numWords
				type
				createdAt
				numHelpful
				numInteresting
				numComment
				connectUser {
					firstName
					lastName
					headline
					profileImg
				}
			}
			nextToken
		}
	}
`;

export const queryGetLearningsByFollowing = /* GraphQL */ `
	query GetFollowingByUserId(
		$userID: String
		$limit: Int
		$nextToken: String
		$learningLimit: Int
	) {
		getFollowingByUserID(
			userID: $userID
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				connectFollowUser {
					userID
					profileURL
					profileURLIdx
					firstName
					lastName
					headline
					profileImg
					connectLearning(
						filter: { type: { eq: "learning" } }
						sortDirection: DESC
						limit: $learningLimit
					) {
						items {
							learningID
							source
							linkInfo
							tag
							title
							description
							learningBrief
							numWords
							type
							createdAt
							numHelpful
							numInteresting
							numComment
						}
					}
				}
			}
			nextToken
		}
	}
`;

export const queryGetTagsByDate = /* GraphQL */ `
	query GetTagByDate($type: String, $limit: Int, $nextToken: String) {
		getTagByDate(
			type: $type
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				tag
			}
			nextToken
		}
	}
`;

export const queryGetLearningsByLatest = /* GraphQL */ `
	query GetLearningByLatest($limit: Int, $nextToken: String) {
		getLearningByLatest(
			type: "learning"
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				learningID
				source
				linkInfo
				tag
				title
				description
				learningBrief
				userID
				numWords
				type
				createdAt
				numHelpful
				numInteresting
				numComment
				connectUser {
					firstName
					lastName
					headline
					profileImg
				}
			}
			nextToken
		}
	}
`;

export const queryGetLearningsByTag = /* GraphQL */ `
	query GetTagByTagByDate($tag: String, $limit: Int, $nextToken: String) {
		getTagByTagByDate(
			tag: $tag
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				connectLearning {
					learningID
					source
					linkInfo
					tag
					title
					description
					learningBrief
					userID
					numWords
					type
					createdAt
					numHelpful
					numInteresting
					numComment
					connectUser {
						firstName
						lastName
						headline
						profileImg
					}
				}
			}
			nextToken
		}
	}
`;

// Learning.js
export const queryGetLearningAndUser = /* GraphQL */ `
	query GetLearning(
		$learningID: String!
		$visitorUserID: String
		$isLogin: Boolean!
	) {
		getLearning(learningID: $learningID) {
			learningID
			source
			linkInfo
			tag
			title
			description
			learning
			userID
			withinChapterID
			numWords
			type
			createdAt
			numHelpful
			numInteresting
			numComment
			dailyPopularLastReset
			updatedAt
			connectUser {
				userID
				profileURL
				profileURLIdx
				firstName
				lastName
				headline
				profileImg
				chapterOrder
				learningOrder
				chapterName
				numLearning
				numReadMark
				numHelpfulMark
				numInterestingMark
				numReadReceive
				numHelpfulReceive
				numInterestingReceive
				numFollowing
				numFollower
				scholar
				scholarMeta
				connectFollow(userID: { eq: $visitorUserID }) @include(if: $isLogin) {
					items {
						userID
					}
				}
			}
			connectSaveLearning {
				learningID
				source
				linkInfo
				tag
				title
				description
				learning
				userID
				withinChapterID
				numWords
				type
				createdAt
				numHelpful
				numInteresting
				numComment
				dailyPopularLastReset
				saveLearningID
				updatedAt
				connectUser {
					userID
					profileURL
					profileURLIdx
					firstName
					lastName
					headline
					profileImg
					chapterOrder
					learningOrder
					chapterName
					numLearning
					numReadMark
					numHelpfulMark
					numInterestingMark
					numReadReceive
					numHelpfulReceive
					numInterestingReceive
					numFollowing
					numFollower
					scholar
					scholarMeta
					connectFollow(userID: { eq: $visitorUserID }) @include(if: $isLogin) {
						items {
							userID
						}
					}
				}
			}
		}
	}
`;

// Learning.js | Profile.js
export const queryGetLearningsAndReadsByChapter = /* GraphQL */ `
	query GetLearningByChapter(
		$userID: String
		$withinChapterID: String
		$sortDirection: ModelSortDirection
		$filter: ModelLearningFilterInput
		# $limit: Int
		$nextToken: String
		$visitorUserID: String
		$isLogin: Boolean!
	) {
		getLearningByChapter(
			userID: $userID
			withinChapterID: { eq: $withinChapterID }
			sortDirection: $sortDirection
			filter: $filter
			limit: 200
			nextToken: $nextToken
		) {
			items {
				learningID
				source
				tag
				title
				userID
				numWords
				type
				createdAt
				numHelpful
				numInteresting
				numComment
				saveLearningID
				# visitUser's read status
				connectRead(userIDType: { beginsWith: { userID: $visitorUserID } })
					@include(if: $isLogin) {
					items {
						type
					}
				}
				connectSaveLearning {
					learningID
					source
					tag
					title
					userID
					numWords
					type
					createdAt
					numHelpful
					numInteresting
					numComment
					connectRead(userIDType: { beginsWith: { userID: $visitorUserID } })
						@include(if: $isLogin) {
						items {
							type
						}
					}
				}
			}
			nextToken
		}
	}
`;

export const queryGetComments = /* GraphQL */ `
	query ListComments(
		$withinCommentID: String
		$createdAtUserID: ModelCommentPrimaryCompositeKeyConditionInput
		$filter: ModelCommentFilterInput
		$limit: Int
		$nextToken: String
	) {
		listComments(
			withinCommentID: $withinCommentID
			createdAtUserID: $createdAtUserID
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			sortDirection: DESC
		) {
			items {
				withinCommentID
				createdAt
				userID
				commentID
				comment
				numReply
				updatedAt
				connectUser {
					profileURL
					profileURLIdx
					firstName
					lastName
					profileImg
				}
				connectComment {
					items {
						withinCommentID
						createdAt
						userID
						commentID
						comment
						numReply
						updatedAt
						connectUser {
							profileURL
							profileURLIdx
							firstName
							lastName
							profileImg
						}
					}
				}
			}
			nextToken
		}
	}
`;

// ContextMenuModal.js
export const queryGetLearningsByChapter = /* GraphQL */ `
	query GetLearningByChapter(
		$userID: String
		$withinChapterID: ModelStringKeyConditionInput
	) {
		getLearningByChapter(userID: $userID, withinChapterID: $withinChapterID) {
			items {
				learningID
			}
		}
	}
`;

// DeleteLearning.js
export const queryGetTagsByLearning = /* GraphQL */ `
	query ListTags($learningID: String) {
		listTags(learningID: $learningID) {
			items {
				learningID
				tag
			}
			nextToken
		}
	}
`;

// useGetEditLearning.js
export const queryGetLearning = /* GraphQL */ `
	query GetLearning($learningID: String!) {
		getLearning(learningID: $learningID) {
			learningID
			source
			linkInfo
			tag
			title
			description
			learning
			userID # this is required to verify if user is the owner
			withinChapterID
			createdAt # show created day
		}
	}
`;

// useGetDrafts.js
export const queryGetDrafts = /* GraphQL */ `
	query GetLearningByChapter($userID: String) {
		getLearningByChapter(
			userID: $userID
			withinChapterID: { beginsWith: "draft" }
			limit: 100
		) {
			items {
				learningID
				source
				linkInfo
				tag
				title
				description
				learning
				learningBrief
				withinChapterID
				numWords
				updatedAt
			}
			nextToken
		}
	}
`;

// Profile.js
export const queryGetReadsByUserId = /* GraphQL */ `
	query GetReadByUserId($userID: String, $limit: Int, $nextToken: String) {
		getReadByUserID(
			userID: $userID
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				learningID
				# userID
				type
				# createdAt
				# updatedAt
				connectLearning {
					source
					tag
					title
					numWords
					createdAt
				}
			}
			nextToken
		}
	}
`;

export const queryGetFollowersByUserId = /* GraphQL */ `
	query GetFollowerByUserId(
		$followUserID: String
		$limit: Int
		$nextToken: String
		$visitorUserID: String
		$isLogin: Boolean!
	) {
		getFollowerByUserID(
			followUserID: $followUserID
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				connectUser {
					userID
					profileURL
					profileURLIdx
					firstName
					lastName
					headline
					profileImg
					numLearning
					numReadMark
					numReadReceive
					scholar
					scholarMeta
					connectFollow(userID: { eq: $visitorUserID }) @include(if: $isLogin) {
						items {
							userID
						}
					}
				}
			}
			nextToken
		}
	}
`;

export const queryGetFollowingByUserId = /* GraphQL */ `
	query GetFollowingByUserId(
		$userID: String
		$limit: Int
		$nextToken: String
		$visitorUserID: String
		$isLogin: Boolean!
	) {
		getFollowingByUserID(
			userID: $userID
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				connectFollowUser {
					userID
					profileURL
					profileURLIdx
					firstName
					lastName
					headline
					profileImg
					numLearning
					numReadMark
					numReadReceive
					scholar
					scholarMeta
					connectFollow(userID: { eq: $visitorUserID }) @include(if: $isLogin) {
						items {
							userID
						}
					}
				}
			}
			nextToken
		}
	}
`;

// useGetLearningsWrite.js
export const queryGetLearningByUserByDate = /* GraphQL */ `
	query GetLearningByUserByDate(
		$userID: String
		$createdAt: ModelStringKeyConditionInput
		$filter: ModelLearningFilterInput
		$limit: Int
		$nextToken: String
	) {
		getLearningByUserByDate(
			userID: $userID
			createdAt: $createdAt
			sortDirection: DESC
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				learningID
				source
				title
				numWords
				createdAt
			}
			nextToken
		}
	}
`;

// useGetLearningsRead.js
export const queryGetLearningsRead = /* GraphQL */ `
	query GetReadByUserId(
		$userID: String
		$createdAt: ModelStringKeyConditionInput
		$filter: ModelReadFilterInput
		$limit: Int
		$nextToken: String
	) {
		getReadByUserID(
			userID: $userID
			createdAt: $createdAt
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				learningID
				type
				createdAt
			}
			nextToken
		}
	}
`;

// useGetCommentsWrite.js
export const queryGetCommentByUserId = /* GraphQL */ `
	query GetCommentByUserId(
		$userID: String
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$limit: Int
		$nextToken: String
	) {
		getCommentByUserID(
			userID: $userID
			createdAt: $createdAt
			sortDirection: $sortDirection
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				commentID
			}
			nextToken
		}
	}
`;

// useGetCommentsByReceiverID.js
export const queryGetCommentByReceiverId = /* GraphQL */ `
	query GetCommentByReceiverId(
		$receiverID: String
		$createdAt: ModelStringKeyConditionInput
		$limit: Int
		$nextToken: String
	) {
		getCommentByReceiverID(
			receiverID: $receiverID
			createdAt: $createdAt
			sortDirection: DESC
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				withinCommentID
				createdAt
				userID
				commentID
				comment
				learningID
				connectUser {
					profileURL
					profileURLIdx
					firstName
					lastName
					profileImg
				}
				connectLearning {
					source
					title
				}
			}
			nextToken
		}
	}
`;
