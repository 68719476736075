import React, { useEffect, useRef, useState } from 'react';
import { getTagList, removeNewLineChars } from 'utils';
import styles from './Tag.module.css';
import update from 'immutability-helper';
import TextareaAutosize from 'react-textarea-autosize';

function Tag(props) {
	return (
		<div className={styles.tag}>
			<h1 className={styles.tagH1}>
				Tag <span className={styles.optional}> (optional)</span>
			</h1>
			<TagInput {...props} />
		</div>
	);
}

function TagInput({ newLearning, setNewLearning, editMode }) {
	const { tag, learningID } = newLearning;
	const [editOn, setEditOn] = useState(true);
	const firstFetchEdit = useRef(true);
	const lastOpenDraft = useRef('');

	// Set editOn to false in editMode if learning has tag
	useEffect(() => {
		if (editMode) {
			if (firstFetchEdit.current && tag) {
				setEditOn(false);
				firstFetchEdit.current = false;
			}
		} else {
			setEditOn(true);
			firstFetchEdit.current = true;
		}
	}, [tag, editMode, setEditOn]);

	// Set editOn to false when open a draft if learning has tag
	useEffect(() => {
		if (learningID.startsWith('draft')) {
			if (lastOpenDraft.current !== learningID) {
				if (tag) setEditOn(false);
				lastOpenDraft.current = learningID;
			}
		}
	}, [learningID, tag]);

	const handleOnChange = (event) => {
		const newTag = removeNewLineChars(event.target.value);
		setNewLearning((prev) => update(prev, { tag: { $set: newTag } }));
	};

	const handleOnBlur = () => {
		if (tag.trim() !== '') {
			const tagList = getTagList(tag);
			const trimTagList = tagList.map((s) => s.trim());
			const newTagList = trimTagList.map(
				(s) => s.charAt(0).toUpperCase() + s.substring(1)
			);
			const newTag = newTagList.join(', ');
			setNewLearning((prev) => update(prev, { tag: { $set: newTag } }));
			setEditOn(false);
		}
	};

	if (editOn)
		return (
			<TextareaAutosize
				className={styles.tagInput}
				autoComplete={'off'}
				autoFocus={true} //Set true, otherwise, turnOnEdit doesn't set focus
				placeholder={
					'Tags help people discover this learning (Separate tags with commas)'
				}
				maxLength={300}
				value={tag}
				minRows={1}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
			/>
		);

	const turnOnEdit = () => {
		setEditOn(true);
	};

	return (
		<button type={'button'} className={styles.tagView} onClick={turnOnEdit}>
			{getTagList(tag).map((item, idx) => (
				<span className={styles.tagSpan} key={idx}>
					{item}
				</span>
			))}
		</button>
	);
}

export default Tag;
