import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

function Meta({ profile }) {
	const { fullProfileURL } = useParams();
	const { firstName = '', lastName = '', headline = '' } = profile;
	const title = `${firstName} ${lastName}'s Learning Journey | TipStory`;
	const description = `${firstName} ${lastName} - ${headline}`;
	return (
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta
				property='og:url'
				content={`https://www.tipstory.org/journey/${fullProfileURL}`}
			/>
			<meta property='og:title' content={title} />
			<meta property='og:description' content={description} />
		</Helmet>
	);
}

export default Meta;
